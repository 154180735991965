import {
  Component,
  OnInit,
  ElementRef,
  Renderer2,
  ViewChild,
  NgZone,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { FlashMessagesService } from "angular2-flash-messages";
import { DatePipe } from "@angular/common";
import { Apollo } from "apollo-angular";
import { ActivatedRoute, Router } from "@angular/router";
import gql from "graphql-tag";
import { bootstrap4Mode } from "ngx-modialog/plugins/bootstrap";
import { saveAs } from "file-saver";
import * as moment from "moment-timezone";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/structure/services/common.service";
import { ConstantService } from "src/app/structure/constants/constant.service";
import { EventEmitterService } from "src/app/structure/services/event.emitter.services";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject, takeUntil, fromEvent } from "rxjs";
import { debounceTime, map, filter } from "rxjs/operators";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import * as CryptoJS from "crypto-js";

import {
	ClassicEditor,
	Essentials,
	CKFinderUploadAdapter,
	Autoformat,
	Bold,
	Italic,
  Underline,
	BlockQuote,
	CKFinder,
	EasyImage,
	Heading,
	Image,
	ImageCaption,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	Link,
	List,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	Table,
	TableToolbar,
	CloudServices,
  Undo
} from 'ckeditor5';
import { environment } from "src/environments/environment";
import { SafeInputPipe } from "src/app/structure/components/formatter/safeInput/safe-input.pipe";

/* require XLSX */
const XLSX = require("xlsx");
const htmlEntities = require("html-entities");

let table1;
declare let $: any;
declare let jQuery: any;
declare let swal: any;
declare let Chartist: any;
declare let loadJS: any;
declare let NProgress: any;

const allStates = gql`
  query allStates($isDefaultDeadlineForDdr: Boolean!) {
    allStates(condition: { isDefaultDeadlineForDdr: $isDefaultDeadlineForDdr }) {
      edges {
        node {
          name
          state
        }
      }
    }
  }
`;

/**
 * Query to fetch allDueDateReports
 *
 */
const allDueDateReports = gql`
  query allDueDateReports {
    allDueDateReports {
      totalCount
      edges {
        node {
          projectId
          projectType
          sp
          parent
          storeGroupName
          store
          title
          startDate
          shippedOn
          deliveredOn
          shipDel
          sD
          deadline
          lastAction
          manufacturer
          state
          report
          group
          days
          dms
          refDate
          yearMonth
          deadlineNotes
          approvalNotifyOn
          approvalEffectiveOn
          tagDisputed
          tagShipped
          disputeGroup
          isApproved
          isPastDue
          isClientProblem
          lastUpdated
          colorSpec
          ageSpec
          factoryInitialResponseOn
          deadlineLastActionOn
          createdAt
          isMgmtReviewRequested
          isMgmtReviewApproved
          reviewMsg
          approveMsg
          submissionInvDate
          firstUlInvoiceDate
          approvalFollowupDetailsCount
          isActiveFopc
        }
      }
    }
  }
`;

const updateDeadlineNoteProject = gql`
  mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
    updatePartsProject(
      input: { productionActionInput: $productionActionInput, projectId: $projectId }
    ) {
      id
    }
  }
`;

const updateApprovalFollowupDetails = gql`
  mutation updateApprovalFollowupDetails(
      $inProjectIdList: [BigInt]!,
      $inMailObjectId: String,
      $inMailSendBy: String) {
    updateApprovalFollowupDetails(
      input: { inProjectIdList: $inProjectIdList, inMailObjectId: $inMailObjectId, inMailSendBy: $inMailSendBy }
    ) {
      json
    }
  }
`;

const getDmStoreDetailsByProject = gql`
  query getDmStoreDetailsByProject($inProjectId: BigInt!) {
    getDmStoreDetailsByProject(inProjectId: $inProjectId)
  }
`;

const billingMgmtDetails = gql`
  query billingMgmtDetails($startDate: Date!, $endDate: Date!) {
    billingMgmtDetails(startDate: $startDate, endDate: $endDate)
  }
`;

const getContactsByProjectId = gql`
  query getContactsByProjectId($inProjectId: BigInt!) {
    getContactsByProjectId(inProjectId: $inProjectId)
  }
`;

const getContactsByProjectIdList = gql`
  query getContactsByProjectIdList($inProjectId: [BigInt]!) {
    getContactsByProjectIdList(inProjectId: $inProjectId)
  }
`;

const allAduUsers = gql`
  query allAduUsers {
    allAduUsers(condition: { isActive: true }) {
      edges {
        node {
          phone
          displayName
          shortName
          solveUserName
          userId
          isSalesPerson
          isDataPullUser
          isImportUser
          isMappingUser
          isPreAuditUser
          isPreAuditReviewer
          isBillingMgmtApprover
          isRetrieveRoUser
          isImportRoUser
          isAuditor
          isReviewer
          isFinalizeUser
          isPostAuditReviewer
          isShippingUser
          email
          groupIdList
        }
      }
    }
  }
`;

interface QueryResponse {
  allMagescenarios: any;
}

// run the plugin to work with version 4 of bootstrap
bootstrap4Mode();

@Component({
  selector: "app-duedate-report",
  templateUrl: "./duedate-report.component.html",
  styleUrls: ["duedate-report.component.scss"],
  // selector: "app-due-date-report",
  // templateUrl: "./due-date-report.component.html",
  // styleUrls: ["due-date-report.component.scss"],
  providers: [DatePipe, CommonService],
})
export class DuedateReportComponent implements OnInit, OnDestroy {
  private subscription$ = new Subject();
  loading: any = true;
  public statusList: any[] = [];
  public listener: any;
  public punchListCount = 0;
  public disputedCount = 0;
  public readyToBillCount = 0;
  public clientListCount = 0;
  public nonContingentCount = 0;
  public pastDueCount = 0;
  public notDueCount = 0;
  public manfRequestCount = 0;
  public errorCount = 0;
  private isScrolled = false;
  public reportList: any[] = [];
  public spList: any[] = [];
  public DUE_DATE_REPORTS_TEMP: any[] = [];
  public flag = false;
  public group: any[] = [];
  public fopc: any[] = [];
  public fopcList: any[] = [{id : true, itemName:'Yes'}];
  public sp: any[] = [];
  public parent: any[] = [];
  public yearMonth: any[] = [];
  public yearMonthList: any[] = [];
  public parentList: any[] = [];
  public reportCategoryList: any[] = [];
  public mainReportSelected: any[] = [];
  public isExpand = false;
  public punchListSelected = false;
  public disputedSelected = false;
  public readyToBillSelected = false;
  public clientSelected = false;
  public pastDueSelected = false;
  public notDueSelected = false;
  public nonContingentSelected = false;

  public manfRequestSelected = false;
  public billingMgmtDetailsList: any[] = [];
  public billingMgmtApproverList: any[] = [];
  public courtPersonGroupAssignee = [
    { id: "isDataPullUser", itemName: "Pull Data" },
    { id: "isImportUser", itemName: "Import" },
    { id: "isMappingUser", itemName: "Mapping" },
    { id: "isPreAuditUser", itemName: "PreAudit" },
    { id: "isPreAuditReviewer", itemName: "PreAudit Review" },
    { id: "isBillingMgmtApprover", itemName: "Billing Mgmt" },
    { id: "isRetrieveRoUser", itemName: "Retrieve RO" },
    { id: "isImportRoUser", itemName: "Import RO" },
    { id: "isAuditor", itemName: "Audit" },
    { id: "isReviewer", itemName: "Review" },
    { id: "isFinalizeUser", itemName: "Finalize" },
    { id: "isPostAuditReviewer", itemName: "PostAudit Review" },
    { id: "isShippingUser", itemName: "Ready-To-Ship" },
  ];
  public CDAUserListFiltered: any[] = [];
  public salesPersonListFiltered: any[] = [];

  public reviewerList: any[] = [];
  public userList: any[] = [];
  public currentUserIsProduction = false;
  public currentUserIsSalesPerson = false;
  public currentUserIsCDAPerson = false;
  public currentUserIsImportPerson = false;
  public currentUserIsProjectAdmin = false;
  public inCreatedBy = "";
  public inCreatedByName = "";
  public displayAdminActions = false;
  public approvalNoticeFollowUpSectionCollapsed = false;
  public approvalNoticeFollowUpSectionCcCollapsed = false;
  public mailSentStatus = 1;
  public displayProgressBar = false;
  public progressMessage = "In Progress ...";
  public showSpinner = false;
  public editDeadlineNoteFlag = false;
  public enableDeadlineNoteEditFlag = true;
  public editEmailSubjectFlag = false;
  public editEmailSubject = "";

  public oldDeadlineNote = "";
  public editDeadlineNoteFormFlag = false;
  public enableDeadlineNoteFormEditFlag = false;


  public billingProcessUsersOrginal: any[] = [];
  public billingProcessUsers: any[] = [];
  public billingProcessUserList: any[] = [];

  public billingProcessUserAllCategoryList: any[] = [];

  public billingProcessUserCategory1List: any[] = [];
  public billingProcessUserCategory2List: any[] = [];
  public billingProcessUserCategory3List: any[] = [];
  public billingProcessUserCategory4List: any[] = [];
  public billingProcessUserCategory5List: any[] = [];

  public billingEmailIdList: any[] = [];
  public billingEmailIdListCc: any[] = [];

  public billingCategory1EmailIdList: any[] = [];
  public billingCategory2EmailIdList: any[] = [];
  public billingCategory3EmailIdList: any[] = [];
  public billingCategory4EmailIdList: any[] = [];
  public billingCategory5EmailIdList: any[] = [];

  public customBillingEmailList: any[] = [];
  public customBillingEmailListCc: any[] = [];

  public billingLoading = false;
  public checked = false;
  public portalSelectedStList: string[] = [];
  public portalSelectedList: any[] = [];
  public templateId = 'emailTemplate2021';
  public errorSelected = false;
  public selectedReportStr = "";
  public count = 0;
  public currentTime: any;
  public downloadTime: any;
  public searchFilterKey: any = "";
  public downloading = false;
  public dueDateReportList: any[] = [];
  public selectedDueDateReports: any[] = [];
  public filteredDueDateReports: any[] = [];
  public selectAllReportFlag = false;
  public searchFilterKeyTrim = "";
  public searchKeyEnable = false;
  public selectAllSpFlag = false;
  public statesList: any[] = [];
  public dmDetails: any = null;
  public dateInput: any = {
    start: null,
    end: null,
  };

  public dateRangePickerOptions = {
    startDate: this.dateInput.start ? this.dateInput.start : moment(),
    endDate: this.dateInput.end ? this.dateInput.end : moment(),
    showDropdowns: true,
    autoApply: true,
    opens: "left",
  };
  width = document.documentElement.clientWidth;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "itemName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  dropdownSettingsGroup: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "itemName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true,
    enableCheckAll: true,
  };
  singleDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: "id",
    textField: "itemName",
    allowSearchFilter: true,
  };
  dropdownSettingsShowLimitEmail: IDropdownSettings = {
    singleSelection: false,
    idField: "id",
    textField: "itemName",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    allowSearchFilter: true,
    enableCheckAll: true,
    itemsShowLimit: 1,
  };

  public editDeadlineNoteForm!: FormGroup;
  public mgmtReportForm!: FormGroup;
  public approvalNoticeFollowUpForm!: FormGroup;
  public message = ``;
  public Editor: any = ClassicEditor;
  public editorConfig: any = {
    toolbar: {
			items: [
        'heading',
				'|',
				'bold',
				'italic',
				'underline',
				'bulletedList',
				'numberedList',
				'|',
				'blockQuote',
				'insertTable',
				'undo',
				'redo'
			]
		},
		image: {
			toolbar: [
				'imageStyle:block',
				'imageStyle:side',
				'|',
				'imageTextAlternative'
			]
		},
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells'
			],
      tableProperties: {
        // The default styles for tables in the editor.
        // They should be synchronized with the content styles.
        defaultProperties: {
            borderStyle: 'dashed',
            borderColor: 'hsl(90, 75%, 60%)',
            borderWidth: '3px',
            alignment: 'left',
            width: '550px',
            height: '450px'
        },
        // The default styles for table cells in the editor.
        // They should be synchronized with the content styles.
        tableCellProperties: {
            defaultProperties: {
                horizontalAlignment: 'center',
                verticalAlignment: 'bottom',
                padding: '10px'
            }
        }
      }
		},
		language: 'en',
    plugins: [
      Undo,
      Autoformat,
      BlockQuote,
      Bold,
      Underline,
      CKFinder,
      CloudServices,
      EasyImage,
      Essentials,
      Heading,
      Image,
      ImageCaption,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      Italic,
      Link,
      List,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      Table,
      TableToolbar,
      CKFinderUploadAdapter
    ],
    // licenseKey: '<YOUR_LICENSE_KEY>',
    // mention: {
    //     Mention configuration
    // }
  }
  public selectedProjectid = null;
  public showSpinnerButton = false;
  public showSpinnerButtonEdit = false;
  public defaultFilterType = null;
  public rowDataDueDateReport: any[] = [];
  public deadlineNoteView: any;
  public summaryData: any;
  public infoData: any;
  public selectAllClicked = false;
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    public constantService: ConstantService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private router: Router,
    private flashMessagesService: FlashMessagesService,
    private EventEmitterService: EventEmitterService,
    public commonService: CommonService,
    private zone: NgZone,
    private safeInput: SafeInputPipe,
    private _Activatedroute: ActivatedRoute
  ) {
    /**
     * Resize window event
     *
     */
    const $resizeEvent = fromEvent(window, "resize")
      .pipe(
        map(() => {
          return document.documentElement.clientWidth;
        })
      )
      .pipe(debounceTime(200));

    $resizeEvent.subscribe((data: any) => {
      this.width = data;
      $("#dueDateReportTable.dataTable thead").css("width", this.width - 127 + "px");
      setTimeout(() => {
        $("#dueDateHeader").width($("#duedateMain").width() - 35);
        $("#dueDateReportTable").DataTable().columns.adjust().draw(false);
      }, 500);
    });

    /**
     * Scroll window event
     *
     */
    window.onscroll = () => {
      this.zone.run(() => {
        $(".storeDetailsTable.fixedHeader-floating").css("display", "none");
        if (document.getElementById("dueDateHeader")) {
          let docs: any = document.getElementById("dueDateHeader")?.style.width;
          const w: any = document.getElementById("dueDateHeader")?.offsetWidth;
          if ($("body").height() - $("#dueDateHeader").height() >= window.innerHeight) {
            if (window.pageYOffset >= 35) {
              $("#dueDateHeader").addClass("header_scrolled2");
              $(".duedateReport.fixedHeader-floating").css("display", "block");
              docs = w + "px";
            } else {
              $("#dueDateHeader").removeClass("header_scrolled2");
              docs = w + "px";
            }
          }
          if (window.pageYOffset < 35 && $("#dueDateHeader").hasClass("header_scrolled2")) {
            $("#dueDateHeader").removeClass("header_scrolled2");
          }
        }
      });
    };
    this.editDeadlineNoteForm = new FormGroup({
      newDeadlineNote: new FormControl(""),
      deadlineNote: new FormControl("", Validators.required),
      deadlineNoteDate: new FormControl("", Validators.required),
    });
    this.mgmtReportForm = new FormGroup({
      fromDate: new FormControl("", Validators.required),
      toDate: new FormControl("", Validators.required),
    });
    this.approvalNoticeFollowUpForm = new FormGroup({
        hasMultipleProject: new FormControl("", Validators.required),
        from: new FormControl("", Validators.required),
        to: new FormControl(""),
        cc: new FormControl(""),
        projectId: new FormControl("", Validators.required),
        projectType: new FormControl(""),
        storeName: new FormControl(""),
        groupName: new FormControl(""),
        deadlineDate: new FormControl(""),
        deadlineNotes: new FormControl("", Validators.required),
        manufacturer: new FormControl(""),
        approvalFollowupDetailsCount: new FormControl("", Validators.required),
        subject: new FormControl("", Validators.required),
        editEmailSubject: new FormControl(""),
        message: new FormControl("", Validators.required),
        category1Email: new FormControl(""),
        category2Email: new FormControl(""),
        category3Email: new FormControl(""),
        category4Email: new FormControl(""),
        category5Email: new FormControl(""),
        customEmail: new FormControl(""),
    });
    
  }

  // tslint:disable-next-line:member-ordering
  // @ViewChild(DaterangePickerComponent) picker: DaterangePickerComponent;

  ngOnInit() {
    console.log("Azue group............test");
    /**
     *  check user group permissions and get user accessible menu list while loading the component
     */
    this.commonService.getGroups(() => {
      this.commonService.checkGroups((flag) => {
        console.log("Azue group............", flag);
        if (!flag) {
          return;
        }

        this.dueDateReportList = [];
        this.getAllStates(() => {
          this.init();
          this.dueDateReports(false);
        });
      });
    });

    loadJS("assets/js/dataTables.fixedHeader.min.js");
    loadJS("assets/js/natural.js");

    /**
     *  Clear the local storage value storageObject in the page load
     *  Which is setted on list RO page and Store searcher page
     */
    localStorage.removeItem("storageObject");

    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: "hover",
      });
    });
    this.defaultFilterType = null;
    const routingFromDashBoard = this._Activatedroute.snapshot.params["filterType"];
    if (routingFromDashBoard) {
      this.defaultFilterType = routingFromDashBoard;
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    $(".duedateReport.fixedHeader-floating").dataTable().fnClearTable();
    $(".duedateReport.fixedHeader-floating").dataTable().fnDestroy();
    $(".duedateReport.fixedHeader-floating").css("display", "none");
    this.subscription$.next(void 0);
    this.subscription$.complete();
  }

  /**
   * Show key-value pair in more info
   *
   */
  setInfoData(val: any) {
    let lastUpdated = "";
    if (val.node["lastUpdated"]) {
      const d = moment(val.node["lastUpdated"]);
      lastUpdated = d.tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss");
    }
    let data = "";
    // data += "Project Title: " + val.node["title"] + "\n";
    // data += "Parent: " + val.node["parent"] + "\n";
    // data += "Ref Date: " + val.node["refDate"] + "\n";
    // data += "Year-Month: " + val.node["yearMonth"] + "\n";
    data +=
      "Submission Fee Invoice Date: " +
      (val.node["submissionInvDate"]
        ? moment(val.node["submissionInvDate"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "First UL Invoice Date: " +
      (val.node["firstUlInvoiceDate"]
        ? moment(val.node["firstUlInvoiceDate"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "Shipped On: " +
      (val.node["shippedOn"]
        ? moment(val.node["shippedOn"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "Delivered On: " +
      (val.node["deliveredOn"]
        ? moment(val.node["deliveredOn"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "Initial Approval Date: " +
      (val.node["factoryInitialResponseOn"]
        ? moment(val.node["factoryInitialResponseOn"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "Approval Date: " +
      (val.node["approvalNotifyOn"]
        ? moment(val.node["approvalNotifyOn"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    data +=
      "Effective on: " +
      (val.node["approvalEffectiveOn"]
        ? moment(val.node["approvalEffectiveOn"]).tz(moment.tz.guess()).format("MM-DD-YYYY")
        : "") +
      "\n";
    // data +=
    //   "Dispute Group: " +
    //   (val.node["disputeGroup"] ? val.node["disputeGroup"] : "") +
    //   "\n";
    // data += "Is Approved: " + val.node["isApproved"] + "\n";
    // data += "Is Past Due: " + val.node["isPastDue"] + "\n";
    // data += "Is Client Problem: " + val.node["isClientProblem"] + "\n";
    // data += "Last Updated: " + lastUpdated + "\n";

    // data += "Tag Shipped: " + val.node["tagShipped"] + "\n";
    // data += "Tag Disputed: " + val.node["tagDisputed"] + "\n";
    // data += "Age Spec: " + val.node["ageSpec"] + "\n";
    return data;
  }

  getCurrentUser() {
    const storedUser = localStorage.getItem("currentUser");
    const currentUser = storedUser ? JSON.parse(storedUser) : {};
    this.inCreatedBy =
      currentUser.userPrincipalName ?? currentUser.displayName ?? currentUser.userName ?? "";
    this.inCreatedByName = currentUser.displayName ? currentUser.displayName : "";
    const activityData = {
      activityName: "Get Current User From Local Storage",
      activityDescription: "Current Page: " + this.router.url,
      activityData: JSON.stringify({
        currentUser: currentUser,
        inCreatedBy: this.inCreatedBy,
        inCreatedByName: this.inCreatedByName
      }),
    };
    this.commonService.saveActivity("DueDate Report", activityData);
  }

  init() {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser")!)
      : "";
    if (currentUser) {
      this.inCreatedBy = currentUser.userPrincipalName;
      this.inCreatedByName = currentUser.displayName;
    } else {
      this.getCurrentUser();
    }

    const adminActionStatus: any = localStorage.getItem("adminAction");
    this.displayAdminActions = adminActionStatus === "true";
    this.EventEmitterService.toggleAdminAction.subscribe((data) => {
      this.displayAdminActions = data == "true";
      // this.dueDateReports(true);

    });
    this.getAduUserList(() => {
      console.log("CDAUserListFiltered====>>>", this.CDAUserListFiltered);
      console.log("salesPersonListFiltered====>>>", this.salesPersonListFiltered);
      this.billingMgmtApproverList = this.getUserLisForStage("Billing Mgmt");
      console.log("billingMgmtApproverList====>>>test", this.billingMgmtApproverList);
    });
  }
  /**
   * dueDateReports list is getting by calling allDueDateReports query.
   */
  dueDateReports(refreshFlag: any) {
    if (!refreshFlag) {
      this.statusList = [];
      this.punchListCount = 0;
      this.disputedCount = 0;
      this.readyToBillCount = 0;
      this.clientListCount = 0;
      this.nonContingentCount = 0;
      this.pastDueCount = 0;
      this.notDueCount = 0;
      this.manfRequestCount = 0;

      this.errorCount = 0;
      this.isScrolled = false;
      this.reportList = [];
      this.spList = [];
      this.flag = false;
      this.group = [];
      this.sp = [];
      this.parent = [];
      this.yearMonth = [];
      this.yearMonthList = [];
      this.parentList = [];
      this.reportCategoryList = [];
      this.mainReportSelected = [];
      this.isExpand = false;
      this.punchListSelected = false;
      this.disputedSelected = false;
      this.readyToBillSelected = false;
      this.clientSelected = false;
      this.nonContingentSelected = false;
      this.pastDueSelected = false;
      this.notDueSelected = false;
      this.manfRequestSelected = false;

      this.errorSelected = false;
      this.selectedReportStr = "";
      this.searchFilterKey = "";
      this.downloading = false;
      this.selectedDueDateReports = [];
      this.filteredDueDateReports = [];
      this.selectAllReportFlag = false;
      this.searchFilterKeyTrim = "";
      this.searchKeyEnable = false;
      this.selectAllSpFlag = false;
    }
    this.DUE_DATE_REPORTS_TEMP = [];
    this.dueDateReportList = [];
    this.count = 0;
    const d = moment();
    this.currentTime = d.tz(moment.tz.guess()).format("MM/DD/YYYY HH:mm:ss");
    this.downloadTime = d.tz(moment.tz.guess()).format("YYYYMMDDHHmmss");
    this.loading = true;
    const allDueDateReportsList = this.apollo.use("portaldbClient").query({
      query: allDueDateReports,
      fetchPolicy: "network-only",
    });

    allDueDateReportsList.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        $.each(result["data"]["allDueDateReports"]["edges"], (key: any, val: any) => {
          let ReadyToBillAge: any = "";
          if (val.node["createdAt"]) {
            const todayDate = moment();
            const endDate = moment.utc(val.node["createdAt"]).local().format("YYYY-MM-DD");
            ReadyToBillAge = todayDate.diff(endDate, "days");
          }
          let isStarred = false;
          const filterState = this.statesList.filter(
            (item: any, index: number) => item.state == val.node["state"]
          );
          if (filterState.length > 0) {
            isStarred = true;
          }
          const obj = {
            id: val.node["projectId"],
            projectType: val.node["projectType"],
            store: val.node["store"],
            sp: val.node["sp"],
            parent: val.node["parent"],
            storeGroupName: val.node["storeGroupName"],
            title: val.node["title"],
            startDate: val.node["startDate"],
            shippedOn: val.node["shippedOn"],
            deliveredOn: val.node["deliveredOn"],
            shipDel: val.node["shipDel"],
            sD: val.node["sD"],
            deadline: val.node["deadline"],
            lastAction: val.node["lastAction"],
            manufacturer: val.node["manufacturer"],
            state: val.node["state"],
            report: val.node["report"],
            group: val.node["group"],
            dms: val.node["dms"],
            days: val.node["days"],
            isActiveFopc: val.node["isActiveFopc"] ? val.node["isActiveFopc"] : false,
            refDate: val.node["refDate"],
            yearMonth: val.node["yearMonth"],
            deadlineNotes: val.node["deadlineNotes"],
            formattedDeadlineNotes: val.node["deadlineNotes"]
              ? val.node["deadlineNotes"]
              : "",
            approvalNotifyOn: val.node["approvalNotifyOn"],
            approvalEffectiveOn: val.node["approvalEffectiveOn"],
            tagDisputed: val.node["tagDisputed"],
            tagShipped: val.node["tagShipped"],
            disputeGroup: val.node["disputeGroup"],
            isApproved: val.node["isApproved"],
            isPastDue: val.node["isPastDue"],
            isClientProblem: val.node["isClientProblem"],
            lastUpdated: val.node["lastUpdated"],
            colorSpec: val.node["colorSpec"],
            ageSpec: val.node["ageSpec"],
            factInitialResponseOn: val.node["factoryInitialResponseOn"],
            isMgmtReviewRequested: val.node["isMgmtReviewRequested"],
            isMgmtReviewApproved: val.node["isMgmtReviewApproved"],
            reviewMsg: val.node["reviewMsg"],
            approveMsg: val.node["approveMsg"],
            submissionInvDate: val.node["submissionInvDate"],
            firstUlInvoiceDate: val.node["firstUlInvoiceDate"],
            approvalFollowupDetailsCount: val.node["approvalFollowupDetailsCount"] <= 2 ? (val.node["approvalFollowupDetailsCount"]).toString(): '2',
            info: this.setInfoData(val),
            deadlineLastActionOn: val.node["deadlineLastActionOn"]
              ? this.transformDateFormat(val.node["deadlineLastActionOn"], "MM-DD-YYYY")
              : moment().format("MM-DD-YYYY"),
            isStarred: isStarred,
            // ageDisplayVal: val.node['createdAt'] ? moment.utc(val.node['createdAt']).local().format("MM-DD-YYYY") : '',
            ageDisplayVal: ReadyToBillAge,
            ReadyToBillAge: ReadyToBillAge,
          };
          this.dueDateReportList.push(obj);
          if (val.node.category && this.statusList.indexOf(val.node.category) === -1) {
            this.statusList.push(val.node.category);
          }
        });
        console.log("dueDateReportList========>>>>", this.dueDateReportList);
        if (this.filteredDueDateReports?.length) {
          const dueDateDataMap = new Map(this.dueDateReportList.map((obj: any) => [obj.id, obj]));
          const resData = this.filteredDueDateReports.map((parentObj: any) => {
            const matchingSubsetObj = dueDateDataMap.get(parentObj.id);
            if (matchingSubsetObj) {
              return { ...parentObj, ...matchingSubsetObj };
            } else {
              return { ...parentObj };
            }
          });
          this.filteredDueDateReports = resData;
        }
        const elm = this;
        $(function () {
          $("#category").select2({
            tags: true,
          });
          $("#category").on("change", (e: any) => {
            elm.group = $(e.target).val();
            elm.filterChange();
          });
          $("#sp").select2({
            tags: true,
          });
          $("#sp").on("change", (e: any) => {
            elm.sp = jQuery(e.target).val();
            elm.filterChange();
          });
          table1 = $("#dueDateReportTable").DataTable();
          table1.search("").draw();
          table1 = $("#dueDateReportTable").dataTable();
          table1.fnClearTable();
          table1 = $("#dueDateReportTable").DataTable({
            fixedHeader: {
              header: true,
              footer: true,
              headerOffset:
                $(".cat__top-bar").outerHeight() + $("#dueDateHeader").outerHeight() - 11,
            },
            responsive: true,
            destroy: true,
            paging: true,
            deferRender: true,
            ordering: true,
            info: true,
            filter: true,
            length: true,
            processing: true,
            order: [], // Initial no order.
            aaSorting: [],
            lengthMenu: [
              [-1, 500, 250, 100],
              ["All", 500, 250, 100],
            ],
            // initialization params as usual
            fnRowCallback: function (settings: any, aData: any) {
              const pagination = $(this)
                .closest(".dataTables_wrapper")
                .find(".dataTables_paginate");
              pagination.toggle(this.api().page.info().pages > 1);
              switch (aData[20]) {
                case "orange/50":
                  $("td:eq(3)", settings).css("background-color", "rgb(255, 165, 0, 0.3)");
                  $("td:eq(4)", settings).css("background-color", "rgb(255, 165, 0, 0.3)");
                  break;
                case "purple/50":
                  $("td:eq(3)", settings).css("background-color", "rgb(128, 0, 128, 0.3)");
                  $("td:eq(4)", settings).css("background-color", "rgb(128, 0, 128, 0.3)");
                  break;
                case "green":
                  $("td:eq(3)", settings).css("background-color", "rgb(153,255,153, 0.3)");
                  $("td:eq(4)", settings).css("background-color", "rgb(153,255,153, 0.3)");
                  break;
                case "red/50":
                  $("td:eq(3)", settings).css("background-color", "rgb(255, 0, 0, 0.3)");
                  $("td:eq(4)", settings).css("background-color", "rgb(255, 0, 0, 0.3)");
                  break;
                case "blue":
                  $("td:eq(3)", settings).css("background-color", "rgb(60, 60, 230, 0.3)");
                  $("td:eq(4)", settings).css("background-color", "rgb(60, 60, 230, 0.3)");
                  break;
                case "white":
                  $("td:eq(3)", settings).css("background-color", "white");
                  $("td:eq(4)", settings).css("background-color", "white");
                  break;
                case "orange":
                  $("td:eq(3)", settings).css("background-color", "rgb(255, 165, 0, 0.9)");
                  $("td:eq(4)", settings).css("background-color", "rgb(255, 165, 0, 0.9)");
                  break;
                case "darkgreen":
                  $("td:eq(3)", settings).css("background-color", "rgb(147, 225, 147)");
                  $("td:eq(4)", settings).css("background-color", "rgb(147, 225, 147)");
                  break;
                case "cyan":
                  $("td:eq(3)", settings).css("background-color", "rgb(142, 238, 238)");
                  $("td:eq(4)", settings).css("background-color", "rgb(142, 238, 238)");
                  break;
                default:
                  $("td:eq(3)", settings).css("background-color", aData[17].split("/")[0]);
                  $("td:eq(4)", settings).css("background-color", aData[17].split("/")[0]);
                  // console.log(aData[20].split('/')[0])
                  if (aData[20].split("/")[0] === "grey") {
                    $("td:eq(3)", settings).children(0).css("color", "#FFFFFF");
                    $("td:eq(4)", settings).children(0).css("color", "#FFFFFF");
                  }
                  break;
              }
              $("td:eq(0)", settings).css("width", "1%");
              $("td:eq(1)", settings).css("width", "4%");
              $("td:eq(2)", settings).css("width", "4%");
              $("td:eq(3)", settings).css("width", "4%");
              $("td:eq(4)", settings).css("width", "8%");
              $("td:eq(5)", settings).css("width", "6%");
              $("td:eq(6)", settings).css("width", "6%");
              $("td:eq(7)", settings).css("width", "4%");
              $("td:eq(8)", settings).css("width", "8%");
              $("td:eq(9)", settings).css("width", "3%");
              $("td:eq(10)", settings).css("width", "7%");
              $("td:eq(11)", settings).css("width", "3%");
              $("td:eq(12)", settings).css("width", "6%");
              $("td:eq(13)", settings).css("width", "6%");
              $("td:eq(14)", settings).css("width", "6%");
              $("td:eq(15)", settings).css("width", "5%");
              $("td:eq(16)", settings).css("width", "5%");
              $("td:eq(17)", settings).css("width", "18%");
              $("td:eq(18)", settings).css("width", "3%");
              $("td:eq(19)", settings).css("width", "3%");
              // console.log("aData[16]::::====== >>>>>>>>>> ", aData);
              if (aData[16] || aData[16] === 0) {
                if (aData[29] <= 2) {
                  $("td:eq(15)", settings).css("background-color", "rgb(61 142 20)");
                } else if (aData[29] > 2) {
                  $("td:eq(15)", settings).css("background-color", "rgb(255, 0, 0)");
                }
              }
              if (aData[26] === "green(30)-red(60)" && aData[1] !== null) {
                if (aData[1] <= 0) {
                  $("td:eq(1)", settings).css("background-color", "rgb(0, 150, 0)");
                } else if (aData[1] >= 1 && aData[1] <= 30) {
                  $("td:eq(1)", settings).css(
                    "background-color",
                    "rgb(" + aData[1] * 3 + ", 150, " + aData[1] * 3 + ")"
                  );
                } else if (aData[1] >= 31 && aData[1] <= 60) {
                  $("td:eq(1)", settings).css(
                    "background-color",
                    "rgb(240, " +
                      (90 - (aData[1] - 30) * 3) +
                      ", " +
                      (90 - (aData[1] - 30) * 3) +
                      ")"
                  );
                } else if (aData[1] > 60) {
                  $("td:eq(1)", settings).css("background-color", "rgb(240, 0, 0)");
                }
              } else if (aData[26] === "[inverse]white(30)-green(0)" && aData[1] !== null) {
                if (aData[1] > 30) {
                  $("td:eq(1)", settings).css("background-color", "rgb(255, 255, 255)");
                } else if (aData[1] >= 0 && aData[1] <= 30) {
                  $("td:eq(1)", settings).css(
                    "background-color",
                    "rgb(" + aData[1] * 3 + ", 150, " + aData[1] * 3 + ")"
                  );
                } else if (aData[1] < 0) {
                  $("td:eq(1)", settings).css("background-color", "rgb(0, 150, 0)");
                }
              } else if (aData[26] === "green(7)-red(30)" && aData[1] !== null) {
                if (aData[1] <= 0) {
                  $("td:eq(1)", settings).css("background-color", "rgb(0, 128, 0)");
                } else if (aData[1] > 0 && aData[1] <= 4) {
                  $("td:eq(1)", settings).css(
                    "background-color",
                    "rgb(" + aData[1] * 42 + ", " + Number(aData[1] * 21 + 128) + ", 0)"
                  );
                } else if (aData[1] >= 5 && aData[1] <= 9) {
                  $("td:eq(1)", settings).css(
                    "background-color",
                    "rgb(255, " + Number(85 + (9 - aData[1]) * 42) + ", 0)"
                  );
                } else if (aData[1] >= 10) {
                  $("td:eq(1)", settings).css("background-color", "rgb(255, 0, 0)");
                }
              }
            },
            autoWidth: false,
            columnDefs: [{ type: "numeric-comma", targets: "_all" }],
            columns: [
              {
                title:
                  '<input name="select_all" value="1" id="storeSelectAll" class="storeSelectAll" type="checkbox" />',
                width: "1%",
                className: "dt-head-center",
                searchable: false,
                orderable: false,
                render: function (data: string, type: any, rows: any, meta: any) {
                  const dataString = btoa(
                    JSON.stringify({
                      projectId:rows[25]
                    })
                  );
                  let checkedFlag = null;
                  if(elm.portalSelectedStList && elm.portalSelectedStList.indexOf(rows[32])!== -1) {
                    checkedFlag = true;
                  }
                  if (rows[7] && ((rows[7] == elm.constantService.NOT_DUE) || (rows[7] == elm.constantService.PAST_DUE)) ) {
                    return (
                      '<input type="checkbox" name="id[]" id="portal_store_' +
                      rows[32] +
                      '"  [checked]="'+checkedFlag+'" class="stores" value="' +
                      dataString +
                      '">'
                    );
                  } else {
                    return (
                      ''
                    );
                  }
                },
              },
              { title: "Age", width: "4%", className: "dt-head-center" },
              { title: "SP", width: "4%", className: "dt-head-center" },
              {
                title: "Parent",
                width: "4%",
                type: "natural-nohtml",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  const d = data;
                  // console.log('row[20]====>>>>>',row[20]);
                  if (data && data.length > 0 && row[20] && row[20].split("/")[0] === "grey") {
                    data =
                      '<span class="pointer gotoStoreOverview duedateTitle" data-toggle="tooltip" data-animation="false" data-placement="top" title="Go to Store Overview" ' +
                      'data-info="' +
                      row[25] +
                      '">' +
                      d +
                      "</span>";
                  } else if (data && data.length > 0) {
                    data =
                      '<span class="pointer gotoStoreOverview duedateTitle" data-toggle="tooltip" data-animation="false" data-placement="top" title="Go to Store Overview" ' +
                      'data-info="' +
                      row[25] +
                      '">' +
                      d +
                      "</span>";
                  }
                  return data;
                },
              },
              {
                title: "Project Title",
                width: "8%",
                type: "natural-nohtml",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  const d = data;
                  if (data && data.length > 0 && row[20] && row[20].split("/")[0] === "grey") {
                    data =
                      '<a href="https://secure.solve360.com/projectblog/' +
                      row[25] +
                      '" class="pointer duedateTitle" data-toggle="tooltip" data-animation="false" data-placement="top"  title="Go to Solve360" ' +
                      'target = "_blank"' +
                      ">" +
                      d +
                      "</a>";
                  } else if (data && data.length > 0) {
                    data =
                      '<a href="https://secure.solve360.com/projectblog/' +
                      row[25] +
                      '" class="pointer duedateTitle" data-toggle="tooltip" data-animation="false" data-placement="top"  title="Go to Solve360" ' +
                      'target = "_blank"' +
                      ">" +
                      d +
                      "</a>";
                  }
                  return data;
                },
              },
              {
                title: "MGMT Review",
                width: "8%",
                type: "natural-nohtml",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  const d = data;
                  // console.log("row[5]====>>>>>", row, row[5], row[30], row[31]);
                  data = "";
                  if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_lws &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_LWS_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No LWS - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Manufacturer Nuance - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Above 60% variance, Manufacturer Nuance - MGMT Requested." +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_MSG +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD,Above 60% variance - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_lws &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_LWS_MSG +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No LWS,Above 60% variance - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Manufacturer Nuance - MGMT Requested." +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_lws &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_LWS_MSG +
                      elm.constantService.BILLING_VARIENCE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No LWS, Base Rate Change - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Base Rate Change, Manufacturer Nuance - MGMT Requested." +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    !JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    // console.log(row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_MSG +
                      elm.constantService.BILLING_VARIENCE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Base Rate Change - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Base Rate Change, Manufacturer Nuance - MGMT Requested." +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Base Rate Change - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, Manufacturer Nuance - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change - MGMT Requested" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_brand_approved &&
                    row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Manufacturer Nuance - MGMT Review requested" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_lws &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_LWS_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No LWS - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    // console.log("MGMT Approved", row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    // console.log("MGMT Approved", row[5], row[30], row[31]);
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    JSON.parse(row[32]).is_brand_approved &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD, Above 60% variance, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_FSD_APPROVED_MSG +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD, Above 60% variance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_lws &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_NO_LWS_APPROVED_MSG +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No LWS,Above 60% variance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Above 60% variance, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Above 60% variance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_IS_NO_FSD_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD, Base Rate Change, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_IS_NO_FSD_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No FSD, Base Rate Change - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_lws &&
                    !JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_IS_NO_LWS_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "No LWS, Base Rate Change - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).is_brand_approved &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService
                        .GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Above 60% variance, Base Rate Change, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Above 60% variance, Base Rate Change - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    JSON.parse(row[32]).is_brand_approved &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Base Rate Change, Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Base Rate Change - MGMT Approved" +
                      "</span></a>";
                  } else if (row[32] && JSON.parse(row[32]).is_brand_approved && row[31]) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:green">' +
                      "Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Base Rate Change, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Base Rate Change" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).is_no_fsd &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_NO_FSD_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, No FSD, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    !JSON.parse(row[5]).is_brand_approved &&
                    JSON.parse(row[5]).is_no_fsd &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_NO_FSD_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, No FSD" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    JSON.parse(row[5]).is_no_lws &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_NO_LWS_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, No LWS" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_no_fsd &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.NO_FSD_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (row[5] && JSON.parse(row[5]).is_no_fsd && !row[30] && !row[31]) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "No FSD" +
                      "</span></a>";
                  } else if (row[5] && JSON.parse(row[5]).is_no_lws && !row[30] && !row[31]) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "No LWS" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_limit_exceeds &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "Above 60% variance" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).has_base_rate_difference &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "Base Rate Change" +
                      "</span></a>";
                  } else if (
                    row[5] &&
                    JSON.parse(row[5]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.IS_BRAND_APPROVED_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.NO_FSD_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "No FSD, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_no_fsd &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "No FSD" +
                      "</span></a>";
                  } else if (row[32] && JSON.parse(row[32]).is_no_lws && !row[30] && !row[31]) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "No LWS" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.GREATER_FORTY_PERCENTAGE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Above 60% variance, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_limit_exceeds &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "Above 60% variance" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer" data-toggle="tooltip" data-animation="false" data-placement="top"  title="' +
                      elm.constantService.BILLING_VARIENCE_BRAND_MSG +
                      '" ' +
                      '><span style="color:red">' +
                      "Base Rate Change, Manufacturer Nuance" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).has_base_rate_difference &&
                    !JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "Base Rate Change" +
                      "</span></a>";
                  } else if (
                    row[32] &&
                    JSON.parse(row[32]).is_brand_approved &&
                    !row[30] &&
                    !row[31]
                  ) {
                    data =
                      '<a href="javascript: void(0);" class="pointer"' +
                      '><span style="color:red">' +
                      "Manufacturer Nuance - MGMT Approved" +
                      "</span></a>";
                  }

                  return data;
                },
              },
              {
                title: "FOPC",
                width: "3%",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  let d = '';
                  if (data) {
                    d ='Yes';
                  }
                  return d;
                },
              },
              { title: "Report", width: "6%", className: "dt-head-center" },
              { title: "DMS", width: "4%", className: "dt-head-center" },
              {
                title: "Manufacturer",
                width: "8%",
                className: "dt-head-center",
              },
              {
                title: "State",
                width: "3%",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  let d = data;
                  if (row[28]) {
                    d =
                      d +
                      ' <span class="selected-starred-state" title="States that has no factory deadline in the statute" data-toggle="tooltip" data-animation="false" data-placement="left">*</span>';
                  }
                  return d;
                },
              },
              {
                title: "Project<br>Start Date",
                width: "7%",
                type: "us_date",
                className: "dt-head-center",
              },
              { title: "S/D", width: "3%", className: "dt-head-center" },
              {
                title: "Ship/Del. Date",
                width: "8%",
                type: "us_date",
                className: "dt-head-center",
              },
              {
                title: "Fact.<br>Deadline",
                width: "8%",
                type: "html-date-sort",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  let d = data;
                  if (row[28]) {
                    d =
                      d +
                      ' <span class="selected-starred-state" title="States that has no factory deadline in the statute" data-toggle="tooltip" data-animation="false" data-placement="left">*</span>';
                  }
                  return d;
                },
              },
              {
                title: "Last Action",
                width: "8%",
                type: "us_date",
                className: "dt-head-center",
              },
              {
                title: "Ready to Bill Age",
                width: "6%",
                className: "dt-head-center-align",
              },
              {
                title: "Deadline Notes",
                width: "16%",
                className: "dt-head-center",
                render: function (data: any, type: any, row: any) {
                  const d = data;
                  if (data && data.length > 50) {
                    data = data.split("↵")[0];
                    data =
                      data.substring(0, 50) +
                      "... " +
                      '<a href="javascript: void(0);" class="pointer" style="color: rgba(8, 135, 201, 0.9);"' +
                      ' data-toggle="tooltip" data-animation="false" data-placement="left" data-note="' +
                      d +
                      '" title="' +
                      d +
                      '">[Read more]</a>';
                  } else {
                    if (data && data.length > 0) {
                      data =
                        data.split("↵")[0] +
                        "... " +
                        '<a href="javascript: void(0);" class="pointer" style="color: rgba(8, 135, 201, 0.9);"' +
                        ' data-toggle="tooltip" data-animation="false" data-placement="left" data-note="' +
                        d +
                        '" title="' +
                        d +
                        '">[Read more]</a>';
                    }
                  }
                  let deadlineData: any = {
                    deadlineNotes: d.replace(/^\s+|\s+$/gm, ""),
                    projectId: row[25],
                    deadlineLastActionOn: row[27],
                  };
                  deadlineData = JSON.stringify(deadlineData);
                  // deadlineData = deadlineData.replace(/^\s+|\s+$/gm,'');
                  // console.log("deadlineDatadeadlineData", deadlineData);
                  deadlineData = deadlineData.replace(/"/g, "\\'");
                  const editIcon =
                    '<i aria-hidden="true" class="fa fa-edit edit-icon-deadline-note" data-info="' +
                    deadlineData +
                    '" ></i>';
                  data += editIcon;
                  return data;
                },
              },
              {
                title: " ",
                width: "3%",
                className: "dt-head-center",
                orderable: false,
                render: function (data: any, type: any, row: any) {
                  const d = data;
                  data =
                    '<a href="javascript: void(0);" data-toggle="tooltip" data-animation="false" data-placement="left" data-note="' +
                    d +
                    '" title="' +
                    d +
                    '">' +
                    '<i aria-hidden="true" class="fa fa-info-circle fa-lg infoIcon"  data-info="' +
                    d +
                    '" ></i></a>';
                  return data;
                },
              },
              {
                title: "Email",
                width: "3%",
                className: "dt-head-center",
                orderable: false,
                visible: ((elm.commonService.adminActionPermissionCheck() > elm.constantService.ADMIN_WEIGTAGE) || elm.currentUserIsProjectAdmin == true),
                render: function (data: any, type: any, row: any) {
                  // let d = (data == 0 ? 'First Follow-Up': (data == 1 ? 'Second Follow-Up': 'Third Follow-Up'));
                  let d = (data == 0 ? 'First Follow-Up': (data == 1 ? 'The first follow-up has already been sent.': 'The first follow-up has already been sent.'));
                  if (row[7] && ((row[7] == elm.constantService.NOT_DUE) || (row[7] == elm.constantService.PAST_DUE)) ) {
                    if(data == 0) {
                      data =
                      '<a href="javascript: void(0);" data-toggle="tooltip" data-animation="false" data-placement="left" data-note="' +
                      row[25] +
                      '" title="' +
                      d +
                      '">' +
                      '<i aria-hidden="true" class="fa fa-envelope-square fa-lg approvalIcon"  data-info="' +
                      row[25] +
                      '" ></i></a>';
                    } else {
                      data =
                      '<a href="javascript: void(0);" data-toggle="tooltip" data-animation="false" data-placement="left" data-note="' +
                      row[25] +
                      '" title="' +
                      d +
                      '">' +
                      '<i aria-hidden="true" class="fa fa-envelope-square fa-lg disabled_btn"  data-info="' +
                      row[25] +
                      '" ></i></a>';
                    }
                  } else {
                    data = '';
                  }
                  return data;
                },
              },
              { title: "Color Spec", visible: false },
              { title: "Ref Date", visible: false },
              { title: "Formatted Deadline Notes", visible: false },
              { title: "Approval Notify On", visible: false },
              { title: "Approval Effective On", visible: false },
              { title: "Id", visible: false },
              { title: "Age Spec", visible: false },
              { title: "Group", visible: false }
            ],
          });
          table1.columns.adjust().draw();
          // tslint:disable-next-line:no-unused-expression
          jQuery.extend(jQuery.fn.dataTableExt.oSort, {
            "us_date-asc": function (a: any, b: any) {
              const x: any = a ? new Date(a.split("-").join("/")) : null;
              const y: any = b ? new Date(b.split("-").join("/")) : null;
              return x < y ? -1 : x > y ? 1 : 0;
            },
            "us_date-desc": function (a: any, b: any) {
              const x: any = a ? new Date(a.split("-").join("/")) : null;
              const y: any = b ? new Date(b.split("-").join("/")) : null;
              return x < y ? 1 : x > y ? -1 : 0;
            },
            "parent-sort-asc": function (a: any, b: any) {
              const x: any = a ? a.toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
              const y: any = b ? b.toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
              return x < y ? -1 : x > y ? 1 : 0;
            },
            "parent-sort-desc": function (a: any, b: any) {
              const x: any = a ? a.toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
              const y: any = b ? b.toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
              return x < y ? 1 : x > y ? -1 : 0;
            },
            "html-date-sort-asc": function (a: any, b: any) {
              const d1 = a ? a.split(" ")[0] : null;
              const d2 = b ? b.split(" ")[0] : null;
              const x: any = d1 ? moment(d1, "MM-DD-YYYY").unix() : 0;
              const y: any = d2 ? moment(d2, "MM-DD-YYYY").unix() : 0;
              return x < y ? -1 : x > y ? 1 : 0;
            },
            "html-date-sort-desc": function (a: any, b: any) {
              const d1 = a ? a.split(" ")[0] : null;
              const d2 = b ? b.split(" ")[0] : null;
              const x: any = d1 ? moment(d1, "MM-DD-YYYY").unix() : 0;
              const y: any = d2 ? moment(d2, "MM-DD-YYYY").unix() : 0;
              return x < y ? 1 : x > y ? -1 : 0;
            },
          });
        });
        this.loading = false;
        if (!this.listener) {
          this.listener = this.renderer.listen(this.elRef.nativeElement, "click", (evt: any) => {
            if (evt.target.className === "stores") {
              const selectedObj = JSON.parse(atob(evt.target.value));
              if (evt.target.checked) {
                const objIndex = this.portalSelectedList.findIndex(
                  (obj: any) => obj.projectId == selectedObj["projectId"]
                );
                if (objIndex == -1) {
                  const projectList = this.dueDateReportList.filter((item: any, index: number) => index < this.dueDateReportList.length)
                  .filter((item: any, index: number) =>item["id"] == selectedObj["projectId"]);
                  if (projectList && projectList.length) {
                    const project = projectList[0];
                    const newObj = {
                      groupName: project.storeGroupName,
                      projectId: project.id,
                      projectName: project.title,
                      projectType: project.projectType,
                      storeName: project.store,
                      approvalFollowupDetailsCount: project.approvalFollowupDetailsCount,
                      deadline: this.transformDate(project.deadline)
                    }
                    this.portalSelectedList.push(newObj);
                    console.log("portalSelectedList", this.portalSelectedList);
                  }
                }
                this.portalSelectedStList = [
                  ...new Set(this.portalSelectedList.map((item: any) => item.projectId)),
                ];
                table1 = $("#dueDateReportTable").DataTable();
                if (table1.data().length === this.portalSelectedStList.length) {
                  const el = $("#storeSelectAll").get(0);
                  // If "Select all" control is checked and has 'indeterminate' property
                  if (el && el.checked && "indeterminate" in el) {
                    // Set visual state of "Select all" control
                    // as 'indeterminate'
                    el.indeterminate = false;
                  }
                }
              } else {
                // If checkbox is not checked
                const objIndex = this.portalSelectedList.findIndex(
                  (obj: any) => obj.projectId === selectedObj["projectId"]
                );
                if (objIndex !== -1) {
                  this.portalSelectedList.splice(objIndex, 1);
                }
                console.log("portalSelectedList", this.portalSelectedList);

                this.portalSelectedStList = [
                  ...new Set(this.portalSelectedList.map((item: any) => item.projectId)),
                ];
                const el = $("#storeSelectAll").get(0);
                // If "Select all" control is checked and has 'indeterminate' property
                if (el && el.checked && "indeterminate" in el) {
                  // Set visual state of "Select all" control
                  // as 'indeterminate'
                  el.indeterminate = true;
                }
              }
            }else if (evt.target["localName"] === "a" && evt.target.className === "pointer") {
              console.log("alert", evt.target.dataset.note);
              this.showAlert(evt.target.dataset.note);
            } else if (
              evt.target["localName"] === "i" &&
              evt.target.className === "fa fa-info-circle fa-lg infoIcon"
            ) {
              this.showInfo(evt.target.dataset.info);
            } else if (
              evt.target["localName"] === "i" &&
              evt.target.className === "fa fa-edit edit-icon-deadline-note"
            ) {
              this.updateDeadlineNoteModal(evt.target.dataset.info);
            } else if (
              evt.target["localName"] === "i" &&
              evt.target.className === "fa fa-envelope-square fa-lg approvalIcon"
            ) {
              this.showApprovalNoticeFollowUpPopup(evt.target.dataset.info);
            } else if (
              evt.target["localName"] === "span" &&
              (evt.target.className === "pointer gotoStoreOverview duedateTitle" ||
                evt.target.className === "pointer gotoStoreOverview duedateTitle1")
            ) {
              this.goToStoreOverview(evt.target.dataset.info);
            } else if (evt.target.className === "storeSelectAll") {
              console.log("eltest");
    
              table1 = $("#dueDateReportTable").DataTable();
              const rows = table1.rows({ search: "applied" }).nodes();
              $(".stores", rows).prop("checked", evt.target.checked);
              // const allData = table1.rows({ search: 'applied'}).nodes().data();
              const allData = table1.rows({ search: "applied" }).data();
    
              this.portalSelectedStList = [];
              this.portalSelectedList = [];
              if (evt.target.checked === true) {
                for (let i = 0; i < allData.length; i++) {
                  if (allData[i][7] && ((allData[i][7] == this.constantService.NOT_DUE) || (allData[i][7] == this.constantService.PAST_DUE)) ) {
                    this.portalSelectedStList.push(
                      allData[i][25] ? allData[i][25].toString() : allData[i][25]
                    );
                    this.portalSelectedList.push({
                      groupName: allData[i][3] ? allData[i][3].toString() : allData[i][3],
                      projectId: allData[i][25] ? allData[i][25].toString() : allData[i][25],
                      projectName:allData[i][4] ? allData[i][4].toString() : allData[i][4],
                      projectType: allData[i][34] ? allData[i][34].toString() : allData[i][34],
                      storeName: allData[i][35] ? allData[i][35].toString() : allData[i][35],
                      approvalFollowupDetailsCount: allData[i][19] ? allData[i][19].toString() : allData[i][19],
                      deadline : allData[i][14] ? allData[i][14].toString(): allData[i][14]
                    });
                  }
                }
              }
            }
          });
        }

        this.setReportCount();
        this.getMainReportList();
        if (!refreshFlag) {
          this.getSpList(false);
          if (
            this.defaultFilterType &&
            (atob(this.defaultFilterType) == "AB" || atob(this.defaultFilterType) == "RB")
          ) {
            if (atob(this.defaultFilterType) === "AB") {
              this.clientListFilter();
            } else {
              this.readyToListFilter();
            }
          } else {
            this.pastDueListFilter();
          }
        } else {
          this.getSpList(true);
          this.filterChange();
        }
        $(document).ready(() => {
          table1 = $("#dueDateReportTable").DataTable();
          table1.on("click", ".storeSelectAll", () => {
            table1 = $("#dueDateReportTable").DataTable();
            const rows = table1.rows({ search: "applied" }).nodes();
            $(".stores", rows).prop("checked", this.checked);
            const allData = table1.rows({ search: "applied" }).data();
            elm.portalSelectedStList = [];
            elm.portalSelectedList = [];
            if (this.checked == true) {
              for (let i = 0; i < allData.length; i++) {
                const obj = {
                  groupName: allData[i][3],
                  projectId: allData[i][25],
                  projectName: allData[i][4],
                  projectType: allData[i][34],
                  storeName: allData[i][35],
                  approvalFollowupDetailsCount: allData[i][19],
                  deadline : allData[i][14]
                };
                if (allData[i][7] && ((allData[i][7] == elm.constantService.NOT_DUE) || (allData[i][7] == elm.constantService.PAST_DUE)) ) {
                  elm.portalSelectedList.push(obj);
                  elm.portalSelectedStList.push(allData[i][25]);
                }
              }
            }
            console.log("portalSelectedList", elm.portalSelectedList);
          });
        });
      },
      error: (err) => {
        this.commonService.errorCallback(err, this);
      },
      complete: () => {
        console.log("Completed");
      },
    });
    return allDueDateReportsList;
  }

  @HostListener("mouseover")
  onMouseOver() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover",
    });
  }
  /**
   * Expand or Collapse Filter
   *
   */
  expandOrCollaseFilter() {
    this.isExpand = !this.isExpand;
    setTimeout(() => {
      $("#dueDateReportTable").DataTable().columns.adjust().draw(false);
    }, 500);
  }

  /**
   * Format date
   *
   */
  transformDate(date: any) {
    let newDate: any = "";
    if (date && date !== null && date !== "") {
      newDate = this.datePipe.transform(date.split("+")[0], "MM-dd-yyyy");
    }
    return newDate;
  }

  /**
   * Show more Info popup
   *
   */
  showInfo(txt: any) {
    if (!$("#showInfo").is(":visible")) {
      $("#showInfo").modal({
        show: true,
        backdrop: "static",
      });
    }
    this.infoData = txt;
  }
  closeInfo() {
    if ($("#showInfo").is(":visible")) {
      $("#showInfo").modal("hide");
    }
  }
  /**
   * Show Deadline Notes popup
   *
   */
  showAlert(txt: any) {
    this.deadlineNoteView = txt;
    if (!$("#deadlieNoteDetails").is(":visible")) {
      $("#deadlieNoteDetails").modal({ show: true, backdrop: "static" });
    }
  }
  closeAlert() {
    if ($("#deadlieNoteDetails").is(":visible")) {
      $("#deadlieNoteDetails").modal("hide");
    }
  }
  /**
   * Multi select report
   *
   */
  multiSelectReports(selectedReport: any, obj: any, flag: any) {
    const temp: any[] = [];
    this.selectedReportStr = "";
    if (selectedReport) {
      if (!this.containsObject(obj, this.mainReportSelected)) {
        this.mainReportSelected.push(obj);
      }
    } else if (obj) {
      for (let n = 0; n < this.mainReportSelected.length; n++) {
        if (this.mainReportSelected[n].id === obj.id) {
          this.mainReportSelected.splice(n, 1);
          break;
        }
      }
    }
    if (this.reportList.length === this.mainReportSelected.length) {
      this.selectAllReportFlag = true;
    } else {
      this.selectAllReportFlag = false;
    }
    let dueDateReportList: any[] = [];
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    } else {
      dueDateReportList = this.dueDateReportList;
    }
    if (this.mainReportSelected.length > 0) {
      if (this.parent.length === 0) {
        for (let i = 0; i < this.mainReportSelected.length; i++) {
          const t = dueDateReportList
            .filter((item: any, index: number) => index < dueDateReportList.length)
            .filter(
              (item: any, index: number) =>
                item["report"] === this.mainReportSelected[i]["itemName"]
            );
          for (let j = 0; j < t.length; j++) {
            if (!this.containsObject(t[j], temp)) {
              temp.push(t[j]);
            }
          }
        }
      } else {
        for (let i = 0; i < this.mainReportSelected.length; i++) {
          for (let j = 0; j < this.parent.length; j++) {
            const t = dueDateReportList
              .filter((item: any, index: number) => index < dueDateReportList.length)
              .filter(
                (item: any, index: number) =>
                  item["report"] === this.mainReportSelected[i]["itemName"]
              )
              .filter((item: any, index: number) => item["parent"] === this.parent[j]["itemName"]);
            for (let k = 0; k < t.length; k++) {
              if (!this.containsObject(t[k], temp)) {
                temp.push(t[k]);
              }
            }
          }
        }
      }
      for (let i = 0; i < this.mainReportSelected.length; i++) {
        if (this.selectedReportStr === "") {
          this.selectedReportStr += this.mainReportSelected[i]["itemName"];
        } else {
          this.selectedReportStr += " / " + this.mainReportSelected[i]["itemName"];
        }
      }
    } else if (this.parent.length > 0) {
      for (let i = 0; i < this.parent.length; i++) {
        const t = dueDateReportList
          .filter((item: any, index: number) => index < dueDateReportList.length)
          .filter((item: any, index: number) => item["parent"] === this.parent[i]["itemName"]);
        for (let j = 0; j < t.length; j++) {
          if (!this.containsObject(t[j], temp)) {
            temp.push(t[j]);
          }
        }
      }
    }
    if (this.selectedReportStr !== "") {
      this.selectedReportStr += " Report";
    }
    if (flag) {
      this.setFilters(temp, this.mainReportSelected, true);
      this.filterChange();
    }
  }

  /**
   *  select Punch List
   *
   */
  punchListFilter() {
    const report = this.constantService.PUNCH_LIST;
    this.punchListSelected = !this.punchListSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.punchListSelected, obj, true);
  }

  /**
   *  select Disputed
   *
   */
  disputeListFilter() {
    const report = this.constantService.DISPUTED;
    this.disputedSelected = !this.disputedSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.disputedSelected, obj, true);
  }

  /**
   *  select Ready to Bill
   *
   */
  readyToListFilter() {
    const report = this.constantService.READY_TO_BILL;
    this.readyToBillSelected = !this.readyToBillSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.readyToBillSelected, obj, true);
  }

  /**
   *  select Client
   *
   */
  clientListFilter() {
    const report = this.constantService.CLIENT;
    this.clientSelected = !this.clientSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.clientSelected, obj, true);
  }

  nonContingentFilter() {
    const report = this.constantService.NONCONTIGENT;
    this.nonContingentSelected = !this.nonContingentSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.nonContingentSelected, obj, true);
  }

  /**
   *  select Past Due
   *
   */
  pastDueListFilter() {
    const report = this.constantService.PAST_DUE;
    this.pastDueSelected = !this.pastDueSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.pastDueSelected, obj, true);
  }

  /**
   *  select Not Due
   *
   */
  notDueListFilter() {
    const report = this.constantService.NOT_DUE;
    this.notDueSelected = !this.notDueSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.notDueSelected, obj, true);
  }

  /**
   *  select Manf. Request
   *
   */
  manfRequestListFilter() {
    const report = this.constantService.MANF_REQUEST;
    this.manfRequestSelected = !this.manfRequestSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.manfRequestSelected, obj, true);
  }

  /**
   *  select Error
   *
   */
  errorListFilter() {
    const report = this.constantService.ERROR;
    this.errorSelected = !this.errorSelected;
    const obj = { id: report, itemName: report };
    this.multiSelectReports(this.errorSelected, obj, true);
  }

  /**
   *  Set Report Count
   *
   */
  setReportCount() {
    this.punchListCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.PUNCH_LIST
      ).length;

    this.disputedCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.DISPUTED
      ).length;

    this.readyToBillCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.READY_TO_BILL
      ).length;

    this.clientListCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter((item: any, index: number) => item["report"] === this.constantService.CLIENT).length;

    this.nonContingentCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.NONCONTIGENT
      ).length;

    this.pastDueCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.PAST_DUE
      ).length;

    this.notDueCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter((item: any, index: number) => item["report"] === this.constantService.NOT_DUE).length;

    this.errorCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter((item: any, index: number) => item["report"] === this.constantService.ERROR).length;

    this.manfRequestCount = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter(
        (item: any, index: number) => item["report"] === this.constantService.MANF_REQUEST
      ).length;
  }

  containsObject(obj: any, list: any) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].id === obj.id) {
        return true;
      }
    }

    return false;
  }

  containsArray(arr: any, list: any) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i][25] === arr[25]) {
        return true;
      }
    }

    return false;
  }

  /**
   *  Create Year Mont List Array
   *
   */
  getYearMonthList() {
    this.yearMonthList = [];
    const dueDateReportList = this.dueDateReportList;
    for (let i = 0; i < dueDateReportList.length; i++) {
      const yearMonth = dueDateReportList[i].yearMonth;
      const obj = { id: yearMonth, itemName: yearMonth };
      if (
        !this.containsObject(obj, this.yearMonthList) &&
        yearMonth !== null &&
        yearMonth !== "N/A"
      ) {
        this.yearMonthList.push(obj);
      }
    }
    this.yearMonthList = this.sortListDesc(this.yearMonthList);
  }

  /**
   *  Create Parent List Array
   *
   */
  getParentList() {
    this.parentList = [];
    const dueDateReportList = this.dueDateReportList;
    for (let i = 0; i < dueDateReportList.length; i++) {
      const parent = dueDateReportList[i].parent;
      const obj = { id: parent, itemName: parent };
      if (!this.containsObject(obj, this.parentList)) {
        this.parentList.push(obj);
      }
    }
    this.parentList = this.sortListAsc(this.parentList);
  }

  sortListAsc(temp: any) {
    temp = temp
      .filter((item: any, index: number) => index < temp.length)
      .sort((a: any, b: any): any => {
        const x: any = a["id"] ? a["id"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
        const y: any = b["id"] ? b["id"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
        return x < y ? -1 : x > y ? 1 : 0;
      });
    return temp;
  }

  sortListDesc(temp: any) {
    temp = temp
      .filter((item: any, index: number) => index < temp.length)
      .sort((a: any, b: any): any => {
        const x: any = a["id"] ? a["id"].toLowerCase() : "";
        const y: any = b["id"] ? b["id"].toLowerCase() : "";
        return x < y ? 1 : x > y ? -1 : 0;
      });
    return temp;
  }

  sortListAscDeadlineDate(temp: any) {
    temp = temp
      .filter((item: any, index: number) => index < temp.length)
      .sort((a: any, b: any): any => {
        const x: any = a["deadline"] ? moment(a["deadline"]) : "";
        const y: any = b["deadline"] ? moment(b["deadline"]) : "";
        return x < y ? -1 : x > y ? 1 : 0;
      });
    return temp;
  }
  /**
   *  Create Report List Array
   *
   */
  getMainReportList() {
    this.reportList = [];
    let dueDateReportList = this.dueDateReportList;
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    }
    for (let i = 0; i < dueDateReportList.length; i++) {
      const report = dueDateReportList[i].report;
      const obj = { id: report, itemName: report };
      if (!this.containsObject(obj, this.reportList)) {
        this.reportList.push(obj);
      }
    }
    this.reportList = this.sortListAsc(this.reportList);
  }

  getMainReportLists() {
    this.reportList = [];
    this.mainReportSelected = [];
    let dueDateReportList = this.dueDateReportList;
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    }
    for (let i = 0; i < dueDateReportList.length; i++) {
      const report = dueDateReportList[i].report;
      const obj = { id: report, itemName: report };
      if (!this.containsObject(obj, this.reportList)) {
        this.reportList.push(obj);
      }
      if (!this.containsObject(obj, this.mainReportSelected)) {
        this.mainReportSelected.push(obj);
      }
    }
    this.reportList = this.sortListAsc(this.reportList);
    this.mainReportSelected = this.sortListAsc(this.mainReportSelected);
  }
  /**
   *  Create SP List Array
   *
   */
  getSpList(refreshFlag: any) {
    this.spList = [];
    if (!refreshFlag) {
      this.sp = [];
    }
    const dueDateReportList = this.dueDateReportList;
    for (let i = 0; i < dueDateReportList.length; i++) {
      const sp = dueDateReportList[i].sp;
      const obj = { id: sp, itemName: sp, selected: false };
      if (!refreshFlag) {
        obj.selected = true;
        if (!this.containsObject(obj, this.sp)) {
          this.sp.push(obj);
        }
      } else {
        if (this.containsObject(obj, this.sp)) {
          obj.selected = true;
        }
      }
      if (!this.containsObject(obj, this.spList)) {
        this.spList.push(obj);
      }
    }
    this.spList = this.sortListAsc(this.spList);
    if (!refreshFlag) {
      this.sp = this.sortListAsc(this.sp);
      this.selectAllSpFlag = true;
    }
  }
  /**
   *  Apply Filter
   *
   */
  filterChange() {
    const temp: any[] = [];
    let temp2: any[] = [];
    let filteredTemp: any[] = [];

    let dueDateReportList = this.dueDateReportList;
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    }
    if (this.group.length > 0 && this.sp.length > 0) {
      let t: any[] = [];
      for (let i = 0; i < this.group.length; i++) {
        // for (let k = 0; k < this.sp.length; k++) {
        if (this.parent.length > 0 && this.mainReportSelected.length > 0) {
          for (let l = 0; l < this.parent.length; l++) {
            for (let x = 0; x < this.mainReportSelected.length; x++) {
              if (this.yearMonth.length > 0) {
                for (let m = 0; m < this.yearMonth.length; m++) {
                  t = dueDateReportList
                    .filter((item: any, index: number) => index < dueDateReportList.length)
                    .filter(
                      (item: any, index: number) =>
                        item["report"] !== this.constantService.ERROR &&
                        item["report"] === this.mainReportSelected[x]["itemName"]
                    )
                    .filter(
                      (item: any, index: number) => item["parent"] === this.parent[l]["itemName"]
                    )
                    .filter(
                      (item: any, index: number) =>
                        item["yearMonth"] === this.yearMonth[m]["itemName"] ||
                        item["yearMonth"] === "N/A"
                    )
                    .filter(
                      (item: any, index: number) => item["group"] === this.group[i]["itemName"]
                    )
                    // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                    .filter((item: any, index: number) => {
                      if (this.dateInput.start) {
                        if (
                          moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")
                        ) {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    })
                    .filter((item: any, index: number) => {
                      if (this.dateInput.end) {
                        if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                          return item;
                        }
                      } else {
                        return item;
                      }
                    });
                  for (let y = 0; y < t.length; y++) {
                    if (!this.containsObject(t[y], temp)) {
                      temp.push(t[y]);
                    }
                  }
                }
              } else {
                t = dueDateReportList
                  .filter((item: any, index: number) => index < dueDateReportList.length)
                  .filter(
                    (item: any, index: number) =>
                      item["report"] !== this.constantService.ERROR &&
                      item["report"] === this.mainReportSelected[x]["itemName"]
                  )
                  .filter(
                    (item: any, index: number) => item["parent"] === this.parent[l]["itemName"]
                  )
                  .filter((item: any, index: number) => item["group"] === this.group[i]["itemName"])
                  // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                  .filter((item: any, index: number) => {
                    if (this.dateInput.start) {
                      if (moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  .filter((item: any, index: number) => {
                    if (this.dateInput.end) {
                      if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  });
              }
              for (let y = 0; y < t.length; y++) {
                if (!this.containsObject(t[y], temp)) {
                  temp.push(t[y]);
                }
              }
            }
          }
        } else if (this.parent.length > 0 && this.mainReportSelected.length === 0) {
          for (let l = 0; l < this.parent.length; l++) {
            if (this.yearMonth.length > 0) {
              for (let m = 0; m < this.yearMonth.length; m++) {
                t = dueDateReportList
                  .filter((item: any, index: number) => index < dueDateReportList.length)
                  .filter(
                    (item: any, index: number) => item["report"] !== this.constantService.ERROR
                  )
                  .filter(
                    (item: any, index: number) => item["parent"] === this.parent[l]["itemName"]
                  )
                  .filter(
                    (item: any, index: number) =>
                      item["yearMonth"] === this.yearMonth[m]["itemName"] ||
                      item["yearMonth"] === "N/A"
                  )
                  .filter((item: any, index: number) => item["group"] === this.group[i]["itemName"])
                  // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                  .filter((item: any, index: number) => {
                    if (this.dateInput.start) {
                      if (moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  .filter((item: any, index: number) => {
                    if (this.dateInput.end) {
                      if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  });
                for (let y = 0; y < t.length; y++) {
                  if (!this.containsObject(t[y], temp)) {
                    temp.push(t[y]);
                  }
                }
              }
            } else {
              t = dueDateReportList
                .filter((item: any, index: number) => index < dueDateReportList.length)
                .filter((item: any, index: number) => item["report"] !== this.constantService.ERROR)
                .filter((item: any, index: number) => item["parent"] === this.parent[l]["itemName"])
                .filter((item: any, index: number) => item["group"] === this.group[i]["itemName"])
                // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                .filter((item: any, index: number) => {
                  if (this.dateInput.start) {
                    if (moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")) {
                      return item;
                    }
                  } else {
                    return item;
                  }
                })
                .filter((item: any, index: number) => {
                  if (this.dateInput.end) {
                    if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                      return item;
                    }
                  } else {
                    return item;
                  }
                });
            }
            for (let y = 0; y < t.length; y++) {
              if (!this.containsObject(t[y], temp)) {
                temp.push(t[y]);
              }
            }
          }
        } else if (this.mainReportSelected.length > 0) {
          for (let x = 0; x < this.mainReportSelected.length; x++) {
            if (this.yearMonth.length > 0) {
              for (let m = 0; m < this.yearMonth.length; m++) {
                t = dueDateReportList
                  .filter((item: any, index: number) => index < dueDateReportList.length)
                  .filter(
                    (item: any, index: number) =>
                      item["yearMonth"] === this.yearMonth[m]["itemName"] ||
                      item["yearMonth"] === "N/A"
                  )
                  .filter(
                    (item: any, index: number) =>
                      item["report"] === this.mainReportSelected[x]["itemName"]
                  )
                  .filter((item: any, index: number) => item["group"] === this.group[i]["itemName"])
                  // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                  .filter((item: any, index: number) => {
                    if (this.dateInput.start) {
                      if (moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  })
                  .filter((item: any, index: number) => {
                    if (this.dateInput.end) {
                      if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                        return item;
                      }
                    } else {
                      return item;
                    }
                  });
                for (let y = 0; y < t.length; y++) {
                  if (!this.containsObject(t[y], temp)) {
                    temp.push(t[y]);
                  }
                }
              }
            } else {
              t = dueDateReportList
                .filter((item: any, index: number) => index < dueDateReportList.length)
                .filter(
                  (item: any, index: number) =>
                    item["report"] === this.mainReportSelected[x]["itemName"]
                )
                .filter((item: any, index: number) => item["group"] === this.group[i]["itemName"])
                // .filter((item: any, index: number) => item["sp"] === this.sp[k]["itemName"])
                .filter((item: any, index: number) => {
                  if (this.dateInput.start) {
                    if (moment(item["deadline"]) >= moment(this.dateInput.start).startOf("day")) {
                      return item;
                    }
                  } else {
                    return item;
                  }
                })
                .filter((item: any, index: number) => {
                  if (this.dateInput.end) {
                    if (moment(item["deadline"]) <= moment(this.dateInput.end).endOf("day")) {
                      return item;
                    }
                  } else {
                    return item;
                  }
                });
            }
            for (let y = 0; y < t.length; y++) {
              if (!this.containsObject(t[y], temp)) {
                temp.push(t[y]);
              }
            }
          }
        }
        for (let j = 0; j < t.length; j++) {
          if (!this.containsObject(t[j], temp)) {
            temp.push(t[j]);
          }
        }
        // }
      }

      if(this.fopc.length > 0){
        for (let i = 0; i < this.fopc.length; i++) {
          let t: any[] = [];
          t = temp.filter((item) => {
            return this.fopc.some((fopcItem) => fopcItem.id == item.isActiveFopc);
          });
          console.log("filterChange fopc: ", this.fopc[i],t );
          for (let j = 0; j < t.length; j++) {
            if (!this.containsObject(t[j], temp2)) {
              temp2.push(t[j]);
            }
          }
        }
        console.log("filterChange fopc temp2: ", temp2 );
      } else {
        temp2 = temp;
      }
      this.rowDataDueDateReport = temp2;
      console.log("filterChange temp: ", temp2);
      console.log("filterChange SP: ", this.sp);
      filteredTemp = temp2.filter((item) => {
        return this.sp.some((spItem) => spItem.itemName == item.sp);
      });
      console.log("filterChange filteredTemp: ", filteredTemp);
    }

    this.reDrawTable(filteredTemp);
  }

  /**
   *  Redraw Table
   *
   */
  reDrawTable(temp: any[]) {
    this.portalSelectedList = [];
    this.portalSelectedStList = [];
    let self = this;
    temp = temp.sort(function (a: any, b: any) {
      if(self.notDueSelected == true && self.punchListSelected == false && 
        self.disputedSelected == false && self.readyToBillSelected == false && 
        self.clientSelected == false && self.pastDueSelected == false &&
        self.nonContingentSelected == false && self.manfRequestSelected == false &&
        self.errorSelected == false) {
          if (a["days"] > b["days"]) {
            return 1;
          }
          if (a["days"] < b["days"]) {
            return -1;
          }
          return 0;
        } else {
          if (a["days"] < b["days"]) {
            return 1;
          }
          if (a["days"] > b["days"]) {
            return -1;
          }
          const p = a["parent"] ? a["parent"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
          const q = b["parent"] ? b["parent"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
          const r = a["title"] ? a["title"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
          const s = b["title"] ? b["title"].toLowerCase().replace(/^[^a-z0-9]*/g, "") : "";
          if (p < q) {
            return -1;
          }
          if (p > q) {
            return 1;
          }
          if (r < s) {
            return -1;
          }
          if (r > s) {
            return 1;
          }
          return 0;
        }
    });
    setTimeout(() => {
      table1 = $("#dueDateReportTable").DataTable();
      table1.search("").draw();
      table1 = $("#dueDateReportTable").dataTable();
      table1.fnClearTable();
      const tempArr: any[] = [];
      this.selectedDueDateReports = [];
      for (let i = 0; i < temp.length; i++) {
        const t = temp[i];
        const rpt = [
          t.id,
          t.days,
          t.sp,
          t.parent,
          t.title,
          t.reviewMsg,
          t.isActiveFopc,
          t.report,
          t.dms,
          t.manufacturer,
          t.state,
          this.transformDate(t.startDate),
          t.sD,
          this.transformDate(t.shipDel),
          this.transformDate(t.deadline),
          this.transformDate(t.lastAction),
          t.ageDisplayVal,
          t.formattedDeadlineNotes,
          t.info,
          t.approvalFollowupDetailsCount,
          t.colorSpec,
          t.refDate,
          t.formattedDeadlineNotes,
          t.approvalNotifyOn,
          t.approvalEffectiveOn,
          t.id,
          t.ageSpec,
          t.deadlineLastActionOn,
          t.isStarred,
          t.ReadyToBillAge,
          t.isMgmtReviewRequested,
          t.isMgmtReviewApproved,
          t.approveMsg,
          t.group,
          t.projectType,
          t.store
        ];
        tempArr.push(rpt);
        this.selectedDueDateReports.push(rpt);
      }
      if (tempArr.length > 0) {
        table1.fnAddData(tempArr, false); // Add new data
      }
      table1.fnDraw(); // Redraw the DataTable
      if (temp.length > 0) {
        setTimeout(() => {
          $("#dueDateReportTable").DataTable().columns.adjust().draw(false);
        }, 100);
      }
      this.count = temp.length;
      this.spCountCalculation(false);
    }, 100);
  }

  /**
   *  Select the date from datepicker in the Filter
   *
   */
  public selectedDate(value: any, dateInput: any) {
    dateInput.start = value.start;
    dateInput.end = value.end;
    this.filterChange();
  }

  /**
   *  Set Filter, when select report or filter options
   *
   */

  setFilters(temp: any, type: any, flag: any) {
    // const factoryDeadLineRange = temp
    //   .filter((item: any, index: number) => index < temp.length)
    //   .sort((a: any, b: any): any => {
    //     const x: any = new Date(a["deadline"]);
    //     const y: any = new Date(b["deadline"]);
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   });

    // if (factoryDeadLineRange.length > 0) {
    //   let min = moment();
    //   let max = moment();
    //   for (let i = 0; i < factoryDeadLineRange.length; i++) {
    //     if (factoryDeadLineRange[i].deadline !== null) {
    //       min = factoryDeadLineRange[i].deadline;
    //       break;
    //     }
    //   }
    //   if (factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline !== null) {
    //     max = factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline;
    //   }
    //   this.dateInput.start = moment(min);
    //   this.dateInput.end = moment(max);
    //   this.dateRangePickerOptions.startDate = moment(min);
    //   this.dateRangePickerOptions.endDate = moment(max);
    // } else {
    //   this.dateInput.start = moment();
    //   this.dateInput.end = moment();
    //   this.dateRangePickerOptions.startDate = moment();
    //   this.dateRangePickerOptions.endDate = moment();
    // }
    this.reportCategoryList = [];
    $(".groupSelection .select-all[_ngcontent-c1]").hide();
    this.group = [];
    for (let i = 0; i < temp.length; i++) {
      const group = temp[i].group;
      const obj = { id: group, itemName: group };
      if (!this.containsObject(obj, this.reportCategoryList)) {
        this.reportCategoryList.push(obj);
        $(".groupSelection .select-all[_ngcontent-c1]").show();
      }
      if (!this.containsObject(obj, this.group)) {
        this.group.push(obj);
      }
    }
    this.reportCategoryList = this.sortListAsc(this.reportCategoryList);
    this.group = this.sortListAsc(this.group);
    if (flag) {
      this.getParentList();
      this.getYearMonthList();
    }
  }

  /**
   *  Apply Filter, when select or unselect report
   *
   */
  updateSelectedCategory(event: any) {
    if (event.target.checked) {
      if (this.group.indexOf(event.target.name) < 0) {
        this.group.push(event.target.name);
      }
    } else {
      if (this.group.indexOf(event.target.name) > -1) {
        this.group.splice(this.group.indexOf(event.target.name), 1);
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when select or unselect SP
   *
   */
  updateSelectedSp(event: any) {
    if (event.target.checked) {
      if (this.sp.indexOf(event.target.name) < 0) {
        this.sp.push(event.target.name);
      }
    } else {
      if (this.sp.indexOf(event.target.name) > -1) {
        this.sp.splice(this.sp.indexOf(event.target.name), 1);
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when select report
   *
   */
  onItemSelect(item: any) {
    if (!this.containsObject(item, this.group)) {
      this.group.push(item);
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselect report
   *
   */
  OnItemDeSelect(item: any) {
    for (let n = 0; n < this.group.length; n++) {
      if (this.group[n].id === item.id) {
        this.group.splice(n, 1);
        break;
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when select all report
   *
   */
  onSelectAll(items: any) {
    this.group = items;
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselect all report
   *
   */
  onDeSelectAll(items: any) {
    this.group = [];
    this.filterChange();
  }

  /**
   *  Apply Filter, when select or unselect SP
   *
   */
  onItemSelectSp(item: any, index: number) {
    let itemName;
    if (item.target.name) {
      itemName = item.target.name.trim();
    } else if (item.target.id) {
      itemName = item.target.id.trim();
    }
    const obj = { id: itemName, itemName: itemName };
    if (item.target.className.indexOf("sp_select_all") === -1) {
      this.selectAllSpFlag = false;
      $("#sp_" + index).addClass("sp_select_all");
      for (let n = 0; n < this.sp.length; n++) {
        if (this.sp[n].id === obj.id) {
          this.sp.splice(n, 1);
          break;
        }
      }
    } else {
      $("#sp_" + index).removeClass("sp_select_all");
      if (!this.containsObject(obj, this.sp)) {
        this.sp.push(obj);
      }
      if (this.spList.length === this.sp.length) {
        this.selectAllSpFlag = true;
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when select all SP
   *
   */
  onSelectAllSp() {
    this.selectAllSpFlag = true;
    this.sp = [];
    for (let i = 0; i < this.spList.length; i++) {
      const sp = this.spList[i];
      $("#sp_" + i).removeClass("sp_select_all");
      if (!this.containsObject(sp, this.sp)) {
        this.sp.push(sp);
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselect all SP
   *
   */
  onDeSelectAllSp() {
    this.selectAllSpFlag = false;
    this.sp = [];
    for (let i = 0; i < this.spList.length; i++) {
      $("#sp_" + i).addClass("sp_select_all");
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when select parent
   *
   */
  onItemSelectParent(item1: any) {
    if (!this.containsObject(item1, this.parent)) {
      this.parent.push(item1);
    }
    this.setParentSelction();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  OnItemDeSelectParent(item1: any) {
    for (let n = 0; n < this.parent.length; n++) {
      if (this.parent[n].id === item1.id) {
        this.parent.splice(n, 1);
        break;
      }
    }
    if (this.parent.length === 0) {
      const temp: any[] = [];
      let dueDateReportList = this.dueDateReportList;
      if (this.searchFilterKey) {
        dueDateReportList = this.filteredDueDateReports;
      }
      for (let x = 0; x < this.mainReportSelected.length; x++) {
        const t = dueDateReportList
          .filter((item: any, index: number) => index < dueDateReportList.length)
          .filter(
            (item: any, index: number) => item["report"] === this.mainReportSelected[x]["itemName"]
          );
        for (let y = 0; y < t.length; y++) {
          if (!this.containsObject(t[y], temp)) {
            temp.push(t[y]);
          }
        }
      }
      this.setFilters(temp, this.mainReportSelected, false);
      // this.reDrawTable(temp);
      this.filterChange();
    } else {
      this.setParentSelction();
    }
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  OnItemSelectAllParent(items: any) {
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.parent)) {
        this.parent.push(items[i]);
      }
    }
    this.selectAllClicked = true;
    this.setParentSelction();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  OnItemDeSelectAllParent(items: any) {
    this.parent = [];
    const temp: any[] = [];
    let dueDateReportList = this.dueDateReportList;
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    }
    for (let x = 0; x < this.mainReportSelected.length; x++) {
      const t = dueDateReportList
        .filter((item: any, index: number) => index < dueDateReportList.length)
        .filter(
          (item: any, index: number) => item["report"] === this.mainReportSelected[x]["itemName"]
        );
      for (let y = 0; y < t.length; y++) {
        if (!this.containsObject(t[y], temp)) {
          temp.push(t[y]);
        }
      }
    }
    this.setFilters(temp, this.mainReportSelected, false);

    this.reDrawTable(temp);
  }

  setParentSelction() {
    this.reportCategoryList = [];
    $(".groupSelection .select-all[_ngcontent-c1]").hide();
    this.group = [];
    const temp: any[] = [];
    let dueDateReportList: any[] = [];
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    } else {
      dueDateReportList = this.dueDateReportList;
    }
    if (this.mainReportSelected.length > 0) {
      if (this.parent.length === 0) {
        for (let i = 0; i < this.mainReportSelected.length; i++) {
          const t = dueDateReportList
            .filter((item: any, index: number) => index < dueDateReportList.length)
            .filter(
              (item: any, index: number) =>
                item["report"] === this.mainReportSelected[i]["itemName"]
            );
          for (let j = 0; j < t.length; j++) {
            if (!this.containsObject(t[j], temp)) {
              temp.push(t[j]);
            }
          }
        }
      } else {
        for (let i = 0; i < this.mainReportSelected.length; i++) {
          for (let j = 0; j < this.parent.length; j++) {
            const t = dueDateReportList
              .filter((item: any, index: number) => index < dueDateReportList.length)
              .filter(
                (item: any, index: number) =>
                  item["report"] === this.mainReportSelected[i]["itemName"]
              )
              .filter((item: any, index: number) => item["parent"] === this.parent[j]["itemName"]);
            for (let k = 0; k < t.length; k++) {
              if (!this.containsObject(t[k], temp)) {
                temp.push(t[k]);
              }
            }
          }
        }
      }
    } else if (this.parent.length > 0) {
      for (let i = 0; i < this.parent.length; i++) {
        const t = dueDateReportList
          .filter((item: any, index: number) => index < dueDateReportList.length)
          .filter((item: any, index: number) => item["parent"] === this.parent[i]["itemName"]);
        for (let j = 0; j < t.length; j++) {
          if (!this.containsObject(t[j], temp)) {
            temp.push(t[j]);
          }
        }
      }
    }

    for (let i = 0; i < temp.length; i++) {
      const group = temp[i].group;
      const obj = { id: group, itemName: group };
      if (!this.containsObject(obj, this.reportCategoryList)) {
        this.reportCategoryList.push(obj);
        $(".groupSelection .select-all[_ngcontent-c1]").show();
      }
      if (!this.containsObject(obj, this.group)) {
        this.group.push(obj);
      }
    }
    // const factoryDeadLineRange = temp
    //   .filter((item: any, index: number) => index < temp.length)
    //   .sort((a: any, b: any): any => {
    //     const x: any = new Date(a["deadline"]);
    //     const y: any = new Date(b["deadline"]);
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   });

    // if (factoryDeadLineRange.length > 0) {
    //   let min = moment();
    //   let max = moment();
    //   for (let i = 0; i < factoryDeadLineRange.length; i++) {
    //     if (factoryDeadLineRange[i].deadline !== null) {
    //       min = factoryDeadLineRange[i].deadline;
    //       break;
    //     }
    //   }
    //   if (factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline !== null) {
    //     max = factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline;
    //   }
    //   this.dateInput.start = moment(min);
    //   this.dateInput.end = moment(max);
    //   this.dateRangePickerOptions.startDate = moment(min);
    //   this.dateRangePickerOptions.endDate = moment(max);
    // } else {
    //   this.dateInput.start = moment();
    //   this.dateInput.end = moment();
    //   this.dateRangePickerOptions.startDate = moment();
    //   this.dateRangePickerOptions.endDate = moment();
    // }
    this.filterChange();
  }
  /**
   *  Apply Filter, when select date
   *
   */
  onItemSelectDate(item: any) {
    if (!this.containsObject(item, this.yearMonth)) {
      this.yearMonth.push(item);
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselect date
   *
   */
  OnItemDeSelectDate(item: any) {
    for (let n = 0; n < this.yearMonth.length; n++) {
      if (this.yearMonth[n].id === item.id) {
        this.yearMonth.splice(n, 1);
        break;
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when selectAll date
   *
   */
  OnItemSelectAllDate(items: any) {
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.yearMonth)) {
        this.yearMonth.push(items[i]);
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselectAll date
   *
   */
  OnItemDeSelectAllDate(item: any) {
    this.yearMonth = [];
    this.filterChange();
  }


  /**
   *  Apply Filter, when select fopc
   *
   */
  onItemSelectFopc(item: any) {
    if (!this.containsObject(item, this.fopc)) {
      this.fopc.push(item);
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselect fopc
   *
   */
  OnItemDeSelectFopc(item: any) {
    for (let n = 0; n < this.fopc.length; n++) {
      if (this.fopc[n].id === item.id) {
        this.fopc.splice(n, 1);
        break;
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when selectAll fopc
   *
   */
  OnItemSelectAllFopc(items: any) {
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.fopc)) {
        this.fopc.push(items[i]);
      }
    }
    this.filterChange();
  }

  /**
   *  Apply Filter, when unselectAll fopc
   *
   */
  OnItemDeSelectAllFopc(item: any) {
    this.fopc = [];
    this.filterChange();
  }
  /**
   *  Apply Filter, when select all report
   *
   */
  onSelectAllReport(items: any) {
    this.group = items;
    let flag = false;
    for (let n = 0; n < items.length; n++) {
      if (n === items.length - 1) {
        flag = true;
      }
      const obj = { id: items[n].id, itemName: items[n].id };
      if (items[n].id === this.constantService.PUNCH_LIST) {
        this.punchListSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.DISPUTED) {
        this.disputedSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.READY_TO_BILL) {
        this.readyToBillSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.CLIENT) {
        this.clientSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.NONCONTIGENT) {
        this.nonContingentSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.PAST_DUE) {
        this.pastDueSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.NOT_DUE) {
        this.notDueSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.MANF_REQUEST) {
        this.manfRequestSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
      if (items[n].id === this.constantService.ERROR) {
        this.errorSelected = true;
        this.multiSelectReports(true, obj, flag);
      }
    }
    this.selectAllReportFlag = true;
  }

  /**
   *  Apply Filter, when unselect all report
   *
   */
  onDeSelectAllReport(items: any) {
    this.punchListSelected = false;
    this.disputedSelected = false;
    this.readyToBillSelected = false;
    this.clientSelected = false;
    this.nonContingentSelected = false;
    this.pastDueSelected = false;
    this.notDueSelected = false;
    this.manfRequestSelected = false;
    this.errorSelected = false;
    this.selectedReportStr = "";
    this.multiSelectReports(false, null, true);
  }

  /**
   *  Apply Clear search after search process
   *
   */
  clearSearch() {
    this.punchListSelected = false;
    this.disputedSelected = false;
    this.readyToBillSelected = false;
    this.clientSelected = false;
    this.nonContingentSelected = false;
    this.pastDueSelected = true;
    this.notDueSelected = false;
    this.manfRequestSelected = false;
    this.errorSelected = false;
    this.group = [];
    this.mainReportSelected = [];
    this.parent = [];
    this.yearMonth = [];
    this.fopc = [];
    this.searchFilterKey = "";
    this.searchFilterKeyTrim = "";
    $(".sp_select_all").removeClass("sp_select_all");
    this.getSpList(false);
    this.selectedReportStr = this.constantService.PAST_DUE + " Report";
    this.selectAllReportFlag = false;
    const report = this.constantService.PAST_DUE;
    const obj = { id: report, itemName: report };
    this.mainReportSelected.push(obj);
    const temp = this.dueDateReportList
      .filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter((item: any, index: number) => item["report"] === report);
    this.setFilters(temp, this.mainReportSelected, true);
    this.rowDataDueDateReport = temp;
    this.reDrawTable(temp);
  }
  /**
   *  Apply Reset Filter
   *
   */
  resetFilter() {
    this.parent = [];
    this.yearMonth = [];
    this.group = [];
    this.fopc = [];
    const temp: any[] = [];
    let dueDateReportList: any[] = [];
    if (this.searchFilterKey) {
      dueDateReportList = this.filteredDueDateReports;
    } else {
      dueDateReportList = this.dueDateReportList;
    }
    for (let x = 0; x < this.mainReportSelected.length; x++) {
      const t = dueDateReportList
        .filter((item: any, index: number) => index < dueDateReportList.length)
        .filter(
          (item: any, index: number) => item["report"] === this.mainReportSelected[x]["itemName"]
        );
      for (let y = 0; y < t.length; y++) {
        if (!this.containsObject(t[y], temp)) {
          temp.push(t[y]);
        }
      }
    }
    for (let i = 0; i < temp.length; i++) {
      const group = temp[i].group;
      const obj = { id: group, itemName: group };
      if (!this.containsObject(obj, this.reportCategoryList)) {
        this.reportCategoryList.push(obj);
        $(".groupSelection .select-all[_ngcontent-c1]").show();
      }
      if (!this.containsObject(obj, this.group)) {
        this.group.push(obj);
      }
    }
    // const factoryDeadLineRange = temp
    //   .filter((item: any, index: number) => index < temp.length)
    //   .sort((a: any, b: any): any => {
    //     const x: any = new Date(a["deadline"]);
    //     const y: any = new Date(b["deadline"]);
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   });

    // if (factoryDeadLineRange.length > 0) {
    //   let min = moment();
    //   let max = moment();
    //   for (let i = 0; i < factoryDeadLineRange.length; i++) {
    //     if (factoryDeadLineRange[i].deadline !== null) {
    //       min = factoryDeadLineRange[i].deadline;
    //       break;
    //     }
    //   }
    //   if (factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline !== null) {
    //     max = factoryDeadLineRange[factoryDeadLineRange.length - 1].deadline;
    //   }
    //   this.dateInput.start = moment(min);
    //   this.dateInput.end = moment(max);
    //   this.dateRangePickerOptions.startDate = moment(min);
    //   this.dateRangePickerOptions.endDate = moment(max);
    // } else {
    //   this.dateInput.start = moment();
    //   this.dateInput.end = moment();
    //   this.dateRangePickerOptions.startDate = moment();
    //   this.dateRangePickerOptions.endDate = moment();
    // }
    this.dateInput.start = null;
    this.dateInput.end = null;
    this.dateRangePickerOptions.startDate = moment();
    this.dateRangePickerOptions.endDate = moment();
    this.filterChange();
  }

  /**
   *  Show Summary Table
   *
   */
  showSummaryPopup(txt: any) {
    console.log("txt", txt);
    if (!$("#showMemmoryModal").is(":visible")) {
      $("#showMemmoryModal").modal({
        show: true,
        backdrop: "static",
      });
    }
    this.summaryData = txt;
  }
  closeSummary() {
    if ($("#showMemmoryModal").is(":visible")) {
      $("#showMemmoryModal").modal("hide");
    }
  }
  /**
   *  Show SP-Report Summary popup values
   *
   */
  reportCount(reportName: any, sp: any) {
    const length = this.dueDateReportList.length;
    if (sp) {
      if (reportName) {
        return this.dueDateReportList
          .filter((item: any, index: number) => index < length)
          .filter((item: any, index: number) => item["report"] === reportName)
          .filter((item: any, index: number) => item["sp"] === sp).length;
      } else {
        return this.dueDateReportList
          .filter((item: any, index: number) => index < length)
          .filter((item: any, index: number) => item["sp"] === sp).length;
      }
    } else if (reportName) {
      return this.dueDateReportList
        .filter((item: any, index: number) => index < length)
        .filter((item: any, index: number) => item["report"] === reportName).length;
    } else {
      return this.dueDateReportList.filter((item: any, index: number) => index < length).length;
    }
  }

  mgmtReportPopup() {
    let objPropertyCalender: { [k: string]: any } = {};
    let objPropertyCalender1: { [k: string]: any } = {};
    objPropertyCalender = this.commonService.getCalenderPropertyObject();
    $("#mgmtReportFromDate").datetimepicker(objPropertyCalender);
    objPropertyCalender1 = this.commonService.getCalenderPropertyObject();
    $("#mgmtReportToDate").datetimepicker(objPropertyCalender1);
    this.mgmtReportForm.reset();
    this.showSpinnerButton = false;
    if (!$("#mgmtReportModal").is(":visible")) {
      $("#mgmtReportModal").modal({
        show: true,
        backdrop: "static",
      });
    }
  }

  closeMgmtReportPopup() {
    this.mgmtReportForm.reset();
    this.showSpinnerButton = false;
    if ($("#mgmtReportModal").is(":visible")) {
      $("#mgmtReportModal").modal("hide");
    }
  }

  mgmtReportFormSubmit() {
    this.showSpinnerButton = true;
    const startDate = this.mgmtReportForm.get("fromDate")?.value;
    const endDate = this.mgmtReportForm.get("toDate")?.value;
    this.billingMgmtDetailsProcess(startDate, endDate, (flag: any) => {
      if (flag) {
        this.getBillingMgmtReportXlsxData();
        this.mgmtReportForm.reset();
      }
    });
  }

  billingMgmtDetailsProcess(startDate: any, endDate: any, callback: any) {
    const dbObj = {
      startDate: startDate,
      endDate: endDate,
    };
    const activityData = {
      activityName: " fetch billingMgmtDetails",
      activityDescription: "Current Page: Duedate report",
      activityData: dbObj,
    };
    this.commonService.saveActivity("DueDate Report", activityData);
    const allProductionSnapshotDetails = this.apollo.use("portaldbClient").query({
      query: billingMgmtDetails,
      fetchPolicy: "network-only",
      variables: dbObj,
    });

    allProductionSnapshotDetails.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        console.log("billingMgmtDetails===>>", result);
        let billingMgmtDetailsList: any[] = [];
        const queryName = "billingMgmtDetails";
        billingMgmtDetailsList = JSON.parse(result.data[queryName]);
        this.billingMgmtDetailsList = billingMgmtDetailsList ? billingMgmtDetailsList : [];

        console.log("billingMgmtDetailsList===>>", this.billingMgmtDetailsList);
        const activityData1 = {
          activityName: " fetch billingMgmtDetails successfully",
          activityDescription: "Current Page: Duedate report",
        };
        this.commonService.saveActivity("DueDate Report", activityData1);
        if (callback) {
          callback(true);
        }
      },
      error: (err) => {
        console.log("error=====>>>", err);
        this.billingMgmtDetailsList = [];
        const activityData2 = {
          activityName: "getProductionSnapshotDetails error",
          activityDescription: "Current Page: DueDate Report",
        };
        this.commonService.saveActivity("DueDate Report", activityData2);
        this.commonService.errorCallback(err, this);
      },
      complete: () => {
        console.log("Completed");
      },
    });
    return allProductionSnapshotDetails;
  }
  /**
   *  Show SP-Report Summary popup
   *
   */
  showSummaryTable() {
    let data: any = '<table class="table table-hover" width="100%">';
    data += "<thead><tr>";
    data += "<th></th>";
    for (let i = 0; i < this.spList.length; i++) {
      data += "<th>" + this.spList[i]["id"] + "</th>";
    }
    data += "<th>Total</th>";
    data += "</tr></thead>";
    data += "<tbody>";
    for (let i = 0; i < this.reportList.length; i++) {
      data += '<tr><td class="infoSummary">' + this.reportList[i]["id"] + "</td>";
      for (let j = 0; j < this.spList.length; j++) {
        data += "<td>" + this.reportCount(this.reportList[i]["id"], this.spList[j]["id"]) + "</td>";
      }
      data += "<td>" + this.reportCount(this.reportList[i]["id"], null) + "</td>";
    }
    data += '</tr><tr class="infoSummary"><td class="infoSummary">Total</td>';
    for (let i = 0; i < this.spList.length; i++) {
      data += "<td>" + this.reportCount(null, this.spList[i]["id"]) + "</td>";
    }
    data += "<td>" + this.reportCount(null, null) + "</td>";
    data += "<tr></tbody></table>";
    console.log(data);
    this.showSummaryPopup(data);
  }

  setFilterSearch(res: any) {
    const searchTerm = res.value ? res.value.toLowerCase().trim() : "";
    if (searchTerm !== "") {
      this.searchKeyEnable = true;
    } else {
      this.searchKeyEnable = false;
    }
  }
  /**
   *  Implement Search filter
   *
   */
  searchFilter(res: any) {
    this.portalSelectedList = [];
    this.portalSelectedStList = [];
    const searchTerm = res.value ? res.value.toLowerCase().trim() : "";
    this.selectedDueDateReports = [];
    this.filteredDueDateReports = [];
    const elm = this;
    const tempArr: any[] = [];
    const allReports: any[] = [];
    if (searchTerm === "" && this.searchFilterKeyTrim) {
      this.clearSearch();
    } else if (searchTerm !== "") {
      this.searchFilterKeyTrim = searchTerm;
      this.selectedReportStr = "";
      this.punchListSelected = true;
      this.disputedSelected = true;
      this.readyToBillSelected = true;
      this.clientSelected = true;
      this.nonContingentSelected = true;
      this.pastDueSelected = true;
      this.notDueSelected = true;
      this.manfRequestSelected = false;
      this.errorSelected = true;
      this.group = [];
      this.mainReportSelected = [];
      this.parent = [];
      this.yearMonth = [];
      this.fopc = [];
      this.selectAllReportFlag = true;
      $(".sp_select_all").removeClass("sp_select_all");
      this.getSpList(false);
      let report = this.constantService.PAST_DUE;
      let obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.DISPUTED;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.PUNCH_LIST;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.NOT_DUE;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.READY_TO_BILL;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.NONCONTIGENT;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.MANF_REQUEST;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      report = this.constantService.CLIENT;
      obj = { id: report, itemName: report };
      this.mainReportSelected.push(obj);
      if (this.errorCount > 0) {
        report = this.constantService.ERROR;
        obj = { id: report, itemName: report };
        this.mainReportSelected.push(obj);
      }
      const temp = this.dueDateReportList;
      this.setFilters(temp, this.mainReportSelected, true);
      for (let i = 0; i < this.mainReportSelected.length; i++) {
        if (this.selectedReportStr === "") {
          this.selectedReportStr += this.mainReportSelected[i]["itemName"];
        } else {
          this.selectedReportStr += " / " + this.mainReportSelected[i]["itemName"];
        }
      }
      if (this.selectedReportStr !== "") {
        this.selectedReportStr += " Report";
      }
      const temp1 = Object.assign(this.dueDateReportList);
      table1 = $("#dueDateReportTable").dataTable();
      table1.fnClearTable();
      for (let i = 0; i < temp1.length; i++) {
        const t = temp1[i];
        allReports.push([
          t.id,
          t.days,
          t.sp,
          t.parent,
          t.title,
          t.reviewMsg,
          t.isActiveFopc,
          t.report,
          t.dms,
          t.manufacturer,
          t.state,
          this.transformDate(t.startDate),
          t.sD,
          this.transformDate(t.shipDel),
          this.transformDate(t.deadline),
          this.transformDate(t.lastAction),
          t.ageDisplayVal,
          t.formattedDeadlineNotes,
          t.info,
          t.approvalFollowupDetailsCount,
          t.colorSpec,
          t.refDate,
          t.formattedDeadlineNotes,
          t.approvalNotifyOn,
          t.approvalEffectiveOn,
          t.id,
          t.ageSpec,
          t.deadlineLastActionOn,
          t.isStarred,
          t.ReadyToBillAge,
          t.isMgmtReviewRequested,
          t.isMgmtReviewApproved,
          t.approveMsg,
          t.group,
          t.projectType,
          t.store
        ]);
      }
      if (allReports.length > 0) {
        table1.fnAddData(allReports, false); // Add new data
      }
      table1.fnDraw(); // Redraw the DataTable

      $.fn.dataTable.ext.search.push(function (settings: any, data: any, dataIndex: any) {
        // eslint-disable-next-line for-direction
        for (let i = 0; i > data.length; i++) {
          if (data[i] === null) {
            data[i] = "";
          }
        }
        if (data[1].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[2].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[3].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[4].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[5].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[7].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[8].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[9].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[10].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[11].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[12].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[13].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[14].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[15].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        if (data[22].toLowerCase().indexOf(searchTerm) > -1) {
          if (!elm.containsArray(allReports[dataIndex], tempArr)) {
            tempArr.push(allReports[dataIndex]);
          }
          return true;
        }
        return false;
      });
      table1 = $("#dueDateReportTable").DataTable();
      table1.search("").draw();
      table1 = $("#dueDateReportTable").dataTable();
      table1.fnClearTable();
      if (tempArr.length > 0) {
        table1.fnAddData(tempArr, false); // Add new data
      }
      table1.fnDraw(); // Redraw the DataTable
      setTimeout(() => {
        $("#dueDateReportTable").DataTable().columns.adjust().draw(false);
      }, 500);
      $.fn.dataTable.ext.search.pop();
      table1 = $("#dueDateReportTable").DataTable();
      const rows = table1.rows().data();
      this.count = rows.length;
      for (let i = 0; i < rows.length; i++) {
        this.selectedDueDateReports.push(rows[i]);
      }
      for (let i = 0; i < this.selectedDueDateReports.length; i++) {
        for (let j = 0; j < this.dueDateReportList.length; j++) {
          if (this.dueDateReportList[j].id === this.selectedDueDateReports[i][25]) {
            this.filteredDueDateReports.push(this.dueDateReportList[j]);
          }
        }
      }
      this.spCountCalculation(true);
    }
  }

  /**
   *  Download XLSX Report
   *
   */
  getReportXlsxData() {
    this.downloading = true;
    const csvData: any[] = [];
    const data: any[] = [];
    const rpt = this.selectedDueDateReports;
    data.push([
      "Age",
      "SP",
      "Parent",
      "Project Title",
      "Report",
      "DMS",
      "Manufacturer",
      "State",
      "Project Start Date",
      "S/D",
      "Ship/Del. Date",
      "Fact. Deadline",
      "Last Action",
      "Ready to Bill Age",
      "Deadline Notes",
    ]);
    for (let i = 0; i < rpt.length; i++) {
      if (rpt[i]) {
        data.push([
          rpt[i][1] ? rpt[i][1] : null,
          rpt[i][2] ? rpt[i][2] : null,
          rpt[i][3] ? rpt[i][3] : null,
          rpt[i][4] ? rpt[i][4] : null,
          rpt[i][7] ? rpt[i][7] : null,
          rpt[i][8] ? rpt[i][8] : null,
          rpt[i][9] ? rpt[i][9] : null,
          rpt[i][10] ? rpt[i][10] : null,
          rpt[i][11] ? rpt[i][11] : null,
          rpt[i][12] ? rpt[i][12] : null,
          rpt[i][13] ? rpt[i][13] : null,
          rpt[i][14] ? rpt[i][14] : null,
          rpt[i][15] ? rpt[i][15] : null,
          rpt[i][16] || rpt[i][16] == 0 ? rpt[i][16] : null,
          rpt[i][17] ? rpt[i][17] : null,
        ]);
      }
    }
    const wb: any = { SheetNames: [], Sheets: {} };
    const ws_name: any = "DueDates";
    /* create worksheet: */
    const ws: any = {};
    /* the range object is used to keep track of the range of the sheet */
    const range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };

    /* Iterate through each element in the structure */
    for (let R = 0; R < data.length; R++) {
      if (range.e.r < R) {
        range.e.r = R;
      }
      for (let C = 0; C < data[R].length; C++) {
        if (range.e.c < C) {
          range.e.c = C;
        }
        // tslint:disable-next-line:max-line-length
        const cell = {
          v: data[R][C],
          s: {
            alignment: { textRotation: 90 },
            font: { sz: 16, bold: true, color: "#FF00FF" },
            fill: { bgColor: "#FFFFFF" },
          },
          t: "s",
        };
        if (cell.v == null) {
          continue;
        }
        /* create the correct cell reference */
        const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
        /* determine the cell type */
        if (typeof cell.v === "number") {
          cell.t = "n";
        } else if (typeof cell.v === "boolean") {
          cell.t = "b";
        } else {
          cell.t = "s";
        }
        /* add to structure */
        ws[cell_ref] = cell;
      }
    }
    ws["!ref"] = XLSX.utils.encode_range(range);
    const wscols = [
      { wch: 4 }, // "characters"
      { wch: 3 },
      { wch: 20 },
      { wch: 45 },
      { wch: 13 },
      { wch: 30 },
      { wch: 15 },
      { wch: 5 },
      { wch: 20 },
      { wch: 3 },
      { wch: 15 },
      { wch: 15 },
      { wch: 12 },
      { wch: 12 },
      { wch: 75 },
    ];

    ws["!cols"] = wscols;
    wb.SheetNames.push(ws_name);
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    wb.Sheets[ws_name] = ws;
    const wbout = XLSX.write(wb, {
      type: "binary",
      bookSST: true,
      bookType: "xlsx",
      cellStyles: true,
    });
    saveAs(
      new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      "DueDateReports_" + this.downloadTime + ".xlsx"
    );
    this.downloading = false;
  }

  /* generate a download */
  s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      // tslint:disable-next-line:no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  statusMsgProcess(reportJson: any) {
    if (reportJson) {
      let res = "";
      if (reportJson.is_no_lws) {
        res += "NO LWS";
      }
      if (reportJson.is_no_fsd) {
        res += "NO FSD";
      }
      if (reportJson.is_limit_exceeds) {
        if (res != "") {
          res += ",";
        }
        res += "Above 60% variance";
      }
      if (reportJson.has_base_rate_difference) {
        if (res != "") {
          res += ",";
        }
        res += "Base Rate Change";
      }
      if (reportJson.is_brand_approved) {
        if (res != "") {
          res += ",";
        }
        res += "Manufacturer Nuance";
      }
      return res;
    } else {
      return null;
    }
  }

  getBillingMgmtReportXlsxData() {
    this.downloading = true;
    const csvData: any[] = [];
    const data: any[] = [];
    const rpt: any = this.billingMgmtDetailsList;
    data.push([
      "Project Name",
      "Reason",
      "Requested By",
      "Requested On",
      "Approved By",
      "Approved On",
    ]);
    for (let i = 0; i < rpt.length; i++) {
      if (rpt[i]) {
        data.push([
          rpt[i].project_name ? rpt[i].project_name : null,
          rpt[i].req_status
            ? this.statusMsgProcess(rpt[i].req_status)
            : this.statusMsgProcess(rpt[i].approve_status),
          rpt[i].requested_by ? rpt[i].requested_by : null,
          rpt[i].requested_on ? rpt[i].requested_on : null,
          rpt[i].approved_by ? rpt[i].approved_by : null,
          rpt[i].approved_on ? rpt[i].approved_on : null,
        ]);
      }
    }
    const wb: any = { SheetNames: [], Sheets: {} };
    const ws_name = "MGMT Review Report";
    /* create worksheet: */
    const ws: any = {};
    /* the range object is used to keep track of the range of the sheet */
    const range = { s: { c: 0, r: 0 }, e: { c: 0, r: 0 } };

    /* Iterate through each element in the structure */
    for (let R = 0; R < data.length; R++) {
      if (range.e.r < R) {
        range.e.r = R;
      }
      for (let C = 0; C < data[R].length; C++) {
        if (range.e.c < C) {
          range.e.c = C;
        }
        // tslint:disable-next-line:max-line-length
        const cell = {
          v: data[R][C],
          s: {
            alignment: { textRotation: 90 },
            font: { sz: 16, bold: true, color: "#FF00FF" },
            fill: { bgColor: "#FFFFFF" },
          },
          t: "s",
        };
        if (cell.v == null) {
          continue;
        }
        /* create the correct cell reference */
        console.log("XLSX=============++>", XLSX);
        const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
        /* determine the cell type */
        if (typeof cell.v === "number") {
          cell.t = "n";
        } else if (typeof cell.v === "boolean") {
          cell.t = "b";
        } else {
          cell.t = "s";
        }
        /* add to structure */
        ws[cell_ref] = cell;
      }
    }
    ws["!ref"] = XLSX.utils.encode_range(range);
    const wscols = [
      { wch: 40 }, // "characters"
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ];

    ws["!cols"] = wscols;
    wb.SheetNames.push(ws_name);
    /**
     * Set worksheet sheet to "narrow".
     */
    ws["!margins"] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };
    wb.Sheets[ws_name] = ws;
    const wbout = XLSX.write(wb, {
      type: "binary",
      bookSST: true,
      bookType: "xlsx",
      cellStyles: true,
    });
    saveAs(
      new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }),
      "MGMT Review Report_" + this.downloadTime + ".xlsx"
    );
    this.downloading = false;
    this.showSpinnerButton = false;
  }

  selectAllReport() {
    const items: any[] = [];
    this.selectAllReportFlag = true;
    for (let i = 0; i < this.reportList.length; i++) {
      const obj = {
        id: this.reportList[i].id,
        itemName: this.reportList[i].itemName,
      };
      items.push(obj);
    }
    this.getMainReportLists();
    this.onSelectAllReport(items);
  }

  unSelectAllReport() {
    this.selectAllReportFlag = false;
    this.mainReportSelected = [];
    this.onDeSelectAllReport([]);
  }

  spCountCalculation(isSearch: boolean) {
    if (isSearch) {
      const mainReportSelectedList: any[] = [];
      if (this.mainReportSelected.length > 0) {
        for (let x = 0; x < this.mainReportSelected.length; x++) {
          const t = this.dueDateReportList
            .filter((item: any, index: number) => index < this.dueDateReportList.length)
            .filter(
              (item: any, index: number) =>
                item["report"] === this.mainReportSelected[x]["itemName"]
            );
          for (let y = 0; y < t.length; y++) {
            if (!this.containsObject(t[y], mainReportSelectedList)) {
              mainReportSelectedList.push(t[y]);
            }
          }
        }
      }
      for (let i = 0; i < this.spList.length; i++) {
        const t = mainReportSelectedList
          .filter((item: any, index: number) => index < mainReportSelectedList.length)
          .filter((item: any, index: number) => item["sp"] === this.spList[i]["itemName"]);
        this.spList[i]["count"] = t.length;
      }
    } else {
      console.log("rowDataDueDateReport", this.rowDataDueDateReport);
      for (let i = 0; i < this.spList.length; i++) {
        const t = this.rowDataDueDateReport
          .filter((item: any, index: number) => index < this.rowDataDueDateReport.length)
          .filter((item: any, index: number) => item["sp"] === this.spList[i]["itemName"])
          .filter((item: any, index: number) => item["deadline"] != null);
        this.spList[i]["count"] = t.length;
      }
    }
  }

  updateDeadlineNoteModal(data: any) {
    data = data.replace(/\\'/g, '"');
    this.selectedProjectid = null;
    let result: any = "";
    result = JSON.parse(data);
    $("#deadlineNoteModal").modal({ show: true, backdrop: "static" });
    let objPropertyCalender: { [k: string]: any } = {};
    objPropertyCalender = this.commonService.getCalenderPropertyObject();
    $("#deadlineLastActionDate").datetimepicker(objPropertyCalender);
    this.selectedProjectid = result.projectId;
    this.editDeadlineNoteForm.patchValue({
      deadlineNote: this.safeInput.parse(result.deadlineNotes),
      deadlineNoteDate: moment().format("MM-DD-YYYY"),
      newDeadlineNote: null
    });
  }

  closeDeadlineNoteModal() {
    this.editDeadlineNoteForm.reset();
    $("#deadlineNoteModal").modal("hide");
  }

  saveDeadlineNoteFormSubmit() {
    NProgress.start();
    this.showSpinnerButton = true;
    const oldDeadlineNote = this.editDeadlineNoteForm.get("deadlineNote")?.value
    ? this.editDeadlineNoteForm.get("deadlineNote")?.value.trim()
    : "";
    const deadlineNote = this.editDeadlineNoteForm.get("newDeadlineNote")?.value
      ? this.editDeadlineNoteForm.get("newDeadlineNote")?.value.trim()
      : "";
    let productionActionInput: any = {};
    let currentDate = moment().format("MM.DD.YY");
    let userShortName = this.getUserShortNameById(this.inCreatedBy);
    let deadlineNotes: any = `${currentDate} ${userShortName}  ${deadlineNote} \n ${oldDeadlineNote}`;
    productionActionInput.deadlineNotes = this.safeInput.parse(deadlineNotes);
    productionActionInput = this.commonService.updateCommonAttribute(productionActionInput);
    this.apollo
      .use("solve360updation")
      .mutate({
        mutation: updateDeadlineNoteProject,
        variables: {
          productionActionInput: productionActionInput,
          projectId: this.selectedProjectid,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata) => {
          const result: any = listdata.data;
          NProgress.done();
          if (result.updatePartsProject.id) {
            this.updateLastActionDeadlineDate(this.selectedProjectid, () => {
              this.deadlineNoteUpdateDelayCounter(false);
            });
          }
        },
        error: (err) => {
          NProgress.done();
          this.showSpinnerButton = false;
          this.commonService.errorCallback(err, this);
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }

  updateDeadlineNoteFormSubmit() {
    NProgress.start();
    this.showSpinnerButtonEdit = true;
    const deadlineNote = this.editDeadlineNoteForm.get("deadlineNote")?.value
      ? this.editDeadlineNoteForm.get("deadlineNote")?.value.trim()
      : "";
    let productionActionInput: any = {};
    productionActionInput.deadlineNotes = this.safeInput.parse(deadlineNote);
    productionActionInput = this.commonService.updateCommonAttribute(productionActionInput);
    this.apollo
      .use("solve360updation")
      .mutate({
        mutation: updateDeadlineNoteProject,
        variables: {
          productionActionInput: productionActionInput,
          projectId: this.selectedProjectid,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata) => {
          const result: any = listdata.data;
          NProgress.done();
          if (result.updatePartsProject.id) {
            this.updateLastActionDeadlineDate(this.selectedProjectid, () => {
              this.showSpinnerButtonEdit = false;
              this.editDeadlineNoteFormFlag = false;
              this.deadlineNoteUpdateDelayCounter(true);
            });
          }
        },
        error: (err) => {
          NProgress.done();
          this.showSpinnerButtonEdit = false;
          this.editDeadlineNoteFormFlag = false;
          this.commonService.errorCallback(err, this);
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }

  deadlineNoteUpdateDelayCounter(closeFlag:any) {
    let counter = this.constantService.DEADLINE_NOTE_DELAY_COUNTER;
    const self = this;
    setInterval(function () {
      if (counter > 0) {
        counter--;
        if (counter === 0) {
          self.showSpinnerButton = false;
          self.dueDateReports(true);
          self.showStatusMessage(self.constantService.UPDATE_DEADLINE_NOTE, "success");
          if(!closeFlag) {
            if ($("#deadlineNoteModal").is(":visible")) {
              $("#deadlineNoteModal").modal("hide");
            }
          }
        }
      }
    }, 1000);
  }

  /**
   * flash message style set for success and error
   *
   */
  showStatusMessage(message: any, statusType: any) {
    let statusClass;
    if (statusType === "success") {
      statusClass = "flashfade alert-success flash-message";
    } else {
      statusClass = "flashfade alert-danger";
    }
    this.flashMessagesService.show(message, {
      cssClass: statusClass,
      timeout: 5000,
    });
  }
  setdeadlineLastActionDate(e: any) {
    this.editDeadlineNoteForm.patchValue({
      deadlineNoteDate: e.value,
    });
  }
  updateLastActionDeadlineDate(projectId: any, callback: any) {
    const deadlineNoteDate = this.editDeadlineNoteForm.get("deadlineNoteDate")?.value;
    let productionActionInput: any = {};
    productionActionInput.dateLastActionDeadline = deadlineNoteDate
      ? this.transformDateFormat(deadlineNoteDate, "YYYY-MM-DD")
      : this.constantService.SOLVE360_RESET_VALUE;
    productionActionInput = this.commonService.updateCommonAttribute(productionActionInput);
    this.apollo
      .use("solve360updation")
      .mutate({
        mutation: updateDeadlineNoteProject,
        variables: {
          productionActionInput: productionActionInput,
          projectId: projectId,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata) => {
          const result: any = listdata.data;
          if (result.updatePartsProject.id) {
            if (callback) {
              callback("success");
            }
          } else {
            if (callback) {
              callback("failure");
            }
          }
        },
        error: (err) => {
          this.showSpinnerButton = false;
          if (callback) {
            callback("error");
          }
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }
  transformDateFormat(date: any, format: any) {
    let newDate = date;
    const allowedFormat = ["YYYY-MM-DD", "MM-DD-YYYY", "DD-MM-YYYY"];
    if (allowedFormat.indexOf(format) >= 0) {
      if (date && date !== null && date !== "") {
        const d = moment(date);
        newDate = d.tz(moment.tz.guess()).format(format);
      }
    }
    return newDate;
  }

  setmgmtReportFromDate(e: any) {
    this.mgmtReportForm.patchValue({
      fromDate: e.value,
    });
  }

  setmgmtReportToDate(e: any) {
    this.mgmtReportForm.patchValue({
      toDate: e.value,
    });
  }

  getAduUserList(callback: any) {
    const allAduUsersList = this.apollo.use("portaldbClient").query({
      query: allAduUsers,
      fetchPolicy: "network-only",
    });

    allAduUsersList.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        const userList: any[] = [];
        const groupIdList: any[] = [];
        this.CDAUserListFiltered = [];
        this.salesPersonListFiltered = [];
        $.each(result["data"]["allAduUsers"]["edges"], (key: any, val: any) => {
          const obj = {
            itemName: val.node["shortName"]
              ? val.node["displayName"] + "(" + val.node["shortName"] + ")"
              : val.node["displayName"],
            id: val.node["displayName"],
            isSalesPerson: val.node["isSalesPerson"],
            isDataPullUser: val.node["isDataPullUser"],
            isImportUser: val.node["isImportUser"],
            isMappingUser: val.node["isMappingUser"],
            isPreAuditUser: val.node["isPreAuditUser"],
            isPreAuditReviewer: val.node["isPreAuditReviewer"],
            isBillingMgmtApprover: val.node["isBillingMgmtApprover"],
            isRetrieveRoUser: val.node["isRetrieveRoUser"],
            isImportRoUser: val.node["isImportRoUser"],
            isAuditor: val.node["isAuditor"],
            isReviewer: val.node["isReviewer"],
            isFinalizeUser: val.node["isFinalizeUser"],
            isPostAuditReviewer: val.node["isPostAuditReviewer"],
            isShippingUser: val.node["isShippingUser"],
            shortName: val.node["shortName"],
            solveUserName: val.node["solveUserName"],
            userId: val.node["userId"],
            email: val.node["email"],
            phone: val.node["phone"],
          };
          userList.push(obj);
          groupIdList.push({
            emailId: val.node["email"],
            groupIdList: val.node["groupIdList"],
            userrId: val.node["userId"],
            solveUserName: val.node["solveUserName"],
            displayName: val.node["displayName"],
          });
        });
        this.userList = userList;
        this.userList = this.sortListAsc(this.userList);
        this.currentUserIsProduction = false;
        this.currentUserIsSalesPerson = false;
        this.currentUserIsCDAPerson = false;
        this.currentUserIsImportPerson = false;
        this.currentUserIsProjectAdmin = false;
        groupIdList.forEach((element: any) => {
          if (element.groupIdList && element.groupIdList.includes("1")) {
            // CDA UserPerson check
            this.CDAUserListFiltered.push({
              id: element.solveUserName,
              itemName: element.displayName,
            });
          }
          if (element.groupIdList && element.groupIdList.includes("4")) {
            // SalesPerson check
            this.salesPersonListFiltered.push({
              id: element.solveUserName,
              itemName: element.displayName,
            });
          }
          if (
            this.inCreatedBy &&
            this.inCreatedBy != "" &&
            element.emailId &&
            element.emailId.toLowerCase() == this.inCreatedBy.toLowerCase()
          ) {
            if (element.groupIdList && element.groupIdList.includes("4")) {
              // SalesPerson check
              this.currentUserIsSalesPerson = true;
            }

            if (element.groupIdList && element.groupIdList.includes("1")) {
              // CDA UserPerson check
              this.currentUserIsCDAPerson = true;
            }

            if (element.groupIdList && element.groupIdList.includes("2")) {
              // Admin User check
              this.currentUserIsCDAPerson = false;
              this.currentUserIsSalesPerson = false;
              this.currentUserIsProduction = true;
            }

            if (element.groupIdList && element.groupIdList.includes("5")) {
              // Import UserPerson check
              this.currentUserIsImportPerson = true;
            }

            if (element.groupIdList && element.groupIdList.includes("7")) {
              // Admin User check
              this.currentUserIsProjectAdmin = true;
            }
          }
          // console.log("currentUserIsSalesPerson===>>", this.currentUserIsSalesPerson)
          // console.log("currentUserIsCDAPerson===>>", this.currentUserIsCDAPerson)
        });
        if (callback) {
          callback();
        }
      },
      error: (err) => {
        this.commonService.errorCallback(err, this);
        if (callback) {
          callback();
        }
      },
      complete: () => {
        console.log("Completed");
      },
    });
    return allAduUsersList;
  }

  getUserLisForStage(stageName: any) {
    if (stageName) {
      const stage = this.courtPersonGroupAssignee
        .filter((item: any, index: number) => index < this.courtPersonGroupAssignee.length)
        .filter((item: any, index: number) => item["itemName"] === stageName);
      console.log("stage===>>", stage);

      if (stage && stage.length) {
        const stageId = stage[0].id;
        console.log("this.userList===>>", this.userList);

        const resList = this.userList
          .filter((item: any, index: number) => index < this.userList.length)
          .filter((item: any, index: number) => item[stageId] === true);
        console.log("resList===>>", resList);
        if (resList && resList.length) {
          return resList;
        }
      }
    }
    return [];
  }

  isBillingMgmtApproverAssignee() {
    if (this.inCreatedBy) {
      const t = this.billingMgmtApproverList
        .filter((item: any, index: number) => index < this.billingMgmtApproverList.length)
        .filter(
          (item: any, index: number) =>
            item["email"] && item["email"].toLowerCase() == this.inCreatedBy.toLowerCase()
        );
      if (t.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  hasNotBillingMgmtApprover() {
    if (!this.isBillingMgmtApproverAssignee()) {
      return true;
    } else {
      return false;
    }
  }

  getAllStates(callback: any) {
    console.log("getAllStates...................");
    this.statesList = [];
    const allStateList = this.apollo.use("portaldbClient").query({
      query: allStates,
      fetchPolicy: "network-only",
      variables: {
        isDefaultDeadlineForDdr: true,
      },
    });

    allStateList.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        $.each(result["data"]["allStates"]["edges"], (key: any, val: any) => {
          const obj = {
            itemName: val.node["name"],
            state: val.node["state"],
          };
          this.statesList.push(obj);
        });
        if (callback) {
          callback();
        }
      },
      error: (err) => {
        if (callback) {
          callback();
        }
        this.commonService.errorCallback(err, this);
      },
      complete: () => {
        console.log("Completed");
      },
    });
    return allStateList;
  }

  getDmDetails(inProjectId: any, callback: any) {
    this.dmDetails = null;
    const dmDetailList = this.apollo.use("portaldbClient").query({
      query: getDmStoreDetailsByProject,
      fetchPolicy: "network-only",
      variables: {
        inProjectId: inProjectId,
      },
    });

    dmDetailList.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        if (result["data"]["getDmStoreDetailsByProject"]) {
          const resList = JSON.parse(result["data"]["getDmStoreDetailsByProject"]);
          $.each(resList, (key: any, val: any) => {
            const obj = {
              project_id: val["project_id"],
              dm_id: val["dm_id"],
              st_id: val["st_id"],
              sg_id: val["sg_id"],
              version: val["version"],
            };
            this.dmDetails = obj;
          });
        }

        if (callback) {
          callback(true);
        }
      },
      error: (err) => {
        if (callback) {
          callback(false);
        }
        this.commonService.errorCallback(err, this);
      },
      complete: () => {
        console.log("Completed");
      },
    });
    return dmDetailList;
  }
  goToStoreOverview(projectId: any) {
    if (projectId) {
      const activityData1 = {
        activityName: "goToStoreOverview",
        activityDescription: "goToStoreOverview: started",
        activityData: {
          inProjectId: projectId,
        },
      };
      this.commonService.saveActivity("DueDate Report", activityData1);
      const storageObjectEncoded = btoa(projectId);
      const link = this.constantService.CREATE_DATA_MASTER_URL + "/" + storageObjectEncoded;
      this.router.navigate([]).then((result) => {
        window.open("#" + link, "_blank");
        const activityData2 = {
          activityName: "goToStoreOverview",
          activityDescription: "goToStoreOverview: completed",
          activityData: {
            inProjectId: projectId,
          },
        };
        this.commonService.saveActivity("DueDate Report", activityData2);
      });
    }
  }
  
  showMultipleApprovalNoticeFollowUpPopup(){
    if(this.portalSelectedStList && this.portalSelectedStList.length == 1) {
      //For single project selection
      this.showApprovalNoticeFollowUpPopup(this.portalSelectedStList[0]);
      return;
    }

    const self = this;
    self.billingEmailIdList = [];
    self.customBillingEmailList = [];
    self.billingEmailIdListCc = [];
    self.customBillingEmailListCc = [];
    self.editDeadlineNoteFlag = false;
    self.editEmailSubjectFlag = false;
    self.enableDeadlineNoteEditFlag = true;
    self.templateId = 'emailTemplateGroup2021';

    console.log(
      "showApprovalNoticeFollowUpPopup======================================>>",
      this.portalSelectedStList
    );
    if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
      $("#approvalNoticeFollowUpModal").modal({ show: true, backdrop: "static" });
    }
    const projectList = self.dueDateReportList.filter((item: any, index: number) => index < self.dueDateReportList.length)
                    .filter((item: any, index: number) =>item["id"] == this.portalSelectedStList[0]);
    if(projectList && projectList.length) {
      self.billingLoading = true;
      const project = projectList[0];
      
      self.approvalNoticeFollowUpForm.patchValue({
        hasMultipleProject: true
      });
      self.approvalNoticeFollowUpForm.patchValue({
        projectId: this.portalSelectedStList
      });
      self.approvalNoticeFollowUpForm.patchValue({
        approvalFollowupDetailsCount: project.approvalFollowupDetailsCount
      });
      self.approvalNoticeFollowUpForm.patchValue({
        groupName: project.storeGroupName
      });
      // self.approvalNoticeFollowUpForm.patchValue({
      //   projectType: project.projectType=='Labor UL'? 'Labor': 'Parts'
      // });
      // self.approvalNoticeFollowUpForm.patchValue({
      //   storeName: project.store
      // });
      // self.approvalNoticeFollowUpForm.patchValue({
      //   deadlineDate: this.transformDate(project.deadline)
      // });
      // self.approvalNoticeFollowUpForm.patchValue({
      //   manufacturer: project.manufacturer
      // });
      self.getBillingProcessUserForProjectList(this.portalSelectedStList, () => {
        
        self.getAllBillingProcessUsersCustom((result: any) => {
          self.billingProcessUsers = result;
          console.log(
            "billingProcessUsers custom======================================>>",
            self.billingProcessUsers
          );
          setTimeout(() => {
            self.approvalNoticeFollowUpSectionCollapsed = false;
            self.mailSentStatus = 1;
  
            self.getBillingProcessUserList();
            
            self.approvalNoticeFollowUpForm.patchValue({
              from: self.inCreatedBy,
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category1Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category2Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category3Email: self.billingProcessUserCategory3List,
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category4Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category5Email: [],
            });
  
            self.approvalNoticeFollowUpForm.patchValue({
              from: self.inCreatedBy,
            });
            let subject = "";
            if (
              self.approvalNoticeFollowUpForm.get('projectId')?.value
            ) {
              subject = `*Action Requested* Armatus - Warranty Uplift Approval Schedule - ${project.storeGroupName}`;
            }
            self.approvalNoticeFollowUpForm.patchValue({
              subject: subject,
            });
            self.getBillingProcessUserList();
           }, 100);
        });
      });
    }
  }


  showApprovalNoticeFollowUpPopup(projectId: any){
    const self = this;
    self.billingEmailIdList = [];
    self.billingEmailIdListCc = [];
    self.customBillingEmailList = [];
    self.customBillingEmailListCc = [];
    self.editDeadlineNoteFlag = false;
    self.editEmailSubjectFlag = false;
    self.enableDeadlineNoteEditFlag = true;
    self.templateId = 'emailTemplate2021';
  
    console.log(
      "showApprovalNoticeFollowUpPopup======================================>>",
      projectId
    );
    if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
      $("#approvalNoticeFollowUpModal").modal({ show: true, backdrop: "static" });
    }
    const projectList = self.dueDateReportList.filter((item: any, index: number) => index < self.dueDateReportList.length)
                    .filter((item: any, index: number) =>item["id"] == projectId);
    if(projectList && projectList.length) {
      self.billingLoading = true;
      const project = projectList[0];
      console.log('project==============>>>>',project);
      self.approvalNoticeFollowUpForm.patchValue({
        projectId: [projectId]
      });
      self.approvalNoticeFollowUpForm.patchValue({
        hasMultipleProject: false
      });
      self.approvalNoticeFollowUpForm.patchValue({
        approvalFollowupDetailsCount: project.approvalFollowupDetailsCount
      });
      self.approvalNoticeFollowUpForm.patchValue({
        projectType: project.projectType=='Labor UL'? 'Labor': 'Parts'
      });
      self.approvalNoticeFollowUpForm.patchValue({
        storeName: project.store
      });
      self.approvalNoticeFollowUpForm.patchValue({
        deadlineDate: this.transformDate(project.deadline)
      });
      self.approvalNoticeFollowUpForm.patchValue({
        manufacturer: project.manufacturer
      });
      self.getBillingProcessUser(parseInt(projectId), () => {
        
        self.getAllBillingProcessUsersCustom((result: any) => {
          self.billingProcessUsers = result;
          console.log(
            "billingProcessUsers custom======================================>>",
            self.billingProcessUsers
          );
          setTimeout(() => {
            self.approvalNoticeFollowUpSectionCollapsed = false;
            self.mailSentStatus = 1;
  
            self.getBillingProcessUserList();
            
            self.approvalNoticeFollowUpForm.patchValue({
              from: self.inCreatedBy,
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category1Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category2Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category3Email: self.billingProcessUserCategory3List,
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category4Email: [],
            });
            self.approvalNoticeFollowUpForm.patchValue({
              category5Email: [],
            });
  
            self.approvalNoticeFollowUpForm.patchValue({
              from: self.inCreatedBy,
            });
            let subject = `*Action Requested* Armatus - Warranty Uplift Approval Reminder - ${project.storeGroupName} - ${project.store}`;
            self.approvalNoticeFollowUpForm.patchValue({
              subject: subject,
            });
            self.getBillingProcessUserList();
           }, 100);
        });
      });
    }

  }

  updateDeadlineNoteSolve360(deadlineNote:any, projectId:any, callback:any) {
    console.log('updateDeadlineNoteSolve360===========>>>',projectId, deadlineNote);
    let productionActionInput: any = {};
    productionActionInput.deadlineNotes = deadlineNote;
    productionActionInput.dateLastActionDeadline = moment().format("YYYY-MM-DD");
    productionActionInput = this.commonService.updateCommonAttribute(productionActionInput);
    this.apollo
      .use("solve360updation")
      .mutate({
        mutation: updateDeadlineNoteProject,
        variables: {
          productionActionInput: productionActionInput,
          projectId: projectId,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata) => {
          const result: any = listdata.data;
          if (result.updatePartsProject.id) {
            // this.updateLastActionDeadlineDate(this.selectedProjectid, () => {
              // this.deadlineNoteUpdateDelayCounter();
              if(callback) {
                callback();
              }
            // });
          }
        },
        error: (err) => {
          this.commonService.errorCallback(err, this);
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }

  get emailListToString(){
    let emailString = '';
    if(this.billingEmailIdList && this.billingEmailIdList.length) {
      const emailList = this.billingEmailIdList.map((obj:any) => obj.firstName);
      emailString = emailList.join(', ');
    }
    return emailString;
  }
  getUserShortNameById(userId: any) {
    let resValue = '';
    if (userId && isNaN(userId)) {
      resValue = userId;
      const result = this.userList
        .filter((item: any, index: number) => index < this.userList.length)
        .filter((item: any, index: number) => item["email"] && item["email"] == userId);
      if (result.length) {
        resValue = result[0].shortName;
      }
    } else {
      const result = this.userList
        .filter((item: any, index: number) => index < this.userList.length)
        .filter((item: any, index: number) => item["userId"] == userId);
      if (result.length) {
        resValue = result[0].shortName;
      }
    }
    return resValue;
  }

  closeApprovalNoticeFollowUpPopup() {
    if ($("#approvalNoticeFollowUpModal").is(":visible")) {
      $("#approvalNoticeFollowUpModal").modal("hide");
    }
    this.approvalNoticeFollowUpForm.reset();
    this.customBillingEmailList = [];
    this.customBillingEmailListCc = [];

  }

  approvalNoticeFollowUpToggle() {
    this.approvalNoticeFollowUpSectionCollapsed = !this.approvalNoticeFollowUpSectionCollapsed;
  }
  
  approvalNoticeFollowUpCCToggle() {
    this.approvalNoticeFollowUpSectionCcCollapsed = !this.approvalNoticeFollowUpSectionCcCollapsed;
  }
  getBillingProcessUser(inProjectId: any, callback: any) {
    const subscription = this.apollo
      .use("dashboardClient")
      .query({
        query: getContactsByProjectId,
        fetchPolicy: "network-only",
        variables: {
          inProjectId: inProjectId,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata: any) => {
          console.log(listdata);
          this.billingProcessUsersOrginal = listdata["data"]["getContactsByProjectId"]
            ? JSON.parse(listdata["data"]["getContactsByProjectId"])
            : [];
          console.log("billingProcessUsersOrginal====>>>", this.billingProcessUsersOrginal);
          if (callback) {
            callback();
          }
        },
        error: (err) => {
          this.commonService.errorCallback(err, this);
        },
        complete: () => {
          console.log("Completed");
        },
      });
    return subscription;
  }

  getBillingProcessUserForProjectList(inProjectId: any, callback: any) {
    const subscription = this.apollo
      .use("dashboardClient")
      .query({
        query: getContactsByProjectIdList,
        fetchPolicy: "network-only",
        variables: {
          inProjectId: inProjectId,
        },
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata: any) => {
          console.log(listdata);
          this.billingProcessUsersOrginal = listdata["data"]["getContactsByProjectIdList"]
            ? JSON.parse(listdata["data"]["getContactsByProjectIdList"])
            : [];
          console.log("billingProcessUsersOrginal getBillingProcessUserForProjectList====>>>", this.billingProcessUsersOrginal);
          if (callback) {
            callback();
          }
        },
        error: (err) => {
          this.commonService.errorCallback(err, this);
        },
        complete: () => {
          console.log("Completed");
        },
      });
    return subscription;
  }

  getAllBillingProcessUsersProcess(i: any, data: any, userLists: any, callback: any) {
    console.log(data, i, data[i]);
    if (data && !data.length) {
      callback(userLists);
      return;
    }
    const obj = {
      project_id: data[i].project_id,
      email: data[i].email,
      contact_name: data[i].contact_name,
      firstname: data[i].firstname,
      job_role: data[i].job_role && data[i].job_role.length ? data[i].job_role : [],
      job_role_new: [],
      contact_type: data[i].contact_type,
    };
    console.log("obj", obj);
    let jobRoleList: any = [];
    $.each(obj.job_role, (k: any, v: any) => {
      console.log("role ====>>", v);

      const roleList = v ? v.toLowerCase().split("/") : [];
      console.log("roleList====>>", roleList);

      // roleList.splice(roleList.indexOf(' '), 1);
      jobRoleList = jobRoleList.concat(roleList);
    });
    const unique = jobRoleList.filter(function (elem: any, index: any, self: any) {
      return index === self.indexOf(elem);
    });
    console.log(jobRoleList, unique);
    obj.job_role_new = unique;
    userLists.push(obj);
    console.log(userLists);
    if (i == 0 || i < data.length - 1) {
      i = i + 1;
      console.log("i===>>", i, data.length);
      this.getAllBillingProcessUsersProcess(i, data, userLists, callback);
    } else {
      console.log("ELSE===============>>", i, userLists);
      callback(userLists);
    }
  }
  getAllBillingProcessUsersCustom(callback: any) {
    const userLists: any[] = [];
    this.getAllBillingProcessUsersProcess(0, this.billingProcessUsersOrginal, userLists, callback);
  }


  getBillingProcessUserList() {
    this.billingProcessUserList = [];
    this.billingEmailIdList = [];
    this.billingEmailIdListCc = [];

    this.billingProcessUserCategory1List = [];
    this.billingProcessUserCategory2List = [];
    this.billingProcessUserCategory3List = [];
    this.billingProcessUserCategory4List = [];
    this.billingProcessUserCategory5List = [];
    const category1 = "Dealer Principal";
    const category2 = "Billing Contact";
    const category3 = "Project Liaison";
    const category4One = "IT Contact";
    const category4Two = "Dms";

    let isFount = false;

    if (this.billingProcessUsers && this.billingProcessUsers.length) {
      $.each(this.billingProcessUsers, (key: any, val: any) => {
        if (val.email) {
          isFount = false;
          const obj = {
            id: val.email,
            itemName: val.email,
            contactName: val.contact_name,
            firstName: val.firstname,
            jobRole: val.job_role_new && val.job_role_new.length ? val.job_role_new : [],
            contactType: val.contact_type,
          };
          const jobRole = Object.assign([], obj.jobRole);
          const exist1List = obj.jobRole
            .filter((item: any, index: any) => index < obj.jobRole.length)
            .filter(
              (item: any, index: any) =>
                item && item.toLowerCase().split(category1.toLowerCase()).length > 1
            );
          if (exist1List && exist1List.length) {
            if (!this.containsObject(obj, this.billingProcessUserCategory1List)) {
              this.billingProcessUserCategory1List.push(Object.assign({}, obj));
            }
            isFount = true;
            const index0 = jobRole.indexOf(category1.toLowerCase());
            if (index0 >= 0) {
              jobRole.splice(index0, 1);
            }
          }

          const exist2List = obj.jobRole
            .filter((item: any, index: any) => index < obj.jobRole.length)
            .filter(
              (item: any, index: any) =>
                item && item.toLowerCase().split(category2.toLowerCase()).length > 1
            );
          if (exist2List && exist2List.length) {
            if (!this.containsObject(obj, this.billingProcessUserCategory2List)) {
              this.billingProcessUserCategory2List.push(Object.assign({}, obj));
            }
            isFount = true;
            const index1 = jobRole.indexOf(category2.toLowerCase());
            if (index1 >= 0) {
              jobRole.splice(index1, 1);
            }
          }

          const exist3List = obj.jobRole
            .filter((item: any, index: any) => index < obj.jobRole.length)
            .filter(
              (item: any, index: any) =>
                item && item.toLowerCase().split(category3.toLowerCase()).length > 1
            );
          if (exist3List && exist3List.length) {
            if (!this.containsObject(obj, this.billingProcessUserCategory3List)) {
              this.billingProcessUserCategory3List.push(Object.assign({}, obj));
            }
            isFount = true;
            const index2 = jobRole.indexOf(category3.toLowerCase());
            if (index2 >= 0) {
              jobRole.splice(index2, 1);
            }
          }

          const exist4List = obj.jobRole
            .filter((item: any, index: any) => index < obj.jobRole.length)
            .filter(
              (item: any, index: any) =>
                item &&
                (item.toLowerCase().split(category4One.toLowerCase()).length > 1 ||
                  item.toLowerCase().split(category4Two.toLowerCase()).length > 1)
            );
          if (exist4List && exist4List.length) {
            if (!this.containsObject(obj, this.billingProcessUserCategory4List)) {
              this.billingProcessUserCategory4List.push(Object.assign({}, obj));
            }
            isFount = true;
            console.log(jobRole);
            const index3 = jobRole.indexOf(category4One.toLowerCase());
            if (index3 >= 0) {
              jobRole.splice(index3, 1);
            }
            const index4 = jobRole.indexOf(category4Two.toLowerCase());
            if (index4 >= 0) {
              jobRole.splice(index4, 1);
            }
          }

          if (jobRole.length) {
            if (!this.containsObject(obj, this.billingProcessUserCategory5List)) {
              obj.itemName =
                jobRole && jobRole.length ? `${obj.id} [${jobRole.toString()}]` : obj.id;
              this.billingProcessUserCategory5List.push(Object.assign({}, obj));
            }
          }
          this.billingProcessUserList.push(Object.assign({}, obj));
        }
      });
    }
    console.log("billingProcessUserCategory1List=====>>>", this.billingProcessUserCategory1List);
    console.log("billingProcessUserCategory2List=====>>>", this.billingProcessUserCategory2List);
    console.log("billingProcessUserCategory3List=====>>>", this.billingProcessUserCategory3List);
    console.log("billingProcessUserCategory4List=====>>>", this.billingProcessUserCategory4List);
    console.log("billingProcessUserCategory5List=====>>>", this.billingProcessUserCategory5List);

    console.log("billingCategory1EmailIdList=====>>>", this.billingCategory1EmailIdList);
    console.log("billingCategory2EmailIdList=====>>>", this.billingCategory2EmailIdList);
    console.log("billingCategory3EmailIdList=====>>>", this.billingCategory3EmailIdList);
    console.log("billingCategory4EmailIdList=====>>>", this.billingCategory4EmailIdList);
    console.log("billingCategory5EmailIdList=====>>>", this.billingCategory5EmailIdList);

    this.billingEmailIdList = [];
    $.each(this.billingCategory1EmailIdList, (key: any, val: any) => {
      if (!this.containsObject(val, this.billingEmailIdList)) {
        const list = this.billingProcessUserCategory1List
        .filter((item: any, index: any) => index < this.billingProcessUserCategory1List.length)
        .filter(
          (item: any, index: any) => item && item.id.toLowerCase() == val.id.toLowerCase()
        );
        console.log("list=======>>>",list);
        if(list && list.length) {
          this.billingEmailIdList.push(list[0]);
        }
      }
    });

    $.each(this.billingCategory2EmailIdList, (key: any, val: any) => {
      if (!this.containsObject(val, this.billingEmailIdList)) {
        const list = this.billingProcessUserCategory2List
        .filter((item: any, index: any) => index < this.billingProcessUserCategory2List.length)
        .filter(
          (item: any, index: any) => item && item.id.toLowerCase() == val.id.toLowerCase()
        );
        console.log("list=======>>>",list);
        if(list && list.length) {
          this.billingEmailIdList.push(list[0]);
        }
      }
    });

    $.each(this.billingCategory3EmailIdList, (key: any, val: any) => {
      if (!this.containsObject(val, this.billingEmailIdList)) {
        const list = this.billingProcessUserCategory3List
        .filter((item: any, index: any) => index < this.billingProcessUserCategory3List.length)
        .filter(
          (item: any, index: any) => item && item.id.toLowerCase() == val.id.toLowerCase()
        );
        console.log("list=======>>>",list);
        if(list && list.length) {
          this.billingEmailIdList.push(list[0]);
        }
      }
    });

    $.each(this.billingCategory4EmailIdList, (key: any, val: any) => {
      if (!this.containsObject(val, this.billingEmailIdList)) {
        const list = this.billingProcessUserCategory4List
        .filter((item: any, index: any) => index < this.billingProcessUserCategory4List.length)
        .filter(
          (item: any, index: any) => item && item.id.toLowerCase() == val.id.toLowerCase()
        );
        console.log("list=======>>>",list);
        if(list && list.length) {
          this.billingEmailIdList.push(list[0]);
        }
      }
    });

    $.each(this.billingCategory5EmailIdList, (key: any, val: any) => {
      if (!this.containsObject(val, this.billingEmailIdList)) {
        const list = this.billingProcessUserCategory5List
        .filter((item: any, index: any) => index < this.billingProcessUserCategory5List.length)
        .filter(
          (item: any, index: any) => item && item.id.toLowerCase() == val.id.toLowerCase()
        );
        console.log("list=======>>>",list);
        if(list && list.length) {
          this.billingEmailIdList.push(list[0]);
        }
      }
    });

    $.each(this.customBillingEmailList, (key: any, val: any) => {
      const exist6List = this.billingEmailIdList
        .filter((item: any, index: any) => index < this.billingEmailIdList.length)
        .filter(
          (item: any, index: any) => val && item && item.id.toLowerCase() == val.toLowerCase()
        );
      if (exist6List && !exist6List.length) {
        const obj1 = {
          id: val,
          itemName: val,
          contactName: val,
          firstName: val,
          jobRole: [],
          contactType: "Manual",
        };
        this.billingEmailIdList.push(obj1);
      }
    });
    console.log("billingEmailIdList=====>>>", this.billingEmailIdList);
    this.approvalNoticeFollowUpForm.patchValue({
      to: this.billingEmailIdList,
    });

    $.each(this.customBillingEmailListCc, (key: any, val: any) => {
      const exist6List = this.billingEmailIdListCc
        .filter((item: any, index: any) => index < this.billingEmailIdListCc.length)
        .filter(
          (item: any, index: any) => val && item && item.id.toLowerCase() == val.toLowerCase()
        );
      if (exist6List && !exist6List.length) {
        const obj1 = {
          id: val,
          itemName: val,
          contactName: val,
          firstName: val,
          jobRole: [],
          contactType: "Manual",
        };
        this.billingEmailIdListCc.push(obj1);
      }
    });
    console.log("billingEmailIdListCc=====>>>", this.billingEmailIdListCc);
    this.approvalNoticeFollowUpForm.patchValue({
      cc: this.billingEmailIdListCc,
    });

    this.portalSelectedList = this.sortListAscDeadlineDate(this.portalSelectedList);
    console.log("portalSelectedList sortListAscDeadlineDate===>>", this.portalSelectedList)
    setTimeout(() => {
      this.billingLoading = false;
      this.message = $(`#${this.templateId}`).html();
      this.approvalNoticeFollowUpForm.patchValue({
        message: this.message
      });

      let currentDate = moment().format("MM.DD.YY");
      let userShortName = this.getUserShortNameById(this.inCreatedBy);
      let followUp = (this.approvalNoticeFollowUpForm.get('approvalFollowupDetailsCount')?.value == 0 ? 'First Follow-Up':(this.approvalNoticeFollowUpForm.get('approvalFollowupDetailsCount')?.value == 1 ? 'Second Follow-Up': (this.approvalNoticeFollowUpForm.get('approvalFollowupDetailsCount')?.value == 2 ? 'Third Follow-Up': '')));
      console.log('billingEmailIdList====>>>>',this.billingEmailIdList);
      const emailList = this.billingEmailIdList.map((obj:any) => obj.contactName);
      console.log('emailList====>>>>',emailList);
      let emailString = emailList.join(', ');
      if(this.enableDeadlineNoteEditFlag == true) {
        let deadlineNotes: any = `${currentDate} ${userShortName}  ${followUp} - Emailed ${emailString} for Approval Notice.`;
        this.approvalNoticeFollowUpForm.patchValue({
          deadlineNotes: deadlineNotes
        });
      }
    }, 1000);
  }

  onSelectBillingCategory1User(item1: any) {
    console.log('onSelectBillingCategory1User====>>>>',item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  onDeSelectBillingCategory1User(item1: any) {
    console.log(item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  onSelectAllBillingCategory1User(items: any) {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.billingCategory1EmailIdList)) {
        this.billingCategory1EmailIdList.push(items[i]);
      }
    }
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  onDeSelectAllBillingCategory1User(items: any) {
    console.log(items);
    this.billingCategory1EmailIdList = [];
    this.getBillingProcessUserList();
  }

  onSelectBillingCategory2User(item1: any) {
    console.log('onSelectBillingCategory2User====>>>>',item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  onDeSelectBillingCategory2User(item1: any) {
    console.log(item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  onSelectAllBillingCategory2User(items: any) {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.billingCategory2EmailIdList)) {
        this.billingCategory2EmailIdList.push(items[i]);
      }
    }
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  onDeSelectAllBillingCategory2User(items: any) {
    console.log(items);
    this.billingCategory2EmailIdList = [];
    this.getBillingProcessUserList();
  }

  onSelectBillingCategory3User(item1: any) {
    console.log('onSelectBillingCategory3User====>>>>',item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  onDeSelectBillingCategory3User(item1: any) {
    console.log(item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  onSelectAllBillingCategory3User(items: any) {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.billingCategory3EmailIdList)) {
        this.billingCategory3EmailIdList.push(items[i]);
      }
    }
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  onDeSelectAllBillingCategory3User(items: any) {
    console.log(items);
    this.billingCategory3EmailIdList = [];
    this.getBillingProcessUserList();
  }

  onSelectBillingCategory4User(item1: any) {
    console.log('onSelectBillingCategory4User====>>>>',item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  onDeSelectBillingCategory4User(item1: any) {
    console.log(item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  onSelectAllBillingCategory4User(items: any) {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.billingCategory4EmailIdList)) {
        this.billingCategory4EmailIdList.push(items[i]);
      }
    }
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  onDeSelectAllBillingCategory4User(items: any) {
    console.log(items);
    this.billingCategory4EmailIdList = [];
    this.getBillingProcessUserList();
  }

  onSelectBillingCategory5User(item1: any) {
    console.log('onSelectBillingCategory5User====>>>>',item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect parent
   *
   */
  onDeSelectBillingCategory5User(item1: any) {
    console.log(item1);
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when select all Parent
   *
   */
  onSelectAllBillingCategory5User(items: any) {
    console.log(items);
    for (let i = 0; i < items.length; i++) {
      if (!this.containsObject(items[i], this.billingCategory5EmailIdList)) {
        this.billingCategory5EmailIdList.push(items[i]);
      }
    }
    this.getBillingProcessUserList();
  }

  /**
   *  Apply Filter, when unselect all Parent
   *
   */
  onDeSelectAllBillingCategory5User(items: any) {
    console.log(items);
    this.billingCategory5EmailIdList = [];
    this.getBillingProcessUserList();
  }

  removeEmailIdsFromListCc(email: any) {
    const self = this;
    let msg = self.constantService.BILLING_MAIL_DISPLAY.DELETE_REC_WARNING;
    msg = msg.replace("{{mailAddress}}", email);
    swal(
      {
        title: "Are you sure?",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger pointer",
        cancelButtonClass: "btn-default pointer",
        confirmButtonText: "Remove",
        closeOnConfirm: true,
      },
      () => {
        const index = self.customBillingEmailListCc.indexOf(email);
        if (index > -1) {
          self.customBillingEmailListCc.splice(index, 1);
          self.approvalNoticeFollowUpForm.patchValue({
            customEmailCc: self.customBillingEmailListCc,
          });
          for (let n = 0; n < this.billingEmailIdListCc.length; n++) {
            if (this.billingEmailIdListCc[n].id && email && this.billingEmailIdListCc[n].id.toLowerCase() == email.toLowerCase()) {
              this.billingEmailIdListCc.splice(n, 1);
              break;
            }
          }
          setTimeout(()=>{
            this.getBillingProcessUserList();
          },50);
          console.log("billingEmailIdListCc=====>>>", self.billingEmailIdListCc);
        }
      }
    );
  }

  removeEmailIdsFromList(email: any) {
    const self = this;
    let msg = self.constantService.BILLING_MAIL_DISPLAY.DELETE_REC_WARNING;
    msg = msg.replace("{{mailAddress}}", email);
    swal(
      {
        title: "Are you sure?",
        text: msg,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger pointer",
        cancelButtonClass: "btn-default pointer",
        confirmButtonText: "Remove",
        closeOnConfirm: true,
      },
      () => {
        const index = self.customBillingEmailList.indexOf(email);
        if (index > -1) {
          self.customBillingEmailList.splice(index, 1);
          self.approvalNoticeFollowUpForm.patchValue({
            customEmail: self.customBillingEmailList,
          });
          for (let n = 0; n < this.billingEmailIdList.length; n++) {
            if (this.billingEmailIdList[n].id && email && this.billingEmailIdList[n].id.toLowerCase() == email.toLowerCase()) {
              this.billingEmailIdList.splice(n, 1);
              break;
            }
          }
          setTimeout(()=>{
            this.getBillingProcessUserList();
          },50);
          console.log("billingEmailIdList=====>>>", self.billingEmailIdList);
        }
      }
    );
  }

  addRecipientsCc(type: any) {
    const self = this;
    if (type === "M") {
      //close popup
      if ($("#approvalNoticeFollowUpModal").is(":visible")) {
        $("#approvalNoticeFollowUpModal").modal("hide");
      }
    }
    setTimeout(() => {
      swal(
        {
          title: "Add New Contact",
          text: "Enter a valid email",
          type: "input",
          showCancelButton: true,
          closeOnConfirm: false,
          inputPlaceholder: "Email-Id",
          className: "confirm-modal-inv-generation",
          customClass: "confirm-modal-inv-generation",
        },
        function (inputValue: any) {
          console.log("inputValue====>>>>>>>>>>>", inputValue);
          if (inputValue === false) {
            if (type === "M") {
              //open popup
              if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
                $("#approvalNoticeFollowUpModal").modal({
                  show: true,
                  backdrop: "static",
                });
              }
            }
            return false;
          } else if (inputValue === "") {
            swal.showInputError("Enter a valid Email");
            return false;
          } else {
            const re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(inputValue).toLowerCase())) {
              // Add to existing email array
              // reload list
              self.customBillingEmailListCc.push(inputValue);
              self.approvalNoticeFollowUpForm.patchValue({
                customEmail: self.customBillingEmailListCc,
              });

              $.each(self.customBillingEmailListCc, (key: any, val: any) => {
                const exist6List = self.billingEmailIdListCc
                  .filter((item: any, index: any) => index < self.billingEmailIdListCc.length)
                  .filter(
                    (item: any, index: any) =>
                      val && item && item.id.toLowerCase() == val.toLowerCase()
                  );
                if (exist6List && !exist6List.length) {
                  const obj1 = {
                    id: val,
                    itemName: val,
                    contactName: val,
                    firstName: val,
                    jobRole: [],
                    contactType: "Manual",
                  };
                  self.billingEmailIdListCc.push(obj1);
                  self.approvalNoticeFollowUpForm.patchValue({
                    to: self.billingEmailIdListCc,
                  });
                }
              });
              console.log("billingEmailIdListCc=====>>>", self.billingEmailIdListCc);

              swal("Done", "Added Contact: " + inputValue, "success");
              // console.log(
              //   self.approvalNoticeFollowUpForm.valid,
              // );
              if (type === "M") {
                //open popup
                setTimeout(() => {
                  self.getBillingProcessUserList();
                }, 1000);
                if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
                  $("#approvalNoticeFollowUpModal").modal({
                    show: true,
                    backdrop: "static",
                  });
                }
              }
            } else {
              swal.showInputError("Enter a valid Email");
            }
          }
          return null;
        }
      );
    }, 250);
  }

  addRecipients(type: any) {
    const self = this;
    if (type === "M") {
      //close popup
      if ($("#approvalNoticeFollowUpModal").is(":visible")) {
        $("#approvalNoticeFollowUpModal").modal("hide");
      }
    }
    setTimeout(() => {
      swal(
        {
          title: "Add New Contact",
          text: "Enter a valid email",
          type: "input",
          showCancelButton: true,
          closeOnConfirm: false,
          inputPlaceholder: "Email-Id",
          className: "confirm-modal-inv-generation",
          customClass: "confirm-modal-inv-generation",
        },
        function (inputValue: any) {
          console.log("inputValue====>>>>>>>>>>>", inputValue);
          if (inputValue === false) {
            if (type === "M") {
              //open popup
              if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
                $("#approvalNoticeFollowUpModal").modal({
                  show: true,
                  backdrop: "static",
                });
              }
            }
            return false;
          } else if (inputValue === "") {
            swal.showInputError("Enter a valid Email");
            return false;
          } else {
            const re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(String(inputValue).toLowerCase())) {
              // Add to existing email array
              // reload list
              self.customBillingEmailList.push(inputValue);
              self.approvalNoticeFollowUpForm.patchValue({
                customEmail: self.customBillingEmailList,
              });

              $.each(self.customBillingEmailList, (key: any, val: any) => {
                const exist6List = self.billingEmailIdList
                  .filter((item: any, index: any) => index < self.billingEmailIdList.length)
                  .filter(
                    (item: any, index: any) =>
                      val && item && item.id.toLowerCase() == val.toLowerCase()
                  );
                if (exist6List && !exist6List.length) {
                  const obj1 = {
                    id: val,
                    itemName: val,
                    contactName: val,
                    firstName: val,
                    jobRole: [],
                    contactType: "Manual",
                  };
                  self.billingEmailIdList.push(obj1);
                  self.approvalNoticeFollowUpForm.patchValue({
                    to: self.billingEmailIdList,
                  });
                }
              });
              console.log("billingEmailIdList=====>>>", self.billingEmailIdList);

              swal("Done", "Added Contact: " + inputValue, "success");
              // console.log(
              //   self.approvalNoticeFollowUpForm.valid,
              // );
              if (type === "M") {
                //open popup
                setTimeout(() => {
                  self.getBillingProcessUserList();
                }, 1000);
                if (!$("#approvalNoticeFollowUpModal").is(":visible")) {
                  $("#approvalNoticeFollowUpModal").modal({
                    show: true,
                    backdrop: "static",
                  });
                }
              }
            } else {
              swal.showInputError("Enter a valid Email");
            }
          }
          return null;
        }
      );
    }, 250);
  }

  trackByFn(index: any, item: any) {
    return index; // or item.id
  }
  
  getEmailByShortName(shortName: any) {
    let resValue = null;
    if (shortName) {
      const result = this.userList
        .filter((item, index) => index < this.userList.length)
        .filter((item, index) => item["shortName"] && item["shortName"] == shortName);
      if (result.length) {
        resValue = result[0].email;
      }
    }
    return resValue;
  }

  sentMailForm(submitBtn: any) {
    submitBtn.disabled = true;
    let activityData = {
      activityName:
        "Sent mail Form : Started in Approval FollowUp Process: DueDate Report",
      activityDescription: "Current Page: " + this.router.url,
    };
    this.commonService.saveActivity("DueDate Report", activityData);
    const inProjectId = this.approvalNoticeFollowUpForm.get("projectId")?.value;
    const deadlineNotes = this.approvalNoticeFollowUpForm.get("deadlineNotes")?.value;

    const toEmailIdList =
      this.approvalNoticeFollowUpForm.get("to")?.value && this.approvalNoticeFollowUpForm.get("to")?.value.length
        ? this.approvalNoticeFollowUpForm.get("to")?.value
        : [];
    const to: any[] = [];
    for (let i = 0; i < toEmailIdList.length; i++) {
      to.push(toEmailIdList[i].id);
    }

    const ccEmailIdList =
      this.approvalNoticeFollowUpForm.get("cc")?.value && this.approvalNoticeFollowUpForm.get("cc")?.value.length
        ? this.approvalNoticeFollowUpForm.get("cc")?.value
        : [];
    /** BCC setup for FOPC Users */
    let bccMailList = "";
    // if (this.parent.selectedStore && this.parent.selectedStore.isFopcStore) {
    //   bccMailList = this.parent.FOPCUserListFiltered.map((obj: any) => obj.emailId).join(", ");
    // }
    /** BCC setup for FOPC Users */

    function isValidEmail(email: string): boolean {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    }

    function areEmailsValid(emails: string[]): boolean {
      for (const email of emails) {
        if (!isValidEmail(email)) {
          return false; // Return false if any email in the array is invalid
        }
      }
      return true; // All emails in the array are valid
    }
    if (areEmailsValid(to)) {
      const from = this.approvalNoticeFollowUpForm.get("from")?.value;
      const subject = this.approvalNoticeFollowUpForm.get("subject")?.value;
      let message: any = this.approvalNoticeFollowUpForm.get("message")?.value;
      console.log("All emails in the array are valid", message);
      if (message.includes("<table>")) {
        message = message.replaceAll(
          `<table>`,
          `<table class="table no-footer" role="grid" border="1">`
        );
      }
      if (message.includes("<th>Project Type</th>")) {
        message = message.replaceAll(
          `<th>Project Type</th>`,
          `<th style="width:90px;">Project Type</th>`
        );
      }
      if (message.includes("<th>Factory Deadline Date</th>")) {
        message = message.replaceAll(
          `<th>Factory Deadline Date</th>`,
          `<th style="width:170px;">Factory Deadline Date</th>`
        );
      }
      console.log("All emails in the array are valid final", message);

      activityData = {
        activityName:
          "Sent mail Form : Validation Success in Approval FollowUp Process: DueDate Report",
        activityDescription: "Current Page: " + this.router.url,
      };
      this.commonService.saveActivity("DueDate Report", activityData);
        const conversionEncryptOutput = CryptoJS.AES.encrypt(
          htmlEntities.encode(message, { mode: "nonAsciiPrintable" }),
          "ARMATUS2020"
        ).toString();
        const conversionDecryptOutput = CryptoJS.AES.decrypt(
          conversionEncryptOutput.trim(),
          "ARMATUS2020"
        ).toString(CryptoJS.enc.Utf8);
        const conversionDecodedOutput = htmlEntities.decode(conversionDecryptOutput, {
          level: "html5",
        });
        // console.log("conversionDecodedOutput====>>",conversionDecodedOutput);
        let userPhoneNo = null;
        const resultUserList = this.userList
          .filter((item: any, index: any) => index < this.userList.length)
          .filter(
            (item: any, index: any) =>
              item["email"] && item["email"].toLowerCase() == this.inCreatedBy.toLowerCase()
          );
        if (resultUserList.length) {
          userPhoneNo = resultUserList[0].phone;
        }
        const userName = this.inCreatedByName;
        const userEmail = this.inCreatedBy;
        console.log("userName=====>>>>", userName);
        let customerEmailFrom = 'adu-devl-internal@netspective.support<adu-devl-internal@netspective.support>';
        const salesPersonList = [];
        for(let i=0; i< inProjectId.length; i++) {
          const projectList = this.dueDateReportList.filter((item: any, index: number) => index < this.dueDateReportList.length)
          .filter((item: any, index: number) =>item["id"] == inProjectId[i]);
          if(projectList && projectList.length) {
            const project = projectList[0];
            let emailId = project && project.sp
                ? this.getEmailByShortName(project.sp)
                : null;
            if(emailId && salesPersonList.indexOf(emailId) == -1) {
              salesPersonList.push(emailId);
            }
          }
        }
        console.log('salesPersonList=======>>',salesPersonList);
        console.log('cc List=======>>',ccEmailIdList);

        for (let i = 0; i < ccEmailIdList.length; i++) {
          if(salesPersonList.indexOf(ccEmailIdList[i].id) == -1) {
            salesPersonList.push(ccEmailIdList[i].id);
          }
        }
        console.log('salesPersonList + cc=======>>',salesPersonList);

        const emailObj = {
          to: to.toString(),
          subject: subject,
          emailBody: conversionEncryptOutput,
          attachments: null,
          from: customerEmailFrom,
          userName: userName,
          userEmail: userEmail,
          mobileNo: userPhoneNo ? userPhoneNo : null,
          cc: salesPersonList.length ? salesPersonList.toString() : '',
          bcc: bccMailList,
          inProjectId: inProjectId,
          deadlineNotes: deadlineNotes
        };
        console.log("Approval FollowUp Process emailObj=====>>>>", emailObj);

        this.sentMailFormAction(emailObj, submitBtn, (resp: any) => {
          this.mailSentStatus = 2;
          setTimeout(() => {
            this.mailSentStatus = 3;
            this.progressMessage = "Success";
          }, 2000);
          this.showSpinner = false;
          if (resp) {
            setTimeout(() => {
              if (resp.status === "success") {
                this.showStatusMessage(
                  "Mail Sent Successfully in Approval FollowUp Process.",
                  "success"
                );

                let inProjectIdList = emailObj.inProjectId;
                let inMailSendBy = emailObj.userName;
            
                let inMailObjectId =
                resp.message && resp.message.responseBody && resp.message.responseBody.id
                    ? resp.message.responseBody.id.replace("<", "").replace(">", "")
                    : null;
    
                this.updateFollowUpDetails(inMailSendBy, inMailObjectId, inProjectIdList,()=>{
                  this.displayProgressBar = false;
                  this.approvalNoticeFollowUpForm.reset();
                  this.customBillingEmailList = [];
                  this.portalSelectedList = [];
                  this.portalSelectedStList = [];
  
                  submitBtn.disabled = false;
                  const activityData = {
                    activityName:
                      "Close Compose : Mail Sent Successfully in Approval FollowUp Process: DueDate Report",
                    activityDescription: "Current Page: " + this.router.url,
                  };
                  this.commonService.saveActivity("DueDate Report", activityData);
                  if ($("#approvalNoticeFollowUpModal").is(":visible")) {
                    $("#approvalNoticeFollowUpModal").modal("hide");
                  }
                  this.mailSentStatus = 1;
                  this.displayProgressBar = false;
                  this.progressMessage = "In Progress ...";
                  this.showSpinner = false;
                })
                this.deadlineNoteUpdateProcess(0 , emailObj.inProjectId, deadlineNotes,()=>{

                });


              } else {
                swal({
                  title: "Error while sending Mail in Approval FollowUp Process",
                  type: "warning",
                  confirmButtonClass: "btn-warning pointer",
                  confirmButtonText: this.constantService.CLOSE,
                });
                submitBtn.disabled = false;
                this.mailSentStatus = 1;
                this.displayProgressBar = false;
                this.progressMessage = "In Progress ...";
                this.showSpinner = false;
                const activityData = {
                  activityName:
                    "Close Compose : Error while sending Mail in Approval FollowUp Process : DueDate Report",
                  activityDescription: "Current Page: " + this.router.url,
                  activityData: resp,
                };
                this.commonService.saveActivity("DueDate Report", activityData);
              }
            }, 4000);
          } else {
            swal({
              title: "Error while sending Mail in Approval FollowUp Process",
              type: "warning",
              confirmButtonClass: "btn-warning pointer",
              confirmButtonText: this.constantService.CLOSE,
            });
            const activityData = {
              activityName:
                "Close Compose : Error while sending Mail in Approval FollowUp Process: DueDate Report",
              activityDescription: "Current Page: " + this.router.url,
              activityData: resp,
            };
            this.commonService.saveActivity("DueDate Report", activityData);
            this.mailSentStatus = 1;
            this.displayProgressBar = false;
            this.progressMessage = "In Progress ...";
            this.showSpinner = false;
          }
        });
    } else {
      console.log("Some emails in the array are not valid");
      swal({
        title: `Email ID Not Valid`,
        type: "warning",
        confirmButtonClass: "btn-warning pointer",
        confirmButtonText: this.constantService.CLOSE,
      });
    }
  }

  sentMailFormAction(emailObj: any, submitBtn: any, callback: any) {
    const self = this;
    console.log("emailObj===>>>", emailObj);
    const activityData = {
      activityName: "Sent mail Form : Inside Action: Duedate Report",
      activityDescription: "Current Page: " + this.router.url,
    };
    this.commonService.saveActivity("DueDate Report", activityData);
    const formData: any = new FormData();
    const formData2: any = new FormData();
    if (!emailObj.from) {
      swal({
        title: `Sales Person does not exist!`,
        type: "warning",
        confirmButtonClass: "btn-warning pointer",
        confirmButtonText: this.constantService.CLOSE,
      });
      const activityData = {
        activityName:
          "Sent mail Form : Warning : Sales Person does not exist:Duedate Report",
        activityDescription: "Current Page: " + this.router.url,
        activityData: emailObj,
      };
      this.commonService.saveActivity("DueDate Report", activityData);
      submitBtn.disabled = false;
      this.mailSentStatus = 1;
      this.displayProgressBar = false;
      this.progressMessage = "In Progress ...";
      this.showSpinner = false;
    } else {
      const activityData = {
        activityName:
          "Compose Mail : Start Mail Sending  in Approval FollowUp Process: DueDate Report",
        activityDescription: "Current Page: " + this.router.url,
      };
      this.commonService.saveActivity("DueDate Report", activityData);
      this.displayProgressBar = true;
      this.showSpinner = true;
      const processId = new Date().getTime();
      formData.append(
        "mailContent",
        JSON.stringify({
          to: emailObj.to,
          subject: emailObj.subject,
          mailBody: emailObj.emailBody,
          from: emailObj.from,
          cc: emailObj.cc,
          bcc: emailObj.bcc,
          attachments: emailObj.attachments,
          userName: emailObj.userName,
          mobileNo: emailObj.mobileNo,
          userEmail: emailObj.userEmail,
          inProjectId: emailObj.inProjectId,
          createddate: moment().format("MM-DD-YYYY"),
        })
      );
      formData.append("processId", processId);
      const mData = JSON.stringify({
        to: emailObj.to,
        cc: emailObj.cc,
        bcc: emailObj.bcc,
        subject: emailObj.subject,
        mailBody: emailObj.emailBody,
        from: emailObj.from,
        userName: emailObj.userName,
        mobileNo: emailObj.mobileNo,
        inProjectId: emailObj.inProjectId,
        processId: processId,
      });
      const activityData1 = {
        activityName:
          "Compose Mail : Start Mail Sending in Approval FollowUp Process: DueDate Report",
        activityDescription: "Parts Approval FollowUp Process ",
        activityData: mData,
      };
      this.commonService.saveActivity("DueDate Report", activityData1);

      console.log("form data variable :   " + formData.toString());
      const token = localStorage.getItem("token");
      const authenticationHeader = new HttpHeaders({
        authorization: token ? `Bearer ${token}` : "",
      });

      this.http
        .post(environment.domainUrl + "/backend/mailSenderWithApprovalFollowUpProcess", formData, {
          headers: authenticationHeader,
        })
        .subscribe(
          (data: any) => {
            let resultData: any = data;
            console.log("response for Email Sending ==>", data);
            if (Object.prototype.hasOwnProperty.call(resultData, "errors")) {
              resultData = resultData.errors[0];
            }
            const activityData = {
              activityName: "Compose Mail : Approval FollowUp Mail Sending Completed ",
              activityDescription: "Mail Sending Action Completed: ",
            };
            self.commonService.saveActivity("DueDate Report", activityData);
            if (data) {
              if (data["status"] == self.constantService.SUCCESS_MESSAGE) {
                callback(resultData);
              } else {
                callback(resultData);
              }
            } else {
              callback(resultData);
            }
          },
          (error) => {
            console.log(JSON.stringify(error));
            const activityData = {
              activityName: "Compose Mail : Mail Sending Failed ",
              activityDescription: "Mail Sending Failed for Approval FollowUp Process in Parts",
              activityData: JSON.stringify(error),
            };
            this.commonService.saveActivity("DueDate Report", activityData);
            const err =
              error && error.HttpErrorResponse
                ? error.HttpErrorResponse
                : {
                    status: true,
                    statusText: "Error while Sending Mail. Backend Service is not Accessible.",
                  };
            this.commonService.errorCallback(err, this);
            submitBtn.disabled = false;
            this.mailSentStatus = 1;
            this.displayProgressBar = false;
            this.progressMessage = "In Progress ...";
            this.showSpinner = false;
            callback("");
          }
        );
    }
  }
  editDeadlineNoteFormFields() {
    this.editDeadlineNoteFormFlag = true;
    this.oldDeadlineNote = this.editDeadlineNoteForm.get('deadlineNote')?.value ? this.editDeadlineNoteForm.get('deadlineNote')?.value.trim(): "";
  }
  updateDeadlineNoteFormFields() {
    const newDeadLineNotes = this.editDeadlineNoteForm.get('deadlineNote')?.value ? this.editDeadlineNoteForm.get('deadlineNote')?.value.trim(): "";
    console.log(this.oldDeadlineNote);
    console.log(newDeadLineNotes);

    if(this.oldDeadlineNote !== newDeadLineNotes){
      this.updateDeadlineNoteFormSubmit();
    } else {
      this.editDeadlineNoteFormFlag = false;
    }
  }
  closeDeadlineNoteFormFields() {
    this.editDeadlineNoteFormFlag = false;
    this.showSpinnerButtonEdit = false;
  }

  editDeadlineNoteFields() {
    this.editDeadlineNoteFlag = true;
    this.oldDeadlineNote = this.approvalNoticeFollowUpForm.get('deadlineNotes')?.value ? this.approvalNoticeFollowUpForm.get('deadlineNotes')?.value.trim(): "";
  }
  saveDeadlineNoteFields() {
    const newDeadLineNotes = this.approvalNoticeFollowUpForm.get('deadlineNotes')?.value ? this.approvalNoticeFollowUpForm.get('deadlineNotes')?.value.trim(): "";
    if(this.oldDeadlineNote !== newDeadLineNotes){
      this.enableDeadlineNoteEditFlag = false;
    }
    this.editDeadlineNoteFlag = false;
  }
  closeDeadlineNoteFields() {
    this.editDeadlineNoteFlag = false;

  }

  editEmailSubjectFields() {
    this.editEmailSubjectFlag = true;
    const subject = this.approvalNoticeFollowUpForm.get('subject')?.value;
    this.approvalNoticeFollowUpForm.patchValue({editEmailSubject:subject});
  }

  saveEmailSubjectFields() {
    const editEmailSubject = this.approvalNoticeFollowUpForm.get('editEmailSubject')?.value;
    this.approvalNoticeFollowUpForm.patchValue({subject:editEmailSubject});
    this.editEmailSubjectFlag = false;
  }

  closeEmailSubjectFields() {
    this.editEmailSubjectFlag = false;
  }

  get hasDifferentGroupProjectSelected() {
    let flag = false;
    if(this.portalSelectedList.length) {
      let groupName = null;
      for (let i = 0; i < this.portalSelectedList.length; i++) {
        groupName = this.portalSelectedList[0].groupName;
        if(groupName !== this.portalSelectedList[i].groupName) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  get hasCompletedFollowupProjectSelected() {
    let flag = false;
    if(this.portalSelectedList.length) {
      for (let i = 0; i < this.portalSelectedList.length; i++) {
        if(this.portalSelectedList[i].approvalFollowupDetailsCount > 0) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  get hasDifferentFollowupProjectSelected() {
    let flag = false;
    if(this.portalSelectedList.length) {
      let approvalFollowupDetailsCount = null;
      for (let i = 0; i < this.portalSelectedList.length; i++) {
        approvalFollowupDetailsCount = this.portalSelectedList[0].approvalFollowupDetailsCount;
        if(approvalFollowupDetailsCount !== this.portalSelectedList[i].approvalFollowupDetailsCount) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  updateFollowUpDetails(inMailSendBy:any, inMailObjectId:any, inProjectIdList:any, callback:any){
    const dbObj = {
      inProjectIdList: inProjectIdList,
      inMailObjectId: inMailObjectId,
      inMailSendBy: inMailSendBy
    };
    const activityData1 = {
      activityName: "updateApprovalFollowupDetails",
      activityDescription: "updateApprovalFollowupDetails: started",
      activityData: dbObj,
    };
    this.commonService.saveActivity("DueDate Report", activityData1);
    const subscription = this.apollo.use("portaldbClient").mutate({
      mutation: updateApprovalFollowupDetails,
      variables: dbObj,
    });
    subscription.subscribe(
      ({ data }) => {
        NProgress.done();
        const result: any = data;
        const activityData2 = {
          activityName: "updateApprovalFollowupDetails",
          activityDescription: "updateApprovalFollowupDetails: completed",
          activityData: data,
        };
        this.commonService.saveActivity("DueDate Report", activityData2);
        if (result && result["updateApprovalFollowupDetails"]) {
          callback();
        }
      },
      (err) => {
        swal({
          title: 'Failed updateApprovalFollowupDetails Process',
          type: "warning",
          confirmButtonClass: "btn-warning pointer",
          confirmButtonText: this.constantService.CLOSE,
        });
        this.commonService.errorCallback(err, this);
        if (callback) {
          callback(false);
        }
      });
  }

  deadlineNoteUpdateProcess(i:any, projectIdList:any, deadlineNotes:any, callback:any){
    console.log('deadlineNoteUpdateProcess====>>',i,projectIdList,deadlineNotes);
    if(projectIdList && projectIdList.length && i < projectIdList.length) {
      const projectList = this.dueDateReportList.filter((item: any, index: number) => index < this.dueDateReportList.length)
      .filter((item: any, index: number) =>item["id"] == projectIdList[i]);
      if(projectList && projectList.length) {
        const project = projectList[0];
        let oldDeadlineNotes = project.formattedDeadlineNotes ? project.formattedDeadlineNotes: '';
        const newDeadlineNotes = `${deadlineNotes} 
                            ${oldDeadlineNotes}`;
        this.updateDeadlineNoteSolve360(this.safeInput.parse(newDeadlineNotes), projectIdList[i], ()=>{
          i++;
          this.deadlineNoteUpdateProcess(i, projectIdList, deadlineNotes, callback);

        });
      } else {
        i++;
        this.deadlineNoteUpdateProcess(i, projectIdList[i], deadlineNotes, callback);
      }
    } else if(projectIdList && projectIdList.length) {
      console.log('deadlineNoteUpdateProcess====>> completed',i,projectIdList,deadlineNotes);
      this.deadlineNoteUpdateDelayCounter(false);
    } else {
      console.log('deadlineNoteUpdateProcess====>> no project',i,projectIdList,deadlineNotes);
      this.dueDateReports(true);
    }
  }

  approvalFollowupChange(followUp: any) {
    this.getBillingProcessUserList();
  }

  get projectListTypeString(){
    let projectTypeString = '';
    if(this.portalSelectedList && this.portalSelectedList.length) {
      const projectTypeList = this.portalSelectedList.map((obj:any) => obj.projectType);
      if(projectTypeList.includes("Parts UL") && projectTypeList.includes("Labor UL")){
        projectTypeString = 'Parts and Labor';
      } else if(projectTypeList.includes("Parts UL")){
        projectTypeString = 'Parts';
      } else if(projectTypeList.includes("Labor UL")){
        projectTypeString = 'Labor';
      }
    }
    return projectTypeString;
  }

}

import { Injectable } from "@angular/core";

@Injectable()
export class ConstantService {
  releaseVerion = "";
  PRODUCTION_SNAPSHOTS = [];
  PRODUCTION_SNAPSHOTS_FILTER = [];
  PRODUCTION_SNAPSHOTS_FILTER_FLAG = false;
  formsStatus = "";
  letterStatus = "";
  b = "";
  s = "";
  dms = "";
  dataStarting = 0;
  dataEnding = 0;
  daysUntilDeadlineStart = 0;
  daysUntilDeadlineEnd = 0;
  daysUntilDeadlineRangeStart = 0;
  daysUntilDeadlineRangeEnd = 0;
  selectedMagescenarios: string = "";
  PUNCH_LIST: string;
  DISPUTED: string;
  READY_TO_BILL: string;
  CLIENT: string;
  NONCONTIGENT: string;
  PAST_DUE: string;
  NOT_DUE: string;
  MANF_REQUEST: string;
  ERROR: string;

  EXCEL_HEADING1: string;
  EXCEL_HEADING2: string;
  EXCEL_HEADING3: string;
  EXCEL_HEADING4: string;
  EXCEL_HEADING5: string;
  EXCEL_HEADING6: string;
  EXCEL_FOOTER: string;
  EXCEL_FOOTER_DYNAMIC: string;

  EXCEL_PSSHEADING1: string;
  EXCEL_PSSHEADING2: string;
  EXCEL_PSSHEADING3: string;
  EXCEL_PSSHEADING4: string;
  EXCEL_PSSHEADING5: string;

  EXCEL_INTERNAL_PSSHEADING: string;
  EXCEL_INTERNAL_PSSHEADING_SUB1: string;
  EXCEL_INTERNAL_PSSHEADING_SUB2: string;
  EXCEL_INTERNAL_PSSHEADING_SUB3: string;
  EXCEL_INTERNAL_PSSHEADING_SUB4: string;
  EXCEL_INTERNAL_PSSHEADING_SUB5: string;

  MAGEURL: string;
  SOLVE360: string;
  PAGE_LIMIT: number;
  ONLY_NUMBERS: string;
  DELETEDTITLE: string;
  DELETEDTEXT: string;
  DELETEDERRORTITLE: string;
  CLOSE: string;
  AREYOUSURE: string;
  CLONE: string;
  ROITEM: string;
  WILLBECLONED: string;
  CLONED: string;
  ROITEMHASBEENCLONED: string;
  CLONNINGERROR: string;
  ADDROITEMERROR: string;
  EDITROITEMERROR: string;
  MIDDLEWARE_FAILURE: string;
  MIDDLEWARE_ERROR: string;
  MIDDLEWARE_ERROR_CODE: string;
  MIDDLEWARE_ERROR_CODE_FOR_LOGOUT: string;
  SESSION_EXPIRED: string;
  MIDDLEWARE_NOT_ACCESSABLE_MESSAGE: string;
  DATABASE_NOT_ACCESSABLE_MESSAGE: string;
  DATABASE_NOT_ACCESSABLE_UNEXPECTED_TOKEN_MESSAGE: string;

  REG_EXP_FOR_DOUBLE: string;
  REG_EXP_FOR_INTEGER: string;
  REG_EXP_FOR_POSATIVE_INTEGER: string;
  REG_EXP_FOR_EMAIL: string;
  REG_EXP_FOR_DATE_FORMAT: object;
  ERROR_MESSAGE_FOR_NUMBERS: string;
  ERROR_MESSAGE_FOR_DOUBLE: string;
  ERROR_MESSAGE_FOR_REQUIRED: string;
  ERROR_MESSAGE_FOR_LWS_BASIS_REQUIRED: string;
  ERROR_MESSAGE_FOR_EMAIL: string;
  ERROR_MESSAGE_FOR_INVALID: string;
  ERROR_MESSAGE_FOR_INVOICE_NUMBER_EXIST: string;
  ERROR_MESSAGE_FOR_IDSUFFIX: string;
  ERROR_MESSAGE_FOR_NAMESUFFIX: string;
  ERROR_MESSAGE_FOR_IDSUFFIX_VALID: string;

  SUCCESS_MESSAGE: string;
  RO_ITEM_SUCCESS_MESSAGE: string;
  RO_ITEM_UPDATE_SUCCESS_MESSAGE: string;
  RO_ITEM_DELETE_SUCCESS_MESSAGE: string;
  CREATE_STORE_PORTAL_SUCCESS_MESSAGE: string;
  CREATE_STORE_PORTAL_ERROR: string;
  ADD_STORE_GROUP_TO_PORTAL_SUCCESS_MESSAGE: string;
  ADD_STORE_GROUP_TO_PORTAL_ERROR: string;
  ADD_STORE_TO_METAGROUP_SUCCESS_MESSAGE: string;
  ADD_STORE_TO_METAGROUP_ERROR: string;
  ADD_SOLO_STORE_TO_PORTAL_SUCCESS_MESSAGE: string;
  ADD_SOLO_STORE_TO_PORTAL_ERROR: string;
  ADD_META_GROUP_TO_PORTAL_SUCCESS_MESSAGE: string;
  ADD_META_GROUP_TO_PORTAL_ERROR: string;
  LINK_STORE_PORTAL_SUCCESS_MESSAGE: string;
  LINK_STORE_PORTAL_ERROR: string;
  LINK_STORE_PORTAL_WARNING: string;
  UNLINK_STORE_PORTAL_SUCCESS_TITLE: string;
  UNLINK_STORE_PORTAL_SUCCESS_TEXT: string;
  UNLINK_STORE_PORTAL_ERROR: string;
  FSD_CREATED_SUCCESS_TITLE: string;
  FSD_CREATED_SUCCESS_MESSAGE: string;
  FSD_CREATION_ERROR_MESSAGE: string;
  FSD_UPDATED_SUCCESS_TITLE: string;
  FSD_UPDATED_SUCCESS_MESSAGE: string;
  FSD_UPDATION_ERROR_MESSAGE: string;
  SOURCE_BUNDLE_CREATED_SUCCESS_MESSAGE1: string;
  SOURCE_BUNDLE_CREATED_SUCCESS_MESSAGE2: string;
  SOURCE_BUNDLE_CREATION_ERROR_MESSAGE: string;
  SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE1: string;
  LABOR_SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE1: string;
  SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE2: string;
  SOURCE_BUNDLE_UPDATION_ERROR_MESSAGE: string;
  LWS_CREATED_SUCCESS_TITLE: string;
  LWS_CREATED_SUCCESS_MESSAGE: string;
  LWS_CREATION_ERROR_MESSAGE: string;
  LWS_UPDATED_SUCCESS_TITLE: string;
  LWS_UPDATED_SUCCESS_MESSAGE: string;
  LWS_UPDATION_ERROR_MESSAGE: string;
  ACTIVATE_THE_BUNDLE: string;
  DEACTIVATE_THE_BUNDLE: string;
  ACTIVATE_THE_SUBMISSION_ID: string;
  DEACTIVATE_THE_SUBMISSION_ID: string;
  DEACTIVATE_PREAUDIT_REVIEW_THE_BUNDLE: string;
  BUNDLE_ACTIVATED_TEXT: string;
  BUNDLE_DEACTIVATED_TEXT: string;
  SUBMISSION_ID_ACTIVATED_TEXT: string;
  SUBMISSION_ID_DEACTIVATED_TEXT: string;
  ACTIVATED: string;
  DEACTIVATED: string;
  BUNDLE_ACTIVATION_ERROR: string;
  BUNDLE_DEACTIVATION_ERROR: string;
  SUBMISSION_ID_ACTIVATION_ERROR: string;
  SUBMISSION_ID_DEACTIVATION_ERROR: string;
  CLEAR_SCENARIO_WARNING_MESSAGE: string;
  CLEAR_RO_WARNING_MESSAGE: string;
  SAVE_COMMENT_SUCCESS_MSG: string;

  BASIS_BUNDLE_CREATED_SUCCESS_MESSAGE1: string;
  BASIS_BUNDLE_CREATED_SUCCESS_MESSAGE2: string;
  BASIS_BUNDLE_CREATION_ERROR_MESSAGE: string;
  BASIS_BUNDLE_UPDATED_SUCCESS_MESSAGE1: string;
  BASIS_BUNDLE_UPDATED_SUCCESS_MESSAGE2: string;
  BASIS_BUNDLE_UPDATION_ERROR_MESSAGE: string;
  TARGET_BUNDLE_CREATED_SUCCESS_MESSAGE1: string;
  TARGET_BUNDLE_CREATED_SUCCESS_MESSAGE2: string;
  TARGET_BUNDLE_CREATION_ERROR_MESSAGE: string;
  DM_CREATED_SUCCESS_TITLE: string;
  DM_CREATED_SUCCESS_MESSAGE: string;
  DM_CREATION_ERROR_MESSAGE: string;
  DM_UPDATED_SUCCESS_TITLE: string;
  DM_UPDATED_SUCCESS_MESSAGE: string;
  DM_UPDATION_ERROR_MESSAGE: string;
  PROJECT_LINK_ERROR_MESSAGE: string;
  PROJECT_UNLINK_ERROR_MESSAGE: string;
  PROJECT_UNLINK_WARNING_MESSAGE: string;
  UNLINK_PROJECT_SUCCESS_TITLE: string;
  UNLINK_PROJECT_SUCCESS_TEXT: string;
  ACTIVATE_DM_SUCCESS_TITLE: string;
  ACTIVATE_DM_SUCCESS_TEXT: string;
  DM_ACTIVATION_ERROR_MESSAGE: string;
  PREAUDIT_CREATED_SUCCESS_TITLE: string;
  PREAUDIT_CREATED_SUCCESS_MESSAGE: string;
  PREAUDIT_CREATION_ERROR_MESSAGE: string;
  POSTAUDIT_CREATED_SUCCESS_TITLE: string;
  POSTAUDIT_CREATED_SUCCESS_MESSAGE: string;
  POSTAUDIT_CREATION_ERROR_MESSAGE: string;
  SUBMITTED_SUCCESS_TITLE: string;
  SUBMITTED_SUCCESS_MESSAGE: string;
  SUBMITTED_ERROR_MESSAGE: string;
  SALES_CONFIRM_SUCCESS_TITLE: string;
  SALES_CONFIRM_SUCCESS_MESSAGE: string;
  SALES_CONFIRM_ERROR_MESSAGE: string;
  SALES_CONFIRM_WARNING_MESSAGE: string;
  CURRENT_WLR_Eff_ON_WARNING_MESSAGE: string;
  CURRENT_WLR_Eff_ON_WARNING_TITLE: string;

  CANCEL_SALES_CONFIRM_SUCCESS_TITLE: string;
  CANCEL_SALES_CONFIRM_SUCCESS_MESSAGE: string;
  CANCEL_SALES_CONFIRM_ERROR_MESSAGE: string;
  CANCEL_SALES_CONFIRM_WARNING_MESSAGE: string;
  MGMT_REVIEW_CONFIRM_WARNING_TITTLE: string;
  MGMT_REVIEW_CONFIRM_WARNING_MESSAGE: string;

  BASIS_OVERRIDE_WARNING_TITLE: string;
  BASIS_OVERRIDE_WARNING_MESSAGE: string;

  RECEIVED_SUCCESS_TITLE: string;
  RECEIVED_SUCCESS_MESSAGE: string;
  RECEIVED_ERROR_MESSAGE: string;
  APPROVED_SUCCESS_TITLE: string;
  APPROVED_SUCCESS_MESSAGE: string;
  APPROVED_ERROR_MESSAGE: string;
  OVERRIDE_ERROR_MESSAGE: string;
  SEND_TO_ACCOUNTING_ON_SUCCESS_TITLE: string;
  SEND_TO_ACCOUNTING_ON_SUCCESS_MESSAGE: string;
  SEND_TO_ACCOUNTING_ON_ERROR_MESSAGE: string;
  PREAUDIT_REVIEW_UPDATED_SUCCESS_TITLE: string;
  PREAUDIT_REVIEW_UPDATED_SUCCESS_MESSAGE: string;
  PREAUDIT_REVIEW_UPDATED_ERROR_MESSAGE: string;
  APPROVED_TARGETMARKUP_ERROR: string;
  INITAPP_SUCCESS_TITLE: string;
  INITAPP_SUCCESS_MESSAGE: string;
  INITAPP_ERROR_MESSAGE: string;
  BEST_CREATED_SUCCESS_TITLE: string;
  BEST_CREATED_SUCCESS_MESSAGE: string;
  BEST_CREATED_ERROR_MESSAGE: string;
  BEST_UPDATED_SUCCESS_TITLE: string;
  BEST_UPDATED_SUCCESS_MESSAGE: string;
  BEST_UPDATED_ERROR_MESSAGE: string;
  DM_AND_FSD_CREATED_SUCCESS_TITLE: string;
  DM_AND_FSD_CREATED_SUCCESS_MESSAGE: string;
  DM_AND_FSD_CREATION_ERROR_MESSAGE: string;
  DM_AND_LWS_CREATED_SUCCESS_TITLE: string;
  DM_AND_LWS_CREATED_SUCCESS_MESSAGE: string;
  DM_AND_LWS_CREATION_ERROR_MESSAGE: string;
  DM_AND_FSD_AND_LWS_CREATED_SUCCESS_TITLE: string;
  DM_AND_FSD_AND_LWS_CREATED_SUCCESS_MESSAGE: string;
  DM_AND_FSD_AND_LWS_CREATION_ERROR_MESSAGE: string;
  PROJECT_UPDATED_IN_SOLVE360: string;
  PROJECT_UPDATED_FAILED_SOLVE360: string;
  BILLING_FINALIZED_SUCCESS_TITLE: string;
  BILLING_FINALIZED_SUCCESS_MESSAGE: string;
  BILLING_FINALIZED_ERROR_MESSAGE: string;
  REVENUE_UPDATED_SUCCESS_TITLE: string;
  REVENUE_UPDATED_SUCCESS_MESSAGE: string;
  REVENUE_UPDATED_ERROR_MESSAGE: string;
  RETRACTED_SUCCESS_TITLE: string;
  RETRACTED_SUCCESS_MESSAGE: string;
  RETRACTED_ERROR_MESSAGE: string;
  REACTIVATED_SUCCESS_TITLE: string;
  REACTIVATED_SUCCESS_MESSAGE: string;
  REACTIVATED_ERROR_MESSAGE: string;
  RUSH_SUCCESS_TITLE: string;
  RUSH_SUCCESS_MESSAGE: string;
  RUSH_ERROR_MESSAGE: string;
  MAIL_ID_REQUIRED_TITLE: string;
  MAIL_ID_REQUIRED_WARNING_MESSAGE: string;

  RUSH_DEACTIVATE_SUCCESS_TITLE: string;
  RUSH_DEACTIVATE_SUCCESS_MESSAGE: string;
  RUSH_DEACTIVATE_ERROR_MESSAGE: string;

  PROXY_REVIEW_SUCCESS_TITLE: string;
  PROXY_REVIEW_SUCCESS_MESSAGE: string;
  PROXY_REVIEW_ERROR_MESSAGE: string;
  PROXY_REVIEW_DEACTIVATE_SUCCESS_TITLE: string;
  PROXY_REVIEW_DEACTIVATE_SUCCESS_MESSAGE: string;
  PROXY_REVIEW_DEACTIVATE_ERROR_MESSAGE: string;

  VENDOR_SUCCESS_TITLE: string;
  VENDOR_SUCCESS_MESSAGE: string;
  VENDOR_ERROR_MESSAGE: string;
  IN_NEED_HANDOFF_SUCCESS_MESSAGE: string;
  VENDOR_DEACTIVATE_SUCCESS_TITLE: string;
  VENDOR_DEACTIVATE_SUCCESS_MESSAGE: string;
  VENDOR_DEACTIVATE_ERROR_MESSAGE: string;

  IN_BOOK_TIME_SUCCESS_TITLE: string;
  IN_BOOK_TIME_SUCCESS_MESSAGE: string;
  IN_BOOK_TIME_ERROR_MESSAGE: string;
  IN_BOOK_TIME_DEACTIVATE_SUCCESS_TITLE: string;
  IN_BOOK_TIME_DEACTIVATE_SUCCESS_MESSAGE: string;
  IN_BOOK_TIME_DEACTIVATE_ERROR_MESSAGE: string;

  IN_NEED_HANDOFF_DEACTIVATE_SUCCESS_MESSAGE: string;
  IN_BOOK_TIME_DEACTIVATE_WARNING_MESSAGE: string;
  IN_SHIPPING_DEACTIVATE_WARNING_MESSAGE: string;

  IN_SHIPPING_SUCCESS_TITLE: string;
  IN_SHIPPING_SUCCESS_MESSAGE: string;
  IN_SHIPPING_ERROR_MESSAGE: string;
  IN_SHIPPING_DEACTIVATE_SUCCESS_TITLE: string;
  IN_SHIPPING_DEACTIVATE_SUCCESS_MESSAGE: string;
  IN_SHIPPING_DEACTIVATE_ERROR_MESSAGE: string;

  IN_SALES_SUCCESS_TITLE: string;
  IN_SALES_SUCCESS_MESSAGE: string;

  IN_SALES_RM_SUCCESS_TITLE: string;
  IN_SALES_RM_SUCCESS_MESSAGE: string;

  IN_SALES_ERROR_MESSAGE: string;

  IN_SALES_DEACTIVATE_SUCCESS_TITLE: string;
  IN_SALES_DEACTIVATE_SUCCESS_MESSAGE: string;
  IN_SALES_DEACTIVATE_ERROR_MESSAGE: string;

  IN_SALES_CONTINUE_SUCCESS_TITLE: string;
  IN_SALES_CONTINUE_SUCCESS_MESSAGE: string;
  IN_SALES_CONTINUE_ERROR_MESSAGE: string;
  OVERRIDE_SHIPCOUNT_ERROR_MESSAGE: string;
  PULL_DATA_SUCCESS_TITLE: string;
  PULL_DATA_SUCCESS_MESSAGE: string;
  PULL_DATA_ERROR_MESSAGE: string;
  SCHEDULE_ERROR_MESSAGE: string;
  DASHBOARD_COMPL_TIME_UPDATE_ERROR_MESSAGE: string;
  DASHBOARD_COMPL_TIME_SUCCESS_MESSAGE: string;
  IMPORT_SUCCESS_TITLE: string;
  IMPORT_SUCCESS_MESSAGE: string;
  IMPORT_ERROR_MESSAGE: string;

  MAPPING_SUCCESS_TITLE: string;
  MAPPING_SUCCESS_MESSAGE: string;
  MAPPING_ERROR_MESSAGE: string;

  RETRIEVE_RO_SUCCESS_TITLE: string;
  RETRIEVE_RO_SUCCESS_MESSAGE: string;
  RETRIEVE_RO_ERROR_MESSAGE: string;

  IMPORT_RO_SUCCESS_TITLE: string;
  IMPORT_RO_SUCCESS_MESSAGE: string;
  IMPORT_RO_ERROR_MESSAGE: string;

  PRE_AUDIT_SUCCESS_TITLE: string;
  PRE_AUDIT_SUCCESS_MESSAGE: string;
  PRE_AUDIT_ERROR_MESSAGE: string;

  PRE_AUDIT_REVIEW_SUCCESS_TITLE: string;
  PRE_AUDIT_REVIEW_SUCCESS_MESSAGE: string;
  PRE_AUDIT_REVIEW_ERROR_MESSAGE: string;

  REVIEW_SUCCESS_TITLE: string;
  REVIEW_SUCCESS_MESSAGE: string;
  REVIEW_ERROR_MESSAGE: string;

  REVIEW_ASSIGN_SUCCESS_TITLE: string;
  REVIEW_ASSIGN_SUCCESS_MESSAGE: string;
  REVIEW_ASSIGN_ERROR_MESSAGE: string;

  ASSIGN_SUCCESS_TITLE: string;
  ASSIGN_SUCCESS_MESSAGE: string;
  ASSIGN_ERROR_MESSAGE: string;

  UPDATE_SUCCESS_TITLE: string;
  UPDATE_SUCCESS_MESSAGE: string;
  UPDATE_ERROR_MESSAGE: string;

  COMPLETE_SUCCESS_TITLE: string;
  COMPLETE_SUCCESS_MESSAGE: string;
  COMPLETE_ERROR_MESSAGE: string;

  SEND_TO_PREAUDIT_REVIEW_SUCCESS_TITLE: string;
  SEND_TO_PREAUDIT_REVIEW_SUCCESS_MESSAGE: string;
  SEND_TO_PREAUDIT_REVIEW_ERROR_MESSAGE: string;

  DECLINED_SUCCESS_TITLE: string;
  DECLINED_SUCCESS_MESSAGE: string;
  DECLINED_ERROR_MESSAGE: string;

  FORWARD_COMPLETE_SUCCESS_TITLE: string;
  FORWARD_COMPLETE_SUCCESS_MESSAGE: string;
  FORWARD_COMPLETE_ERROR_MESSAGE: string;

  BACKWARD_COMPLETE_SUCCESS_TITLE: string;
  BACKWARD_COMPLETE_SUCCESS_MESSAGE: string;
  BACKWARD_COMPLETE_ERROR_MESSAGE: string;

  AUDIT_SUCCESS_TITLE: string;
  AUDIT_SUCCESS_MESSAGE: string;
  AUDIT_ERROR_MESSAGE: string;

  AUDIT_DISPATCH_SUCCESS_TITLE: string;
  AUDIT_DISPATCH_SUCCESS_MESSAGE: string;
  AUDIT_DISPATCH_ERROR_MESSAGE: string;

  AUDIT_UPDATE_SUCCESS_TITLE: string;
  AUDIT_UPDATE_SUCCESS_MESSAGE: string;
  AUDIT_UPDATE_ERROR_MESSAGE: string;

  AUDIT_REASSIGN_SUCCESS_TITLE: string;
  AUDIT_REASSIGN_SUCCESS_MESSAGE: string;
  AUDIT_REASSIGN_ERROR_MESSAGE: string;

  AUDIT_SEND_TO_REVIEW_SUCCESS_TITLE: string;
  AUDIT_SEND_TO_REVIEW_SUCCESS_MESSAGE: string;
  AUDIT_SEND_TO_REVIEW_ERROR_MESSAGE: string;

  AUDIT_RETRIEVE_MISSING_RO_SUCCESS_TITLE: string;
  AUDIT_RETRIEVE_MISSING_RO_SUCCESS_MESSAGE: string;
  AUDIT_RETRIEVE_MISSING_RO_ERROR_MESSAGE: string;

  AUDIT_RETRIEVE_MISSING_RO_RECEIVE_SUCCESS_TITLE: string;
  AUDIT_RETRIEVE_MISSING_RO_RECEIVE_SUCCESS_MESSAGE: string;
  AUDIT_RETRIEVE_MISSING_RO_RECEIVE_ERROR_MESSAGE: string;

  SENT_TO_FINALIZE_SUCCESS_TITLE: string;
  SENT_TO_FINALIZE_SUCCESS_MESSAGE: string;
  SENT_TO_FINALIZE_ERROR_MESSAGE: string;

  SENT_TO_FINALIZE_ERROR_MESSAGE_BOTH_TYPE: string;

  AUDITOR_REPORT_NOT_REVIEWED_WARNING_TITLE: string;
  AUDITOR_REPORT_NOT_REVIEWED_WARNING_MESSAGE: string;

  REAUDIT_SUCCESS_TITLE: string;
  REAUDIT_SUCCESS_MESSAGE: string;
  REAUDIT_ERROR_MESSAGE: string;

  FINALIZE_SUCCESS_TITLE: string;
  FINALIZE_SUCCESS_MESSAGE: string;
  FINALIZE_ERROR_MESSAGE: string;

  SHIPPED_SUCCESS_TITLE: string;
  SHIPPED_SUCCESS_MESSAGE: string;
  SHIPPED_ERROR_MESSAGE: string;

  POST_AUDIT_REVIEW_SUCCESS_TITLE: string;
  POST_AUDIT_REVIEW_SUCCESS_MESSAGE: string;
  POST_AUDIT_REVIEW_ERROR_MESSAGE: string;

  START_UP_NOTES_UPDATED_SUCCESS_TITLE: string;
  START_UP_NOTES_UPDATED_SUCCESS_MESSAGE: string;
  START_UP_NOTES_UPDATION_ERROR_MESSAGE: string;

  INTERNAL_COMMUNICATION_NOTES_UPDATED_SUCCESS_TITLE: string;
  INTERNAL_COMMUNICATION_NOTES_UPDATED_SUCCESS_MESSAGE: string;
  INTERNAL_COMMUNICATION_NOTES_UPDATION_ERROR_MESSAGE: string;

  CDA_NOTES_UPDATED_SUCCESS_TITLE: string;
  CDA_NOTES_UPDATED_SUCCESS_MESSAGE: string;
  CDA_NOTES_UPDATION_ERROR_MESSAGE: string;

  WARRANTY_LABOR_RATE_WARNING_TITTLE: string;
  WARRANTY_LABOR_RATE_WARNING_MESSAGE: string;

  COURT_COMMENT_UPDATED_SUCCESS_TITLE: string;
  COURT_COMMENT_UPDATED_SUCCESS_MESSAGE: string;
  COURT_COMMENT_UPDATION_ERROR_MESSAGE: string;

  DISPUTED_SUCCESS_TITLE: string;
  DISPUTED_SUCCESS_MESSAGE: string;
  DISPUTED_ERROR_MESSAGE: string;
  UNDISPUTED_SUCCESS_TITLE: string;
  UNDISPUTED_SUCCESS_MESSAGE: string;
  UNDISPUTED_ERROR_MESSAGE: string;

  DISPUTE_ANALYSIS_SUCCESS_TITLE: string;
  DISPUTE_ANALYSIS_SUCCESS_MESSAGE: string;
  DISPUTE_ANALYSIS_ERROR_MESSAGE: string;

  MANF_REQUEST_SUCCESS_TITLE: string;
  MANF_REQUEST_SUCCESS_MESSAGE: string;
  MANF_REQUEST_ERROR_MESSAGE: string;

  MANF_REQUEST_COMPLETE_SUCCESS_TITLE: string;
  MANF_REQUEST_COMPLETE_SUCCESS_MESSAGE: string;
  MANF_REQUEST_COMPLETE_ERROR_MESSAGE: string;

  MANF_REQUEST_DELIVERED_SUCCESS_TITLE: string;
  MANF_REQUEST_DELIVERED_SUCCESS_MESSAGE: string;
  MANF_REQUEST_DELIVERED_ERROR_MESSAGE: string;

  REVIEWED_PREAUDIT_BUNDLE_EXIST: string;
  ACTIVE_APPROVE_INIT_APPROVE_BUNDLE_EXIST: string;
  ACTIVE_SUBMIT_BUNDLE_EXIST: string;
  NO_ACTIVE_SUBMIT_BUNDLE_EXIST: string;
  NO_ACTIVE_POST_AUDIT_BUNDLE_EXIST: string;
  ACTIVE_APPROVE_BUNDLE_EXIST: string;
  ACTIVE_POSTAUDIT_BUNDLE_EXIST: string;
  ACTIVE_BILLING_IS_NOT_EXISTS: string;
  ACTIVE_INITAPPROVE_BUNDLE_EXIST: string;
  ACTIVE_BILLING_IS_EXISTS: string;
  BASIS_CREATED_SUCCESS_TITLE: string;
  BASIS_CREATED_SUCCESS_MESSAGE: string;
  BASIS_CREATED_ERROR_MESSAGE: string;
  AUTOFILL_ERROR_AT_3TH_COLUMN: string;
  AUTOFILL_ERROR_AT_6TH_COLUMN: string;
  AUTOFILL_ERROR_AT_7TH_COLUMN: string;
  AUTOFILL_ERROR_AT_8TH_COLUMN: string;
  AUTOFILL_ERROR_AT_9TH_COLUMN: string;

  AUTOFILL_ERROR_AT_13TH_COLUMN: string;
  AUTOFILL_ERROR_AT_14TH_COLUMN: string;
  AUTOFILL_ERROR_AT_15TH_COLUMN: string;
  AUTOFILL_ERROR_AT_16TH_COLUMN: string;
  AUTOFILL_ERROR_AT_17TH_COLUMN: string;
  AUTOFILL_REQUIRED_AT_18TH_COLUMN: string;
  AUTOFILL_ERROR_AT_18TH_COLUMN: string;
  AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT: string;
  AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT_MIN: string;
  AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT_MIN_V2: string;
  AUTOFILL_REQUIRED_AT_4TH_COLUMN: string;
  AUTOFILL_ERROR_AT_4TH_COLUMN: string;
  AUTOFILL_13TH_14TH_COLUMN_NEGATIVE: string;
  AUTOFILL_15TH_16TH_COLUMN_NEGATIVE: string;
  BASIS_OVERRIDE_SUCCESS_TITLE: string;
  BASIS_OVERRIDE_SUCCESS_MESSAGE: string;
  BASIS_OVERRIDE_ERROR_MESSAGE: string;
  TARGET_CREATED_SUCCESS_TITLE: string;
  TARGET_CREATED_SUCCESS_MESSAGE: string;
  TARGET_CREATED_ERROR_MESSAGE: string;

  SNAPSHOT_CREATED_SUCCESS_TITLE: string;
  SNAPSHOT_CREATED_SUCCESS_MESSAGE: string;
  SNAPSHOT_CREATION_ERROR_MESSAGE: string;
  SNAPSHOT_VERSION_NAME_ALREADY_EXIST: string;

  HOME_URL: string;
  DASHBOARD_URL: string;
  SWAT_URL: string;
  SWAT_URL_ID: string;
  PRODUCTION_DASHBOARD_URL: string;
  SIM_URL: string;
  LIST_RO: string;
  ADD_RO_ITEM: string;
  EDIT_RO_ITEM: string;
  ADD_RO: string;
  REYNOLDS_RO_EXCEPTION_URL: string;
  UNDER_CONSTRUCTION_URL: string;
  DASHBOAD_UNDER_CONSTRUCTION: string;
  JOINT_OPTIMIZATION_URL: string;
  CLONING_SCENARIOS_URL: string;
  FACTORY_LABOR_FORMS_URL: string;
  DUE_DATE_REPORT_URL: string;
  CREATE_DATA_MASTER_URL: string;
  CREATE_DATA_MASTER_BETA_URL: string;
  OPERATION_PAGE_NAME: string;
  STORE_SEARCHER_URL: string;
  DM_CONTROL_PANEL_URL: string;
  GRP_RESULT_SUMMARY_URL: string;
  USERS_URL: string;
  SECONDARY_UNRESOLVED_URL: string;
  FUTURE_PROJECTS_URL: string;
  EXCEPTION_MANAGEMENT_URL: string;
  RANGE_SELECTOR_BETA_URL: string;
  AUDIT_MODULE_URL: string;
  AUDITOR_LIST_URL: string;
  SUBMISSION_SUMMARY_URL: string;

  TEST_ENV: string;
  UAT_ENV: string;
  DEV_ENV: string;
  PROD_ENV: string;
  BACKUP_ENV: string;
  ID_SUFFIX_MAX_LENGTH: number;
  SCENARIO_NAME_LENGTH: number;
  SCENARIO_ID_LENGTH: number;

  MARKUP_AS_RATIO: number;
  MARKUP_AS_PERCENTAGE: number;

  LIGHT_GREEN: string;
  MEDIUM_GREEN: string;
  LIGHT_RED: string;
  LIGHT_RED_V1: string;
  LIGHT_GREY: string;
  LIGHT_YELLOW: string;
  LIGHT_BLUE: string;
  LIGHT_PURPLE: string;
  MEDIUM_RED: string;
  MEDIUM_PURPLE: string;
  LIGHT_ORANGE: string;
  BLACK: string;
  VIOLET: string;
  LIGHT_CYAN: string;
  GREY: string;
  WHITE: string;
  LIGHT_RED1: string;
  LIGHT_GREEN1: string;
  LIGHT_YELLOW1: string;
  LIGHT_BROWN: string;
  LIGHT_YELLOW2: string;
  DARK_GREEN: string;

  PROJECT_NOT_CONTRACTED: string;
  PROJECT_WAS_RETRACTED: string;
  PROJECT_APPEARS_TO_BE_NOT_VIABLE: string;
  INSUFFICIENT_DATA_TO_CALCULATE_REVENUE: string;
  NO_ACTIVE_DATA_BUNDLE_TO_CALCULATE_REVENUE: string;
  NO_REVENUE_FORCAST_FOR_PROJECTION: string;
  SOLVE360_RESET_VALUE: string;
  MAGE_UPDATE_META_ERROR_MESSAGE: string;
  REBUILD_BUILDOUT_ERROR_MESSAGE: string;
  ERROR_MESSAGE_FOR_BASIS_REQUIRED: string;
  ERROR_MESSAGE_FOR_BASIS_TARGET_REQUIRED: string;
  UPDATE_DEADLINE_NOTE: string;
  DEADLINE_NOTE_DELAY_COUNTER: number;
  SITE_COPYRIGHT_MESSAGE: string;
  BILLING_MAIL_DISPLAY: any;
  BUILD_ERROR_MESSAGE: string;

  REACTIVATE_SUCCESS_TITLE: string;
  RETRACT_SUCCESS_TITLE: string;
  REACTIVATE_WARNING_TITLE: string;
  RETRACT_WARNING_TITLE: string;
  REACTIVATE_FAIL_TITLE: string;
  RETRACT_FAIL_TITLE: string;
  PRODUCTION_SNAPSHOTS_WARNING_STAGE_TITTLE: string;
  PRODUCTION_SNAPSHOTS_WARNING_TITTLE: string;
  PRODUCTION_SNAPSHOTS_WARNING_MESSAGE: string;
  RESOLVE_WARNING_TITLE: string;
  STATUS_CHANGE_ERROR_MESSAGE_NOTLINKED: string;
  STATUS_CHANGE_ERROR_MESSAGE_NOTPRIMARY: string;
  STATUS_CHANGE_ERROR_MESSAGE_NOTSECONDARY: string;
  STATUS_CHANGE_ERROR_MESSAGE_APPROVED: string;
  STATUS_CHANGE_ERROR_MESSAGE_INITAPPROVED: string;
  STATUS_CHANGE_ERROR_MESSAGE_RETEACTED: string;
  STATUS_CHANGE_ERROR_MESSAGE_ACTIVATED: string;
  STATUS_RESOLVE_SUCCESS_TITLE: string;
  STATUS_RESOLVE_FAILURE_TITLE: string;
  STATUS_RESOLVE_FAILURE_SOLVE360: string;
  STATUS_REACTIVE_FAILURE_SOLVE360: string;
  STATUS_RETRACT_FAILURE_SOLVE360: string;
  STATUS_CHANGE_ERROR_MESSAGE_RESOLVED_ALREADY: string;
  STATUS_REACTIVATE_EMITTER_MESSAGE_SOLVE360: string;
  STATUS_RETRACT_EMITTER_MESSAGE_SOLVE360: string;
  STATUS_PROJECT_NOTFOUND_SOLVE360: string;
  STATUS_REACTIVATE_EMITTER_MESSAGE: string;
  ADMIN_WEIGTAGE = 15;
  POWER_USER_WEIGTAGE = 7;
  RELOAD_GROUP_DELAY_COUNTER = 3;
  CREATE_PROJECT_MODAL_TITLE: string;
  UPDATE_PROJECT_MODAL_TITLE: string;
  ERROR_PROJECT_NOT_LINKED: string;
  VALIDATION_ERROR_CREATE_DM: string;
  AWS_FILE_UPLOADED_SUCCESS: string;
  AWS_FILE_UPLOADED_FAILED: string;
  RANGE_SELECTOR_URL: string;
  RULE_DETAILS_URL: string;
  CDK_ANALYSIS_URL: string;
  EXCEPTION_ANALYSIS_URL: string;
  SET_EXTENDED_RO_FAILED: string;
  SET_EXTENDED_RO_FAILED_MESSAGE: string;
  SET_EXTENDED_RO_ES_FAILED: string;
  SET_EXTENDED_RO_EE_FAILED: string;
  SET_EXTENDED_RO_SE_FAILED: string;
  SET_EXTENDED_RO_SES_WARNING: string;
  SET_EXTENDED_RO_SEE_WARNING: string;
  RO_SAVED_SUCCESSFULLY: String;
  INVOICE_GENERATION_URL: string;
  AUDITOR_REPORT_URL: string;
  MANAGE_PAY_TYPE_URL: string;
  INVOICE_REBUILD_URL: string;
  MANAGE_MAIL_URL: string;
  MANAGE_BILLING_ACTIVITY_URL: string;
  FIND_TEXT_IN_RULES: String;
  MAGE_UPDATE_META_SUCCESS_MESSAGE: string;
  MAIL_SEND_SUCCESS: string;
  MAIL_NOT_SENT: string;
  NO_SCENARIO_KEY: string;
  RANGE_SELECTOR_REPORT_TEMPLATE_URL_PARTS: string;
  RANGE_SELECTOR_REPORT_TEMPLATE_URL_LABOR: string;
  RANGE_SELECTOR_QUALIFIED_MESSAGE: string;
  MAGE_MAPPER_URL: string;
  MAKE_DISPOSITION_URL: string;
  PRODUCTION_SNAPSHOT_URL: string;
  PRODUCTION_SNAPSHOT_BETA_URL: string;
  SOLVE_SYNC_URL: string;

  AUTOFILL_ERROR_AT_9TH_COLUMN_MIN: string;
  AUTOFILL_ERROR_AT_10TH_COLUMN_MIN: string;
  AUTOFILL_ERROR_AT_11TH_COLUMN_MIN: string;
  AUTOFILL_ERROR_AT_12TH_COLUMN_MIN: string;
  AUTOFILL_ERROR_AT_13TH_COLUMN_MIN: string;
  AUTOFILL_ERROR_AT_14TH_COLUMN_MIN: string;
  AUTOFILL_REQUIRED_AT_14TH_COLUMN_MIN: string;
  AUTOFILL_9TH_10TH_COLUMN_NEGATIVE_MIN: string;
  AUTOFILL_11TH_12TH_COLUMN_NEGATIVE_MIN: string;

  AUTOFILL_ERROR_AT_7TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_8TH_COLUMN_MIN_V2: string;
  AUTOFILL_7TH_8TH_COLUMN_NEGATIVE_MIN_V2: string;
  AUTOFILL_9TH_10TH_COLUMN_NEGATIVE_MIN_V2: string;
  AUTOFILL_ERROR_AT_9TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_10TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_11TH_COLUMN_MIN_V2: string;
  AUTOFILL_REQUIRED_AT_12TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_12TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_5TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_1TH_COLUMN_MIN_V2: string;
  AUTOFILL_ERROR_AT_3TH_COLUMN_MIN_V2: string;

  ACTIVE_BASIS_BUNDLE_EXISTS: string;
  ACTIVE_BASIS_BUNDLE_EXISTS_MESSAGE: string;
  UPDATE: string;
  DM_DELETED_SUCCESS_TITLE: string;
  DM_DELETED_SUCCESS_MESSAGE: string;
  DM_DELETION_ERROR_MESSAGE: string;
  DM_DELETE_WARNING_MESSAGE: string;
  DM_DELETE_WARNING_TITLE: string;
  DM_DELETE_WARNING_MESSAGE_FOR_SUBMITTED_PROJECT: string;
  DM_DELETE_WARNING_MESSAGE_FOR_SNAPSHOT_EXIST: string;
  AUDIT_REPORT_DOWNLOAD_ERROR: string;
  SEND_TO_ACCOUNTING_WARNING_MESSAGE: string;
  CURRENT_WLR_WARNING_MESSAGE: string;
  VENDOR_ACTIVATE_WARNING_MESSAGE: string;
  VENDOR_DEACTIVATE_WARNING_MESSAGE: string;
  IS_NO_BRAND_MSG: string;
  IS_NO_FSD_MSG: string;
  IS_NO_LWS_MSG: string;
  IS_NO_FSD_APPROVED_MSG: string;
  IS_NO_LWS_APPROVED_MSG: string;
  IS_BRAND_APPROVED_MSG: string;
  IS_NO_FSD_BRAND_APPROVED_MSG: string;
  NO_FSD: string;
  NO_LWS: string;
  ABOVE40_VARIANCE: string;
  GREATER_FORTY_PERCENTAGE_MSG: string;
  BILLING_VARIENCE_MSG: string;
  BILLING_VARIENCE_NO_FSD_BRAND_MSG: string;
  BILLING_VARIENCE_NO_FSD_MSG: string;
  BILLING_VARIENCE_NO_LWS_MSG: string;
  GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_MSG: string;
  GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_MSG: string;
  GREATER_FORTY_PERCENTAGE_APPROVED_MSG: string;
  BILLING_VARIENCE_APPROVED_MSG: string;
  GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_APPROVED_MSG: string;
  BILLING_VARIENCE_IS_NO_FSD_APPROVED_MSG: string;
  BILLING_VARIENCE_IS_NO_LWS_APPROVED_MSG: string;

  GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_APPROVED_MSG: string;
  BILLING_VARIENCE_BRAND_APPROVED_MSG: string;
  BILLING_VARIENCE_IS_NO_FSD_BRAND_APPROVED_MSG: string;
  GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG: string;
  BRAND_APPROVED_MSG: string;

  IN_SALES_ACTIVATE_WARNING_MESSAGE: string;
  IN_SALES_DEACTIVATE_WARNING_MESSAGE: string;
  IN_SALES_REMOVE_WARNING_MESSAGE: string;
  PREAUDIT_REVIEW_COMPLETE_WARNING_MESSAGE: string;
  IN_NEED_HANDOFF_DEACTIVATE_WARNING_MESSAGE: string;

  NETSPECTIVE_ACTIVATE_WARNING_MESSAGE: string;
  NETSPECTIVE_DEACTIVATE_WARNING_MESSAGE: string;

  NETSPECTIVE_SUCCESS_TITLE: string;
  NETSPECTIVE_SUCCESS_MESSAGE: string;
  NETSPECTIVE_ERROR_MESSAGE: string;

  NETSPECTIVE_DEACTIVATE_SUCCESS_TITLE: string;
  NETSPECTIVE_DEACTIVATE_SUCCESS_MESSAGE: string;
  NETSPECTIVE_DEACTIVATE_ERROR_MESSAGE: string;

  MGMT_REVIEW_ACTIVATE_WARNING_MESSAGE: string;
  MGMT_REVIEW_DEACTIVATE_WARNING_MESSAGE: string;

  MGMT_REVIEW_START_SUCCESS_TITLE: string;
  MGMT_REVIEW_START_SUCCESS_MESSAGE: string;
  MGMT_REVIEW_START_ERROR_MESSAGE: string;

  MGMT_REVIEW_END_SUCCESS_TITLE: string;
  MGMT_REVIEW_END_SUCCESS_MESSAGE: string;
  MGMT_REVIEW_END_ERROR_MESSAGE: string;

  CDA_TEMPLATE_URL: string;
  PREAUDIT_REVIEW_EMAIL_TEMPLATE_URL: string;
  DEFAULT_BAND: any = {};
  REPORT_HEADER: any = {};
  REVIEW_SUMMARY_FILE_NAME = "";
  REVIEW_SUMMARY_TEMPLATE_URL: string;
  PRJ_WARNING_MESSAGE: string;
  BILLING_WARNING_POPUP: string;

  CURRENT_RATE_ON_WARNING_TITLE: string;
  WARRANTY_PAID_HOUR_ON_WARNING_TITLE: string;
  MONTHS_ON_WARNING_TITLE: string;

  IS_NO_FSD_BRAND_MSG: string;
  IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_MSG: string;
  GREATER_FORTY_PERCENTAGE_BRAND_MSG: string;
  IS_NO_FSD_BILLING_VARIENCE_BRAND_MSG: string;
  BILLING_VARIENCE_BRAND_MSG: string;
  IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG: any;
  NO_FSD_BRAND_MSG: string;
  VIEW_RO_LIST: any;
  VIEW_RO_LIST_AUDIT: string[];
  DEVELOPMENT_ENV: string;
  REMOVED_EMAIL_FROM_RECEIVED_ON_POPUP: string[];

  constructor() {
    this.releaseVerion = "v2.12.3";
    this.SOLVE360 = "http://demo.adudigitalservicekit.com/graphiql";
    this.MAGEURL = "http://demo.adudigitalservicekit.com/graphiql";
    this.PAGE_LIMIT = 10;

    /**
     * Regular expression to validate the fields
     */
    this.REG_EXP_FOR_DOUBLE = "^([0-9]*?(\\.[0-9]+)?$)";
    this.REG_EXP_FOR_INTEGER = "^([0-9])";
    this.REG_EXP_FOR_POSATIVE_INTEGER = "/^-?[0-9][^.]*$/";
    this.REG_EXP_FOR_EMAIL =
      "^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$";
    this.REG_EXP_FOR_DATE_FORMAT = /(\d{4})\-(\d{2})\-(\d{2})/;

    /**
     * Validation messages
     */
    this.ERROR_MESSAGE_FOR_NUMBERS = "This entry can only contain numbers";
    this.ERROR_MESSAGE_FOR_DOUBLE =
      "This entry can only contain numbers with or without decimal value";
    this.ERROR_MESSAGE_FOR_REQUIRED = "This entry can't be empty";
    this.ERROR_MESSAGE_FOR_EMAIL = "Please enter a valid email";
    this.ERROR_MESSAGE_FOR_IDSUFFIX = "Target Scenario key exceeds 43 characters.";
    this.ERROR_MESSAGE_FOR_NAMESUFFIX = "Target Scenario name exceeds 50 characters.";
    this.ERROR_MESSAGE_FOR_IDSUFFIX_VALID = "Target Scenario key already exist";
    this.ERROR_MESSAGE_FOR_LWS_BASIS_REQUIRED = "Please select any option other than None";
    /**
     * Messages
     */
    this.SUCCESS_MESSAGE = "success";
    this.RO_ITEM_SUCCESS_MESSAGE = "RO Item Added Successfully";
    this.RO_ITEM_UPDATE_SUCCESS_MESSAGE = "RO Item Updated Successfully";
    this.RO_ITEM_DELETE_SUCCESS_MESSAGE = "RO Item Deleted Successfully";
    this.ERROR_MESSAGE_FOR_INVALID = "This entry is't valid";
    this.ERROR_MESSAGE_FOR_INVOICE_NUMBER_EXIST = "RO number already exists";
    this.ONLY_NUMBERS = "/^-?d+$/";
    this.DELETEDTITLE = "Deleted";
    this.DELETEDTEXT = "This RO item has been deleted.";
    this.DELETEDERRORTITLE = "Error occurred while deleting";
    this.CLOSE = "Close";
    this.AREYOUSURE = "Are you sure?";
    this.CLONE = "Clone";
    this.ROITEM = "RO item #";
    this.WILLBECLONED = "  will be cloned";
    this.CLONED = "Cloned";
    this.ROITEMHASBEENCLONED = "This RO item has been cloned.";
    this.CLONNINGERROR = "Error occurred while clonning";
    this.ADDROITEMERROR = "Failed to add RO item";
    this.EDITROITEMERROR = "Failed to edit RO item";
    this.MIDDLEWARE_FAILURE = "Middleware not accessable, Please contact administrator";
    this.MIDDLEWARE_ERROR = "Error in Middleware, Please contact administrator";
    this.MIDDLEWARE_ERROR_CODE = "[ERR: 4001]";
    this.MIDDLEWARE_ERROR_CODE_FOR_LOGOUT = "[ERR: 5001]";
    this.SESSION_EXPIRED = "Session expired, Please relogin to continue.";
    this.MIDDLEWARE_NOT_ACCESSABLE_MESSAGE =
      "Middleware not accessable, Please contact administrator";
    this.DATABASE_NOT_ACCESSABLE_MESSAGE = "DB access failed, Please retry";
    this.DATABASE_NOT_ACCESSABLE_UNEXPECTED_TOKEN_MESSAGE =
      "DB access failed, Please retry (Hint: Unexpected token)";

    this.CREATE_STORE_PORTAL_SUCCESS_MESSAGE = "Store Added Successfully to Portal";
    this.CREATE_STORE_PORTAL_ERROR = "Failed to add Store to Portal";
    this.ADD_STORE_GROUP_TO_PORTAL_SUCCESS_MESSAGE = "Store Group Added Successfully to Portal";
    this.ADD_STORE_GROUP_TO_PORTAL_ERROR = "Failed to add Store Group to Portal";
    this.ADD_STORE_TO_METAGROUP_SUCCESS_MESSAGE = "Store Added Successfully to Meta Group";
    this.ADD_STORE_TO_METAGROUP_ERROR = "Failed to add Store to Meta Group";
    this.ADD_SOLO_STORE_TO_PORTAL_SUCCESS_MESSAGE = "Solo Store Added Successfully to Portal";
    this.ADD_SOLO_STORE_TO_PORTAL_ERROR = "Failed to add Solo Store to Portal";
    this.ADD_META_GROUP_TO_PORTAL_SUCCESS_MESSAGE = "Meta Group Added Successfully to Portal";
    this.ADD_META_GROUP_TO_PORTAL_ERROR = "Failed to add Meta Group to Portal";
    this.LINK_STORE_PORTAL_SUCCESS_MESSAGE = "Store linked Successfully to Solve360";
    this.LINK_STORE_PORTAL_ERROR = "Error occurred while Linking to Solve360";
    this.LINK_STORE_PORTAL_WARNING = "No Orphan Stores Available to Link";
    this.UNLINK_STORE_PORTAL_SUCCESS_TITLE = "Unlinked";
    this.UNLINK_STORE_PORTAL_SUCCESS_TEXT = "Store unlinked Successfully from Solve360";
    this.UNLINK_STORE_PORTAL_ERROR = "Error occurred while Unlinking";
    this.FSD_CREATED_SUCCESS_TITLE = "FSD Created";
    this.FSD_CREATED_SUCCESS_MESSAGE = "FSD Created Successfully";
    this.FSD_CREATION_ERROR_MESSAGE = "Error occurred while FSD Creation";
    this.FSD_UPDATED_SUCCESS_TITLE = "FSD Updated";
    this.FSD_UPDATED_SUCCESS_MESSAGE = "FSD Updated Successfully";
    this.FSD_UPDATION_ERROR_MESSAGE = "Error occurred while FSD Updation";
    this.SOURCE_BUNDLE_CREATED_SUCCESS_MESSAGE1 = "Source Part Bundle";
    this.SOURCE_BUNDLE_CREATED_SUCCESS_MESSAGE2 = "Created Successfully";
    this.SOURCE_BUNDLE_CREATION_ERROR_MESSAGE = "Error occurred while Source Part Bundle Creation";
    this.SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE1 = "Source Part Bundle";
    this.LABOR_SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE1 = "Source Labor Bundle";
    this.SOURCE_BUNDLE_UPDATED_SUCCESS_MESSAGE2 = "Updated Successfully";
    this.SOURCE_BUNDLE_UPDATION_ERROR_MESSAGE = "Error occurred while Source Part Bundle Updation";
    this.BASIS_BUNDLE_CREATED_SUCCESS_MESSAGE1 = "Basis Part Bundle";
    this.BASIS_BUNDLE_CREATED_SUCCESS_MESSAGE2 = "Created Successfully";
    this.BASIS_BUNDLE_CREATION_ERROR_MESSAGE = "Error occurred while Basis Part Bundle Creation";
    this.BASIS_BUNDLE_UPDATED_SUCCESS_MESSAGE1 = "Basis Part Bundle";
    this.BASIS_BUNDLE_UPDATED_SUCCESS_MESSAGE2 = "Updated Successfully";
    this.BASIS_BUNDLE_UPDATION_ERROR_MESSAGE = "Error occurred while Basis Part Bundle Updation";
    this.TARGET_BUNDLE_CREATED_SUCCESS_MESSAGE1 = "Target Part Bundle";
    this.TARGET_BUNDLE_CREATED_SUCCESS_MESSAGE2 = "Created Successfully";
    this.TARGET_BUNDLE_CREATION_ERROR_MESSAGE = "Error occurred while Target Part Bundle Creation";
    this.LWS_CREATED_SUCCESS_TITLE = "LWS Created";
    this.LWS_CREATED_SUCCESS_MESSAGE = "LWS Created Successfully";
    this.LWS_CREATION_ERROR_MESSAGE = "Error occurred while LWS Creation";
    this.LWS_UPDATED_SUCCESS_TITLE = "LWS Updated";
    this.LWS_UPDATED_SUCCESS_MESSAGE = "LWS Updated Successfully";
    this.LWS_UPDATION_ERROR_MESSAGE = "Error occurred while LWS Updation";
    this.ACTIVATE_THE_BUNDLE = "This will Activate the Bundle.";
    this.DEACTIVATE_THE_BUNDLE = "This will Deactivate the Bundle.";
    this.ACTIVATE_THE_SUBMISSION_ID = "This will Activate the Submission Id.";
    this.DEACTIVATE_THE_SUBMISSION_ID = "This will Deactivate the Submission Id.";
    this.DEACTIVATE_PREAUDIT_REVIEW_THE_BUNDLE =
      "This will Deactivate the Bundle and \n PreAudit Review will be lost.";
    this.BUNDLE_ACTIVATED_TEXT = "Bundle Activated Successfully";
    this.BUNDLE_DEACTIVATED_TEXT = "Bundle Deactivated Successfully";
    this.SUBMISSION_ID_ACTIVATED_TEXT= "Submission Id Activated Successfully";
    this.SUBMISSION_ID_DEACTIVATED_TEXT= "Submission Id Deactivated Successfully";
    this.ACTIVATED = "Activated";
    this.DEACTIVATED = "Deactivated";
    this.BUNDLE_ACTIVATION_ERROR = "Error Occured on Activation of Bundle";
    this.BUNDLE_DEACTIVATION_ERROR = "Error Occured on Deactivation of Bundle";
    this.SUBMISSION_ID_ACTIVATION_ERROR = "Error Occured on Activation of Submission Id";
    this.SUBMISSION_ID_DEACTIVATION_ERROR = "Error Occured on Deactivation of Submission Id";
    this.CLEAR_SCENARIO_WARNING_MESSAGE = "This Scenario will be deleted from Mage & Mapper";
    this.CLEAR_RO_WARNING_MESSAGE =
      "Clear RO will remove entire ROs of this store. Do you want to proceed?";
    this.SAVE_COMMENT_SUCCESS_MSG =
      "Sales Comments updated Successfully";
    this.DM_CREATED_SUCCESS_TITLE = "DM Created";
    this.DM_CREATED_SUCCESS_MESSAGE = "DM Created Successfully";
    this.DM_CREATION_ERROR_MESSAGE = "Error occurred while DM Creation";
    this.DM_UPDATED_SUCCESS_TITLE = "DM Updated";
    this.DM_UPDATED_SUCCESS_MESSAGE = "DM Updated Successfully";
    this.DM_UPDATION_ERROR_MESSAGE = "Error occurred while DM Updation";
    this.PROJECT_LINK_ERROR_MESSAGE = "Error occurred while linking Parts Project to DM";
    this.PROJECT_UNLINK_ERROR_MESSAGE = "Error occurred while unlinking Parts Project from DM";
    this.PROJECT_UNLINK_WARNING_MESSAGE = "This Project will be unlinked";
    this.UNLINK_PROJECT_SUCCESS_TITLE = "Unlinked";
    this.UNLINK_PROJECT_SUCCESS_TEXT = "Project unlinked Successfully";
    this.ACTIVATE_DM_SUCCESS_TITLE = "Activated";
    this.ACTIVATE_DM_SUCCESS_TEXT = "DM Activated Successfully";
    this.DM_ACTIVATION_ERROR_MESSAGE = "Error occurred while DM Activation";
    this.PREAUDIT_CREATED_SUCCESS_TITLE = "PreAudit Created";
    this.PREAUDIT_CREATED_SUCCESS_MESSAGE = "PreAudit Created Successfully";
    this.PREAUDIT_CREATION_ERROR_MESSAGE = "Error occurred while PreAudit Creation";
    this.POSTAUDIT_CREATED_SUCCESS_TITLE = "PostAudit Created";
    this.POSTAUDIT_CREATED_SUCCESS_MESSAGE = "PostAudit Created Successfully";
    this.POSTAUDIT_CREATION_ERROR_MESSAGE = "Error occurred while PostAudit Creation";
    this.SUBMITTED_SUCCESS_TITLE = "Data Bundle Submitted";
    this.SUBMITTED_SUCCESS_MESSAGE = "Data Bundle Submitted Successfully";
    this.SUBMITTED_ERROR_MESSAGE = "Error occurred while Data Bundle Submition";
    this.SALES_CONFIRM_SUCCESS_TITLE = "Sales Confirmed";
    this.SALES_CONFIRM_SUCCESS_MESSAGE = "Sales Confirmed Successfully";
    this.SALES_CONFIRM_ERROR_MESSAGE = "Error occurred while Sales Confirmation";
    this.SALES_CONFIRM_WARNING_MESSAGE = "This will provide Shipping Confirmation";
    this.CURRENT_WLR_Eff_ON_WARNING_MESSAGE =
      "Please enter the date in the valid formats. Valid formats are listed in (i) icon.";
    this.CURRENT_WLR_Eff_ON_WARNING_TITLE = `Valid Date Formats
    MM/YY
    MM/DD/YYYY
    MM/DD/YY
    YYYY/MM/DD
    YYYY/MM 
    MMM/YY
    YYYY/MMM
    MM-DD-YYYY
    YYYY-MM-DD
    YYYY-MM
    MM-YY
    MMM-YY
    MMM-YYYY
    MM-YYYY
    YYYY-MMM
    YY-MMM
    YY/MMM
    YY-MM
    YY/MM
    MMDDYYYY
    M/D/YYYY
    M/DD/YY
    M/D/YY  
    M-D-YY  
    M/YY
    M/YYYY
    MMM/YYYY
    MM/YYYY
    M-YY
    M-YYYY`;
    this.CURRENT_RATE_ON_WARNING_TITLE = `Valid Rate Formats
    $100
    $100.00
    $125
    $125.00
    $1,000
    $1,000.00`;
    this.WARRANTY_PAID_HOUR_ON_WARNING_TITLE = `Valid Formats
    1
    1.00`;
    this.MONTHS_ON_WARNING_TITLE = `Valid Month Formats
    Values should be below 12`;
    this.CANCEL_SALES_CONFIRM_SUCCESS_TITLE = "Cancel Sales Confirmation";
    this.CANCEL_SALES_CONFIRM_SUCCESS_MESSAGE = "Cancel Sales Confirmation Successfully";
    this.CANCEL_SALES_CONFIRM_ERROR_MESSAGE = "Error occurred while Cancel Sales Confirmation";
    this.CANCEL_SALES_CONFIRM_WARNING_MESSAGE = "This will provide Cancel Shipping Confirmation";
    this.BASIS_OVERRIDE_WARNING_TITLE = "Basis Already Override.";
    this.BASIS_OVERRIDE_WARNING_MESSAGE = "Are you sure to change to another source for Billing?";

    this.MGMT_REVIEW_CONFIRM_WARNING_TITTLE = "MGMT.Review not completed";
    this.MGMT_REVIEW_CONFIRM_WARNING_MESSAGE = "Do you want to continue ?";

    this.RECEIVED_SUCCESS_TITLE = "Received Data Bundle";
    this.RECEIVED_SUCCESS_MESSAGE = "Received Data Bundle Successfully";
    this.RECEIVED_ERROR_MESSAGE = "Error occurred while Receiving Data Bundle";
    this.APPROVED_SUCCESS_TITLE = "Data Bundle Approved";
    this.APPROVED_SUCCESS_MESSAGE = "Data Bundle Approved Successfully";
    this.APPROVED_ERROR_MESSAGE = "Error occurred while Data Bundle Approval";
    this.OVERRIDE_ERROR_MESSAGE = "Error while updating data";
    this.SEND_TO_ACCOUNTING_ON_SUCCESS_TITLE = "Data Bundle Send To Accounting";
    this.SEND_TO_ACCOUNTING_ON_SUCCESS_MESSAGE = "Data Bundle Send To Accounting Successfully";
    this.SEND_TO_ACCOUNTING_ON_ERROR_MESSAGE =
      "Error occurred while Data Bundle Send To Accounting On";
    this.PREAUDIT_REVIEW_UPDATED_SUCCESS_TITLE = "PreAudit Review Updated";
    this.PREAUDIT_REVIEW_UPDATED_SUCCESS_MESSAGE = "PreAudit Review Updated Successfully";
    this.PREAUDIT_REVIEW_UPDATED_ERROR_MESSAGE = "Error occurred while PreAudit Review Updatation";
    this.APPROVED_TARGETMARKUP_ERROR = "Target markup should be less than 10";
    this.INITAPP_SUCCESS_TITLE = "Data Bundle Init Approved";
    this.INITAPP_SUCCESS_MESSAGE = "Data Bundle Init Approved Successfully";
    this.INITAPP_ERROR_MESSAGE = "Error occurred while Data Bundle Init Approval";
    this.SNAPSHOT_CREATED_SUCCESS_TITLE = "Snapshot Created";
    this.SNAPSHOT_CREATED_SUCCESS_MESSAGE = "Snapshot Created Successfully";
    this.SNAPSHOT_CREATION_ERROR_MESSAGE = "Error occurred while Snapshot Creation";
    this.SNAPSHOT_VERSION_NAME_ALREADY_EXIST = "Snapshot version name is already exist";
    this.BEST_CREATED_SUCCESS_TITLE = "Best Created";
    this.BEST_CREATED_SUCCESS_MESSAGE = "Best Created Successfully";
    this.BEST_CREATED_ERROR_MESSAGE = "Error occurred while Data Best Creation";
    this.BEST_UPDATED_SUCCESS_TITLE = "Best Updated";
    this.BEST_UPDATED_SUCCESS_MESSAGE = "Best Updated Successfully";
    this.BEST_UPDATED_ERROR_MESSAGE = "Error occurred while Data Best Updation";
    this.DM_AND_FSD_CREATED_SUCCESS_TITLE = "DM & FSD Created";
    this.DM_AND_FSD_CREATED_SUCCESS_MESSAGE = "DM & FSD Created Successfully";
    this.DM_AND_FSD_CREATION_ERROR_MESSAGE = "Error occurred while DM & FSD Creation";
    this.DM_AND_LWS_CREATED_SUCCESS_TITLE = "DM & LWS Created";
    this.DM_AND_LWS_CREATED_SUCCESS_MESSAGE = "DM & LWS Created Successfully";
    this.DM_AND_LWS_CREATION_ERROR_MESSAGE = "Error occurred while DM & LWS Creation";
    this.DM_AND_FSD_AND_LWS_CREATED_SUCCESS_TITLE = "DM, FSD & LWS Created";
    this.DM_AND_FSD_AND_LWS_CREATED_SUCCESS_MESSAGE = "DM, FSD & LWS Created Successfully";
    this.DM_AND_FSD_AND_LWS_CREATION_ERROR_MESSAGE = "Error occurred while DM, FSD & LWS Creation";
    this.PROJECT_UPDATED_IN_SOLVE360 = "Project Updated in Solve360";
    this.PROJECT_UPDATED_FAILED_SOLVE360 = "Solve360 Project Updation Failed";
    this.BILLING_FINALIZED_SUCCESS_TITLE = "Billing Finalized";
    this.BILLING_FINALIZED_SUCCESS_MESSAGE = "Billing Finalized Successfully";
    this.BILLING_FINALIZED_ERROR_MESSAGE = "Error occurred while Billing Finalize";
    this.REVENUE_UPDATED_SUCCESS_TITLE = "Revenue Updated";
    this.REVENUE_UPDATED_SUCCESS_MESSAGE = "Revenue Updated Successfully";
    this.REVENUE_UPDATED_ERROR_MESSAGE = "Error occurred while Revenue Updating";
    this.RETRACTED_SUCCESS_TITLE = "Retracted";
    this.RETRACTED_SUCCESS_MESSAGE = "Retracted Successfully";
    this.RETRACTED_ERROR_MESSAGE = "Error occurred while Retracting";
    this.REACTIVATED_SUCCESS_TITLE = "Reactivated";
    this.REACTIVATED_SUCCESS_MESSAGE = "Reactivated Successfully";
    this.REACTIVATED_ERROR_MESSAGE = "Error occurred while Retracting";

    this.RUSH_SUCCESS_TITLE = "Rush";
    this.RUSH_SUCCESS_MESSAGE = "Added to Rush Successfully";
    this.RUSH_ERROR_MESSAGE = "Error occurred while Adding to Rush";
    this.MAIL_ID_REQUIRED_TITLE = "Recipient Mail Address does not exist";
    this.MAIL_ID_REQUIRED_WARNING_MESSAGE = "Do you want to continue ?";

    this.RUSH_DEACTIVATE_SUCCESS_TITLE = "Undo Rush";
    this.RUSH_DEACTIVATE_SUCCESS_MESSAGE = "Remove from Rush Successfully";
    this.RUSH_DEACTIVATE_ERROR_MESSAGE = "Error occurred while Removing Rush";

    this.PROXY_REVIEW_SUCCESS_TITLE = "Proxy Review";
    this.PROXY_REVIEW_SUCCESS_MESSAGE = "Added to Proxy Review Successfully";
    this.PROXY_REVIEW_ERROR_MESSAGE = "Error occurred while Adding to Proxy Review";
    this.PROXY_REVIEW_DEACTIVATE_SUCCESS_TITLE = "Undo Proxy Review";
    this.PROXY_REVIEW_DEACTIVATE_SUCCESS_MESSAGE = "Remove from Proxy Review Successfully";
    this.PROXY_REVIEW_DEACTIVATE_ERROR_MESSAGE = "Error occurred while Removing Proxy Review";

    this.VENDOR_SUCCESS_TITLE = "Activate Vendor";
    this.VENDOR_SUCCESS_MESSAGE = "Added to Vendor Successfully";
    this.VENDOR_ERROR_MESSAGE = "Error occurred while Adding to Vendor";
    this.IN_NEED_HANDOFF_SUCCESS_MESSAGE = "Added to Need HandOff Successfully";
    this.VENDOR_DEACTIVATE_SUCCESS_TITLE = "Deactivate Vendor";
    this.VENDOR_DEACTIVATE_SUCCESS_MESSAGE = "Remove from Vendor Successfully";
    this.VENDOR_DEACTIVATE_ERROR_MESSAGE = "Error occurred while Removing Vendor";
    this.IN_NEED_HANDOFF_DEACTIVATE_SUCCESS_MESSAGE = "Remove from Need HandOff Successfully";
    this.IN_BOOK_TIME_DEACTIVATE_WARNING_MESSAGE = "This will remove from Book Time";
    this.IN_SHIPPING_DEACTIVATE_WARNING_MESSAGE =
      "This will remove from Shipping";

    this.IN_SALES_SUCCESS_TITLE = "Activate In Sales";
    this.IN_SALES_SUCCESS_MESSAGE = "Added to In Sales Successfully";

    this.IN_SALES_RM_SUCCESS_TITLE = "Removed Comment";
    this.IN_SALES_RM_SUCCESS_MESSAGE = "Removed Comment Successfully";

    this.IN_SALES_ERROR_MESSAGE = "Error occurred while Adding to In Sales";

    this.IN_SALES_DEACTIVATE_SUCCESS_TITLE = "Deactivate In Sales";
    this.IN_SALES_DEACTIVATE_SUCCESS_MESSAGE = "Remove from In Sales Successfully";
    this.IN_SALES_DEACTIVATE_ERROR_MESSAGE = "Error occurred while Removing In Sales";

    this.IN_SALES_CONTINUE_SUCCESS_TITLE = "Continue In Sales";
    this.IN_SALES_CONTINUE_SUCCESS_MESSAGE = "Continue In Sales Successfully";
    this.IN_SALES_CONTINUE_ERROR_MESSAGE = "Error occurred while Continue In Sales";
    this.OVERRIDE_SHIPCOUNT_ERROR_MESSAGE = "Error occurred while Updating data";

    this.PULL_DATA_SUCCESS_TITLE = "Data Pulled";
    this.PULL_DATA_SUCCESS_MESSAGE = "Data Pulled Successfully";
    this.PULL_DATA_ERROR_MESSAGE = "Error occurred while Pull Data";
    this.SCHEDULE_ERROR_MESSAGE = "Error occurred while Schedule";

    this.DASHBOARD_COMPL_TIME_UPDATE_ERROR_MESSAGE = "Error occurred while update Completion Time";
    this.DASHBOARD_COMPL_TIME_SUCCESS_MESSAGE = "Successfully updated completion time";

    this.IMPORT_SUCCESS_TITLE = "Imported";
    this.IMPORT_SUCCESS_MESSAGE = "Imported Successfully";
    this.IMPORT_ERROR_MESSAGE = "Error occurred while Import";

    this.MAPPING_SUCCESS_TITLE = "Mapped";
    this.MAPPING_SUCCESS_MESSAGE = "Mapped Successfully";
    this.MAPPING_ERROR_MESSAGE = "Error occurred while Mapping";

    this.RETRIEVE_RO_SUCCESS_TITLE = "Retrieved RO";
    this.RETRIEVE_RO_SUCCESS_MESSAGE = "Retrieved RO Successfully";
    this.RETRIEVE_RO_ERROR_MESSAGE = "Error occurred while Retrieve RO";

    this.IMPORT_RO_SUCCESS_TITLE = "Import RO";
    this.IMPORT_RO_SUCCESS_MESSAGE = "Import RO Successfully";
    this.IMPORT_RO_ERROR_MESSAGE = "Error occurred while Import RO";

    this.PRE_AUDIT_SUCCESS_TITLE = "PreAudited";
    this.PRE_AUDIT_SUCCESS_MESSAGE = "PreAudit Successfully";
    this.PRE_AUDIT_ERROR_MESSAGE = "Error occurred while PreAudit";

    this.PRE_AUDIT_REVIEW_SUCCESS_TITLE = "PreAudit Review";
    this.PRE_AUDIT_REVIEW_SUCCESS_MESSAGE = "PreAudit Review Successfully";
    this.PRE_AUDIT_REVIEW_ERROR_MESSAGE = "Error occurred while PreAudit Review";

    this.REVIEW_SUCCESS_TITLE = "Reviewed";
    this.REVIEW_SUCCESS_MESSAGE = "Review Successfully";
    this.REVIEW_ERROR_MESSAGE = "Error occurred while Review";

    this.REVIEW_ASSIGN_SUCCESS_TITLE = "Assigned";
    this.REVIEW_ASSIGN_SUCCESS_MESSAGE = "Assigned for Review Successfully";
    this.REVIEW_ASSIGN_ERROR_MESSAGE = "Error occurred while Assign for Review";

    this.ASSIGN_SUCCESS_TITLE = "Assigned";
    this.ASSIGN_SUCCESS_MESSAGE = "Assigned Successfully";
    this.ASSIGN_ERROR_MESSAGE = "Error occurred while Assign Process";

    this.UPDATE_SUCCESS_TITLE = "Updated";
    this.UPDATE_SUCCESS_MESSAGE = "Updated Successfully";
    this.UPDATE_ERROR_MESSAGE = "Error occurred while Update Process";

    this.COMPLETE_SUCCESS_TITLE = "Completed";
    this.COMPLETE_SUCCESS_MESSAGE = "Completed Successfully";
    this.COMPLETE_ERROR_MESSAGE = "Error occurred while Complete Process";

    this.SEND_TO_PREAUDIT_REVIEW_SUCCESS_TITLE = "Send to PreAudit Review";
    this.SEND_TO_PREAUDIT_REVIEW_SUCCESS_MESSAGE = "Send to PreAudit Review Successfully";
    this.SEND_TO_PREAUDIT_REVIEW_ERROR_MESSAGE = "Error occurred while Send to PreAudit Review";

    this.DECLINED_SUCCESS_TITLE = "Declined";
    this.DECLINED_SUCCESS_MESSAGE = "Declined Successfully";
    this.DECLINED_ERROR_MESSAGE = "Error occurred while Declined Process";

    this.FORWARD_COMPLETE_SUCCESS_TITLE = "Current Stage Completed";
    this.FORWARD_COMPLETE_SUCCESS_MESSAGE = "Completed Successfully";
    this.FORWARD_COMPLETE_ERROR_MESSAGE = "Error occurred while Complete Process";

    this.BACKWARD_COMPLETE_SUCCESS_TITLE = "Current Stage Deactivated";
    this.BACKWARD_COMPLETE_SUCCESS_MESSAGE = "Current Stage Deactivated Successfully";
    this.BACKWARD_COMPLETE_ERROR_MESSAGE =
      "Error occurred while Current Stage Deactivation Process";

    this.AUDIT_SUCCESS_TITLE = "Audited";
    this.AUDIT_SUCCESS_MESSAGE = "Audited Successfully";
    this.AUDIT_ERROR_MESSAGE = "Error occurred while Audit";

    this.AUDIT_DISPATCH_SUCCESS_TITLE = "Dispatched";
    this.AUDIT_DISPATCH_SUCCESS_MESSAGE = "Dispatched Successfully";
    this.AUDIT_UPDATE_SUCCESS_TITLE = "Updated";
    this.AUDIT_UPDATE_SUCCESS_MESSAGE = "Updated Successfully";
    this.AUDIT_DISPATCH_ERROR_MESSAGE = "Error occurred while Dispatch";

    this.AUDIT_REASSIGN_SUCCESS_TITLE = "ReAssigned";
    this.AUDIT_REASSIGN_SUCCESS_MESSAGE = "ReAssigned Successfully";
    this.AUDIT_REASSIGN_ERROR_MESSAGE = "Error occurred while ReAssign";

    this.AUDIT_UPDATE_ERROR_MESSAGE = "Error occurred while Update";

    this.AUDIT_SEND_TO_REVIEW_SUCCESS_TITLE = "Sent to Review";
    this.AUDIT_SEND_TO_REVIEW_SUCCESS_MESSAGE = "Sent to Review Successfully";
    this.AUDIT_SEND_TO_REVIEW_ERROR_MESSAGE = "Error occurred while Sent to Review";

    this.AUDIT_RETRIEVE_MISSING_RO_SUCCESS_TITLE = "Missing RO Requested";
    this.AUDIT_RETRIEVE_MISSING_RO_SUCCESS_MESSAGE = "Missing RO Requested Successfully";
    this.AUDIT_RETRIEVE_MISSING_RO_ERROR_MESSAGE = "Error occurred while Retrieve Missing RO";

    this.AUDIT_RETRIEVE_MISSING_RO_RECEIVE_SUCCESS_TITLE = "Missing RO Received";
    this.AUDIT_RETRIEVE_MISSING_RO_RECEIVE_SUCCESS_MESSAGE = "Missing RO Received Successfully";
    this.AUDIT_RETRIEVE_MISSING_RO_RECEIVE_ERROR_MESSAGE =
      "Error occurred while Receive Missing RO";

    this.SENT_TO_FINALIZE_SUCCESS_TITLE = "Sent to Finalize";
    this.SENT_TO_FINALIZE_SUCCESS_MESSAGE = "Sent to Finalize Successfully";
    this.SENT_TO_FINALIZE_ERROR_MESSAGE = "Error occurred while Sent to Finalize";

    this.SENT_TO_FINALIZE_ERROR_MESSAGE_BOTH_TYPE = "Both primary and secondary need to finalize";

    this.AUDITOR_REPORT_NOT_REVIEWED_WARNING_TITLE = "Auditor Reports Not Reviewed";
    this.AUDITOR_REPORT_NOT_REVIEWED_WARNING_MESSAGE =
      "Before sending to Review or Final, please review all Auditor Report tabs and mark reviewed.";

    this.REAUDIT_SUCCESS_TITLE = "Sent back to Auditor";
    this.REAUDIT_SUCCESS_MESSAGE = "Sent back to Auditor Successfully";
    this.REAUDIT_ERROR_MESSAGE = "Error occurred while Sent back to Auditor";

    this.FINALIZE_SUCCESS_TITLE = "Finalized";
    this.FINALIZE_SUCCESS_MESSAGE = "Finalize Successfully";
    this.FINALIZE_ERROR_MESSAGE = "Error occurred while Finalize";

    this.SHIPPED_SUCCESS_TITLE = "Shipped";
    this.SHIPPED_SUCCESS_MESSAGE = "Shipped Successfully";
    this.SHIPPED_ERROR_MESSAGE = "Error occurred while Shipping";

    this.POST_AUDIT_REVIEW_SUCCESS_TITLE = "PostAudit Review";
    this.POST_AUDIT_REVIEW_SUCCESS_MESSAGE = "PostAudit Review Successfully";
    this.POST_AUDIT_REVIEW_ERROR_MESSAGE = "Error occurred while PostAudit Review";

    this.DISPUTED_SUCCESS_TITLE = "Begin Dispute";
    this.DISPUTED_SUCCESS_MESSAGE = "Begin Dispute Successfully";
    this.DISPUTED_ERROR_MESSAGE = "Error occurred while Begin Dispute";
    this.UNDISPUTED_SUCCESS_TITLE = "Resolve Dispute";
    this.UNDISPUTED_SUCCESS_MESSAGE = "Resolve Dispute Successfully";
    this.UNDISPUTED_ERROR_MESSAGE = "Error occurred while Resolve Dispute";

    this.DISPUTE_ANALYSIS_SUCCESS_TITLE = "Dispute Analysis";
    this.DISPUTE_ANALYSIS_SUCCESS_MESSAGE = "Dispute Analysis Added Successfully";
    this.DISPUTE_ANALYSIS_ERROR_MESSAGE = "Error occurred while Dispute Analysis";

    this.MANF_REQUEST_SUCCESS_TITLE = "Begin Manf.Request";
    this.MANF_REQUEST_SUCCESS_MESSAGE = "Begin Manf.Request Successfully";
    this.MANF_REQUEST_ERROR_MESSAGE = "Error occurred while Begin Manf.Request";

    this.MANF_REQUEST_COMPLETE_SUCCESS_TITLE = "Complete Manf. Request";
    this.MANF_REQUEST_COMPLETE_SUCCESS_MESSAGE = "Complete Manf. Request Successfully";
    this.MANF_REQUEST_COMPLETE_ERROR_MESSAGE = "Error occurred while Complete Manf. Request";

    this.MANF_REQUEST_DELIVERED_SUCCESS_TITLE = "Manf. Request delivered on";
    this.MANF_REQUEST_DELIVERED_SUCCESS_MESSAGE = "Manf. Request delivered Successfully";
    this.MANF_REQUEST_DELIVERED_ERROR_MESSAGE = "Error occurred while Manf. Request delivered";

    this.NETSPECTIVE_SUCCESS_TITLE = "Netspective support started";
    this.NETSPECTIVE_SUCCESS_MESSAGE = "Netspective support started successfully";
    this.NETSPECTIVE_ERROR_MESSAGE = "Error occurred while starting Netspective support";

    this.NETSPECTIVE_DEACTIVATE_SUCCESS_TITLE = "Netspective support completed";
    this.NETSPECTIVE_DEACTIVATE_SUCCESS_MESSAGE = "Netspective support completed successfully";
    this.NETSPECTIVE_DEACTIVATE_ERROR_MESSAGE =
      "Error occurred while completing Netspective support";

    this.MGMT_REVIEW_ACTIVATE_WARNING_MESSAGE = "MGMT.Review tag add";
    this.MGMT_REVIEW_DEACTIVATE_WARNING_MESSAGE = "MGMT.Review Complete";

    this.MGMT_REVIEW_START_SUCCESS_TITLE = "MGMT.Review tag added";
    this.MGMT_REVIEW_START_SUCCESS_MESSAGE = "MGMT.Review tag added Successfully";
    this.MGMT_REVIEW_START_ERROR_MESSAGE = "Error occurred while MGMT.Review";

    this.MGMT_REVIEW_END_SUCCESS_TITLE = "MGMT.Review Completed";
    this.MGMT_REVIEW_END_SUCCESS_MESSAGE = "MGMT.Review Completed Successfully";
    this.MGMT_REVIEW_END_ERROR_MESSAGE = "Error occurred while MGMT.Review Complete";

    this.REVIEWED_PREAUDIT_BUNDLE_EXIST = "Reviewed PreAudit bundle exist";
    this.ACTIVE_APPROVE_INIT_APPROVE_BUNDLE_EXIST = "Active Approve/Init.Approve bundle exists";
    this.ACTIVE_SUBMIT_BUNDLE_EXIST = "Active Submit bundle exists";
    this.NO_ACTIVE_SUBMIT_BUNDLE_EXIST = "No active Submit bundle exists";
    this.NO_ACTIVE_POST_AUDIT_BUNDLE_EXIST = "No active Post Audit bundle exists";
    this.ACTIVE_APPROVE_BUNDLE_EXIST = "Active Approve bundle exists";
    this.ACTIVE_POSTAUDIT_BUNDLE_EXIST = "Active PostAudit bundle exists";
    this.ACTIVE_BILLING_IS_NOT_EXISTS = "Active Billing is not exists";
    this.ACTIVE_INITAPPROVE_BUNDLE_EXIST = "Active InitApprove bundle exists";
    this.ACTIVE_BILLING_IS_EXISTS = "Active Billing is exists";
    this.BASIS_CREATED_SUCCESS_TITLE = "Basis Created";
    this.BASIS_CREATED_SUCCESS_MESSAGE = "Basis Created Successfully";
    this.BASIS_CREATED_ERROR_MESSAGE = "Error occurred while Basis Creation";
    this.AUTOFILL_ERROR_AT_13TH_COLUMN = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_ERROR_AT_14TH_COLUMN = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_ERROR_AT_15TH_COLUMN = "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_16TH_COLUMN = "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_17TH_COLUMN = "Invalid number (# Months Year To Date).";
    this.AUTOFILL_ERROR_AT_18TH_COLUMN =
      "Invalid Rate for (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_REQUIRED_AT_18TH_COLUMN =
      "Invalid Rate for (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT = "Number of fields expected is 19, found ";
    this.AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT_MIN = "Number of fields expected is 14, found ";
    this.AUTOFILL_ERROR_EXPECTED_COLUMN_COUNT_MIN_V2 = "Number of fields expected is 14, found ";
    this.AUTOFILL_REQUIRED_AT_4TH_COLUMN =
      "Invalid Rate for (CP-ELR-LWS P3M). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_4TH_COLUMN =
      "Invalid Rate for (CP-ELR-LWS). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_8TH_COLUMN =
      "Invalid Rate for (Current Door or Posted Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_3TH_COLUMN =
      "Invalid Rate for (Next CP Increase - How Much?). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_7TH_COLUMN =
      "Invalid Rate for (Highest Rate on Grid Above Door Rate if Applicable). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_6TH_COLUMN =
      "Invalid Rate for (Lowest Rate on Competitive Work). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_9TH_COLUMN =
      "Invalid Rate for (Previous Door or Posted Rate). Valid formats listed in (R) icon";

    this.AUTOFILL_13TH_14TH_COLUMN_NEGATIVE =
      "(Warranty Paid Hours - Prior Year) should be greater than zero";
    this.AUTOFILL_15TH_16TH_COLUMN_NEGATIVE =
      "(Warranty Paid Hours - Year To Date) should be greater than zero";
    this.BASIS_OVERRIDE_SUCCESS_TITLE = "Basis Overridden";
    this.BASIS_OVERRIDE_SUCCESS_MESSAGE = "Basis Overridden Successfully";
    this.BASIS_OVERRIDE_ERROR_MESSAGE = "Error occurred while Basis Overriding";
    this.TARGET_CREATED_SUCCESS_TITLE = "Target Created";
    this.TARGET_CREATED_SUCCESS_MESSAGE = "Target Created Successfully";
    this.TARGET_CREATED_ERROR_MESSAGE = "Error occurred while Target Creation";

    this.AUTOFILL_ERROR_AT_9TH_COLUMN_MIN = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_ERROR_AT_10TH_COLUMN_MIN = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_ERROR_AT_11TH_COLUMN_MIN = "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_12TH_COLUMN_MIN = "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_13TH_COLUMN_MIN = "Invalid number (# Months Year To Date)";
    this.AUTOFILL_ERROR_AT_14TH_COLUMN_MIN =
      "Invalid Rate for (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_REQUIRED_AT_14TH_COLUMN_MIN =
      "Invalid Rate for (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_9TH_10TH_COLUMN_NEGATIVE_MIN =
      "(Warranty Paid Hours - Prior Year) should be greater than zero";
    this.AUTOFILL_11TH_12TH_COLUMN_NEGATIVE_MIN =
      "(Warranty Paid Hours - Year To Date) should be greater than zero";

    this.AUTOFILL_ERROR_AT_7TH_COLUMN_MIN_V2 = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_ERROR_AT_8TH_COLUMN_MIN_V2 = "Invalid number (Warranty Paid Hours - Prior Year)";
    this.AUTOFILL_7TH_8TH_COLUMN_NEGATIVE_MIN_V2 =
      "(Warranty Paid Hours - Prior Year) should be greater than zero";
    this.AUTOFILL_9TH_10TH_COLUMN_NEGATIVE_MIN_V2 =
      "(Warranty Paid Hours - Year To Date) should be greater than zero";
    this.AUTOFILL_ERROR_AT_9TH_COLUMN_MIN_V2 =
      "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_10TH_COLUMN_MIN_V2 =
      "Invalid number (Warranty Paid Hours - Year To Date)";
    this.AUTOFILL_ERROR_AT_11TH_COLUMN_MIN_V2 = "Invalid number (# Months Year To Date)";
    this.AUTOFILL_REQUIRED_AT_12TH_COLUMN_MIN_V2 =
      "Invalid number (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_12TH_COLUMN_MIN_V2 =
      "Invalid number (Curr Warr Labor Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_5TH_COLUMN_MIN_V2 =
      "Invalid number (Next CP Increase - How Much?). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_1TH_COLUMN_MIN_V2 =
      "Invalid number (Current Door or Posted Rate). Valid formats listed in (R) icon";
    this.AUTOFILL_ERROR_AT_3TH_COLUMN_MIN_V2 =
      "Invalid number (CP-ELR-LWS). Valid formats listed in (R) icon";
    /**
     * Urls
     */
    this.HOME_URL = "/home";
    this.DASHBOARD_URL = "/project-overview";
    // this.AUDIT_SUMMARY_URL = "/swat";
    this.SWAT_URL = "/swat";
    this.SWAT_URL_ID = "swat";
    this.PRODUCTION_DASHBOARD_URL = "/productivity-dashboard";
    this.SIM_URL = "/operations/swap-invoice-master";
    this.LIST_RO = "/operations/mage-grid-editor";
    this.ADD_RO_ITEM = "/operations/mage-grid-editor/add-ro-items";
    this.EDIT_RO_ITEM = "/operations/mage-grid-editor/edit-ro-items";
    this.ADD_RO = "/operations/mage-grid-editor/add-ro";
    this.REYNOLDS_RO_EXCEPTION_URL = "/operations/ro-master-exception";
    this.UNDER_CONSTRUCTION_URL = "/under-construction";
    this.DASHBOAD_UNDER_CONSTRUCTION = "/dashboad-under-construction";
    this.JOINT_OPTIMIZATION_URL = "/operations/joint-optimization";
    this.CLONING_SCENARIOS_URL = "/operations/cloning-scenarios";
    this.FACTORY_LABOR_FORMS_URL = "/operations/factory-labor-forms";
    this.DUE_DATE_REPORT_URL = "/due-date-report";
    this.CREATE_DATA_MASTER_URL = "/store-overview";
    this.CREATE_DATA_MASTER_BETA_URL = "/store-overview-beta";
    this.OPERATION_PAGE_NAME = "Operations";
    this.STORE_SEARCHER_URL = "/store-searcher";
    this.DM_CONTROL_PANEL_URL = "/group-overview";
    this.GRP_RESULT_SUMMARY_URL = "/dm-summary";
    this.RANGE_SELECTOR_URL = "/range-selector";
    this.RULE_DETAILS_URL = "/rule-details";
    this.CDK_ANALYSIS_URL = "/cdk-analysis";
    this.EXCEPTION_ANALYSIS_URL = "/exception-analysis";
    this.INVOICE_GENERATION_URL = "/download-ros";
    this.AUDITOR_REPORT_URL = "/auditor-report";
    this.MANAGE_PAY_TYPE_URL = "/mage-paytype-manage";
    this.MAGE_MAPPER_URL = "/operations/clearscenario";
    this.MAKE_DISPOSITION_URL = "/makes-disposition";
    this.INVOICE_REBUILD_URL = "/invoice-rebuild";
    this.MANAGE_MAIL_URL = "/manufacturer-submissions";
    this.MANAGE_BILLING_ACTIVITY_URL = "/billing-process-history";
    this.PRODUCTION_SNAPSHOT_URL = "/production-snapshot";
    this.PRODUCTION_SNAPSHOT_BETA_URL = "/production-snapshot-beta";
    this.USERS_URL = "/user-management";
    this.SECONDARY_UNRESOLVED_URL = "/secondary-unresolved";
    this.FUTURE_PROJECTS_URL = "/future-projects";
    this.EXCEPTION_MANAGEMENT_URL = "/exception-management";
    this.RANGE_SELECTOR_BETA_URL = "/range-selector-beta";
    this.AUDIT_MODULE_URL = "/audit-module";
    this.AUDITOR_LIST_URL = "/auditor-list";
    this.SUBMISSION_SUMMARY_URL = "/submission-summary";
    this.SOLVE_SYNC_URL = "/solve-update-log";

    this.UAT_ENV = "uat";
    this.TEST_ENV = "test";
    this.DEV_ENV = "dev";
    this.PROD_ENV = "prod";
    this.BACKUP_ENV = "backup";
    this.DEVELOPMENT_ENV = "development";
    this.ID_SUFFIX_MAX_LENGTH = 4;
    this.SCENARIO_NAME_LENGTH = 50;
    this.SCENARIO_ID_LENGTH = 20;

    this.MARKUP_AS_RATIO = 0;
    this.MARKUP_AS_PERCENTAGE = 1;

    this.PUNCH_LIST = "Punch List";
    this.DISPUTED = "Disputed";
    this.READY_TO_BILL = "Ready to Bill";
    this.CLIENT = "Accounting to Bill";
    this.NONCONTIGENT = "Non-Contingent";

    this.PAST_DUE = "Past Due";
    this.NOT_DUE = "Not Due";
    this.MANF_REQUEST = "Manf. Request";
    this.ERROR = "Error";

    this.EXCEL_HEADING1 =
      "Warranty Parts Uplift Projection Based on Dealer Financial Statement Data*";
    this.EXCEL_HEADING2 =
      '*  The "Unadjusted" uplift would result if our process achieved an increased warranty markup equal to the current customer pay mark up. HOWEVER, Financial Statement Data does not typically reveal factors like work mix; G/L account coding; unusual parts pricing and discounting policies - all of which influence the ultimate result. Strength of Statute and unpredictable OEM behavioral variations are also key contributors and are subject to changes without notice. Such unknown influences can adversely impact the accuracy of the projection. What we know at the time of the projections is factored into the "Optimized" results, but only a detailed evaluation of a store\'s DMS data will allow us to determine the actual uplift potential for a store. Even then, a "mid-stream" change in OEM behavior can significantly impact the final result. Please Note: This Financial Statement Data Projection assumes "average" discounting.';
    this.EXCEL_HEADING3 = "Financial Statement Data from Dealer";
    this.EXCEL_HEADING4 =
      "Optimized Uplift: *Current OEM and State Statute Impact, Factored \n with Actual Armatus OEM Specific Results Database Averages, \n Applied to Financial Statement Data Provided";
    this.EXCEL_HEADING5 =
      "Profit Acquisition Fee and Payback Period Assuming \n Approval as Projected";
    this.EXCEL_HEADING6 = "Expectations Based on Financial Statement Data";
    this.EXCEL_FOOTER =
      "Questions? Please contact Mike Kilchenstein (410) 975-0115     mikek@dealeruplift.com";
    this.EXCEL_FOOTER_DYNAMIC =
      "Questions? Please contact {{footerName}}  {{footerPhone}}   {{footerEmail}}";

    this.EXCEL_PSSHEADING1 = "Warranty Parts Uplift: Project Stage Summary";
    this.EXCEL_PSSHEADING2 = "Uplift Projected from Financial Statement Data ";
    this.EXCEL_PSSHEADING3 = "Optimized and Audited Pre-Discount Results";
    this.EXCEL_PSSHEADING4 = "Optimized and Audited Post-Discount (Ready to Submit Results)";
    this.EXCEL_PSSHEADING5 = "Factory Approved Uplift";

    this.EXCEL_INTERNAL_PSSHEADING = "Warranty Parts Uplift: Internal Project Stage Summary";
    this.EXCEL_INTERNAL_PSSHEADING_SUB1 = "Uplift Projected from Financial Statement Data";
    this.EXCEL_INTERNAL_PSSHEADING_SUB2 = "Optimized Pre RO Audit Uplift";
    this.EXCEL_INTERNAL_PSSHEADING_SUB3 = "Optimized Pre-Discount Uplift";
    this.EXCEL_INTERNAL_PSSHEADING_SUB4 = "Optimized Post-Discount Uplift";
    this.EXCEL_INTERNAL_PSSHEADING_SUB5 = "Factory Approved Uplift";

    this.LIGHT_GREEN = "#27a102";
    this.MEDIUM_GREEN = "#1c6000";
    this.LIGHT_RED = "#f64d52";
    this.LIGHT_RED_V1 = "#DFC8C8";
    this.LIGHT_GREY = "#acacac";
    this.LIGHT_YELLOW = "rgb(167, 155, 7)";
    this.LIGHT_BLUE = "#00b8ff";
    this.LIGHT_PURPLE = "#efbbff";
    this.MEDIUM_RED = "#f64d52";
    this.MEDIUM_PURPLE = "#be29ec";
    this.LIGHT_ORANGE = "#f39834";
    this.BLACK = "#0f1010";
    this.VIOLET = "#E6E6FA";
    this.LIGHT_CYAN = "#E0FFFF";
    this.GREY = "#5a5959";
    this.WHITE = "#fff";
    this.LIGHT_RED1 = "#a20000";
    this.LIGHT_GREEN1 = "rgb(126, 204, 102)";
    this.LIGHT_YELLOW1 = "rgb(219, 219, 106)";
    this.LIGHT_BROWN = "#e08b3e";
    this.LIGHT_YELLOW2 = "rgb(248, 255, 189)";
    this.DARK_GREEN = "rgb(147, 225, 147)";

    this.PROJECT_NOT_CONTRACTED = "Project Not Contracted";
    this.PROJECT_WAS_RETRACTED = "Project was Retracted";
    this.PROJECT_APPEARS_TO_BE_NOT_VIABLE = "Project Appears to be Not Viable";
    this.INSUFFICIENT_DATA_TO_CALCULATE_REVENUE = "Insufficient Data to Calculate Revenue";
    this.NO_ACTIVE_DATA_BUNDLE_TO_CALCULATE_REVENUE = "No Active Data Bundles to Calculate Revenue";
    this.NO_REVENUE_FORCAST_FOR_PROJECTION = "No Revenue Forecast for Projections";
    this.SOLVE360_RESET_VALUE = " ";
    this.MAGE_UPDATE_META_ERROR_MESSAGE = "Error occurred while Mage update meta process";
    this.REBUILD_BUILDOUT_ERROR_MESSAGE = "Error occurred while Rebuild buildout process";
    this.ERROR_MESSAGE_FOR_BASIS_TARGET_REQUIRED = "Both Basis and Target Bundle can't be None";
    this.ERROR_MESSAGE_FOR_BASIS_REQUIRED = "Basis Bundle can't be None";
    this.UPDATE_DEADLINE_NOTE = "Deadline Note Updated Successfully ";
    this.DEADLINE_NOTE_DELAY_COUNTER = 4;
    this.SITE_COPYRIGHT_MESSAGE = "Copyright: Armatus Dealer Uplift All rights reserved.";
    this.BUILD_ERROR_MESSAGE = "Error occurred while Build process";

    this.BILLING_MAIL_DISPLAY = {
      MAIL_CONTENT_DATA_ERROR: "Error while loading data!",
      FORWARD_MAIL_CONFIRM: "Forward mail to Sender",
      ERROR_WHILE_FORWARD_DATA: "Error while forwarding mail to Sender.",
      FORWARD_MAIL_TO_RECIPIENTS: "Forward mail to Recipients",
      ERROR_WHILE_FORWARD_TO_RECIPIENTS: "Error while forwarding mail to Recipients.",
      DELETE_REC_WARNING: "Remove {{mailAddress}} from the Contact's list.",
    };

    this.REACTIVATE_SUCCESS_TITLE = "Activated Successfully";
    this.RETRACT_SUCCESS_TITLE = "Retracted Successfully";
    this.REACTIVATE_WARNING_TITLE = "Cannot Activate Project";
    this.RETRACT_WARNING_TITLE = "Cannot Retract Project";
    this.REACTIVATE_FAIL_TITLE = "Error";
    this.RETRACT_FAIL_TITLE = "Error";
    this.PRODUCTION_SNAPSHOTS_WARNING_STAGE_TITTLE = "Project stage already updated by other";
    this.PRODUCTION_SNAPSHOTS_WARNING_TITTLE = "Project stage mismatch found";
    this.PRODUCTION_SNAPSHOTS_WARNING_MESSAGE = "Click Ok to load the latest Project details";
    this.RESOLVE_WARNING_TITLE = "Cannot Resolve Project";
    this.STATUS_CHANGE_ERROR_MESSAGE_NOTLINKED =
      '{{projectType}} project not linked with solve360 for the store "{{storeName}}"';
    this.STATUS_CHANGE_ERROR_MESSAGE_NOTPRIMARY =
      '{{projectType}} project of the store "{{storeName}}" is not Primary';
    this.STATUS_CHANGE_ERROR_MESSAGE_NOTSECONDARY =
      '{{projectType}} project of the store "{{storeName}}" is not Secondary';
    this.STATUS_CHANGE_ERROR_MESSAGE_APPROVED =
      '{{projectType}} project of the store "{{storeName}}" is in the Approved stage';
    this.STATUS_CHANGE_ERROR_MESSAGE_INITAPPROVED =
      '{{projectType}} project of store "{{storeName}}" is in the InitApproved stage';
    this.STATUS_CHANGE_ERROR_MESSAGE_RETEACTED =
      '{{projectType}} project of the store "{{storeName}}" is already in the retracted state';
    this.STATUS_CHANGE_ERROR_MESSAGE_ACTIVATED =
      '{{projectType}} project of the store "{{storeName}}" is already in the active state';
    this.STATUS_RESOLVE_SUCCESS_TITLE = "Resolved Successfully";
    this.STATUS_RESOLVE_FAILURE_TITLE = "Failed to Resolve";
    this.STATUS_RESOLVE_FAILURE_SOLVE360 = "Failed to resolve the project in the Solve360";
    this.STATUS_REACTIVE_FAILURE_SOLVE360 = "Failed to activate the project in the Solve360";
    this.STATUS_RETRACT_FAILURE_SOLVE360 = "Failed to retract the project in the Solve360";
    this.STATUS_CHANGE_ERROR_MESSAGE_RESOLVED_ALREADY =
      '{{projectType}} project of store "{{storeName}}" is already in the resolved state';
    this.STATUS_REACTIVATE_EMITTER_MESSAGE_SOLVE360 =
      "Activation on process: Updating to Solve360.";
    this.STATUS_RETRACT_EMITTER_MESSAGE_SOLVE360 = "Retraction on process: Updating to Solve360.";
    this.STATUS_PROJECT_NOTFOUND_SOLVE360 = "Project not found in the Solve360";
    this.STATUS_REACTIVATE_EMITTER_MESSAGE = "Activation on process: Updating to Portal.";
    this.CREATE_PROJECT_MODAL_TITLE = "Create Project";
    this.UPDATE_PROJECT_MODAL_TITLE = "Update Project";
    this.ERROR_PROJECT_NOT_LINKED = "Project not linked";
    this.VALIDATION_ERROR_CREATE_DM = "Please Fill all mandatory fields";
    this.AWS_FILE_UPLOADED_SUCCESS = "File uploaded Successfully";
    this.AWS_FILE_UPLOADED_FAILED = "File upload Failed";
    this.SET_EXTENDED_RO_FAILED = "Failed";
    this.SET_EXTENDED_RO_FAILED_MESSAGE = "Cannot set this RO as ${type}";
    this.SET_EXTENDED_RO_ES_FAILED =
      "Please set the RO index which is less than SE and less than EE.";
    this.SET_EXTENDED_RO_EE_FAILED =
      "Please set the RO index which is greater than ES and greater than SE.";
    this.SET_EXTENDED_RO_SE_FAILED =
      "Please set the RO index which is greater than ES and less than EE.";
    this.SET_EXTENDED_RO_SES_WARNING = "The starting RO and Extended Start RO are same.";
    this.SET_EXTENDED_RO_SEE_WARNING = "The ending RO and Extended End RO are same.";
    this.RO_SAVED_SUCCESSFULLY = "RO Range Set Successfully";
    this.FIND_TEXT_IN_RULES = "Labor is all ROs in the previous month";
    this.MAGE_UPDATE_META_SUCCESS_MESSAGE = "Meta data updated successfully";
    this.MAIL_SEND_SUCCESS = "Mail Send Successfully";
    this.MAIL_NOT_SENT = "Mail Not Sent";
    this.NO_SCENARIO_KEY = "No Scenario Key";
    this.RANGE_SELECTOR_REPORT_TEMPLATE_URL_PARTS =
      "/assets/templates/Analytical Progress Report_PARTS_Template.docx? r = " +
      new Date().getTime();
    this.RANGE_SELECTOR_REPORT_TEMPLATE_URL_LABOR =
      "/assets/templates/Analytical Progress Report_LABOR_Template.docx? r = " +
      new Date().getTime();
    this.RANGE_SELECTOR_QUALIFIED_MESSAGE =
      "No range found with in {{days}} {{daysOrMonths}} (E Open -  S Open)";
    this.ACTIVE_BASIS_BUNDLE_EXISTS = "Active Basis bundle exists";
    this.ACTIVE_BASIS_BUNDLE_EXISTS_MESSAGE =
      "This update will cause to change the 'Door Rate' & 'Fr Cust - Curr Warr LR' values in Solve360. Do you want to continue ?";
    this.UPDATE = "Update";
    this.DM_DELETED_SUCCESS_TITLE = "DM Deleted";
    this.DM_DELETED_SUCCESS_MESSAGE = "DM Deleted Successfully";
    this.DM_DELETION_ERROR_MESSAGE = "Error occurred while DM Deletion";
    this.DM_DELETE_WARNING_MESSAGE = "This DM Version will be deleted";
    this.DM_DELETE_WARNING_TITLE = "Cannot delete";
    this.DM_DELETE_WARNING_MESSAGE_FOR_SUBMITTED_PROJECT =
      "Cannot delete the DM which have an Active Submit Bundle";
    this.DM_DELETE_WARNING_MESSAGE_FOR_SNAPSHOT_EXIST = "Snapshot exist with the selected DM";
    this.AUDIT_REPORT_DOWNLOAD_ERROR = "File reference missing in GGS";
    this.SEND_TO_ACCOUNTING_WARNING_MESSAGE = "This will enable Send to Accounting";
    this.CURRENT_WLR_WARNING_MESSAGE = "Current WLR went into effect with in one year";
    this.IN_SALES_ACTIVATE_WARNING_MESSAGE = "This will add to In Sales";
    this.VENDOR_ACTIVATE_WARNING_MESSAGE = "This will add to Vendor";
    this.VENDOR_DEACTIVATE_WARNING_MESSAGE = "This will remove from Vendor";
    this.IS_NO_BRAND_MSG =
      "Manufacturer Nuance (BMW/Benz) - MGMT Review requested";
    this.IS_NO_FSD_MSG = "Needs management approval to bill on COGS.";
    this.IS_NO_FSD_BRAND_MSG =
      "Needs management approval to bill on COGS, Manufacturer Nuance (BMW/Benz).";  
    this.IS_NO_FSD_BILLING_VARIENCE_BRAND_MSG =
      "COGS, Base Rate Change, Manufacturer Nuance (BMW/Benz), please get management approval."; 
    this.IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_MSG =
      "Needs management approval to bill on COGS, Above 60% variance in volume, Manufacturer Nuance (BMW/Benz).";  
    this.IS_NO_LWS_MSG = "Needs management approval to bill on calculated hours.";
    this.IS_NO_FSD_BRAND_APPROVED_MSG = "COGS, Manufacturer Nuance (BMW/Benz) Approved by Management";
    this.IS_BRAND_APPROVED_MSG = "Manufacturer Nuance (BMW/Benz)";
    this.IS_NO_FSD_APPROVED_MSG = "Management Approved COGS";
    this.IS_NO_FSD_GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG = "Management Approved COGS, Variance, Manufacturer Nuance (BMW/Benz)";
    this.IS_NO_LWS_APPROVED_MSG = "Management Approved Calculated Hours";
    this.NO_FSD = "No FSD";
    this.NO_LWS = "No LWS";
    this.ABOVE40_VARIANCE = "Above 60% variance";
    this.GREATER_FORTY_PERCENTAGE_MSG =
      "Above 60% variance in volume, please get management approval.";
    this.GREATER_FORTY_PERCENTAGE_BRAND_MSG =
      "Above 60% variance in volume, Manufacturer Nuance (BMW/Benz), please get management approval.";
    this.BILLING_VARIENCE_MSG =
      "Base Rate Change, please get management approval.";
    this.BILLING_VARIENCE_BRAND_MSG =
      "Base Rate Change, Manufacturer Nuance (BMW/Benz), please get management approval.";this.BILLING_VARIENCE_MSG = "Base Rate Change, please get management approval.";
    this.BILLING_VARIENCE_NO_FSD_MSG = "Base Rate Change, No FSD, please get management approval.";
    this.BILLING_VARIENCE_NO_FSD_BRAND_MSG =
      "Base Rate Change, No FSD, Manufacturer Nuance (BMW/Benz), please get management approval."; 
    this.NO_FSD_BRAND_MSG =
      "No FSD, Manufacturer Nuance (BMW/Benz), please get management approval."; 
    this.BILLING_VARIENCE_NO_LWS_MSG = "Base Rate Change, No LWS, please get management approval.";
    this.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_MSG =
      "Above 60% variance in volume, Base Rate Change, please get management approval.";
    this.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_MSG =
      "Above 60% variance in volume, Base Rate Change, Manufacturer Nuance (BMW/Benz), please get management approval.";
    this.GREATER_FORTY_PERCENTAGE_APPROVED_MSG = "Variance Approved by Management";
    this.GREATER_FORTY_PERCENTAGE_BRAND_APPROVED_MSG =
      "Variance, Manufacturer Nuance (BMW/Benz) Approved by Management.";
    this.BILLING_VARIENCE_APPROVED_MSG = "Base Rate Change Approved by Management";
    this.BILLING_VARIENCE_BRAND_APPROVED_MSG =
      "Base Rate Change, Manufacturer Nuance (BMW/Benz) Approved by Management.";  
    this.BRAND_APPROVED_MSG =
      "Manufacturer Nuance (BMW/Benz) Approved by Management";
    this.BILLING_VARIENCE_IS_NO_FSD_APPROVED_MSG = "Management Approved COGS, Base Rate Change";
    this.BILLING_VARIENCE_IS_NO_FSD_BRAND_APPROVED_MSG =
      "COGS, Base Rate Change, Manufacturer Nuance (BMW/Benz) Approved by Management.";
    this.BILLING_VARIENCE_IS_NO_LWS_APPROVED_MSG =
      "Management Approved Calculated Hours, Base Rate Change";
    this.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_APPROVED_MSG =
      "Base Rate Change, 60% variance Approved by Management";
    this.GREATER_FORTY_PERCENTAGE_BILLING_VARIENCE_BRAND_APPROVED_MSG =
      "Base Rate Change, 60% variance, Manufacturer Nuance (BMW/Benz) Approved by Management"; 
    this.IN_SALES_DEACTIVATE_WARNING_MESSAGE = "This will remove from In Sales";
    this.IN_SALES_REMOVE_WARNING_MESSAGE = "This will remove the In-Sales Tag";
    this.NETSPECTIVE_ACTIVATE_WARNING_MESSAGE = "This will start Netspective support";
    this.NETSPECTIVE_DEACTIVATE_WARNING_MESSAGE = "This will complete Netspective support";
    this.PREAUDIT_REVIEW_COMPLETE_WARNING_MESSAGE = "This will complete PreAudit Review directly";

    this.IN_NEED_HANDOFF_DEACTIVATE_WARNING_MESSAGE = "This will remove from Need Handoff";

    this.IN_BOOK_TIME_SUCCESS_TITLE = "Book Time tag added";
    this.IN_BOOK_TIME_SUCCESS_MESSAGE = "Book Time tag added successfully";
    this.IN_BOOK_TIME_ERROR_MESSAGE = "Error occurred while adding Book Time tag";
    this.IN_BOOK_TIME_DEACTIVATE_SUCCESS_TITLE = "Book Time tag removed";
    this.IN_BOOK_TIME_DEACTIVATE_SUCCESS_MESSAGE = "Book Time tag removed successfully";
    this.IN_BOOK_TIME_DEACTIVATE_ERROR_MESSAGE = "Error occurred while removing Book Time tag";

    this.IN_SHIPPING_SUCCESS_TITLE = "Shipping tag added";
    this.IN_SHIPPING_SUCCESS_MESSAGE = "Shipping tag added successfully";
    this.IN_SHIPPING_ERROR_MESSAGE = "Error occurred while adding shipping tag";
    this.IN_SHIPPING_DEACTIVATE_SUCCESS_TITLE = "Shipping tag removed";
    this.IN_SHIPPING_DEACTIVATE_SUCCESS_MESSAGE = "Shipping tag removed successfully";
    this.IN_SHIPPING_DEACTIVATE_ERROR_MESSAGE = "Error occurred while removing shipping tag";

    this.START_UP_NOTES_UPDATED_SUCCESS_TITLE = "Start Up Notes Updated";
    this.START_UP_NOTES_UPDATED_SUCCESS_MESSAGE = "Start Up Notes Updated Successfully";
    this.START_UP_NOTES_UPDATION_ERROR_MESSAGE = "Error occurred while Start Up Notes Updation";

    this.INTERNAL_COMMUNICATION_NOTES_UPDATED_SUCCESS_TITLE =
      "Internal Communication Notes Updated";
    this.INTERNAL_COMMUNICATION_NOTES_UPDATED_SUCCESS_MESSAGE =
      "Internal Communication Notes Updated Successfully";
    this.INTERNAL_COMMUNICATION_NOTES_UPDATION_ERROR_MESSAGE =
      "Error occurred while Internal Communication Notes Updation";

    this.CDA_NOTES_UPDATED_SUCCESS_TITLE = "CDA Notes Updated";
    this.CDA_NOTES_UPDATED_SUCCESS_MESSAGE = "CDA Notes Updated Successfully";
    this.CDA_NOTES_UPDATION_ERROR_MESSAGE = "Error occurred while CDA Notes Updation";

    this.WARRANTY_LABOR_RATE_WARNING_TITTLE = "Warranty Labor Rate mismatch found";
    this.WARRANTY_LABOR_RATE_WARNING_MESSAGE =
      "Warranty Labor Rate and Curr Warranty Labor Rate are different. Do you Want to continue?";

    this.COURT_COMMENT_UPDATED_SUCCESS_TITLE = "Court Comment Added";
    this.COURT_COMMENT_UPDATED_SUCCESS_MESSAGE = "Court Comment Added Successfully";
    this.COURT_COMMENT_UPDATION_ERROR_MESSAGE = "Error occurred while Court Comment Addition";
    // this.CDA_TEMPLATE_URL = '/assets/templates/CDA Template.xlsx?r=' + (new Date()).getTime();
    this.CDA_TEMPLATE_URL =
      "/assets/templates/CDA_Production_Snapshot_Template.xlsx?r=" + new Date().getTime();
    this.PREAUDIT_REVIEW_EMAIL_TEMPLATE_URL =
      "/assets/templates/emailTemplate.html?r=" + new Date().getTime();
    this.REVIEW_SUMMARY_TEMPLATE_URL =
      "/assets/templates/Review Summary.xlsx?r=" + new Date().getTime();
    this.DEFAULT_BAND = {
      RATEUPPER: "150",
      RATELOWER: "10",
      HOURSUPPER: "5",
      HOURSLOWER: "0.1",
      MUUPPER: "3",
      MULOWER: "1.2",
      COSTUPPER: "800",
      COSTLOWER: "0.5",
    };
    this.REPORT_HEADER = {
      A: "Project Name",
      B: "Type",
      C: "Reviewer",
      D: "Audito",
      E: "Highlight",
      F: "Highlight Remove",
      G: "Highlight Count",
      H: "Discount",
      I: "Discount Remove",
      J: "Exclude",
      K: "Allow",
      L: "Grid Edit",
      M: "Hunt Valley",
      N: "Open Void",
      O: "Misc",
      P: "Total",
      Q: "Note",
      R: "Start Date",
      S: "Completed On",
    };
    this.REVIEW_SUMMARY_FILE_NAME = "Review Summary.xlsx";
    this.PRJ_WARNING_MESSAGE =
      'A previous round {{projectType}} project is in the "Billing in Progress" stage for this Store';
    this.BILLING_WARNING_POPUP =
      "Please browse actual Approval letter or Select verbal Approval or Non-Responsive Customer option. Only one option allowed";
    // if (environment.envName === this.TEST_ENV) {
    //   this.CREATE_DATA_MASTER_URL = this.CREATE_DATA_MASTER_BETA_URL;
    // }

    this.VIEW_RO_LIST = [
      "reynoldsrci",
      "automate",
      "dealertrack",
      "dealerbuilt",
      "cdk3pa",
      "autosoft",
      "reynolds"
    ];
    this.VIEW_RO_LIST_AUDIT = [
      "reynoldsrci",
      "dealertrack",
      "cdk3pa",
      "autosoft",
      "reynolds"
    ];
    this.REMOVED_EMAIL_FROM_RECEIVED_ON_POPUP = [
      "pattyt@dealeruplift.com"
    ];
  }
}

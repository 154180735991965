import { Component, Injectable, Input, Output, EventEmitter } from "@angular/core";
import gql from "graphql-tag";

@Injectable()
export class DMQueryService {
  allStoreGroups;
  getStoresForStoreGroup;
  createFsdForStore;
  allDataBundleSourceParts;
  getFsdDataForStore;
  insertSourceParts;
  insertBasisParts;
  getPartsBasisListForDm;
  getSourceDataForTargetBundle;
  createNewProjectionFromTarget;
  allDataBundleTargetParts;
  getProjectionData;
  getPssForStoresAndDm;
  getFsdListForDm;
  updateFsdForStore;
  allDataMasters;
  createDmFsdAndLwsForStore;
  updateDataMasterForStore;
  allStoreS360Companies;
  getPartProjectsForStore;
  updateSourcePart;
  updateBasisPart;
  updateTargetPart;
  linkProjectPartsWithDm;
  unlinkProjectPartsFromDm;
  activateDatamaster;
  allStores;
  getPrimaryBundleDataForDm;
  getSourceBundlesForDm;
  getSnapshotsForDm;
  createMageForDm;
  saveAuditBuildDataParts;
  saveAuditBuildDataLabor;
  getRoReviewStatusForSubmission;
  updateGridEditStatus;
  isSubmissionRoReviewPending;
  allMages;
  activePreAuditData;
  submitDataBundle;
  updateShippingConfirmationStatus;
  receiveSubmittedPartsBundle;
  receiveSubmittedLaborBundle;
  approveDataBundle;
  allSampleDataSets;
  getInternalPssForStoresAndDm;
  getSourceOfTarget;
  initApprovePartsBundle;
  updatePartsInitApproveReductionReason;
  updateLaborInitApproveReductionReason;
  getPartsInitApproveDetails;
  getLaborInitApproveDetails;
  createLwsForStore;
  allLaborWorksheetData;
  createLaborMageForDm;
  allLaborMages;
  allDataBundleTargetLabors;
  submitLaborDataBundle;
  initApproveLaborBundle;
  approveLaborDataBundle;
  getLaborSourceBundlesForDm;
  updateSourceLabor;
  getLaborSnapshotsForDm;
  updateLwsForStore;
  allDataBundleTargetLaborsList;
  getLaborProjectsForStore;
  unlinkProjectLaborFromDm;
  linkProjectLaborWithDm;
  createPartsBest;
  allDmBests;
  updatePartsBest;
  createLaborBest;
  updateLaborBest;
  getLaborPssForStoresAndDm;
  updateSolve360ForDm;
  updateSolve360ForFsd;
  updateSolve360ForPreAudit;
  updateSolve360ForPostAudit;
  updateSolve360ForSubmitted;
  updateSolve360ForInitApproved;
  updateSolve360ForApproved;
  updateSolve360ForRevenue;
  updateSolve360ForFinalized;
  updateSolve360ForLaborFinalized;
  updateSolve360ForLws;
  updateSolve360ForLaborPreAudit;
  updateSolve360ForLaborPostAudit;
  updateSolve360ForLaborSubmitted;
  updateSolve360ForLaborInitApproved;
  updateSolve360ForLaborApproved;
  updateSolve360ForLaborRevenue;
  updateSolve360ForSyncParts;
  updateSolve360ForSyncLabor;
  allManufacturers;
  allStates;
  createSolve360Store;
  insertSolveStoreToPortal;
  insertStoreFromPortal;
  linkStoresToSolve;
  createProject;
  toggleTargetPartsActiveStatus;
  toggleTargetLaborActiveStatus;
  getProjectList;
  getLaborBasisListForDm;
  toggleBasisPartsActiveStatus;
  toggleBasisLaborActiveStatus;
  finalizePartsBillingBasis;
  finalizeLaborBillingBasis;
  allPartsRevenues;
  updatePartsRevenueBaseData;
  allLaborRevenues;
  updateLaborRevenueBaseData;
  getDmLinkedProjects;
  allS360Dms;
  getSourceOfBasis;
  createBasisFromSource;
  allS360Users;
  addPortalTag;
  addCustomerTag;
  retractPartsDm;
  retractLaborDm;
  reactivatePartsDm;
  reactivateLaborDm;
  updateSolve360ForPartRetraction;
  updateSolve360ForLaborRetraction;
  updateSolve360ForPartReActivate;
  updateSolve360ForLaborReActivate;
  isCompanyIdExistInS360;
  salesPersonDetails;
  isProjectIdExistInS360;
  overridePartsBasis;
  overrideLaborBasis;
  allS360ProjectObj;
  updateLaborClientComment;
  updatePartsClientComment;
  createPartsTargetFromSource;
  updateSolve360ForProductionSnapshot;
  saveProjectNote;
  getPreAuditLaborReportData;
  isValidScenariokey;
  mageUpdateMeta;
  rebuildBuildout;
  allAuditFiles;
  generateImpactData;
  generateAuditorData;
  allFilereferences;
  getBuildoutTime;
  buildPartsSubmission;
  buildLaborSubmission;
  getScenarioOverview;
  isCompanyExist;
  getProjectionDataV2;
  updateResolveProject;
  updateMgmtReviewApproval;
  getPartsImpactReport;
  getLabourImpactReport;
  getMageDataImpactReport;
  allReviewers;
  allAuditReviewers;
  allAuditors;
  updatePartsNetRevenueStatus;
  updateLaborNetRevenueStatus;
  updateSolve360PartsProject;
  updateSolve360LaborProject;
  getS360ProjectObj;
  loadS360ProjectObj;
  updateSolve360ProjectDetailsParts;
  updateSolve360ProjectDetailsLabor;
  updateImpactNotes;
  updatePartsProjectContractStatus;
  updateLaborProjectContractStatus;
  updateProjectProjectionStatus;
  updateStoreResolve;
  getMetaGroupsForStoreGroup;
  updateMetaGroupWithStoreRelation;
  uploadFileTOSolve;
  updateDmPartsAdditionalData;
  updateDmLaborAdditionalData;
  createEvent;
  deleteEvent;
  createScheduledEmail;
  createScheduledAttachmentEmail;
  updateDataBundleTargetPartsAdditionalInfo;
  updateDataBundleTargetLaborAdditionalInfo;
  getAuditorVerificationReport;
  updatePartsDmDisputedStatus;
  updateLaborDmDisputedStatus;
  addDisputedTag;
  removeDisputedTag;
  deleteDmVersion;
  getReferralPayeeForProject;
  sendPartsProjectForBilling;
  sendLaborProjectForBilling;
  getContactsByProjectId;
  getPreauditConfirmationDetails;
  savePreauditConfirmation;
  updateSolve360ForPartsPreAuditReview;
  updateSolve360ForLaborPreAuditReview;
  reviewPartsPreauditConfirmation;
  reviewLaborPreauditConfirmation;
  updateDeadlineNoteProject;
  updateInSalesProject;
  addStageNoteProject;
  allDmStageCourtComments;
  getPreAuditPostAuditDetails;
  createSplitOffSecondaryDetails;
  getProductionStageForDm;
  allAduUsers;
  updateRushStatus;
  createProjectInfo;
  updateTargetBundleDetails;
  getPsStageDetails;
  getPrevStageDetailsForDm;
  getApprovedPartsProjectHistory;
  getApprovedLaborProjectHistory;
  getProjectStageHistory;
  saveSupplRoRequestDetails;
  saveManfRequestDetailsNew;
  updatePartsDmDisputeAnalysis;
  updateLaborDmDisputeAnalysis;
  getStateDetailsByManufacturer;
  getDmStoreDetailsByProject;
  updatePartsApprovedBundleDetails;
  updateLaborApprovedBundleDetails;
  getBillingProgressProjectsForStoreWithType;
  getDataForPartsImpactReport;
  getDataForLaborImpactReport;
  allSubmissionScenarios;
  isValidSubmissionUuid;
  getSubmissionBuildOverviewParts;
  getSubmissionBuildOverviewLabor;
  updateAuditSourceOfDataMaster;
  updateProjectInfo;
  updateAuditSourceByProjectDms;
  solve360UpdateForManfRequest;
  solve360UpdateForManfRequestLabor;
  getProjectSubmissionForSwitching;
  updateProjectSubmissionSwitching;
  updateSolve360ForSubmissionIdParts;
  updateSolve360ForSubmissionIdLabor;
  constructor() {
    /**
     * Query to fetch all portal StoreGroups
     *
     */
    this.allStoreGroups = gql`
      query allSolve360StoreGroups {
        allSolve360StoreGroups(condition: { isInPortal: true, tagGroupRegion: false }) {
          edges {
            node {
              companyId
              companyName
              brandList
              state
              sgId
              isInPortal
              tagStoreGroup
              tagGroupRegion
              tagSoloStore
              tagCustomer
              sp
              salesPerson
              spEmail
              spPhone
              createdBy
              createdAt
              updatedBy
              updatedAt
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all Stores for StoreGroups
     *
     */
    this.getStoresForStoreGroup = gql`
      query getStoresForStoreGroup($inSgId: String!) {
        getStoresForStoreGroup(inSgId: $inSgId) {
          edges {
            node {
              stId
              sgId
              storeName
              manufacturer
              state
              companyId
              isInPortal
              isInSolve
              mgIdList
              sp
              contact
              salesPerson
              dms
              brandCode
              stateName
              createdBy
              createdAt
              updatedBy
              updatedAt
              dealerCode
              marketingSource
              tagCustomer
              isFopcStore
              performanceCoach
            }
          }
        }
      }
    `;

    /**
     * Mutation to create FSD for Store
     *
     */
    this.createFsdForStore = gql`
      mutation createFsdForStore(
        $inType: String!
        $inDmId: String!
        $inStId: String!
        $inDescription: String!
        $inComponentData: JSON!
        $inBasisDefaultBundle: String
        $inTargetDefaultBundle: String
        $inCreatedBy: String!
      ) {
        createFsdForStore(
          input: {
            inType: $inType
            inDmId: $inDmId
            inStId: $inStId
            inDescription: $inDescription
            inComponentData: $inComponentData
            inBasisDefaultBundle: $inBasisDefaultBundle
            inTargetDefaultBundle: $inTargetDefaultBundle
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle source parts
     *
     */
    this.allDataBundleSourceParts = gql`
      query allDataBundleSourceParts($dmId: BigInt!) {
        allDataBundleSourceParts(condition: { dmId: $dmId }) {
          edges {
            node {
              dataBundleId
              stId
              dmId
              bundleClassification
              bundleVersion
              description
              totalWarrantyCost
              totalWarrantySale
              warrantyMarkup
              totalRetailCost
              totalRetailSale
              retailMarkup
              numberOfMonths
            }
          }
        }
      }
    `;

    /**
     * Query to fetch FSD data for store
     *
     */
    this.getFsdDataForStore = gql`
      query getFsdDataForStore($inStId: String!) {
        getFsdDataForStore(inStId: $inStId)
      }
    `;

    /**
     * Mutation to insert Source parts
     *
     */
    this.insertSourceParts = gql`
      mutation insertSourceParts(
        $inDmId: String!
        $inStId: String!
        $inBundleClassification: String!
        $inDescription: String!
        $inTotalWarrantyCost: BigFloat!
        $inTotalWarrantySale: BigFloat!
        $inWarrantyMarkup: BigFloat!
        $inTotalRetailCost: BigFloat!
        $inTotalRetailSale: BigFloat!
        $inRetailMarkup: BigFloat!
        $inCpGpAverage: BigFloat!
        $inNoOfMonths: Int!
        $inFsdId: String
        $inCreatedBy: String!
      ) {
        insertSourceParts(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inBundleClassification: $inBundleClassification
            inDescription: $inDescription
            inTotalWarrantyCost: $inTotalWarrantyCost
            inTotalWarrantySale: $inTotalWarrantySale
            inWarrantyMarkup: $inWarrantyMarkup
            inTotalRetailCost: $inTotalRetailCost
            inTotalRetailSale: $inTotalRetailSale
            inRetailMarkup: $inRetailMarkup
            inCpGpAverage: $inCpGpAverage
            inNoOfMonths: $inNoOfMonths
            inFsdId: $inFsdId
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to insert Basis parts
     *
     */
    this.insertBasisParts = gql`
      mutation insertBasisParts(
        $inDmId: String!
        $inStId: String!
        $inBundleClassification: String!
        $inDescription: String!
        $inIsPrimary: Boolean!
        $inIsActive: Boolean!
        $inMonthlyWarrantyCost: BigFloat!
        $inMonthlyWarrantySale: BigFloat!
        $inInitialMarkup: BigFloat!
        $inSourceClassification: String!
        $inSourceVersion: Int!
        $inCreatedBy: String!
      ) {
        insertBasisParts(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inBundleClassification: $inBundleClassification
            inDescription: $inDescription
            inIsPrimary: $inIsPrimary
            inIsActive: $inIsActive
            inMonthlyWarrantyCost: $inMonthlyWarrantyCost
            inMonthlyWarrantySale: $inMonthlyWarrantySale
            inInitialMarkup: $inInitialMarkup
            inSourceClassification: $inSourceClassification
            inSourceVersion: $inSourceVersion
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle basis parts
     *
     */
    this.getPartsBasisListForDm = gql`
      query getPartsBasisListForDm($inDmId: String!) {
        getPartsBasisListForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              stId
              dmId
              bundleClassification
              description
              bundleVersion
              isPrimary
              isActive
              monthlyWarrantyCost
              monthlyWarrantySale
              initialMarkup
              sourceClassification
              sourceVersion
              createdAt
              hasMarkupOverride
              hasCostOverride
              overrideComment
            }
          }
        }
      }
    `;

    /**
     * Query to fetch SourceData for Target Bundle
     *
     */
    this.getSourceDataForTargetBundle = gql`
      query getSourceDataForTargetBundle($inDataBundleId: String!) {
        getSourceDataForTargetBundle(inDataBundleId: $inDataBundleId)
      }
    `;

    /**
     * Mutation to insert Target parts
     *
     */
    this.createNewProjectionFromTarget = gql`
      mutation createNewProjectionFromTarget(
        $inDmId: String!
        $inStId: String!
        $inTargetBundleId: String!
        $inNewSourceClassification: String!
        $inDescription: String!
        $inFactoryBehaviorFactor: BigFloat!
        $inFactorEmpOverride: BigFloat!
        $inCreatedBy: String!
      ) {
        createNewProjectionFromTarget(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inTargetBundleId: $inTargetBundleId
            inNewSourceClassification: $inNewSourceClassification
            inDescription: $inDescription
            inFactoryBehaviorFactor: $inFactoryBehaviorFactor
            inFactorEmpOverride: $inFactorEmpOverride
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle basis parts
     *
     */
    this.allDataBundleTargetParts = gql`
      query getPartsTargetListForDm($inDmId: String!) {
        getPartsTargetListForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              stId
              dmId
              bundleClassification
              description
              bundleVersion
              isActive
              targetMarkup
              sourceClassification
              sourceVersion
              sourceRetailMarkup
              brandNormalizationFactor
              factoryBehaviorFactor
              factorEmpOverride
              brandCapFactor
              cpGpAverage
              createdAt
              ineffectiveDate
              actionDate
              isBest
              additionalInfo
            }
          }
        }
      }
    `;

    /**
     * Query to fetch projection data
     *
     */
    this.getProjectionData = gql`
      query getProjectionForStoresAndDm($inStIdList: [String]!, $inDmIdList: [String]!) {
        getProjectionForStoresAndDm(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              annualWarrPartsCost
              cpPartsGrossProfit
              effWpGrossProfit
              cpGpMarkup
              effWpGrossProfitMarkup
              cpWarrMarkupDelta
              addlAnnualWpProfit
              stStatuteAif
              projectedWpMarkup
              alBrandedApprAvg
              addlPtsAbvCurrEffWm
              optAddlOgAwpProfit
              currLikelyFactBehaviorImpact
              ogWpMarkupAdjForFactory
              addlOgAwpProfitAdjForFactory
              postApprSubmissionFee
              monthlyUpliftFee
              totalProfitAcqCost
              netWpAddlPuYr1
              nomToReturnFullProfitAcqCost
              addlPtsAbvCeWmPostFactBeh
              state
              manufacturer
            }
          }
        }
      }
    `;

    /**
     * Query to fetch PSS data
     *
     */

    this.getPssForStoresAndDm = gql`
      query getPssForStoresAndDm($inStIdList: [String]!, $inDmIdList: [String]!) {
        getPssForStoresAndDm(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              state
              manufacturer
              upAvgMonthlyWarrCogs
              upCurrEffWpMu
              upProAdjMarkup
              upAddlPtsOvrCurrEffWpMu
              upAddlMonthlyProfit
              upAddlAnnualProfit
              projAcqSubmissionFee
              projAcqMonthlyUplift
              projAcqAnnualUpliftFee
              projAcqTotal
              projImpactNetUplift
              projImpactPayback
              preAuditMu
              preAuditAddlPtsOvrCurr
              preAuditMonthlyProfitUplift
              preAuditAnnualProfitUplift
              preAuditAcqSubmissionFee
              preAuditAcqMonthlyUplift
              preAuditAcqAnnualUpliftFee
              preAuditAcqTotal
              preAuditImpactNetUplift
              preAuditImpactPayback
              preDiscBestPostAuditMu
              preDiscIncrOvrCurrEffWpMu
              preDiscAddlMonthlyProfit
              preDiscAddlAnnualProfit
              postDiscBestPostAuditMu
              postDiscIncrOvrCurrEffWpMu
              postDiscAddlMonthlyProfit
              postDiscAddlAnnualProfit
              oemApprovalDeadline
              postAuditAcqSubmissionFee
              postAuditAcqMonthlyUplift
              postAuditAcqAnnualUpliftFee
              postAuditAcqTotal
              postAuditImpactNetUplift
              postAuditImpactPayback
              approvedWpMarkup
              faPointImpact
              faAnnualImpact
              apprAddlPtsAbvCurrEffWpMu
              apprAdjAddlMonthlyProfit
              apprAdjAddlAnnualProfit
              apprAcqSubmissionFee
              apprAcqMonthlyUplift
              apprAcqAnnualUpliftFee
              apprAcqTotal
              apprImpactNetUplift
              apprImpactPayback
              clientCommunicationComment
              finalAcqSubmissionFee
              finalAcqMonthlyUplift
              finalAcqAnnualUpliftFee
              finalAcqTotal
              finalImpactNetUplift
              finalImpactPayback
              isRetracted
              retractionType
              isSecondaryResolved
            }
          }
        }
      }
    `;

    /**
     * Query to fetch fsd list for DM
     *
     */
    this.getFsdListForDm = gql`
      query getFsdListForDm($inDmId: String!) {
        getFsdListForDm(inDmId: $inDmId) {
          edges {
            node {
              type
              dmId
              stId
              fsdId
              version
              componentData
              isEditable
              description
              defaultBasisBundle
              defaultTargetBundle
            }
          }
        }
      }
    `;

    /**
     * Mutation to update FSD for store
     *
     */
    this.updateFsdForStore = gql`
      mutation updateFsdForStore(
        $inType: String!
        $inFsdId: String!
        $inDescription: String!
        $inComponentData: JSON!
        $inUpdatedBy: String!
      ) {
        updateFsdForStore(
          input: {
            inType: $inType
            inFsdId: $inFsdId
            inDescription: $inDescription
            inComponentData: $inComponentData
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all DataMasters
     *
     */
    this.allDataMasters = gql`
      query allDataMasters($stId: BigInt!) {
        allDataMasters(condition: { stId: $stId }) {
          edges {
            node {
              dmId
              stId
              version
              description
              isActive
              createdBy
              createdAt
              updatedBy
              updatedAt
              auditSource
              dmPartsByDmIdAndStId {
                edges {
                  node {
                    custStatedWpMarkup
                    approvedMarkupAverage
                    brandNormalizationFactor
                    isMsrp
                  }
                }
              }
              laborWorksheetDataByDmIdAndStId {
                edges {
                  node {
                    custStatedWarrLr
                  }
                }
              }
              dmLaborsByDmIdAndStId {
                edges {
                  node {
                    custStatedWlr
                  }
                }
              }
            }
          }
        }
      }
    `;

    /**
     * Mutation to update FSD for store
     *
     */
    this.createDmFsdAndLwsForStore = gql`
      mutation createDmFsdAndLwsForStore(
        $inFsdType: String
        $inStId: String!
        $inType: String!
        $inDmDescription: String
        $inCustStatedWpMarkup: BigFloat
        $inCustStatedWlr: BigFloat
        $inApprovedMarkupAverage: BigFloat
        $inBrandNormalizationFactor: BigFloat!
        $inFsdDescription: String
        $inFsdComponentData: JSON
        $inPartsBasisDefaultBundle: String
        $inPartsTargetDefaultBundle: String
        $inCustStatedCpDoorLr: BigFloat
        $inPrevCpDoorLr: BigFloat
        $inCustStatedWarrLr: BigFloat
        $inCurrPricingPolicyAsOf: String
        $inPlannedCpDoorLrIncrOn: String
        $inPlannedCpDoorLrIncrOf: BigFloat
        $inGridPricingCeiling: BigFloat
        $inGridPricingFloor: BigFloat
        $inAcceptFactoryCoupons: String
        $inFactAiPgmAsOf: String
        $inFactAiPgmExpiresOn: String
        $inIsGridPricingAbvDr: String
        $inIsInFactAiOrCpiPgm: String
        $inLwsComponentData: JSON
        $inCreatedBy: String!
        $inLaborBasisDefaultBundle: String
        $inLwsType: String
        $inIsMsrp: Boolean
      ) {
        createDmFsdAndLwsForStore(
          input: {
            inFsdType: $inFsdType
            inStId: $inStId
            inType: $inType
            inDmDescription: $inDmDescription
            inCustStatedWpMarkup: $inCustStatedWpMarkup
            inCustStatedWlr: $inCustStatedWlr
            inApprovedMarkupAverage: $inApprovedMarkupAverage
            inBrandNormalizationFactor: $inBrandNormalizationFactor
            inFsdDescription: $inFsdDescription
            inFsdComponentData: $inFsdComponentData
            inPartsBasisDefaultBundle: $inPartsBasisDefaultBundle
            inPartsTargetDefaultBundle: $inPartsTargetDefaultBundle
            inCustStatedCpDoorLr: $inCustStatedCpDoorLr
            inPrevCpDoorLr: $inPrevCpDoorLr
            inCustStatedWarrLr: $inCustStatedWarrLr
            inCurrPricingPolicyAsOf: $inCurrPricingPolicyAsOf
            inPlannedCpDoorLrIncrOn: $inPlannedCpDoorLrIncrOn
            inPlannedCpDoorLrIncrOf: $inPlannedCpDoorLrIncrOf
            inGridPricingCeiling: $inGridPricingCeiling
            inGridPricingFloor: $inGridPricingFloor
            inAcceptFactoryCoupons: $inAcceptFactoryCoupons
            inFactAiPgmAsOf: $inFactAiPgmAsOf
            inFactAiPgmExpiresOn: $inFactAiPgmExpiresOn
            inIsGridPricingAbvDr: $inIsGridPricingAbvDr
            inIsInFactAiOrCpiPgm: $inIsInFactAiOrCpiPgm
            inLwsComponentData: $inLwsComponentData
            inCreatedBy: $inCreatedBy
            inLaborBasisDefaultBundle: $inLaborBasisDefaultBundle
            inLwsType: $inLwsType
            inIsMsrp: $inIsMsrp
            inIsCreateLaborBasis: true
          }
        ) {
          json
        }
      }
    `;
    /**
     * Mutation to update FSD for store
     *
     */
    this.updateDataMasterForStore = gql`
      mutation updateDataMasterForStore(
        $inDmId: String!
        $inDescription: String!
        $inCustStatedWpMarkup: BigFloat
        $inCustStatedWlr: BigFloat
        $inApprovedMarkupAverage: BigFloat
        $inBrandNormalizationFactor: BigFloat!
        $inUpdatedBy: String!
        $inIsMsrp: Boolean
      ) {
        updateDataMasterForStore(
          input: {
            inDmId: $inDmId
            inDescription: $inDescription
            inCustStatedWpMarkup: $inCustStatedWpMarkup
            inCustStatedWlr: $inCustStatedWlr
            inApprovedMarkupAverage: $inApprovedMarkupAverage
            inBrandNormalizationFactor: $inBrandNormalizationFactor
            inUpdatedBy: $inUpdatedBy
            inIsMsrp: $inIsMsrp
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch companyId
     *
     */
    this.allStoreS360Companies = gql`
      query allStoreS360Companies($stId: BigInt!) {
        allStoreS360Companies(condition: { stId: $stId }) {
          edges {
            node {
              companyId
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all Part Projects for Store
     *
     */
    this.getPartProjectsForStore = gql`
      query getPartProjectsForStore($inCompanyId: String!) {
        getPartProjectsForStore(inCompanyId: $inCompanyId) {
          edges {
            node {
              projectId
              projectName
            }
          }
        }
      }
    `;

    /**
     * Mutation to update FSD for store
     *
     */
    this.updateSourcePart = gql`
      mutation updateSourcePart(
        $inDataBundleId: String!
        $inDescription: String!
        $inTotalWarrantyCost: BigFloat!
        $inTotalWarrantySale: BigFloat!
        $inWarrantyMarkup: BigFloat!
        $inTotalRetailCost: BigFloat!
        $inTotalRetailSale: BigFloat!
        $inRetailMarkup: BigFloat!
        $inCpGpAverage: BigFloat!
        $inNoOfMonths: BigFloat!
        $inUpdatedBy: String!
      ) {
        updateSourcePart(
          input: {
            inDataBundleId: $inDataBundleId
            inDescription: $inDescription
            inTotalWarrantyCost: $inTotalWarrantyCost
            inTotalWarrantySale: $inTotalWarrantySale
            inWarrantyMarkup: $inWarrantyMarkup
            inTotalRetailCost: $inTotalRetailCost
            inTotalRetailSale: $inTotalRetailSale
            inRetailMarkup: $inRetailMarkup
            inCpGpAverage: $inCpGpAverage
            inNoOfMonths: $inNoOfMonths
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to update Basis part
     *
     */
    this.updateBasisPart = gql`
      mutation updateBasisPart(
        $inDataBundleId: String!
        $inDescription: String!
        $inIsPrimary: Boolean!
        $inIsActive: Boolean!
        $inMonthlyWarrantyCost: BigFloat!
        $inMonthlyWarrantySale: BigFloat!
        $inInitialMarkup: BigFloat!
        $inSourceClassification: String!
        $inSourceVersion: Int!
        $inUpdatedBy: String!
      ) {
        updateBasisPart(
          input: {
            inDataBundleId: $inDataBundleId
            inDescription: $inDescription
            inIsPrimary: $inIsPrimary
            inIsActive: $inIsActive
            inMonthlyWarrantyCost: $inMonthlyWarrantyCost
            inMonthlyWarrantySale: $inMonthlyWarrantySale
            inInitialMarkup: $inInitialMarkup
            inSourceClassification: $inSourceClassification
            inSourceVersion: $inSourceVersion
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to update Target part
     *
     */
    this.updateTargetPart = gql`
      mutation updateTargetPart(
        $inDataBundleId: String!
        $inDescription: String!
        $inIsActive: Boolean!
        $inTargetMarkup: BigFloat!
        $inSourceClassification: String!
        $inSourceVersion: Int!
        $inSourceRetailMarkup: BigFloat!
        $inBrandNormalizationFactor: BigFloat!
        $inFactoryBehaviorFactor: BigFloat!
        $inCpGpAverage: BigFloat!
        $inUpdatedBy: String!
      ) {
        updateTargetPart(
          input: {
            inDataBundleId: $inDataBundleId
            inDescription: $inDescription
            inIsActive: $inIsActive
            inTargetMarkup: $inTargetMarkup
            inSourceClassification: $inSourceClassification
            inSourceVersion: $inSourceVersion
            inSourceRetailMarkup: $inSourceRetailMarkup
            inBrandNormalizationFactor: $inBrandNormalizationFactor
            inFactoryBehaviorFactor: $inFactoryBehaviorFactor
            inCpGpAverage: $inCpGpAverage
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to link the project part
     *
     */
    this.linkProjectPartsWithDm = gql`
      mutation linkProjectPartsWithDm(
        $inDmId: String!
        $inStId: String!
        $inProjectId: String!
        $inCreatedBy: String!
      ) {
        linkProjectPartsWithDm(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inProjectId: $inProjectId
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to unlink the project part
     *
     */
    this.unlinkProjectPartsFromDm = gql`
      mutation unlinkProjectPartsFromDm(
        $inDmId: String!
        $inProjectId: String!
        $inUpdatedBy: String!
      ) {
        unlinkProjectPartsFromDm(
          input: { inDmId: $inDmId, inProjectId: $inProjectId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to activate DM
     *
     */
    this.activateDatamaster = gql`
      mutation activateDatamaster($inDmId: String!) {
        activateDatamaster(input: { inDmId: $inDmId }) {
          json
        }
      }
    `;

    /**
     * Query to fetch approvedMarkupAverage of a store
     *
     */
    this.allStores = gql`
      query allStores($stId: BigInt!) {
        allStores(condition: { stId: $stId }) {
          edges {
            node {
              manufacturerByManufacturerId {
                approvedMarkupAverage
                discountedNFactor
              }
            }
          }
        }
      }
    `;

    /**
     * Query to fetch get PrimaryBundle Data for DM
     *
     */
    this.getPrimaryBundleDataForDm = gql`
      query getPrimaryBundleDataForDm($inDmId: String!) {
        getPrimaryBundleDataForDm(inDmId: $inDmId)
      }
    `;

    /**
     * Query to fetch get Source Bundles for DM
     *
     */
    this.getSourceBundlesForDm = gql`
      query getSourceBundlesForDm($inDmId: String!) {
        getSourceBundlesForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              bundleClassification
              bundleVersion
              description
              totalWarrantyCost
              totalWarrantySale
              warrantyMarkup
              totalRetailCost
              totalRetailSale
              retailMarkup
              cpGpAverage
              numberOfMonths
              fsdId
              bundleDoc
              bundleLink
              mageId
              isLatestBundle
              isActiveBasis
            }
          }
        }
      }
    `;

    /**
     * Query to fetch Snapshots for DM
     *
     */
    this.getSnapshotsForDm = gql`
      query getSnapshotsForDm($inDmId: String!) {
        getSnapshotsForDm(inDmId: $inDmId) {
          edges {
            node {
              version
              description
              createdAt
              basisCost
              basisMu
              targetMu
              isPrimaryBasis
              isPrimaryTarget
              stage
            }
          }
        }
      }
    `;

    /**
     * Mutation to create Mage for Dm
     *
     */
    this.createMageForDm = gql`
      mutation createMageForDm(
        $inDmId: String!
        $inStId: String!
        $inDescription: String!
        $inComponentData: JSON!
        $inTargetClassification: String!
        $inFrAllAuditorData: String
        $inFrQualAuditorData: String
        $inCreatedBy: String!
      ) {
        createMageForDm(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inDescription: $inDescription
            inComponentData: $inComponentData
            inTargetClassification: $inTargetClassification
            inFrAllAuditorData: $inFrAllAuditorData
            inFrQualAuditorData: $inFrQualAuditorData
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    this.saveAuditBuildDataParts = gql`
      mutation saveAuditBuildDataParts(
        $inSubmissionUuid: String!
        $inVersion: Int!
        $inBundleType: String!
      ) {
        saveAuditBuildDataParts(
          input: {
            inSubmissionUuid: $inSubmissionUuid
            inVersion: $inVersion
            inBundleType: $inBundleType
          }
        ) {
          json
        }
      }
    `;

    this.saveAuditBuildDataLabor = gql`
      mutation saveAuditBuildDataLabor(
        $inSubmissionUuid: String!
        $inVersion: Int!
        $inBundleType: String!
      ) {
        saveAuditBuildDataLabor(
          input: {
            inSubmissionUuid: $inSubmissionUuid
            inVersion: $inVersion
            inBundleType: $inBundleType
          }
        ) {
          json
        }
      }
    `;

    this.getRoReviewStatusForSubmission = gql`
      query getRoReviewStatusForSubmission($inSubmissionUuid: String!, $inProjectType: String!) {
        getRoReviewStatusForSubmission(
          inSubmissionUuid: $inSubmissionUuid
          inProjectType: $inProjectType
        )
      }
    `;

    this.updateGridEditStatus = gql`
      mutation updateGridEditStatus(
        $inProjectId: BigInt!
        $inProjectType: String!
        $inStageName: String
        $inIsGridEdit: Boolean!
        $inUpdatedBy: String!
      ) {
        updateGridEditStatus(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inStageName: $inStageName
            inIsGridEdit: $inIsGridEdit
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.isSubmissionRoReviewPending = gql`
      query isSubmissionRoReviewPending($inSubmissionUuid: String!) {
        isSubmissionRoReviewPending(inSubmissionUuid: $inSubmissionUuid)
      }
    `;
    /**
     * Query to fetch all Mages
     *
     */
    this.allMages = gql`
      query allMages($dmId: BigInt!) {
        allMages(condition: { dmId: $dmId }) {
          edges {
            node {
              mageId
              version
              description
              componentData
              targetClassification
              createdAt
              mageAuditorFileReferencesByMageId {
                edges {
                  node {
                    frAllAuditorData
                    frQualAuditorData
                  }
                }
              }
            }
          }
        }
      }
    `;

    /**
     * Query to fetch active PreAudit data
     *
     */
    this.activePreAuditData = gql`
      query allDataBundleTargetParts(
        $dmId: BigInt!
        $bundleClassification: String!
        $isActive: Boolean!
      ) {
        allDataBundleTargetParts(
          condition: {
            dmId: $dmId
            bundleClassification: $bundleClassification
            isActive: $isActive
          }
        ) {
          edges {
            node {
              dataBundleSourcePartByDmIdAndSourceClassificationAndSourceVersion {
                mageByMageId {
                  description
                  componentData
                }
              }
            }
          }
        }
      }
    `;

    /**
     * Mutation to submit DataBundle
     *
     */
    this.submitDataBundle = gql`
      mutation submitDataBundle(
        $inDmId: String!
        $inPostAuditTargetId: String!
        $inSubmittedBy: String!
        $inSubmittedDate: Date!
        $inAdditionalInfo: JSON
      ) {
        submitDataBundle(
          input: {
            inDmId: $inDmId
            inPostAuditTargetId: $inPostAuditTargetId
            inSubmittedBy: $inSubmittedBy
            inSubmittedDate: $inSubmittedDate
            inAdditionalInfo: $inAdditionalInfo
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to approve DataBundle
     *
     */
    this.approveDataBundle = gql`
      mutation approveDataBundle(
        $inDmId: String!
        $inTargetToApprove: String!
        $inApprovedTargetMu: BigFloat!
        $inApprovedBy: String!
        $inApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        approveDataBundle(
          input: {
            inDmId: $inDmId
            inTargetToApprove: $inTargetToApprove
            inApprovedTargetMu: $inApprovedTargetMu
            inApprovedBy: $inApprovedBy
            inApprovedDate: $inApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch Sample data
     *
     */
    this.allSampleDataSets = gql`
      query allSampleDataSets {
        allSampleDataSets {
          edges {
            node {
              nodeId
              sdvId
              type
              payload
              created
            }
          }
        }
      }
    `;

    /**
     * Query to fetch Internal PSS data
     *
     */

    this.getInternalPssForStoresAndDm = gql`
      query getInternalPssForStoresAndDm($inStIdList: [String]!, $inDmIdList: [String]!) {
        getInternalPssForStoresAndDm(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              upAvgMonthlyWarrCogs
              upCurrWpMu
              upNormProjectionMu
              upIncrOvrCurr
              upAddlAnnualProfit
              preAudMu
              preAudIncrOvrCurr
              preAudAnnualUplift
              preDiscInitMu
              preDiscIncrOvrCurr
              preDiscAddlAnnualProfit
              postDiscMu
              postDiscIncrOvrCurr
              postDiscAddlAnnualProfit
              postDiscDueDate
              approvedWpMu
              approvedImpact
              approvedAnnualImpact
              approvedAdjAddlMonthlyProfit
              approvedAdjAddlAnnualProfit
              state
              manufacturer
            }
          }
        }
      }
    `;

    /**
     * Query to fetch getSource Of Target data
     *
     */
    this.getSourceOfTarget = gql`
      query getSourceOfTarget($inDmId: String!, $inTargetBundleId: String!) {
        getSourceOfTarget(inDmId: $inDmId, inTargetBundleId: $inTargetBundleId)
      }
    `;

    /**
     * Mutation to init approve DataBundle
     *
     */
    this.initApprovePartsBundle = gql`
      mutation initApprovePartsBundle(
        $inDmId: String!
        $inSubmittedTargetId: String!
        $inInitApprTargetMu: BigFloat!
        $inInitApprovedBy: String!
        $inInitApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        initApprovePartsBundle(
          input: {
            inDmId: $inDmId
            inSubmittedTargetId: $inSubmittedTargetId
            inInitApprTargetMu: $inInitApprTargetMu
            inInitApprovedBy: $inInitApprovedBy
            inInitApprovedDate: $inInitApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to init approve Reduction Reason
     *
     */
    this.updatePartsInitApproveReductionReason = gql`
      mutation updatePartsInitApproveReductionReason(
        $inDmId: String!
        $inInitApproveReductionReason: String
      ) {
        updatePartsInitApproveReductionReason(
          input: {
            inDmId: $inDmId
            inInitApproveReductionReason: $inInitApproveReductionReason
          }
        ) {
          json
        }
      }
    `;

    this.updateLaborInitApproveReductionReason = gql`
      mutation updateLaborInitApproveReductionReason(
        $inDmId: String!
        $inInitApproveReductionReason: String
      ) {
        updateLaborInitApproveReductionReason(
          input: {
            inDmId: $inDmId
            inInitApproveReductionReason: $inInitApproveReductionReason
          }
        ) {
          json
        }
      }
    `;

    this.getPartsInitApproveDetails = gql`
      query getPartsInitApproveDetails($inDmId: String!) {
        getPartsInitApproveDetails(inDmId: $inDmId)
      }
    `;

    this.getLaborInitApproveDetails = gql`
      query getLaborInitApproveDetails($inDmId: String!) {
        getLaborInitApproveDetails(inDmId: $inDmId)
      }
    `;

    /**
     * Mutation to create LWS for Store
     *
     */
    this.createLwsForStore = gql`
      mutation createLwsForStore(
        $inDmId: String!
        $inStId: String!
        $inCustStatedCpDoorLr: BigFloat
        $inPrevCpDoorLr: BigFloat
        $inCustStatedWarrLr: BigFloat!
        $inCurrPricingPolicyAsOf: String
        $inPlannedCpDoorLrIncrOn: String
        $inPlannedCpDoorLrIncrOf: BigFloat
        $inGridPricingCeiling: BigFloat
        $inGridPricingFloor: BigFloat
        $inAcceptFactoryCoupons: String
        $inFactAiPgmAsOf: String
        $inFactAiPgmExpiresOn: String
        $inIsGridPricingAbvDr: String
        $inIsInFactAiOrCpiPgm: String
        $inLwsComponentData: JSON!
        $inCreatedBy: String!
        $inType: String!
        $inDefaultBasisBundle: String
      ) {
        createLwsForStore(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inCustStatedCpDoorLr: $inCustStatedCpDoorLr
            inPrevCpDoorLr: $inPrevCpDoorLr
            inCustStatedWarrLr: $inCustStatedWarrLr
            inCurrPricingPolicyAsOf: $inCurrPricingPolicyAsOf
            inPlannedCpDoorLrIncrOn: $inPlannedCpDoorLrIncrOn
            inPlannedCpDoorLrIncrOf: $inPlannedCpDoorLrIncrOf
            inGridPricingCeiling: $inGridPricingCeiling
            inGridPricingFloor: $inGridPricingFloor
            inAcceptFactoryCoupons: $inAcceptFactoryCoupons
            inFactAiPgmAsOf: $inFactAiPgmAsOf
            inFactAiPgmExpiresOn: $inFactAiPgmExpiresOn
            inIsGridPricingAbvDr: $inIsGridPricingAbvDr
            inIsInFactAiOrCpiPgm: $inIsInFactAiOrCpiPgm
            inLwsComponentData: $inLwsComponentData
            inCreatedBy: $inCreatedBy
            inType: $inType
            inDefaultBasisBundle: $inDefaultBasisBundle
            inIsCreateBasis: true
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all DM Labors
     *
     */
    this.allLaborWorksheetData = gql`
      query allLaborWorksheetData($dmId: BigInt!) {
        allLaborWorksheetData(condition: { dmId: $dmId }) {
          edges {
            node {
              stId
              custStatedCpDoorLr
              prevCpDoorLr
              custStatedWarrLr
              currPricingPolicyAsOf
              plannedCpDoorLrIncrOn
              plannedCpDoorLrIncrOf
              gridPricingCeiling
              gridPricingFloor
              acceptFactoryCoupons
              factAiPgmAsOf
              factAiPgmExpiresOn
              isGridPricingAbvDr
              isInFactAiOrCpiPgm
              lwsComponentData
              modeType
              defaultBasisBundle
            }
          }
        }
      }
    `;

    /**
     * Mutation to create Labor Mage
     *
     */
    this.createLaborMageForDm = gql`
      mutation createLaborMageForDm(
        $inDmId: String!
        $inStId: String!
        $inDescription: String!
        $inComponentData: JSON!
        $inTargetClassification: String!
        $inFrAllAuditorData: String
        $inFrQualAuditorData: String
        $inCreatedBy: String!
      ) {
        createLaborMageForDm(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inDescription: $inDescription
            inComponentData: $inComponentData
            inTargetClassification: $inTargetClassification
            inFrAllAuditorData: $inFrAllAuditorData
            inFrQualAuditorData: $inFrQualAuditorData
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all Labor Mages
     *
     */
    this.allLaborMages = gql`
      query allLaborMages($dmId: BigInt!) {
        allLaborMages(condition: { dmId: $dmId }) {
          edges {
            node {
              lbrMageId
              version
              description
              componentData
              targetClassification
              createdAt
              laborMageAuditorFileReferencesByLbrMageId {
                edges {
                  node {
                    frAllAuditorData
                    frQualAuditorData
                  }
                }
              }
            }
          }
        }
      }
    `;

    /**
     * Query to fetch active Labor PreAudit data
     *
     */
    this.allDataBundleTargetLabors = gql`
      query allDataBundleTargetLabors(
        $dmId: BigInt!
        $bundleClassification: String!
        $isActive: Boolean!
      ) {
        allDataBundleTargetLabors(
          condition: {
            dmId: $dmId
            bundleClassification: $bundleClassification
            isActive: $isActive
          }
        ) {
          edges {
            node {
              dataBundleSourceLaborByDmIdAndSourceClassificationAndSourceVersion {
                laborMageByLbrMageId {
                  description
                  componentData
                }
              }
            }
          }
        }
      }
    `;

    /**
     * Mutation to submit Labor DataBundle
     *
     */
    this.submitLaborDataBundle = gql`
      mutation submitLaborDataBundle(
        $inDmId: String!
        $inPostAuditTargetId: String!
        $inSubmittedBy: String!
        $inSubmittedDate: Date!
        $inAdditionalInfo: JSON
      ) {
        submitLaborDataBundle(
          input: {
            inDmId: $inDmId
            inPostAuditTargetId: $inPostAuditTargetId
            inSubmittedBy: $inSubmittedBy
            inSubmittedDate: $inSubmittedDate
            inAdditionalInfo: $inAdditionalInfo
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to init approve Labor DataBundle
     *
     */
    this.initApproveLaborBundle = gql`
      mutation initApproveLaborBundle(
        $inDmId: String!
        $inSubmittedTargetId: String!
        $inInitApprTargetLr: BigFloat!
        $inInitApprovedBy: String!
        $inInitApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        initApproveLaborBundle(
          input: {
            inDmId: $inDmId
            inSubmittedTargetId: $inSubmittedTargetId
            inInitApprTargetLr: $inInitApprTargetLr
            inInitApprovedBy: $inInitApprovedBy
            inInitApprovedDate: $inInitApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to approve Labor DataBundle
     *
     */
    this.approveLaborDataBundle = gql`
      mutation approveLaborDataBundle(
        $inDmId: String!
        $inTargetIdToApprove: String!
        $inApprovedTargetLr: BigFloat!
        $inApprovedBy: String!
        $inApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        approveLaborDataBundle(
          input: {
            inDmId: $inDmId
            inTargetIdToApprove: $inTargetIdToApprove
            inApprovedTargetLr: $inApprovedTargetLr
            inApprovedBy: $inApprovedBy
            inApprovedDate: $inApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch get Source Bundles for DM
     *
     */
    this.getLaborSourceBundlesForDm = gql`
      query getLaborSourceBundlesForDm($inDmId: String!) {
        getLaborSourceBundlesForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              bundleClassification
              bundleVersion
              description
              totalWarrantyHours
              totalWarrantySale
              warrantyLaborRate
              cpHours
              cpSales
              cpLaborRate
              numberOfMonths
              bundleDoc
              bundleLink
              mageId
              isLatestBundle
              isActiveBasis
            }
          }
        }
      }
    `;

    /**
     * Mutation to update FSD for store
     *
     */
    this.updateSourceLabor = gql`
      mutation updateSourceLabor(
        $inDataBundleId: String!
        $inDescription: String!
        $inUpdatedBy: String!
      ) {
        updateSourceLabor(
          input: {
            inDataBundleId: $inDataBundleId
            inDescription: $inDescription
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch Snapshots for DM
     *
     */
    this.getLaborSnapshotsForDm = gql`
      query getLaborSnapshotsForDm($inDmId: String!) {
        getLaborSnapshotsForDm(inDmId: $inDmId) {
          edges {
            node {
              version
              description
              createdAt
              stage
              basisHours
              basisLr
              targetLr
              isPrimaryBasis
              isPrimaryTarget
            }
          }
        }
      }
    `;

    /**
     * Mutation to update LWS for Store
     *
     */
    this.updateLwsForStore = gql`
      mutation updateLwsForStore(
        $inDmId: String!
        $inStId: String!
        $inCustStatedCpDoorLr: BigFloat
        $inPrevCpDoorLr: BigFloat
        $inCustStatedWarrLr: BigFloat!
        $inCurrPricingPolicyAsOf: String
        $inPlannedCpDoorLrIncrOn: String
        $inPlannedCpDoorLrIncrOf: BigFloat
        $inGridPricingCeiling: BigFloat
        $inGridPricingFloor: BigFloat
        $inAcceptFactoryCoupons: String
        $inFactAiPgmAsOf: String
        $inFactAiPgmExpiresOn: String
        $inIsGridPricingAbvDr: String
        $inIsInFactAiOrCpiPgm: String
        $inLwsComponentData: JSON!
        $inUpdatedBy: String!
        $inType: String!
        $inDefaultBasisBundle: String
      ) {
        updateLwsForStore(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inCustStatedCpDoorLr: $inCustStatedCpDoorLr
            inPrevCpDoorLr: $inPrevCpDoorLr
            inCustStatedWarrLr: $inCustStatedWarrLr
            inCurrPricingPolicyAsOf: $inCurrPricingPolicyAsOf
            inPlannedCpDoorLrIncrOn: $inPlannedCpDoorLrIncrOn
            inPlannedCpDoorLrIncrOf: $inPlannedCpDoorLrIncrOf
            inGridPricingCeiling: $inGridPricingCeiling
            inGridPricingFloor: $inGridPricingFloor
            inAcceptFactoryCoupons: $inAcceptFactoryCoupons
            inFactAiPgmAsOf: $inFactAiPgmAsOf
            inFactAiPgmExpiresOn: $inFactAiPgmExpiresOn
            inIsGridPricingAbvDr: $inIsGridPricingAbvDr
            inIsInFactAiOrCpiPgm: $inIsInFactAiOrCpiPgm
            inLwsComponentData: $inLwsComponentData
            inUpdatedBy: $inUpdatedBy
            inType: $inType
            inDefaultBasisBundle: $inDefaultBasisBundle
            inIsCreateBasis: true
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle basis parts
     *
     */
    this.allDataBundleTargetLaborsList = gql`
      query getLaborTargetListForDm($inDmId: String!) {
        getLaborTargetListForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              bundleClassification
              bundleVersion
              description
              isActive
              sourceClassification
              sourceVersion
              sourceRetailLaborRate
              targetLaborRate
              discountEffect
              nonDiscCpLrAdj
              expiresOn
              actionDate
              ineffectiveDate
              createdAt
              isBest
              additionalInfo
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all Part Projects for Store
     *
     */
    this.getLaborProjectsForStore = gql`
      query getLaborProjectsForStore($inCompanyId: String!) {
        getLaborProjectsForStore(inCompanyId: $inCompanyId) {
          edges {
            node {
              projectId
              projectName
            }
          }
        }
      }
    `;

    /**
     * Mutation to unlink the project labor
     *
     */
    this.unlinkProjectLaborFromDm = gql`
      mutation unlinkProjectLaborFromDm(
        $inDmId: String!
        $inProjectId: String!
        $inUpdatedBy: String!
      ) {
        unlinkProjectLaborFromDm(
          input: { inDmId: $inDmId, inProjectId: $inProjectId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to link the project labor
     *
     */
    this.linkProjectLaborWithDm = gql`
      mutation linkProjectLaborWithDm(
        $inDmId: String!
        $inStId: String!
        $inProjectId: String!
        $inCreatedBy: String!
      ) {
        linkProjectLaborWithDm(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inProjectId: $inProjectId
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to best DataBundle
     *
     */
    this.createPartsBest = gql`
      mutation createPartsBest(
        $inDmId: String!
        $inDescription: String!
        $inBestMu: BigFloat!
        $inNoOfRo: Int!
        $inCreatedBy: String!
      ) {
        createPartsBest(
          input: {
            inDmId: $inDmId
            inDescription: $inDescription
            inBestMu: $inBestMu
            inNoOfRo: $inNoOfRo
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all Dm Bests
     *
     */
    this.allDmBests = gql`
      query allDmBests($dmId: BigInt!) {
        allDmBests(condition: { dmId: $dmId }) {
          edges {
            node {
              dmId
              partsBestMu
              partsNoOfRo
              partsDescription
              laborBestLr
              laborBestCalcProtocol
              laborNoOfRo
              laborDescription
            }
          }
        }
      }
    `;

    /**
     * Mutation to best DataBundle
     *
     */
    this.updatePartsBest = gql`
      mutation updatePartsBest(
        $inDmId: String!
        $inDescription: String!
        $inBestMu: BigFloat!
        $inNoOfRo: Int!
        $inUpdatedBy: String!
      ) {
        updatePartsBest(
          input: {
            inDmId: $inDmId
            inDescription: $inDescription
            inBestMu: $inBestMu
            inNoOfRo: $inNoOfRo
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to create best DataBundle
     *
     */
    this.createLaborBest = gql`
      mutation createLaborBest(
        $inDmId: String!
        $inDescription: String!
        $inBestLr: BigFloat!
        $inBestCalcProtocol: String!
        $inNoOfRo: Int!
        $inCreatedBy: String!
      ) {
        createLaborBest(
          input: {
            inDmId: $inDmId
            inDescription: $inDescription
            inBestLr: $inBestLr
            inBestCalcProtocol: $inBestCalcProtocol
            inNoOfRo: $inNoOfRo
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to update best DataBundle
     *
     */
    this.updateLaborBest = gql`
      mutation updateLaborBest(
        $inDmId: String!
        $inDescription: String!
        $inBestLr: BigFloat!
        $inBestCalcProtocol: String!
        $inNoOfRo: Int!
        $inUpdatedBy: String!
      ) {
        updateLaborBest(
          input: {
            inDmId: $inDmId
            inDescription: $inDescription
            inBestLr: $inBestLr
            inBestCalcProtocol: $inBestCalcProtocol
            inNoOfRo: $inNoOfRo
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;
    /**
     * Query to fetch Labor PSS data
     *
     */

    this.getLaborPssForStoresAndDm = gql`
      query getLaborPssForStoresAndDm($inStIdList: [String]!, $inDmIdList: [String]!) {
        getLaborPssForStoresAndDm(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              state
              manufacturer
              avgWarrLbrPdiHrsPerMonth
              warrLbrRate
              isUpliftViable
              preAuditLbrRate
              preAuditAddlRate
              preAuditAddlMonthlyProfit
              preAuditAddlAnnualProfit
              preAuditCostToSubmit
              preAuditUpliftShare
              preAuditAnnualUpliftFee
              preAuditTotalAcqCost
              preAuditNetUplift
              preAuditPayback
              preDiscBestRate
              preDiscIncrOvrCurrLr
              preDiscAddlMonthlyProfit
              preDiscAddlAnnualProfit
              postDiscBestRate
              postDiscIncrOvrCurrLr
              postDiscAddlMonthlyProfit
              postDiscAddlAnnualProfit
              oemApprovalDeadline
              postDiscCostToSubmit
              postDiscUpliftShare
              postDiscAnnualUpliftFee
              postDiscTotalProfitAcqCost
              postDiscNetProfitUplift
              postDiscRoiMonths
              apprLaborRate
              apprFaRateImpact
              apprFaAnnualProfitImpact
              apprIncrOvrCurrLr
              apprFaAddlMonthlyProfit
              apprFaAddlAnnualProfit
              apprCostToSubmit
              apprUpliftShare
              apprAnnualUpliftFee
              apprTotalAcqCost
              apprNetUplift
              apprPayback
              clientCommunicationComment
              finalCostToSubmit
              finalUpliftShare
              finalAnnualUpliftFee
              finalTotalAcqCost
              finalNetUplift
              finalPayback
              isRetracted
              retractionType
              isLaborMaintenance
              laborMaintenanceFee
              isSecondaryResolved
            }
          }
        }
      }
    `;

    this.updateSolve360ForDm = gql`
      mutation updatePartsProject(
        $customerStatedWmuType: CustomerStatedWmuType
        $customerStatedWmu: Float
        $projectId: ID!
        $productionActionInput: ProductionActionInput
      ) {
        updatePartsProject(
          input: {
            customerStatedWmuType: $customerStatedWmuType
            customerStatedWmu: $customerStatedWmu
            projectId: $projectId
            productionActionInput: $productionActionInput
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForFsd = gql`
      mutation updatePartsProject(
        $previousYearFsd: Float
        $ytdFsd: Float
        $previous12MonthsFsd: Float
        $fsdCpMu: Float
        $normalizedWmu: Float
        $normalizedFsdProject: Float
        $fsdEffectiveWmu: Float
        $productionActionInput: ProductionActionInput
        $markupBillingBasis: MarkupBillingBasis
        $billingBasisCogs: BillingBasisCogs
        $adjustedOrBillingCogs: Float
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            previousYearFsd: $previousYearFsd
            ytdFsd: $ytdFsd
            previous12MonthsFsd: $previous12MonthsFsd
            fsdCpMu: $fsdCpMu
            normalizedWmu: $normalizedWmu
            normalizedFsdProject: $normalizedFsdProject
            fsdEffectiveWmu: $fsdEffectiveWmu
            productionActionInput: $productionActionInput
            markupBillingBasis: $markupBillingBasis
            billingBasisCogs: $billingBasisCogs
            adjustedOrBillingCogs: $adjustedOrBillingCogs
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForPreAudit = gql`
      mutation updatePartsProject(
        $calculated: Float
        $currentCalculatedWmu: Float
        $customerStatedWmu: Float
        $preAuditMu: Float
        $preAuditBaseMu: Float
        $preAuditPul: Float
        $productionActionInput: ProductionActionInput
        $partsImpactAsOf: Date
        $fsdEffectiveWmu: Float
        $bestMarkup: Float
        $partsRODateRange: String
        $partsRONumericRange: String
        $partsSelectedSize: String
        $partsExtendedDates: String
        $partsExtendedRange: String
        $partsExtendedSize: String
        $projectId: ID!
        $projectPreAuditGeneratedOn: Date
        $partsRangeSelectedOn: Date
        $partsRangeSelectedBy: String
        $partsMappedOn: Date
        $partsMappedBy: String
        $impactFactorsNotes: String
        $partsRangeAssignedOn: Date
        $markupBillingBasis: MarkupBillingBasis
        $billingBasisCogs: BillingBasisCogs
        $adjustedOrBillingCogs: Float
      ) {
        updatePartsProject(
          input: {
            calculated: $calculated
            currentCalculatedWmu: $currentCalculatedWmu
            customerStatedWmu: $customerStatedWmu
            preAuditMu: $preAuditMu
            preAuditBaseMu: $preAuditBaseMu
            preAuditPul: $preAuditPul
            productionActionInput: $productionActionInput
            partsImpactAsOf: $partsImpactAsOf
            fsdEffectiveWmu: $fsdEffectiveWmu
            bestMarkup: $bestMarkup
            partsRODateRange: $partsRODateRange
            partsRONumericRange: $partsRONumericRange
            partsSelectedSize: $partsSelectedSize
            partsExtendedDates: $partsExtendedDates
            partsExtendedRange: $partsExtendedRange
            partsExtendedSize: $partsExtendedSize
            projectId: $projectId
            projectPreAuditGeneratedOn: $projectPreAuditGeneratedOn
            partsRangeSelectedOn: $partsRangeSelectedOn
            partsRangeSelectedBy: $partsRangeSelectedBy
            partsMappedOn: $partsMappedOn
            partsMappedBy: $partsMappedBy
            impactFactorsNotes: $impactFactorsNotes
            partsRangeAssignedOn: $partsRangeAssignedOn
            markupBillingBasis: $markupBillingBasis
            billingBasisCogs: $billingBasisCogs
            adjustedOrBillingCogs: $adjustedOrBillingCogs
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForPostAudit = gql`
      mutation updatePartsProject(
        $calculated: Float
        $currentCalculatedWmu: Float
        $customerStatedWmu: Float
        $preDiscountWmu: Float
        $postDiscountWmu: Float
        $preDiscountPul: Float
        $postDiscountPul: Float
        $productionActionInput: ProductionActionInput
        $partsImpactAsOf: Date
        $fsdEffectiveWmu: Float
        $partsRODateRange: String
        $partsRONumericRange: String
        $partsSelectedSize: String
        $projectId: ID!
        $projectPostAuditGeneratedOn: Date
        $impactFactorsNotes: String
        $markupBillingBasis: MarkupBillingBasis
        $billingBasisCogs: BillingBasisCogs
        $adjustedOrBillingCogs: Float
        $selfChopMu: Float
      ) {
        updatePartsProject(
          input: {
            calculated: $calculated
            currentCalculatedWmu: $currentCalculatedWmu
            customerStatedWmu: $customerStatedWmu
            preDiscountWmu: $preDiscountWmu
            postDiscountWmu: $postDiscountWmu
            preDiscountPul: $preDiscountPul
            postDiscountPul: $postDiscountPul
            productionActionInput: $productionActionInput
            partsImpactAsOf: $partsImpactAsOf
            fsdEffectiveWmu: $fsdEffectiveWmu
            partsRODateRange: $partsRODateRange
            partsRONumericRange: $partsRONumericRange
            partsSelectedSize: $partsSelectedSize
            projectId: $projectId
            projectPostAuditGeneratedOn: $projectPostAuditGeneratedOn
            impactFactorsNotes: $impactFactorsNotes
            markupBillingBasis: $markupBillingBasis
            billingBasisCogs: $billingBasisCogs
            adjustedOrBillingCogs: $adjustedOrBillingCogs
            selfChopMu: $selfChopMu
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForSubmitted = gql`
      mutation updatePartsProject(
        $productionActionInput: ProductionActionInput
        $submittedWmu: Float
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            productionActionInput: $productionActionInput
            submittedWmu: $submittedWmu
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForInitApproved = gql`
      mutation updatePartsProject(
        $productionActionInput: ProductionActionInput
        $initAdditionalWmu: Float
        $initAdditionalWmuPoints: Float
        $initApprovedPul: Float
        $approvedWmu: Float
        $approvedPul: Float
        $additionalMuPoints: Float
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            productionActionInput: $productionActionInput
            initAdditionalWmu: $initAdditionalWmu
            initAdditionalWmuPoints: $initAdditionalWmuPoints
            initApprovedPul: $initApprovedPul
            approvedWmu: $approvedWmu
            approvedPul: $approvedPul
            additionalMuPoints: $additionalMuPoints
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForApproved = gql`
      mutation updatePartsProject(
        $approvedWmu: Float
        $approvedPul: Float
        $productionActionInput: ProductionActionInput
        $additionalMuPoints: Float
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            approvedWmu: $approvedWmu
            approvedPul: $approvedPul
            productionActionInput: $productionActionInput
            additionalMuPoints: $additionalMuPoints
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForRevenue = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForFinalized = gql`
      mutation updatePartsProject(
        $adjustedOrBillingCogs: Float
        $productionActionInput: ProductionActionInput
        $billingBasisCogs: BillingBasisCogs
        $markupBillingBasis: MarkupBillingBasis
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            adjustedOrBillingCogs: $adjustedOrBillingCogs
            productionActionInput: $productionActionInput
            billingBasisCogs: $billingBasisCogs
            markupBillingBasis: $markupBillingBasis
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborFinalized = gql`
      mutation updateLaborProject(
        $laborHoursBillingBasis: Float
        $productionActionInput: ProductionActionInput
        $billingBasisLaborHrs: BillingBasisLaborHrs
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            laborHoursBillingBasis: $laborHoursBillingBasis
            productionActionInput: $productionActionInput
            billingBasisLaborHrs: $billingBasisLaborHrs
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;
    this.updateSolve360ForLws = gql`
      mutation updateLaborProject(
        $previousYearHrsPerMonth: Float
        $ytdHrsPerMonth: Float
        $prev12MonthsHrsPerMonth: Float
        $currentWarrantyLr: Float
        $laborDoorRate: Float
        $currWlrEffOn: Date
        $cpElrLws: Float
        $projectId: ID!
        $productionActionInput: ProductionActionInput
      ) {
        updateLaborProject(
          input: {
            previousYearHrsPerMonth: $previousYearHrsPerMonth
            ytdHrsPerMonth: $ytdHrsPerMonth
            prev12MonthsHrsPerMonth: $prev12MonthsHrsPerMonth
            currentWarrantyLr: $currentWarrantyLr
            laborDoorRate: $laborDoorRate
            currWlrEffOn: $currWlrEffOn
            cpElrLws: $cpElrLws
            projectId: $projectId
            productionActionInput: $productionActionInput
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborPreAudit = gql`
      mutation updateLaborProject(
        $costCalculatedHrsPerMonth: Float
        $currentObservedWarrantyLr: Float
        $currentWarrantyLr: Float
        $preAuditLr: Float
        $preAuditBaseLr: Float
        $billingBasisLaborHrs: BillingBasisLaborHrs
        $preAuditLul: Float
        $productionActionInput: ProductionActionInput
        $laborImpactAsOf: Date
        $bestLr: Float
        $statutoryPreAuditLR: Float
        $factoryPreAuditLR: Float
        $bestLrCalculatedProtocol: BestLrCalculatedProtocol
        $laborRODateRange: String
        $laborRONumericRange: String
        $laborSelectedSize: String
        $laborExtendedDates: String
        $laborExtendedRange: String
        $laborExtendedSize: String
        $projectId: ID!
        $laborRangeSelectedOn: Date
        $laborRangeSelectedBy: String
        $laborMappedOn: Date
        $laborMappedBy: String
        $impactFactorsLaborNotes: String
        $laborRangeAssignedOn: Date
        $laborHoursBillingBasis: Float
        $projectPreAuditGeneratedOn: Date
      ) {
        updateLaborProject(
          input: {
            costCalculatedHrsPerMonth: $costCalculatedHrsPerMonth
            currentObservedWarrantyLr: $currentObservedWarrantyLr
            currentWarrantyLr: $currentWarrantyLr
            preAuditLr: $preAuditLr
            preAuditBaseLr: $preAuditBaseLr
            billingBasisLaborHrs: $billingBasisLaborHrs
            preAuditLul: $preAuditLul
            productionActionInput: $productionActionInput
            laborImpactAsOf: $laborImpactAsOf
            bestLr: $bestLr
            statutoryPreAuditLR: $statutoryPreAuditLR
            factoryPreAuditLR: $factoryPreAuditLR
            bestLrCalculatedProtocol: $bestLrCalculatedProtocol
            laborRODateRange: $laborRODateRange
            laborRONumericRange: $laborRONumericRange
            laborSelectedSize: $laborSelectedSize
            laborExtendedDates: $laborExtendedDates
            laborExtendedRange: $laborExtendedRange
            laborExtendedSize: $laborExtendedSize
            projectId: $projectId
            laborRangeSelectedOn: $laborRangeSelectedOn
            laborRangeSelectedBy: $laborRangeSelectedBy
            laborMappedOn: $laborMappedOn
            laborMappedBy: $laborMappedBy
            impactFactorsLaborNotes: $impactFactorsLaborNotes
            laborRangeAssignedOn: $laborRangeAssignedOn
            laborHoursBillingBasis: $laborHoursBillingBasis
            projectPreAuditGeneratedOn: $projectPreAuditGeneratedOn
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborPostAudit = gql`
      mutation updateLaborProject(
        $costCalculatedHrsPerMonth: Float
        $currentObservedWarrantyLr: Float
        $currentWarrantyLr: Float
        $billingBasisLaborHrs: BillingBasisLaborHrs
        $preDiscountLr: Float
        $postDiscountLr: Float
        $preDiscountLul: Float
        $postDiscountLul: Float
        $productionActionInput: ProductionActionInput
        $laborImpactAsOf: Date
        $projectId: ID!
        $impactFactorsLaborNotes: String
        $laborRODateRange: String
        $laborRONumericRange: String
        $laborSelectedSize: String
        $laborHoursBillingBasis: Float
        $selfChopLr: Float
        $wiStyleLr: Float
      ) {
        updateLaborProject(
          input: {
            costCalculatedHrsPerMonth: $costCalculatedHrsPerMonth
            currentObservedWarrantyLr: $currentObservedWarrantyLr
            currentWarrantyLr: $currentWarrantyLr
            billingBasisLaborHrs: $billingBasisLaborHrs
            preDiscountLr: $preDiscountLr
            postDiscountLr: $postDiscountLr
            preDiscountLul: $preDiscountLul
            postDiscountLul: $postDiscountLul
            productionActionInput: $productionActionInput
            laborImpactAsOf: $laborImpactAsOf
            projectId: $projectId
            impactFactorsLaborNotes: $impactFactorsLaborNotes
            laborRODateRange: $laborRODateRange
            laborRONumericRange: $laborRONumericRange
            laborSelectedSize: $laborSelectedSize
            laborHoursBillingBasis: $laborHoursBillingBasis
            selfChopLr: $selfChopLr
            wiStyleLr: $wiStyleLr
          }
        ) {
          id
        }
      }
    `;
    this.updateSolve360ForLaborSubmitted = gql`
      mutation updateLaborProject(
        $productionActionInput: ProductionActionInput
        $submittedLr: Float
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            productionActionInput: $productionActionInput
            submittedLr: $submittedLr
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborInitApproved = gql`
      mutation updateLaborProject(
        $initApprovedLr: Float
        $initApprovedLul: Float
        $initAdditionalLr: Float
        $additionalLR: Float
        $approvedLr: Float
        $approvedLul: Float
        $productionActionInput: ProductionActionInput
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            initApprovedLr: $initApprovedLr
            initApprovedLul: $initApprovedLul
            initAdditionalLr: $initAdditionalLr
            additionalLR: $additionalLR
            approvedLr: $approvedLr
            approvedLul: $approvedLul
            productionActionInput: $productionActionInput
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborApproved = gql`
      mutation updateLaborProject(
        $approvedLr: Float
        $approvedLul: Float
        $productionActionInput: ProductionActionInput
        $additionalLR: Float
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            approvedLr: $approvedLr
            approvedLul: $approvedLul
            productionActionInput: $productionActionInput
            additionalLR: $additionalLR
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborRevenue = gql`
      mutation updateLaborProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updateLaborProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForSyncParts = gql`
      mutation updatePartsProject(
        $customerStatedWmuType: CustomerStatedWmuType
        $customerStatedWmu: Float
        $previousYearFsd: Float
        $ytdFsd: Float
        $previous12MonthsFsd: Float
        $currentCalculatedWmu: Float
        $billingBasisCogs: BillingBasisCogs
        $markupBillingBasis: MarkupBillingBasis
        $fsdCpMu: Float
        $normalizedWmu: Float
        $normalizedFsdProject: Float
        $calculated: Float
        $preAuditMu: Float
        $preAuditBaseMu: Float
        $preAuditPul: Float
        $preDiscountWmu: Float
        $postDiscountWmu: Float
        $preDiscountPul: Float
        $postDiscountPul: Float
        $approvedWmu: Float
        $approvedPul: Float
        $bestMarkup: Float
        $partsImpactAsOf: Date
        $productionActionInput: ProductionActionInput
        $adjustedOrBillingCogs: Float
        $fsdEffectiveWmu: Float
        $additionalMuPoints: Float
        $addInactiveStatus: InactiveStatus
        $removeInactiveStatus: InactiveStatus
        $partsRODateRange: String
        $partsRONumericRange: String
        $partsSelectedSize: String
        $projectId: ID!
        $projectPreAuditGeneratedOn: Date
        $partsRangeSelectedOn: Date
        $partsRangeSelectedBy: String
        $projectPostAuditGeneratedOn: Date
        $partsMappedOn: Date
        $partsMappedBy: String
        $impactFactorsNotes: String
        $partsRangeAssignedOn: Date
        $partsExtendedRange: String
        $partsExtendedSize: String
        $partsExtendedDates: String
        $disputedTagStatus: Boolean
        $initAdditionalWmuPoints: Float
        $initAdditionalWmu: Float
        $initApprovedPul: Float
        $selfChopMu: Float
        $submittedWmu: Float
        $submissionId: String
      ) {
        updatePartsProject(
          input: {
            customerStatedWmuType: $customerStatedWmuType
            customerStatedWmu: $customerStatedWmu
            previousYearFsd: $previousYearFsd
            ytdFsd: $ytdFsd
            previous12MonthsFsd: $previous12MonthsFsd
            currentCalculatedWmu: $currentCalculatedWmu
            billingBasisCogs: $billingBasisCogs
            markupBillingBasis: $markupBillingBasis
            fsdCpMu: $fsdCpMu
            normalizedWmu: $normalizedWmu
            normalizedFsdProject: $normalizedFsdProject
            calculated: $calculated
            preAuditMu: $preAuditMu
            preAuditBaseMu: $preAuditBaseMu
            preAuditPul: $preAuditPul
            preDiscountWmu: $preDiscountWmu
            postDiscountWmu: $postDiscountWmu
            preDiscountPul: $preDiscountPul
            postDiscountPul: $postDiscountPul
            approvedWmu: $approvedWmu
            approvedPul: $approvedPul
            bestMarkup: $bestMarkup
            partsImpactAsOf: $partsImpactAsOf
            productionActionInput: $productionActionInput
            adjustedOrBillingCogs: $adjustedOrBillingCogs
            fsdEffectiveWmu: $fsdEffectiveWmu
            additionalMuPoints: $additionalMuPoints
            addInactiveStatus: $addInactiveStatus
            removeInactiveStatus: $removeInactiveStatus
            partsRODateRange: $partsRODateRange
            partsRONumericRange: $partsRONumericRange
            partsSelectedSize: $partsSelectedSize
            projectId: $projectId
            projectPreAuditGeneratedOn: $projectPreAuditGeneratedOn
            partsRangeSelectedOn: $partsRangeSelectedOn
            partsRangeSelectedBy: $partsRangeSelectedBy
            projectPostAuditGeneratedOn: $projectPostAuditGeneratedOn
            partsMappedOn: $partsMappedOn
            partsMappedBy: $partsMappedBy
            impactFactorsNotes: $impactFactorsNotes
            partsRangeAssignedOn: $partsRangeAssignedOn
            partsExtendedRange: $partsExtendedRange
            partsExtendedSize: $partsExtendedSize
            partsExtendedDates: $partsExtendedDates
            disputedTagStatus: $disputedTagStatus
            initAdditionalWmuPoints: $initAdditionalWmuPoints
            initAdditionalWmu: $initAdditionalWmu
            initApprovedPul: $initApprovedPul
            selfChopMu: $selfChopMu
            submittedWmu: $submittedWmu
            submissionId: $submissionId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForSyncLabor = gql`
      mutation updateLaborProject(
        $previousYearHrsPerMonth: Float
        $ytdHrsPerMonth: Float
        $prev12MonthsHrsPerMonth: Float
        $currentWarrantyLr: Float
        $billingBasisLaborHrs: BillingBasisLaborHrs
        $costCalculatedHrsPerMonth: Float
        $laborHoursBillingBasis: Float
        $currentObservedWarrantyLr: Float
        $preAuditLr: Float
        $preAuditBaseLr: Float
        $preAuditLul: Float
        $preDiscountLr: Float
        $postDiscountLr: Float
        $preDiscountLul: Float
        $postDiscountLul: Float
        $approvedLr: Float
        $approvedLul: Float
        $bestLr: Float
        $bestLrCalculatedProtocol: BestLrCalculatedProtocol
        $laborImpactAsOf: Date
        $laborDoorRate: Float
        $additionalLR: Float
        $statutoryPreAuditLR: Float
        $factoryPreAuditLR: Float
        $productionActionInput: ProductionActionInput
        $addInactiveStatus: InactiveStatus
        $removeInactiveStatus: InactiveStatus
        $laborRODateRange: String
        $laborRONumericRange: String
        $laborSelectedSize: String
        $projectId: ID!
        $laborRangeSelectedOn: Date
        $laborRangeSelectedBy: String
        $laborMappedOn: Date
        $laborMappedBy: String
        $impactFactorsLaborNotes: String
        $laborRangeAssignedOn: Date
        $laborExtendedRange: String
        $laborExtendedSize: String
        $laborExtendedDates: String
        $disputedTagStatus: Boolean
        $initApprovedLr: Float
        $initApprovedLul: Float
        $initAdditionalLr: Float
        $currWlrEffOn: Date
        $projectPreAuditGeneratedOn: Date
        $selfChopLr: Float
        $wiStyleLr: Float
        $submittedLr: Float
        $submissionId: String
        $cpElrLws: Float
      ) {
        updateLaborProject(
          input: {
            previousYearHrsPerMonth: $previousYearHrsPerMonth
            ytdHrsPerMonth: $ytdHrsPerMonth
            prev12MonthsHrsPerMonth: $prev12MonthsHrsPerMonth
            currentWarrantyLr: $currentWarrantyLr
            billingBasisLaborHrs: $billingBasisLaborHrs
            costCalculatedHrsPerMonth: $costCalculatedHrsPerMonth
            laborHoursBillingBasis: $laborHoursBillingBasis
            currentObservedWarrantyLr: $currentObservedWarrantyLr
            preAuditLr: $preAuditLr
            preAuditBaseLr: $preAuditBaseLr
            preAuditLul: $preAuditLul
            preDiscountLr: $preDiscountLr
            postDiscountLr: $postDiscountLr
            preDiscountLul: $preDiscountLul
            postDiscountLul: $postDiscountLul
            approvedLr: $approvedLr
            approvedLul: $approvedLul
            bestLr: $bestLr
            bestLrCalculatedProtocol: $bestLrCalculatedProtocol
            laborImpactAsOf: $laborImpactAsOf
            laborDoorRate: $laborDoorRate
            additionalLR: $additionalLR
            statutoryPreAuditLR: $statutoryPreAuditLR
            factoryPreAuditLR: $factoryPreAuditLR
            productionActionInput: $productionActionInput
            addInactiveStatus: $addInactiveStatus
            removeInactiveStatus: $removeInactiveStatus
            laborRODateRange: $laborRODateRange
            laborRONumericRange: $laborRONumericRange
            laborSelectedSize: $laborSelectedSize
            projectId: $projectId
            laborRangeSelectedOn: $laborRangeSelectedOn
            laborRangeSelectedBy: $laborRangeSelectedBy
            laborMappedOn: $laborMappedOn
            laborMappedBy: $laborMappedBy
            impactFactorsLaborNotes: $impactFactorsLaborNotes
            laborRangeAssignedOn: $laborRangeAssignedOn
            laborExtendedRange: $laborExtendedRange
            laborExtendedSize: $laborExtendedSize
            laborExtendedDates: $laborExtendedDates
            disputedTagStatus: $disputedTagStatus
            initApprovedLr: $initApprovedLr
            initApprovedLul: $initApprovedLul
            initAdditionalLr: $initAdditionalLr
            currWlrEffOn: $currWlrEffOn
            selfChopLr: $selfChopLr
            wiStyleLr: $wiStyleLr
            submittedLr: $submittedLr
            projectPreAuditGeneratedOn: $projectPreAuditGeneratedOn
            submissionId: $submissionId
            cpElrLws: $cpElrLws
          }
        ) {
          id
        }
      }
    `;
    /**
     * Query to fetch all Manufacturers
     *
     */
    this.allManufacturers = gql`
      query allManufacturers {
        allManufacturers {
          edges {
            node {
              manufacturerId
              manufacturer
              approvedMarkupAverage
              discountedNFactor
              aliases
              abbreviation
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all States
     *
     */
    this.allStates = gql`
      query allStates {
        allStates {
          edges {
            node {
              stateId
              state
              name
              manufacturerReviewDays
            }
          }
        }
      }
    `;
    /**
     * Mutation to create store in Solve360
     *
     */
    this.createSolve360Store = gql`
      mutation createStore(
        $dmsCode: String!
        $assignedToId: Int!
        $testTag: Boolean!
        $storeGroupId: ID!
        $storeName: String!
        $state: String!
        $brandList: String!
        $createdBy: String
        $createdOn: Date
        $modifiedBy: String
        $modifiedOn: Date
        $marketingSource: String!
      ) {
        createStore(
          input: {
            dmsCode: $dmsCode
            assignedToId: $assignedToId
            testTag: $testTag
            storeGroupId: $storeGroupId
            storeName: $storeName
            state: $state
            brandList: $brandList
            createdBy: $createdBy
            createdOn: $createdOn
            modifiedBy: $modifiedBy
            modifiedOn: $modifiedOn
            marketingSource: $marketingSource
          }
        ) {
          id
          status
        }
      }
    `;

    /**
     * Mutation to insert SolveStore to Portal
     *
     */
    this.insertSolveStoreToPortal = gql`
      mutation insertSolveStoreToPortal(
        $inSgId: String!
        $inCompanyIdList: [String]!
        $inCreatedBy: String!
      ) {
        insertSolveStoreToPortal(
          input: { inSgId: $inSgId, inCompanyIdList: $inCompanyIdList, inCreatedBy: $inCreatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to insert Store from Portal
     *
     */
    this.insertStoreFromPortal = gql`
      mutation insertStoreFromPortal(
        $inDmsName: String!
        $inSgId: String!
        $inStName: String!
        $inStateId: String!
        $inManufacturerId: String!
        $inCreatedBy: String!
        $inMarketingSource: String!
      ) {
        insertStoreFromPortal(
          input: {
            inDmsName: $inDmsName
            inSgId: $inSgId
            inStName: $inStName
            inStateId: $inStateId
            inManufacturerId: $inManufacturerId
            inCreatedBy: $inCreatedBy
            inMarketingSource: $inMarketingSource
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to link Store to Solve360
     *
     */
    this.linkStoresToSolve = gql`
      mutation linkStoresToSolve($inStId: String!, $inCompanyId: String!, $inLinkedBy: String!) {
        linkStoresToSolve(
          input: { inStId: $inStId, inCompanyId: $inCompanyId, inLinkedBy: $inLinkedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to create project
     *
     */
    this.createProject = gql`
      mutation createProject(
        $assignedToId: Int!
        $testTag: Boolean!
        $storeId: ID!
        $groupId: ID!
        $projectName: String!
        $contractStatus: ContractStatus!
        $primaryProjectType: ProjectType!
        $laborProjectType: LaborProjectType
        $controlStatute: String!
        $dmsCode: String!
        $brand: String!
        $projectStartDate: Date!
        $isDualProject: Boolean!
        $stage: Stage
        $scenarioKey: String
        $submissionId: String
        $letterRequestedOn: Date
        $letterSource: String
        $formsRequirement: String
        $formsRequestedOn: Date
        $thirdPartyForm: String
        $thirdPartyFormRequested: Date
        $contractDate: Date
        $bookingDate: Date
        $partsRangeAssignedTo: String
        $laborRangeAssignedTo: String
        $thirdPartyFormReceived: Date
        $dlrLtrInHouse: Date
        $formsInHouse: Date
        $impactFactorsNotes: String
        $impactFactorsLaborNotes: String
        $projectNotes: String
        $projectLaborNotes: String
        $laborMaintenance: Boolean
        $createdBy: String
        $createdOn: Date
        $startUpNotes: String
      ) {
        createProject(
          input: {
            assignedToId: $assignedToId
            testTag: $testTag
            storeId: $storeId
            groupId: $groupId
            projectName: $projectName
            contractStatus: $contractStatus
            laborProjectType: $laborProjectType
            primaryProjectType: $primaryProjectType
            controlStatute: $controlStatute
            dmsCode: $dmsCode
            brand: $brand
            projectStartDate: $projectStartDate
            isDualProject: $isDualProject
            stage: $stage
            scenarioKey: $scenarioKey
            submissionId: $submissionId
            letterRequestedOn: $letterRequestedOn
            letterSource: $letterSource
            formsRequirement: $formsRequirement
            formsRequestedOn: $formsRequestedOn
            thirdPartyForm: $thirdPartyForm
            thirdPartyFormRequested: $thirdPartyFormRequested
            contractDate: $contractDate
            bookingDate: $bookingDate
            partsRangeAssignedTo: $partsRangeAssignedTo
            laborRangeAssignedTo: $laborRangeAssignedTo
            thirdPartyFormReceived: $thirdPartyFormReceived
            dlrLtrInHouse: $dlrLtrInHouse
            formsInHouse: $formsInHouse
            impactFactorsNotes: $impactFactorsNotes
            impactFactorsLaborNotes: $impactFactorsLaborNotes
            projectNotes: $projectNotes
            projectLaborNotes: $projectLaborNotes
            laborMaintenance: $laborMaintenance
            createdBy: $createdBy
            createdOn: $createdOn
            startUpNotes: $startUpNotes
          }
        ) {
          id
          status
        }
      }
    `;

    /**
     * Mutation to toggle Target Parts Active Status
     *
     */
    this.toggleTargetPartsActiveStatus = gql`
      mutation toggleTargetPartsActiveStatus($inDataBundleId: String!, $inUpdatedBy: String!) {
        toggleTargetPartsActiveStatus(
          input: { inDataBundleId: $inDataBundleId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to toggle Target Labor Active Status
     *
     */
    this.toggleTargetLaborActiveStatus = gql`
      mutation toggleTargetLaborActiveStatus($inDataBundleId: String!, $inUpdatedBy: String!) {
        toggleTargetLaborActiveStatus(
          input: { inDataBundleId: $inDataBundleId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all Project List
     *
     */
    this.getProjectList = gql`
      query getProjectListForStoreGroup($inSgId: String!) {
        getProjectListForStoreGroup(inSgId: $inSgId)
      }
    `;

    /**
     * Query to fetch all data bundle basis labor
     *
     */
    this.getLaborBasisListForDm = gql`
      query getLaborBasisListForDm($inDmId: String!) {
        getLaborBasisListForDm(inDmId: $inDmId) {
          edges {
            node {
              dataBundleId
              dmId
              stId
              bundleClassification
              bundleVersion
              description
              isPrimary
              isActive
              monthlyWarrantyHours
              monthlyWarrantySale
              initialLaborRate
              sourceClassification
              sourceVersion
              createdAt
              hasHoursOverride
              hasLrOverride
              overrideComment
            }
          }
        }
      }
    `;

    /**
     * Mutation to toggle Basis Parts Active Status
     *
     */
    this.toggleBasisPartsActiveStatus = gql`
      mutation toggleBasisPartsActiveStatus($inDataBundleId: String!, $inUpdatedBy: String!) {
        toggleBasisPartsActiveStatus(
          input: { inDataBundleId: $inDataBundleId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to toggle Basis Parts Active Status
     *
     */
    this.toggleBasisLaborActiveStatus = gql`
      mutation toggleBasisLaborActiveStatus($inDataBundleId: String!, $inUpdatedBy: String!) {
        toggleBasisLaborActiveStatus(
          input: { inDataBundleId: $inDataBundleId, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to finalize Parts Billing Basis
     *
     */
    this.finalizePartsBillingBasis = gql`
      mutation createPartsBasisFromSource(
        $inDmId: String!
        $inSourceId: String!
        $inBasisType: String!
        $inCreatedBy: String!
      ) {
        createPartsBasisFromSource(
          input: {
            inDmId: $inDmId
            inSourceId: $inSourceId
            inBasisType: $inBasisType
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to finalize Labor Billing Basis
     *
     */
    this.finalizeLaborBillingBasis = gql`
      mutation createLaborBasisFromSource(
        $inDmId: String!
        $inSourceId: String!
        $inBasisType: String!
        $inCreatedBy: String!
      ) {
        createLaborBasisFromSource(
          input: {
            inDmId: $inDmId
            inSourceId: $inSourceId
            inBasisType: $inBasisType
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle basis labor
     *
     */
    this.allPartsRevenues = gql`
      query allPartsRevenues($dmId: BigInt!) {
        allPartsRevenues(condition: { dmId: $dmId }) {
          edges {
            node {
              submissionFee
              upliftShare
              upliftShareTerm
              partsBasis
              partsTarget
              submissionFeeCap
              submissionOverrideFee
              haveSplitCommission
              splitCommissionWith
              isNonContingent
            }
          }
        }
      }
    `;

    /**
     * Mutation to update Parts Revenue Data
     *
     */
    this.updatePartsRevenueBaseData = gql`
      mutation updatePartsRevenueBaseData(
        $inDmId: String!
        $inStId: String!
        $inSubmissionFeeCap: BigFloat
        $inSubmissionOverrideFee: BigFloat
        $inUpliftShare: BigFloat!
        $inUpliftShareTerm: BigFloat!
        $inUpdatedBy: String!
        $inHaveSplitCommission: Boolean!
        $inSplitCommissionWith: String
        $inIsNonContingent: Boolean!
      ) {
        updatePartsRevenueBaseData(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inSubmissionFeeCap: $inSubmissionFeeCap
            inSubmissionOverrideFee: $inSubmissionOverrideFee
            inUpliftShare: $inUpliftShare
            inUpliftShareTerm: $inUpliftShareTerm
            inUpdatedBy: $inUpdatedBy
            inHaveSplitCommission: $inHaveSplitCommission
            inSplitCommissionWith: $inSplitCommissionWith
            inIsNonContingent: $inIsNonContingent
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all data bundle basis labor
     *
     */
    this.allLaborRevenues = gql`
      query allLaborRevenues($dmId: BigInt!) {
        allLaborRevenues(condition: { dmId: $dmId }) {
          edges {
            node {
              submissionFee
              upliftShare
              upliftShareTerm
              laborBasis
              laborTarget
              submissionFeeCap
              maintenanceFee
              submissionOverrideFee
              haveSplitCommission
              splitCommissionWith
              isNonContingent
            }
          }
        }
      }
    `;

    /**
     * Mutation to update Parts Revenue Data
     *
     */
    this.updateLaborRevenueBaseData = gql`
      mutation updateLaborRevenueBaseData(
        $inDmId: String!
        $inStId: String!
        $inSubmissionFeeCap: BigFloat
        $inSubmissionOverrideFee: BigFloat
        $inUpliftShare: BigFloat
        $inUpliftShareTerm: BigFloat
        $inUpdatedBy: String!
        $inMaintenanceFee: BigFloat
        $inHaveSplitCommission: Boolean!
        $inSplitCommissionWith: String
        $inIsNonContingent: Boolean!
      ) {
        updateLaborRevenueBaseData(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inSubmissionFeeCap: $inSubmissionFeeCap
            inSubmissionOverrideFee: $inSubmissionOverrideFee
            inUpliftShare: $inUpliftShare
            inUpliftShareTerm: $inUpliftShareTerm
            inUpdatedBy: $inUpdatedBy
            inMaintenanceFee: $inMaintenanceFee
            inHaveSplitCommission: $inHaveSplitCommission
            inSplitCommissionWith: $inSplitCommissionWith
            inIsNonContingent: $inIsNonContingent
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch DM linked part projects
     *
     */
    this.getDmLinkedProjects = gql`
      query getDmLinkedProjects($inDmId: String!) {
        getDmLinkedProjects(inDmId: $inDmId)
      }
    `;

    /**
     * Query to fetch all S360 DMS
     *
     */
    this.allS360Dms = gql`
      query allS360Dms($isPrimaryDms: Boolean!) {
        allS360Dms(condition: { isPrimaryDms: $isPrimaryDms }) {
          edges {
            node {
              name
              aliases
              abbreviation
              position
            }
          }
        }
      }
    `;

    /**
     * Query to fetch Source of Basis
     *
     */
    this.getSourceOfBasis = gql`
      query getSourceOfBasis($inDmId: String!, $inBasisBundleId: String!) {
        getSourceOfBasis(inDmId: $inDmId, inBasisBundleId: $inBasisBundleId)
      }
    `;

    /**
     * Mutation to update Basis part
     *
     */
    this.createBasisFromSource = gql`
      mutation createBasisFromSource(
        $inDmId: String!
        $inStId: String!
        $inBasisBundleId: String!
        $inNewSourceClassification: String!
        $inDescription: String!
        $inCreatedBy: String!
      ) {
        createBasisFromSource(
          input: {
            inDmId: $inDmId
            inStId: $inStId
            inBasisBundleId: $inBasisBundleId
            inNewSourceClassification: $inNewSourceClassification
            inDescription: $inDescription
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all S360 Users
     *
     */
    this.allS360Users = gql`
      query allS360Users {
        allS360Users(condition: { preventLogin: false }) {
          edges {
            node {
              userId
              name
              email
            }
          }
        }
      }
    `;

    /**
     * Mutation to add portal tag in Solve360 object
     *
     */
    this.addPortalTag = gql`
      mutation addPortalTag($objectId: ID!, $type: ObjectType!) {
        addPortalTag(input: { objectId: $objectId, type: $type }) {
          id
          status
        }
      }
    `;

    /**
     * Mutation to add Customer tag in Solve360 object
     *
     */
    this.addCustomerTag = gql`
      mutation addCustomerTag($objectId: ID!, $type: ObjectType!) {
        addCustomerTag(input: { objectId: $objectId, type: $type }) {
          id
          status
        }
      }
    `;

    /**
     * Mutation to retract dm part
     *
     */
    this.retractPartsDm = gql`
      mutation retractPartsDm(
        $inDmId: String!
        $inRetractionType: String!
        $inRetractedBy: String!
        $inProjectId: BigInt!
        $inRetractionNote: String!
        $inRetractedOn: Date!
      ) {
        retractPartsDm(
          input: {
            inDmId: $inDmId
            inRetractionType: $inRetractionType
            inRetractedBy: $inRetractedBy
            inProjectId: $inProjectId
            inRetractionNote: $inRetractionNote
            inRetractedOn: $inRetractedOn
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to retract dm labor
     *
     */
    this.retractLaborDm = gql`
      mutation retractLaborDm(
        $inDmId: String!
        $inRetractionType: String!
        $inRetractedBy: String!
        $inProjectId: BigInt!
        $inRetractionNote: String!
        $inRetractedOn: Date!
      ) {
        retractLaborDm(
          input: {
            inDmId: $inDmId
            inRetractionType: $inRetractionType
            inRetractedBy: $inRetractedBy
            inProjectId: $inProjectId
            inRetractionNote: $inRetractionNote
            inRetractedOn: $inRetractedOn
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to reactivate dm labor
     *
     */
    this.reactivatePartsDm = gql`
      mutation reactivatePartsDm(
        $inDmId: String!
        $inReactivatedBy: String!
        $inProjectId: BigInt!
      ) {
        reactivatePartsDm(
          input: { inDmId: $inDmId, inReactivatedBy: $inReactivatedBy, inProjectId: $inProjectId }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to reactivate dm labor
     *
     */
    this.reactivateLaborDm = gql`
      mutation reactivateLaborDm(
        $inDmId: String!
        $inReactivatedBy: String!
        $inProjectId: BigInt!
      ) {
        reactivateLaborDm(
          input: { inDmId: $inDmId, inReactivatedBy: $inReactivatedBy, inProjectId: $inProjectId }
        ) {
          json
        }
      }
    `;

    this.updateSolve360ForPartRetraction = gql`
      mutation updatePartsProject(
        $addInactiveStatus: InactiveStatus
        $productionActionInput: ProductionActionInput
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            addInactiveStatus: $addInactiveStatus
            productionActionInput: $productionActionInput
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborRetraction = gql`
      mutation updateLaborProject(
        $addInactiveStatus: InactiveStatus
        $productionActionInput: ProductionActionInput
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            addInactiveStatus: $addInactiveStatus
            productionActionInput: $productionActionInput
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForPartReActivate = gql`
      mutation updatePartsProject(
        $removeInactiveStatus: InactiveStatus
        $productionActionInput: ProductionActionInput
        $projectId: ID!
      ) {
        updatePartsProject(
          input: {
            removeInactiveStatus: $removeInactiveStatus
            productionActionInput: $productionActionInput
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborReActivate = gql`
      mutation updateLaborProject(
        $removeInactiveStatus: InactiveStatus
        $productionActionInput: ProductionActionInput
        $projectId: ID!
      ) {
        updateLaborProject(
          input: {
            removeInactiveStatus: $removeInactiveStatus
            productionActionInput: $productionActionInput
            projectId: $projectId
          }
        ) {
          id
        }
      }
    `;

    /**
     * Query to check companyId exist or not in Portal DB
     *
     */
    this.isCompanyIdExistInS360 = gql`
      query allS360CompanyHeaders($companyId: BigInt!) {
        allS360CompanyHeaders(condition: { companyId: $companyId }) {
          totalCount
        }
      }
    `;
    /**
     * Query to fetch all Salesperson details
     *
     */
    this.salesPersonDetails = gql`
      query allSalesPeople {
        allSalesPeople(condition: { isSalesPerson: true }) {
          edges {
            node {
              solveUserId
              spName
              spEmail
              spPhone
              haveSplitCommission
            }
          }
        }
      }
    `;

    /**
     * Query to check ProjectId exist or not in Portal DB
     *
     */
    this.isProjectIdExistInS360 = gql`
      query allS360ProjectBases($projectId: BigInt!) {
        allS360ProjectBases(condition: { projectId: $projectId }) {
          totalCount
        }
      }
    `;

    /**
     * Mutation to receive Parts DataBundle
     *
     */
    this.receiveSubmittedPartsBundle = gql`
      mutation receiveSubmittedPartsBundle(
        $inDmId: String!
        $inSubmittedId: String!
        $inReceivedDate: Date!
        $inReceivedBy: String!
      ) {
        receiveSubmittedPartsBundle(
          input: {
            inDmId: $inDmId
            inSubmittedId: $inSubmittedId
            inReceivedDate: $inReceivedDate
            inReceivedBy: $inReceivedBy
          }
        ) {
          json
        }
      }
    `;
    /**
     * Mutation to receive Labor DataBundle
     *
     */
    this.receiveSubmittedLaborBundle = gql`
      mutation receiveSubmittedLaborBundle(
        $inDmId: String!
        $inSubmittedId: String!
        $inReceivedDate: Date!
        $inReceivedBy: String!
      ) {
        receiveSubmittedLaborBundle(
          input: {
            inDmId: $inDmId
            inSubmittedId: $inSubmittedId
            inReceivedDate: $inReceivedDate
            inReceivedBy: $inReceivedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to update Parts Override basis
     *
     */
    this.overridePartsBasis = gql`
      mutation overridePartsBasis(
        $inDmId: String!
        $inBasisId: String!
        $inWarrMu: BigFloat!
        $inWarrCost: BigFloat!
        $inOverrideComment: String!
        $inCreatedBy: String!
      ) {
        overridePartsBasis(
          input: {
            inDmId: $inDmId
            inBasisId: $inBasisId
            inWarrMu: $inWarrMu
            inWarrCost: $inWarrCost
            inOverrideComment: $inOverrideComment
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to update Labor Override basis
     *
     */
    this.overrideLaborBasis = gql`
      mutation overrideLaborBasis(
        $inDmId: String!
        $inBasisId: String!
        $inWarrLr: BigFloat!
        $inWarrHours: BigFloat!
        $inOverrideComment: String!
        $inCreatedBy: String!
      ) {
        overrideLaborBasis(
          input: {
            inDmId: $inDmId
            inBasisId: $inBasisId
            inWarrLr: $inWarrLr
            inWarrHours: $inWarrHours
            inOverrideComment: $inOverrideComment
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch all S360 Objects
     *
     */
    this.allS360ProjectObj = gql`
      query getProjectById($ProjectId: ID!) {
        getProjectById(ProjectId: $ProjectId) {
          projectId
          projectName
          contractStatus
          primaryProjectType
          laborProjectType
          controlStatute
          brand
          projectStartDate
          assignedToId
          calculated
          previousYearFsd
          ytdFsd
          previous12MonthsFsd
          adjustedOrBillingCogs
          customerStatedWmuType
          customerStatedWmu
          currentCalculatedWmu
          fsdEffectiveWmu
          markupBillingBasis
          normalizedWmu
          preAuditMu
          preAuditBaseMu
          postDiscountWmu
          approvedWmu
          preDiscountWmu
          billingBasisCogs
          costCalculatedHrsPerMonth
          previousYearHrsPerMonth
          ytdHrsPerMonth
          prev12MonthsHrsPerMonth
          currentWarrantyLr
          currentObservedWarrantyLr
          preAuditLr
          preAuditBaseLr
          preDiscountLr
          postDiscountLr
          approvedLr
          billingBasisLaborHrs
          laborDoorRate
          shippingDeadline
          shippedOn
          approvalNotifyOn
          approvalEffectiveOn
          billedSubmissionFee
          billedRecurringTerm
          splitCommissionTo
          approvedMonthlyUplift
          billedRecurringFee
          fsdCpMu
          bestMarkup
          normalizedFsdProject
          preAuditPul
          preDiscountPul
          postDiscountPul
          approvedPul
          partsImpactAsOf
          additionalMuPoints
          partsRODateRange
          partsRONumericRange
          factoryInitialResponseOn
          upliftFeePercentage
          laborHoursBillingBasis
          bestLrCalculatedProtocol
          bestLr
          preAuditLul
          preDiscountLul
          postDiscountLul
          approvedLul
          statutoryPreAuditLR
          factoryPreAuditLR
          additionalLR
          laborImpactAsOf
          laborRODateRange
          laborRONumericRange
          addInactiveStatus
          partsSelectedSize
          laborSelectedSize
          submissionRange
          submissionSize
          submissionDates
          factoryDeadline
          deadlineReminder
          partsExtendedRange
          partsExtendedSize
          partsExtendedDates
          laborExtendedRange
          laborExtendedSize
          laborExtendedDates
          projectPreAuditGeneratedOn
          projectPostAuditGeneratedOn
          partsRangeSelectedOn
          trackingId
          laborRangeSelectedOn
          partsRangeSelectedBy
          laborRangeSelectedBy
          finalizedBy
          finalizedOn
          shippedBy
          secondaryProjectShippingDeadline
          partsMappedOn
          partsMappedBy
          laborMappedOn
          laborMappedBy
          projectNotes
          projectLaborNotes
          letterRequestedOn
          letterSource
          formsRequirement
          formsRequestedOn
          thirdPartyForm
          thirdPartyFormRequested
          contractDate
          bookingDate
          partsRangeAssignedTo
          laborRangeAssignedTo
          thirdPartyFormReceived
          dlrLtrInHouse
          formsInHouse
          annualMaintenanceFee
          partsRangeAssignedOn
          laborRangeAssignedOn
          commentLettersComplete
          auditReviewPerformedBy
          auditSentForReviewOnDate
          auditReviewCompletedOnDate
          disputedTagStatus
          initAdditionalWmuPoints
          initAdditionalWmu
          initApprovedPul
          initApprovedLr
          initApprovedLul
          initAdditionalLr
          revenueCode
          maintenanceRevenueCode
          partsAuditedBy
          laborAuditedBy
          firstReferralFeePayee
          sendToAccountingOn
          preAuditConfirmedBy
          preAuditConfirmedOn
          preAuditConfirmerComments
          currWlrEffOn
          startUpNotes
          selfChopMu
          selfChopLr
          wiStyleLr
          submittedWmu
          submittedLr
          retractedOn
        }
      }
    `;

    /**
     * Mutation to add parts client comment
     *
     */
    this.updatePartsClientComment = gql`
      mutation updatePartsClientComment(
        $inDmId: String!
        $inProjectId: BigInt!
        $inClientComment: String
        $inUpdatedBy: String!
        $inIsResolved: Boolean
      ) {
        updatePartsClientComment(
          input: {
            inDmId: $inDmId
            inProjectId: $inProjectId
            inClientComment: $inClientComment
            inUpdatedBy: $inUpdatedBy
            inIsResolved: $inIsResolved
          }
        ) {
          json
        }
      }
    `;
    /**
     * Mutation to add labor client comment
     *
     */
    this.updateLaborClientComment = gql`
      mutation updateLaborClientComment(
        $inDmId: String!
        $inProjectId: BigInt!
        $inClientComment: String
        $inUpdatedBy: String!
        $inIsResolved: Boolean
      ) {
        updateLaborClientComment(
          input: {
            inDmId: $inDmId
            inProjectId: $inProjectId
            inClientComment: $inClientComment
            inUpdatedBy: $inUpdatedBy
            inIsResolved: $inIsResolved
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to create Parts Target From Source
     *
     */
    this.createPartsTargetFromSource = gql`
      mutation createPartsTargetFromSource(
        $inDmId: String!
        $inSourceId: String!
        $inCreatedBy: String!
      ) {
        createPartsTargetFromSource(
          input: { inDmId: $inDmId, inSourceId: $inSourceId, inCreatedBy: $inCreatedBy }
        ) {
          json
        }
      }
    `;

    this.updateSolve360ForProductionSnapshot = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.saveProjectNote = gql`
      mutation saveProjectNote(
        $inProjectId: BigInt!
        $inProjectType: String
        $inNoteType: String
        $inNote: String
        $inCreatedBy: String
        $inAdditionalInfo: JSON
      ) {
        saveProjectNote(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inNoteType: $inNoteType
            inNote: $inNote
            inAdditionalInfo: $inAdditionalInfo
            inCreatedBy: $inCreatedBy
          }
        ) {
          json
        }
      }
    `;
    /**
     * Query to fetch Pre audit labor report data
     *
     */
    this.getPreAuditLaborReportData = gql`
      query getPreAuditLaborForStoresAndDm($inStIdList: [String]!, $inDmIdList: [String]!) {
        getPreAuditLaborForStoresAndDm(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              state
              manufacturer
              warrLbrPdiHrsPerMonth
              warrLbrRate
              isUpliftViable
              bestCurrWarrRate
              laborCurrStage
              addlHourlyRate
              addlMonthlyProfit
              addlAnnualProfit
              costToSubmit
              upliftShare
              totalProfitAcq
              netProfitUpliftFirstYr
              roiMonths
              ongoingProfitUpliftYr2Plus
              profitImpactFiveYear
              profitImpactTenYear
              clientCommunicationComment
            }
          }
        }
      }
    `;

    /**
     * Query to check wheather scenariokey is valid
     *
     */
    this.isValidScenariokey = gql`
      query isValidScenariokey($inScenariokey: String!) {
        isValidScenariokey(inScenariokey: $inScenariokey)
      }
    `;

    /**
     * Query to check wheather inSubmissionUuid is valid
     *
     */
    this.isValidSubmissionUuid = gql`
      query isValidSubmissionUuid($inSubmissionUuid: String!) {
        isValidSubmissionUuid(inSubmissionUuid: $inSubmissionUuid)
      }
    `;

    /**
     * Mutation to mageUpdateMeta
     *
     */
    this.mageUpdateMeta = gql`
      mutation mageUpdateMeta($inScenariokey: String!) {
        mageUpdateMeta(input: { inScenariokey: $inScenariokey }) {
          clientMutationId
        }
      }
    `;

    this.allAuditFiles = gql`
      query allAuditFiles($fileName: String!) {
        allAuditFiles(condition: { fileName: $fileName }) {
          edges {
            node {
              fileTitle
              documentType
              fileContentType
              documentContent
              uploadAt
            }
          }
        }
      }
    `;

    /**
     * mutation to rebuildBuildout
     *
     */
    this.rebuildBuildout = gql`
      mutation rebuildBuildout($inScenariokey: String!) {
        rebuildBuildout(input: { inScenariokey: $inScenariokey }) {
          json
        }
      }
    `;
    this.getSubmissionBuildOverviewParts = gql`
      query getSubmissionBuildOverviewParts($inSubmissionUuid: String!) {
        getSubmissionBuildOverviewParts(inSubmissionUuid: $inSubmissionUuid)
      }
    `;

    this.getSubmissionBuildOverviewLabor = gql`
      query getSubmissionBuildOverviewLabor($inSubmissionUuid: String!) {
        getSubmissionBuildOverviewLabor(inSubmissionUuid: $inSubmissionUuid)
      }
    `;
    /**
     * mutation to generateImpactData
     *
     */
    this.generateImpactData = gql`
      mutation generateImpactData($inScenariokey: String!) {
        generateImpactData(input: { inScenariokey: $inScenariokey }) {
          json
        }
      }
    `;

    /**
     * mutation to generateAuditorData
     *
     */
    this.generateAuditorData = gql`
      mutation generateAuditorData($inScenariokey: String!) {
        generateAuditorData(input: { inScenariokey: $inScenariokey }) {
          json
        }
      }
    `;

    /**
     * Query to check wheather scenariokey is valid
     *
     */
    this.allFilereferences = gql`
      query allFilereferences($frName: String!) {
        allFilereferences(condition: { frName: $frName }) {
          edges {
            node {
              frTitle
              frDocumentdate
              frDocumentcontent
            }
          }
        }
      }
    `;

    this.getDataForPartsImpactReport = gql`
      query getDataForPartsImpactReport($inSubmissionUuid: String!) {
        getDataForPartsImpactReport(inSubmissionUuid: $inSubmissionUuid)
      }
    `;

    this.getDataForLaborImpactReport = gql`
      query getDataForLaborImpactReport($inSubmissionUuid: String!) {
        getDataForLaborImpactReport(inSubmissionUuid: $inSubmissionUuid)
      }
    `;

    this.allSubmissionScenarios = gql`
      query allSubmissionScenarios {
        allSubmissionScenarios {
          edges {
            node {
              submissionUuid
              projectType
              scenariokey
              manufacturer
              stateCode
            }
          }
        }
      }
    `;
    /**
     * buildPartsSubmission
     *
     */
    this.buildPartsSubmission = gql`
      mutation buildPartsSubmission($inSubmissionUuid: String!, $inBuildBy: String) {
        buildPartsSubmission(
          input: { inSubmissionUuid: $inSubmissionUuid, inBuildBy: $inBuildBy }
        ) {
          json
        }
      }
    `;

    /**
     * buildLaborSubmission
     *
     */
    this.buildLaborSubmission = gql`
      mutation buildLaborSubmission($inSubmissionUuid: String!, $inBuildBy: String) {
        buildLaborSubmission(
          input: { inSubmissionUuid: $inSubmissionUuid, inBuildBy: $inBuildBy }
        ) {
          json
        }
      }
    `;
    /**
     * Query to fetch Buildout Time
     *
     */
    this.getBuildoutTime = gql`
      query getBuildoutTime($inScenariokey: String!) {
        getBuildoutTime(inScenariokey: $inScenariokey)
      }
    `;

    /**
     * Query to fetch Scenario Overview
     *
     */
    this.getScenarioOverview = gql`
      query getScenarioOverview($inScenariokey: String!) {
        getScenarioOverview(inScenariokey: $inScenariokey)
      }
    `;
    /**
     * Query to check company exist or not in Portal / Solve360
     *
     */
    this.isCompanyExist = gql`
      query validateCompanyName(
        $inCompanyName: String!
        $inPortal: Boolean!
        $inSolve: Boolean!
        $inObjectType: String
        $inObjectId: String
      ) {
        validateCompanyName(
          inCompanyName: $inCompanyName
          inPortal: $inPortal
          inSolve: $inSolve
          inObjectType: $inObjectType
          inObjectId: $inObjectId
        )
      }
    `;

    /**
     * Query to fetch projection data
     *
     */
    this.getProjectionDataV2 = gql`
      query getProjectionForStoresAndDmV2($inStIdList: [String]!, $inDmIdList: [String]!) {
        getProjectionForStoresAndDmV2(inStIdList: $inStIdList, inDmIdList: $inDmIdList) {
          edges {
            node {
              stId
              stName
              state
              manufacturer
              annualWarrPartsCost
              cpPartsGrossProfit
              effWpGrossProfit
              cpGpMarkup
              effWpGrossProfitMarkup
              cpWarrMarkupDelta
              addlAnnualWpProfit
              stStatuteAif
              projectedWpMarkup
              addlPtsAbvCurrEffWm
              stEntitledMonthlyProfit
              stEntitledAnnlProfit
              factBehaviorFactor
              muIncrNetOfFactBeh
              netProjectedMu
              projectedMonthlyPu
              projectedAnnlProfitUplift
              postApprSubmissionFee
              monthlyUpliftFee
              totalProfitAcqCost
              netWpAddlPuYr1
              paybackPeriod
              alBrandedApprAvg
              clientCommunicationComment
              pricingPolicy
            }
          }
        }
      }
    `;

    this.updateResolveProject = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
          status
        }
      }
    `;

    this.updateMgmtReviewApproval = gql`
      mutation updateMgmtReviewApproval(
        $inDataBundleId: Int!
        $inProjectType: String!
        $inIsMgmtReviewRequested: Boolean
        $inIsMgmtReviewApproved: Boolean
        $inUser: String!
        $inMgmtReviewStatusMsg: JSON!
      ) {
        updateMgmtReviewApproval(
          input: {
            inDataBundleId: $inDataBundleId
            inProjectType: $inProjectType
            inIsMgmtReviewRequested: $inIsMgmtReviewRequested
            inIsMgmtReviewApproved: $inIsMgmtReviewApproved
            inUser: $inUser
            inMgmtReviewStatusMsg: $inMgmtReviewStatusMsg
          }
        ) {
          json
        }
      }
    `;

    /**
     * Query to fetch parts import Report data
     *
     */
    this.getPartsImpactReport = gql`
      query getPartsImpactDataFromPortal($inDmId: String!, $inMageStage: String) {
        getPartsImpactDataFromPortal(inDmId: $inDmId, inMageStage: $inMageStage)
      }
    `;
    /**
     * Query to fetch labour import Report data
     *
     */
    this.getLabourImpactReport = gql`
      query getLaborImpactDataFromPortal($inDmId: String!, $inMageStage: String) {
        getLaborImpactDataFromPortal(inDmId: $inDmId, inMageStage: $inMageStage)
      }
    `;

    /**
     * Query to fetch MAGE Data for import Report
     *
     */
    this.getMageDataImpactReport = gql`
      query getMageDataForImpactReport($inScenariokey: String!) {
        getMageDataForImpactReport(inScenariokey: $inScenariokey)
      }
    `;

    /**
     * Query to fetch all Receivers
     *
     */
    this.allReviewers = gql`
      query allReviewers {
        allReviewers {
          edges {
            node {
              id
              name
              shortName
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all Receivers
     *
     */
    this.allAuditReviewers = gql`
      query allAuditReviewers {
        allAuditReviewers {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `;

    /**
     * Query to fetch all Auditors
     *
     */
    this.allAuditors = gql`
      query allAuditors {
        allAuditors(condition: { isAuditor: true }) {
          edges {
            node {
              id
              name
              email
            }
          }
        }
      }
    `;

    this.updatePartsNetRevenueStatus = gql`
      mutation updatePartsNetRevenueStatus(
        $inDmId: String
        $inNetRevenueStatus: Boolean!
        $inUpdatedBy: String!
      ) {
        updatePartsNetRevenueStatus(
          input: {
            inDmId: $inDmId
            inNetRevenueStatus: $inNetRevenueStatus
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.updateLaborNetRevenueStatus = gql`
      mutation updateLaborNetRevenueStatus(
        $inDmId: String
        $inNetRevenueStatus: Boolean!
        $inUpdatedBy: String!
      ) {
        updateLaborNetRevenueStatus(
          input: {
            inDmId: $inDmId
            inNetRevenueStatus: $inNetRevenueStatus
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;
    /**
     * Query to fetch S360 project details
     *
     */
    this.getS360ProjectObj = gql`
      query getProjectById($ProjectId: ID!) {
        getProjectById(ProjectId: $ProjectId) {
          projectId
          impactFactorsNotes
          projectNotes
          impactFactorsLaborNotes
          projectLaborNotes
        }
      }
    `;
    this.updateSolve360PartsProject = gql`
      mutation updatePartsProject(
        $impactFactorsNotes: String
        $projectId: ID!
        $projectNotes: String
        $productionActionInput: ProductionActionInput
      ) {
        updatePartsProject(
          input: {
            impactFactorsNotes: $impactFactorsNotes
            projectId: $projectId
            projectNotes: $projectNotes
            productionActionInput: $productionActionInput
          }
        ) {
          id
          status
        }
      }
    `;
    this.updateSolve360LaborProject = gql`
      mutation updateLaborProject(
        $impactFactorsLaborNotes: String
        $projectId: ID!
        $projectLaborNotes: String
        $productionActionInput: ProductionActionInput
      ) {
        updateLaborProject(
          input: {
            impactFactorsLaborNotes: $impactFactorsLaborNotes
            projectId: $projectId
            projectLaborNotes: $projectLaborNotes
            productionActionInput: $productionActionInput
          }
        ) {
          id
          status
        }
      }
    `;

    /**
     * Query to fetch all S360 Objects
     *
     */
    this.loadS360ProjectObj = gql`
      query getProjectById($ProjectId: ID!) {
        getProjectById(ProjectId: $ProjectId) {
          projectId
          projectName
          contractStatus
          primaryProjectType
          laborProjectType
          controlStatute
          brand
          projectStartDate
          assignedToId
          letterRequestedOn
          letterSource
          formsRequirement
          formsRequestedOn
          thirdPartyForm
          thirdPartyFormRequested
          contractDate
          bookingDate
          partsRangeAssignedTo
          laborRangeAssignedTo
          dmsCode
          thirdPartyFormReceived
          dlrLtrInHouse
          formsInHouse
          startUpNotes
        }
      }
    `;
    this.updateSolve360ProjectDetailsParts = gql`
      mutation updatePartsProject($projectId: ID!, $productionActionInput: ProductionActionInput) {
        updatePartsProject(
          input: { projectId: $projectId, productionActionInput: $productionActionInput }
        ) {
          id
          status
        }
      }
    `;
    this.updateSolve360ProjectDetailsLabor = gql`
      mutation updateLaborProject($projectId: ID!, $productionActionInput: ProductionActionInput) {
        updateLaborProject(
          input: { projectId: $projectId, productionActionInput: $productionActionInput }
        ) {
          id
          status
        }
      }
    `;

    this.updateImpactNotes = gql`
      mutation updateImpactNotes(
        $inDmId: BigInt!
        $inProjectType: String!
        $inImpactNotes: String!
      ) {
        updateImpactNotes(
          input: { inDmId: $inDmId, inProjectType: $inProjectType, inImpactNotes: $inImpactNotes }
        ) {
          json
        }
      }
    `;
    this.updatePartsProjectContractStatus = gql`
      mutation updatePartsContractStatus(
        $inDmId: String!
        $inIsContracted: Boolean
        $inUpdatedBy: String
      ) {
        updatePartsContractStatus(
          input: { inDmId: $inDmId, inIsContracted: $inIsContracted, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;
    this.updateLaborProjectContractStatus = gql`
      mutation updateLaborContractStatus(
        $inDmId: String!
        $inIsContracted: Boolean
        $inUpdatedBy: String
      ) {
        updateLaborContractStatus(
          input: { inDmId: $inDmId, inIsContracted: $inIsContracted, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;
    this.updateProjectProjectionStatus = gql`
      mutation updateProjectionStatus(
        $inDmId: String!
        $inNoProjection: Boolean
        $inUpdatedBy: String
      ) {
        updateProjectionStatus(
          input: { inDmId: $inDmId, inNoProjection: $inNoProjection, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;
    /**
     * Query for get verification Report
     *
     */

    this.getAuditorVerificationReport = gql`
      query getMageAuditVerificationReport($inScenariokey: String!) {
        getMageAuditVerificationReport(inScenariokey: $inScenariokey)
      }
    `;

    this.updateStoreResolve = gql`
      mutation updateStore(
        $storeId: ID!
        $salesLeadOnDate: Date
        $prospectOnDate: Date
        $deferredOnDate: Date
        $deferredBecause: String
        $plannedFollowUp: Date
        $salesNotes: String
        $modifiedBy: String
        $modifiedOn: Date
        $soldOnDate: String
      ) {
        updateStore(
          input: {
            storeId: $storeId
            salesLeadOnDate: $salesLeadOnDate
            prospectOnDate: $prospectOnDate
            deferredOnDate: $deferredOnDate
            deferredBecause: $deferredBecause
            plannedFollowUp: $plannedFollowUp
            salesNotes: $salesNotes
            modifiedBy: $modifiedBy
            modifiedOn: $modifiedOn
            soldOnDate: $soldOnDate
          }
        ) {
          id
          status
        }
      }
    `;
    this.getMetaGroupsForStoreGroup = gql`
      query getMetaGroupsForStoreGroup($inSgId: String!) {
        getMetaGroupsForStoreGroup(inSgId: $inSgId) {
          edges {
            node {
              metaSgId
              parentSgId
              metaStoreGroupName
              metaStoreGroupName
              manufacturer
              state
              companyId
            }
          }
        }
      }
    `;
    this.updateMetaGroupWithStoreRelation = gql`
      mutation updateMetaGroupWithStoreRelation(
        $metaGroupId: ID!
        $storeId: [String]!
        $type: Boolean!
      ) {
        updateMetaGroupWithStoreRelation(
          input: { metaGroupId: $metaGroupId, storeId: $storeId, type: $type }
        ) {
          id
          status
        }
      }
    `;
    this.uploadFileTOSolve = gql`
      mutation fileUpload($parent: ID!, $fileName: String, $fileContent: String) {
        fileUpload(input: { parent: $parent, fileName: $fileName, fileContent: $fileContent }) {
          id
          status
        }
      }
    `;
    this.updateDmPartsAdditionalData = gql`
      mutation updateDmPartsAdditionalData(
        $inDmId: String!
        $inUpdatedBy: String
        $inAdditionalData: JSON!
      ) {
        updateDmPartsAdditionalData(
          input: { inDmId: $inDmId, inUpdatedBy: $inUpdatedBy, inAdditionalData: $inAdditionalData }
        ) {
          json
        }
      }
    `;
    this.updateDmLaborAdditionalData = gql`
      mutation updateDmLaborAdditionalData(
        $inDmId: String!
        $inUpdatedBy: String
        $inAdditionalData: JSON!
      ) {
        updateDmLaborAdditionalData(
          input: { inDmId: $inDmId, inUpdatedBy: $inUpdatedBy, inAdditionalData: $inAdditionalData }
        ) {
          json
        }
      }
    `;

    this.createScheduledEmail = gql`
      mutation createScheduledEmail(
        $projectId: Int!
        $subject: String
        $body: String
        $scheduledtime: Date!
        $priority: ScheduledEmailPriority!
        $from: String!
        $to: String!
        $cc: String
        $bcc: String
      ) {
        createScheduledEmail(
          input: {
            projectId: $projectId
            subject: $subject
            body: $body
            scheduledtime: $scheduledtime
            priority: $priority
            from: $from
            to: $to
            cc: $cc
            bcc: $bcc
          }
        ) {
          id
          status
        }
      }
    `;

    this.createScheduledAttachmentEmail = gql`
      mutation createScheduledAttachmentEmail($scheduleId: Int!, $file: String!, $caption: String) {
        createScheduledAttachmentEmail(
          input: { scheduleId: $scheduleId, file: $file, caption: $caption }
        ) {
          id
          status
        }
      }
    `;

    this.createEvent = gql`
      mutation createEvent(
        $projectId: ID!
        $eventTitle: String
        $details: String!
        $completed: Int
        $timeStart: Date!
        $timeEnd: Date!
        $location: String
        $eventType: EventType!
        $priority: EventPriority!
        $noticeNow: EventNoticeNow
        $remindTime: Int!
        $attendees: String
        $externalAttendees: String
        $repeats: EventRepeatType
        $repeatEvery: Int
        $repeatUntil: Date
        $repeatInterval: EventPriority
      ) {
        createEvent(
          input: {
            projectId: $projectId
            eventTitle: $eventTitle
            details: $details
            completed: $completed
            timeStart: $timeStart
            timeEnd: $timeEnd
            location: $location
            eventType: $eventType
            priority: $priority
            noticeNow: $noticeNow
            remindTime: $remindTime
            attendees: $attendees
            externalAttendees: $externalAttendees
            repeats: $repeats
            repeatEvery: $repeatEvery
            repeatUntil: $repeatUntil
            repeatInterval: $repeatInterval
          }
        ) {
          id
          status
        }
      }
    `;
    this.deleteEvent = gql`
      mutation removeEvent($actId: Int!) {
        removeEvent(input: { actId: $actId }) {
          id
          status
        }
      }
    `;

    this.updateDataBundleTargetPartsAdditionalInfo = gql`
      mutation updateDataBundleTargetPartsAdditionalInfo(
        $inDataBundleId: String!
        $inUpdatedBy: String
        $inAdditionalInfo: JSON!
      ) {
        updateDataBundleTargetPartsAdditionalInfo(
          input: {
            inDataBundleId: $inDataBundleId
            inUpdatedBy: $inUpdatedBy
            inAdditionalInfo: $inAdditionalInfo
          }
        ) {
          json
        }
      }
    `;
    this.updateDataBundleTargetLaborAdditionalInfo = gql`
      mutation updateDataBundleTargetLaborAdditionalInfo(
        $inDataBundleId: String!
        $inUpdatedBy: String
        $inAdditionalInfo: JSON!
      ) {
        updateDataBundleTargetLaborAdditionalInfo(
          input: {
            inDataBundleId: $inDataBundleId
            inUpdatedBy: $inUpdatedBy
            inAdditionalInfo: $inAdditionalInfo
          }
        ) {
          json
        }
      }
    `;

    this.updatePartsDmDisputedStatus = gql`
      mutation updatePartsDmDisputedStatus(
        $inDmId: String!
        $inIsDisputed: Boolean!
        $inDisputedBy: String
        $inComment: String
      ) {
        updatePartsDmDisputedStatus(
          input: {
            inDmId: $inDmId
            inIsDisputed: $inIsDisputed
            inDisputedBy: $inDisputedBy
            inComment: $inComment
          }
        ) {
          json
        }
      }
    `;

    this.updateLaborDmDisputedStatus = gql`
      mutation updateLaborDmDisputedStatus(
        $inDmId: String!
        $inIsDisputed: Boolean!
        $inDisputedBy: String
        $inComment: String
      ) {
        updateLaborDmDisputedStatus(
          input: {
            inDmId: $inDmId
            inIsDisputed: $inIsDisputed
            inDisputedBy: $inDisputedBy
            inComment: $inComment
          }
        ) {
          json
        }
      }
    `;

    this.addDisputedTag = gql`
      mutation addDisputedTag($projectId: ID!) {
        addDisputedTag(input: { objectId: $projectId }) {
          id
          status
        }
      }
    `;

    this.removeDisputedTag = gql`
      mutation removeDisputedTag($projectId: ID!) {
        removeDisputedTag(input: { objectId: $projectId }) {
          id
          status
        }
      }
    `;

    this.deleteDmVersion = gql`
      mutation deleteDmVersion($inDmId: BigInt!, $inDeletedBy: String!) {
        deleteDmVersion(input: { inDmId: $inDmId, inDeletedBy: $inDeletedBy }) {
          json
        }
      }
    `;

    this.getReferralPayeeForProject = gql`
      query getReferralPayeeForProject($inProjectId: BigInt!) {
        getReferralPayeeForProject(inProjectId: $inProjectId)
      }
    `;

    this.sendPartsProjectForBilling = gql`
      mutation sendPartsProjectForBilling($inDmId: BigInt!, $inDate: Date, $inSentBy: String) {
        sendPartsProjectForBilling(
          input: { inDmId: $inDmId, inDate: $inDate, inSentBy: $inSentBy }
        ) {
          json
        }
      }
    `;

    this.sendLaborProjectForBilling = gql`
      mutation sendLaborProjectForBilling($inDmId: BigInt, $inDate: Date, $inSentBy: String) {
        sendLaborProjectForBilling(
          input: { inDmId: $inDmId, inDate: $inDate, inSentBy: $inSentBy }
        ) {
          json
        }
      }
    `;

    this.getContactsByProjectId = gql`
      query getContactsByProjectId($inProjectId: BigInt!) {
        getContactsByProjectId(inProjectId: $inProjectId)
      }
    `;

    this.getPreauditConfirmationDetails = gql`
      query getPreauditConfirmationDetails($inDmId: BigInt!, $inProjectType: String!) {
        getPreauditConfirmationDetails(inDmId: $inDmId, inProjectType: $inProjectType)
      }
    `;

    this.savePreauditConfirmation = gql`
      mutation savePreauditConfirmation(
        $inDmId: BigInt!
        $inProjectType: String!
        $inConfirmer: String!
        $inConfirmedOn: Date!
        $inComment: String!
        $inUpdatedBy: String!
        $inEvalOverride: Boolean
      ) {
        savePreauditConfirmation(
          input: {
            inDmId: $inDmId
            inProjectType: $inProjectType
            inConfirmer: $inConfirmer
            inConfirmedOn: $inConfirmedOn
            inComment: $inComment
            inUpdatedBy: $inUpdatedBy
            inEvalOverride: $inEvalOverride
          }
        ) {
          json
        }
      }
    `;

    this.updateSolve360ForPartsPreAuditReview = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForLaborPreAuditReview = gql`
      mutation updateLaborProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updateLaborProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.reviewPartsPreauditConfirmation = gql`
      mutation reviewPartsPreauditConfirmation($inDmId: BigInt!, $inUpdatedBy: String!) {
        reviewPartsPreauditConfirmation(input: { inDmId: $inDmId, inUpdatedBy: $inUpdatedBy }) {
          json
        }
      }
    `;

    this.reviewLaborPreauditConfirmation = gql`
      mutation reviewLaborPreauditConfirmation($inDmId: BigInt!, $inUpdatedBy: String!) {
        reviewLaborPreauditConfirmation(input: { inDmId: $inDmId, inUpdatedBy: $inUpdatedBy }) {
          json
        }
      }
    `;

    this.solve360UpdateForManfRequest = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
          status
        }
      }
    `;

    this.solve360UpdateForManfRequestLabor = gql`
      mutation updateLaborProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updateLaborProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
          status
        }
      }
    `;

    this.updateDeadlineNoteProject = gql`
      mutation updatePartsProject($productionActionInput: ProductionActionInput, $projectId: ID!) {
        updatePartsProject(
          input: { productionActionInput: $productionActionInput, projectId: $projectId }
        ) {
          id
        }
      }
    `;

    this.updateInSalesProject = gql`
      mutation updateInSalesProject(
        $inProjectId: BigInt!
        $inProjectType: String!
        $inStageName: String!
        $inIsInSales: Boolean!
        $inUpdatedBy: String
      ) {
        updateInSalesProject(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inIsInSales: $inIsInSales
            inStageName: $inStageName
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.addStageNoteProject = gql`
      mutation saveCourtComment(
        $inDataBundleId: BigInt!
        $inDmId: BigInt!
        $inProjectType: String!
        $inStage: String!
        $inCourtComment: String
        $inUpdatedBy: String
      ) {
        saveCourtComment(
          input: {
            inDataBundleId: $inDataBundleId
            inDmId: $inDmId
            inProjectType: $inProjectType
            inStage: $inStage
            inCourtComment: $inCourtComment
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.allDmStageCourtComments = gql`
      query allDmStageCourtComments($dmId: BigInt!) {
        allDmStageCourtComments(condition: { dmId: $dmId }, orderBy: UPDATED_ON_DESC) {
          edges {
            node {
              projectType
              stage
              courtComment
              dataBundleId
            }
          }
        }
      }
    `;

    this.getPreAuditPostAuditDetails = gql`
      query getPreAuditPostAuditDetails(
        $inProjectId: BigInt!
        $inStageName: String!
        $inProjectType: String!
      ) {
        getPreAuditPostAuditDetails(
          inProjectId: $inProjectId
          inStageName: $inStageName
          inProjectType: $inProjectType
        )
      }
    `;

    this.createSplitOffSecondaryDetails = gql`
      mutation createSplitOffSecondaryDetails(
        $inOldProjectId: BigInt!
        $inNewProjectId: BigInt!
        $inProjectType: String!
        $inDmId: BigInt
        $inUpdatedBy: String
      ) {
        createSplitOffSecondaryDetails(
          input: {
            inOldProjectId: $inOldProjectId
            inNewProjectId: $inNewProjectId
            inProjectType: $inProjectType
            inDmId: $inDmId
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;
    /**
     * Mutation to get Production Stage For Dm
     *
     */
    this.getProductionStageForDm = gql`
      mutation getProductionStageForDm($inDmId: BigInt!, $inProjectType: String!) {
        getProductionStageForDm(input: { inDmId: $inDmId, inProjectType: $inProjectType }) {
          json
        }
      }
    `;

    this.updateShippingConfirmationStatus = gql`
      mutation updateShippingConfirmationStatus(
        $inProjectId: BigInt!
        $inProjectType: String!
        $inStageName: String!
        $inIsReadyToShip: Boolean!
        $inReadyToShipOn: Date
        $inUpdatedBy: String
      ) {
        updateShippingConfirmationStatus(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inStageName: $inStageName
            inIsReadyToShip: $inIsReadyToShip
            inReadyToShipOn: $inReadyToShipOn
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.allAduUsers = gql`
      query allAduUsers {
        allAduUsers(condition: { isActive: true }) {
          edges {
            node {
              phone
              displayName
              shortName
              solveUserName
              userId
              isSalesPerson
              isDataPullUser
              isImportUser
              isMappingUser
              isPreAuditUser
              isPreAuditReviewer
              isBillingMgmtApprover
              isRetrieveRoUser
              isImportRoUser
              isAuditor
              isReviewer
              isFinalizeUser
              isPostAuditReviewer
              isShippingUser
              isRangeByUser
              enableOverride
              email
              groupIdList
            }
          }
        }
      }
    `;

    this.updateRushStatus = gql`
      mutation updateRushStatus(
        $inProjectId: BigInt!
        $inStageName: String!
        $inProjectType: String!
        $inIsRush: Boolean!
        $inUpdatedBy: String
        $inNote: String
      ) {
        updateRushStatus(
          input: {
            inProjectId: $inProjectId
            inStageName: $inStageName
            inProjectType: $inProjectType
            inIsRush: $inIsRush
            inUpdatedBy: $inUpdatedBy
            inNote: $inNote
          }
        ) {
          json
        }
      }
    `;
    /**
     * Mutation to update ProjectInfo events
     *
     */
    this.createProjectInfo = gql`
      mutation createProjectInfo(
        $inProjectId: BigInt!
        $inProjectType: String
        $inStartOn: Date
        $inDms: String
        $inData: JSON
        $inCreatedBy: String
        $inIsRush: Boolean
        $inCurrentDate: Date
        $inIsFutureDated: Boolean
      ) {
        createProjectInfo(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inStartOn: $inStartOn
            inDms: $inDms
            inData: $inData
            inCreatedBy: $inCreatedBy
            inIsRush: $inIsRush
            inCurrentDate: $inCurrentDate
            inIsFutureDated: $inIsFutureDated
          }
        ) {
          json
        }
      }
    `;

    this.updateTargetBundleDetails = gql`
      mutation updateTargetBundleDetails(
        $inDmId: BigInt!
        $inProjectId: BigInt!
        $inProjectType: String
        $inType: String
        $inStageName: String
        $inData: JSON
        $inUpdatedBy: String
      ) {
        updateTargetBundleDetails(
          input: {
            inDmId: $inDmId
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inType: $inType
            inStageName: $inStageName
            inData: $inData
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.getPsStageDetails = gql`
      query getPsStageDetails($inProjectId: BigInt!, $inStageName: String!) {
        getPsStageDetails(inProjectId: $inProjectId, inStageName: $inStageName)
      }
    `;

    this.getPrevStageDetailsForDm = gql`
      query getPrevStageDetailsForDm($inDmId: BigInt!, $inProjectType: String!, $inStage: String!) {
        getPrevStageDetailsForDm(inDmId: $inDmId, inProjectType: $inProjectType, inStage: $inStage)
      }
    `;

    this.getApprovedPartsProjectHistory = gql`
      query getApprovedPartsProjectHistory($inStId: BigInt!) {
        getApprovedPartsProjectHistory(inStId: $inStId)
      }
    `;

    this.getApprovedLaborProjectHistory = gql`
      query getApprovedLaborProjectHistory($inStId: BigInt!) {
        getApprovedLaborProjectHistory(inStId: $inStId)
      }
    `;

    this.getProjectStageHistory = gql`
      query getProjectStageHistory($inProjectId: BigInt!, $inStageName: String!) {
        getProjectStageHistory(inProjectId: $inProjectId, inStageName: $inStageName)
      }
    `;

    this.saveManfRequestDetailsNew = gql`
      mutation saveManfRequestDetailsNew(
        $inDmId: BigInt!
        $inProjectType: String
        $inType: String
        $inManfRequestNote: String
        $inData: JSON
        $inUpdatedBy: String
      ) {
        saveManfRequestDetailsNew(
          input: {
            inDmId: $inDmId
            inProjectType: $inProjectType
            inType: $inType
            inManfRequestNote: $inManfRequestNote
            inData: $inData
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.saveSupplRoRequestDetails = gql`
      mutation saveSupplRoRequestDetails(
        $inDmId: BigInt!
        $inProjectType: String
        $inType: String
        $inData: JSON
        $inUpdatedBy: String
      ) {
        saveSupplRoRequestDetails(
          input: {
            inDmId: $inDmId
            inProjectType: $inProjectType
            inType: $inType
            inData: $inData
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.updatePartsDmDisputeAnalysis = gql`
      mutation updatePartsDmDisputeAnalysis(
        $inDmId: String!
        $inComment: String
        $inDisputeAnalysisOn: Date!
        $inAnalysisBy: String
      ) {
        updatePartsDmDisputeAnalysis(
          input: {
            inDmId: $inDmId
            inComment: $inComment
            inDisputeAnalysisOn: $inDisputeAnalysisOn
            inAnalysisBy: $inAnalysisBy
          }
        ) {
          json
        }
      }
    `;

    this.updateLaborDmDisputeAnalysis = gql`
      mutation updateLaborDmDisputeAnalysis(
        $inDmId: String!
        $inComment: String
        $inDisputeAnalysisOn: Date!
        $inAnalysisBy: String
      ) {
        updateLaborDmDisputeAnalysis(
          input: {
            inDmId: $inDmId
            inComment: $inComment
            inDisputeAnalysisOn: $inDisputeAnalysisOn
            inAnalysisBy: $inAnalysisBy
          }
        ) {
          json
        }
      }
    `;

    this.getStateDetailsByManufacturer = gql`
      query getStateDetailsByManufacturer($inState: String!, $inManufacturer: String!) {
        getStateDetailsByManufacturer(inState: $inState, inManufacturer: $inManufacturer)
      }
    `;

    this.getDmStoreDetailsByProject = gql`
      query getDmStoreDetailsByProject($inProjectId: BigInt!) {
        getDmStoreDetailsByProject(inProjectId: $inProjectId)
      }
    `;

    /**
     * Mutation to approve updatePartsApprovedBundleDetails
     *
     */

    this.updatePartsApprovedBundleDetails = gql`
      mutation updatePartsApprovedBundleDetails(
        $inDmId: String!
        $inApprovedBy: String!
        $inApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        updatePartsApprovedBundleDetails(
          input: {
            inDmId: $inDmId
            inApprovedBy: $inApprovedBy
            inApprovedDate: $inApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    /**
     * Mutation to approve updateLaborApprovedBundleDetails
     *
     */

    this.updateLaborApprovedBundleDetails = gql`
      mutation updateLaborApprovedBundleDetails(
        $inDmId: String!
        $inApprovedBy: String!
        $inApprovedDate: Date!
        $inIneffectiveDate: Date!
      ) {
        updateLaborApprovedBundleDetails(
          input: {
            inDmId: $inDmId
            inApprovedBy: $inApprovedBy
            inApprovedDate: $inApprovedDate
            inIneffectiveDate: $inIneffectiveDate
          }
        ) {
          json
        }
      }
    `;

    this.getBillingProgressProjectsForStoreWithType = gql`
      query getBillingProgressProjectsForStoreWithType($inCompanyId: BigInt!, $inType: String!) {
        getBillingProgressProjectsForStoreWithType(inCompanyId: $inCompanyId, inType: $inType)
      }
    `;

    this.updateAuditSourceOfDataMaster = gql`
      mutation updateAuditSourceOfDataMaster(
        $inDmId: BigInt!
        $inAuditSource: String!
        $inUpdatedBy: String!
      ) {
        updateAuditSourceOfDataMaster(
          input: { inDmId: $inDmId, inAuditSource: $inAuditSource, inUpdatedBy: $inUpdatedBy }
        ) {
          json
        }
      }
    `;

    this.updateProjectInfo = gql`
      mutation updateProjectInfo(
        $inProjectId: BigInt!
        $inStartOn: Date
        $inData: JSON
        $inCreatedBy: String
        $inCurrentDate: Date
        $inIsFutureDated: Boolean
      ) {
        updateProjectInfo(
          input: {
            inProjectId: $inProjectId
            inStartOn: $inStartOn
            inData: $inData
            inCreatedBy: $inCreatedBy
            inCurrentDate: $inCurrentDate
            inIsFutureDated: $inIsFutureDated
          }
        ) {
          json
        }
      }
    `;

    this.updateAuditSourceByProjectDms = gql`
      mutation updateAuditSourceByProjectDms(
        $inProjectId: BigInt!
        $inSubmissionUuid: String
        $inDms: String!
        $inSchedulerId: String
      ) {
        updateAuditSourceByProjectDms(
          input: {
            inProjectId: $inProjectId
            inSubmissionUuid: $inSubmissionUuid
            inDms: $inDms,
            inSchedulerId: $inSchedulerId
          }
        ) {
          json
        }
      }
    `;


    this.getProjectSubmissionForSwitching = gql`
      query getProjectSubmissionForSwitching($inProjectId: BigInt!) {
        getProjectSubmissionForSwitching(inProjectId: $inProjectId)
      }
    `;

    this.updateProjectSubmissionSwitching = gql`
      mutation updateProjectSubmissionSwitching(
        $inProjectId: BigInt!
        $inProjectType: String!
        $inSubmissionUuid: String
        $inUpdatedBy: String!
      ) {
        updateProjectSubmissionSwitching(
          input: {
            inProjectId: $inProjectId
            inProjectType: $inProjectType
            inSubmissionUuid: $inSubmissionUuid
            inUpdatedBy: $inUpdatedBy
          }
        ) {
          json
        }
      }
    `;

    this.updateSolve360ForSubmissionIdParts = gql`
      mutation updatePartsProject(
        $projectId: ID!
        $submissionId: String
        $productionActionInput: ProductionActionInput
      ) {
        updatePartsProject(
          input: {
            projectId: $projectId
            submissionId: $submissionId
            productionActionInput: $productionActionInput
          }
        ) {
          id
        }
      }
    `;

    this.updateSolve360ForSubmissionIdLabor = gql`
      mutation updateLaborProject(
        $projectId: ID!
        $submissionId: String
        $productionActionInput: ProductionActionInput
      ) {
        updateLaborProject(
          input: {
            projectId: $projectId
            submissionId: $submissionId
            productionActionInput: $productionActionInput
          }
        ) {
          id
        }
      }
    `;

  }
}

import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ConstantService } from "../constants/constant.service";
import { MyPercentagePipe } from "../components/formatter/percentage/my-percentage.pipe";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { EventEmitterService } from "./event.emitter.services";
import { FlashMessagesService } from "angular2-flash-messages";
import { SubscriptionConstantService } from "../constants/subscription.constant.service";
import * as moment from "moment-timezone";
import { MsalService } from "@azure/msal-angular";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { debounceTime, map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Subject, takeUntil, Subscription } from "rxjs";

declare let analytics: any;

const readPayload = gql`
  subscription {
    readPayload
  }
`;
const allReleaseDetails = gql`
  query allReleaseDetails {
    allReleaseDetails(condition: { isActive: true }) {
      edges {
        node {
          versionNo
        }
      }
    }
  }
`;

@Injectable()
export class CommonService {
  azureGroup: any[] = [];
  private subscription$ = new Subject();

  uploadFileTOSolve = gql`
    mutation fileUpload($parent: ID!, $fileName: String, $fileContent: String) {
      fileUpload(input: { parent: $parent, fileName: $fileName, fileContent: $fileContent }) {
        id
        status
      }
    }
  `;
  removeSolveFiles = gql`
    mutation removeFiles($files: [Int]!) {
      removeFiles(input: { files: $files }) {
        id
        status
      }
    }
  `;
  fileUploadAttachment = gql`
    mutation fileUploadAttachment($parent: Int!, $fileName: String, $fileContent: String) {
      fileUploadAttachment(
        input: { parent: $parent, fileName: $fileName, fileContent: $fileContent }
      ) {
        id
        status
      }
    }
  `;
  getProductionSnapshotDetails = gql`
    query getProductionSnapshotDetails(
      $inProjectIdList: [String]
      $inUserId: String
      $inGroupList: [String]
    ) {
      getProductionSnapshotDetails(
        inProjectIdList: $inProjectIdList
        inUserId: $inUserId
        inGroupList: $inGroupList
      )
    }
  `;
  constructor(
    private http: HttpClient,
    private authService: MsalService,
    private router: Router,
    private route: ActivatedRoute,
    private constantService: ConstantService,
    private _perpipe: MyPercentagePipe,
    private apollo: Apollo,
    private _EventEmitterService: EventEmitterService,
    private flashMessagesService: FlashMessagesService,
    private _SubscriptionConstantService: SubscriptionConstantService
  ) {}
  todoSubscription: Subscription = new Subscription();
  portalSubscription: Subscription = new Subscription();
  init() {
    const self = this;
    this.todoSubscription = this.apollo
      .use("authclient")
      .subscribe({
        query: readPayload,
      })
      .pipe(takeUntil(this.subscription$))
      .subscribe({
        next: (listdata) => {
          const result: any = listdata;
          const subscribeData = JSON.parse(JSON.parse(result.data.readPayload));
          const action: any = subscribeData.action;
          console.log(":::::::::: SUBSCRIPTION DATA COMMON:::::::::::::", subscribeData);
          if (action === self._SubscriptionConstantService.STORE_CREATION) {
            if (subscribeData.type === this._SubscriptionConstantService.NOTIFICATION_STORE) {
              self._EventEmitterService.dataSubscription.emit(subscribeData);
            } else if (
              subscribeData.type === this._SubscriptionConstantService.NOTIFICATION_GROUP
            ) {
              self._EventEmitterService.dataSubscriptionStoreSearcher.emit(subscribeData);
            } else if (
              subscribeData.type === this._SubscriptionConstantService.NOTIFICATION_SOLO_STORE
            ) {
              self._EventEmitterService.dataSubscriptionSoloStore.emit(subscribeData);
            } else {
              self._EventEmitterService.displayProgress.emit("");
            }
          } else if (action === self._SubscriptionConstantService.PROJECT_CREATION) {
            console.log(":::::::::: SUBSCRIPTION DATA SOLVE360:::::::::::::", subscribeData);

            //  this.getProductionSnapshotListByIdList([subscribeData.result], (projectName) => {
            //     const newSubscribeData = Object.assign({},subscribeData);
            //     newSubscribeData['project_id'] = subscribeData.result;
            //     newSubscribeData['project_name'] = projectName;
            //     newSubscribeData['event_type'] = 'Created';
            //     // self._EventEmitterService.psProjectUpdate.emit(newSubscribeData);
            //     console.log("PS Update new project create from SO:=====>>>",newSubscribeData);
            //   });
            if (
              subscribeData.type === this._SubscriptionConstantService.NOTIFICATION_PROJECT_PARTS
            ) {
              self._EventEmitterService.dataSubscriptionProjectParts.emit(subscribeData);
            } else if (
              subscribeData.type === this._SubscriptionConstantService.NOTIFICATION_PROJECT_LABOR
            ) {
              self._EventEmitterService.dataSubscriptionProjectLabor.emit(subscribeData);
            } else {
              self._EventEmitterService.displayProgress.emit("");
            }
          } else if (action === self._SubscriptionConstantService.PRODUCTION_SNAPSHOT_EVENTS) {
            console.log(":::::::::: SUBSCRIPTION DATA PS:::::::::::::", subscribeData);
            const newSubscribeData = Object.assign({}, subscribeData);
            if (newSubscribeData.type && newSubscribeData.type === "create_project") {
              newSubscribeData["event_type"] = "Created";
            } else {
              newSubscribeData["event_type"] = "Updated";
            }
            // if(environment.envName ==='dev') {
            //     self._EventEmitterService.psProjectUpdate.emit(newSubscribeData);
            // } else {
            //     if(!(newSubscribeData.is_test && newSubscribeData.is_test === true)) {
            self._EventEmitterService.psProjectUpdate.emit(newSubscribeData);
            console.log("PS Update:=====>>>", newSubscribeData);
            //     }
            // }
          } else {
            self._EventEmitterService.displayProgress.emit("");
          }
        },
        error: (err) => {},
        complete: () => {
          console.log("Completed");
        },
      });
  }
  isValidateTokenExpired(error: any) {
    if (error["message"]["name"] != null) {
      if (error["message"]["name"] === "TokenExpiredError") {
        this.router.navigate(["/login"]);
      }
    } else {
      if (error["message"] != null) {
        if (error["message"] === "Error Decoding JWT Token") {
          this.router.navigate(["/login"]);
        }
      }
    }
  }

  silentLogin(callback: any) {
    const account = this.authService.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["user.read", "openid", "profile"],
      account: account,
    };
    this.authService.instance
      .acquireTokenSilent(accessTokenRequest)
      .then((result) => {
        console.log("acquireTokenSilent=========================>>", result);
        const token = result.idToken;
        console.log("Id Token silent=========================>>", token);
        localStorage.setItem("token", token);
        console.log("silent token =====>>>", localStorage.getItem("token"));
        const activityData2 = {
          activityName: "save token",
          activityDescription: "acquireTokenSilent getJwtToken",
          activityData: {
            token: localStorage.getItem("token"),
          },
        };
        this.saveActivity("getJwtToken", activityData2);
        if (callback) {
          callback(true);
        }
      })
      .catch((error) => {
        console.log("acquireTokenSilent Error======>>", error);
        if (callback) {
          callback(false);
        }
      });
  }
  /**
   * function to store user details and jwt token in local storage to keep user logged in between page refreshes
   */

  getJwtToken(callback: any) {
    if (this.authService.instance.getAllAccounts().length > 0) {
      console.log("authenticated true");
      this.silentLogin(callback);
    } else {
      console.log("authenticated false");
      if (callback) {
        callback(false);
      }
    }
  }
  /**
   * function to check user group permissions
   */
  checkGroups(callback: (flag: boolean) => void) {
    const groupList = [
      { menu: "DD", url: this.constantService.DUE_DATE_REPORT_URL },
      { menu: "DD", url: this.constantService.HOME_URL },
      { menu: "GO", url: this.constantService.DM_CONTROL_PANEL_URL },
      { menu: "GS", url: this.constantService.GRP_RESULT_SUMMARY_URL },
      { menu: "PO", url: this.constantService.DASHBOARD_URL },
      { menu: "PS", url: this.constantService.PRODUCTION_SNAPSHOT_URL },
      { menu: "PD", url: this.constantService.PRODUCTION_DASHBOARD_URL },
      { menu: "SO", url: this.constantService.CREATE_DATA_MASTER_URL },
      { menu: "SS", url: this.constantService.STORE_SEARCHER_URL },
      { menu: "UM", url: this.constantService.USERS_URL },
      { menu: "SU", url: this.constantService.SECONDARY_UNRESOLVED_URL },
      { menu: "FP", url: this.constantService.FUTURE_PROJECTS_URL },
      { menu: "EM", url: this.constantService.EXCEPTION_MANAGEMENT_URL },
      { menu: "BH", url: this.constantService.MANAGE_BILLING_ACTIVITY_URL },
      { menu: "AS", url: this.constantService.SWAT_URL },
      { menu: "AS", url: this.constantService.SWAT_URL_ID },
      { menu: "SL", url: this.constantService.SOLVE_SYNC_URL },
    ];

    let flag = false;
    const currentMenuItems = JSON.parse(localStorage.getItem("menuItems") || "");
    console.log("currentMenuItems ====>>>>>", currentMenuItems);
    if (currentMenuItems && currentMenuItems.length > 0) {
      for (let i = 0; i < currentMenuItems.length; i++) {
        for (let j = 0; j < groupList.length; j++) {
          if (currentMenuItems[i].menu === groupList[j].menu) {
            if (currentMenuItems[i].menu === "DO" && this.route.snapshot.url.length == 2) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (this.router.url === groupList[j].url) {
              flag = true;
              this.router.navigate([groupList[j].url]);
              break;
            } else if (
              this.router.url === this.constantService.ADD_RO_ITEM &&
              currentMenuItems[i].menu === "MG" &&
              currentMenuItems[i].weightage >= 3
            ) {
              flag = true;
              this.router.navigate([this.constantService.ADD_RO_ITEM]);
              break;
            } else if (
              this.router.url === this.constantService.EDIT_RO_ITEM &&
              currentMenuItems[i].menu === "MG" &&
              currentMenuItems[i].weightage >= 7
            ) {
              flag = true;
              this.router.navigate([this.constantService.EDIT_RO_ITEM]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "RS" &&
              this.router.url.indexOf(this.constantService.RANGE_SELECTOR_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "GI" &&
              this.router.url.indexOf(this.constantService.INVOICE_GENERATION_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "SO" &&
              this.router.url.indexOf(this.constantService.CREATE_DATA_MASTER_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "SB" &&
              this.router.url.indexOf(this.constantService.CREATE_DATA_MASTER_BETA_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "MG" &&
              this.router.url.indexOf(this.constantService.LIST_RO) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "DD" &&
              this.router.url.indexOf(this.constantService.DUE_DATE_REPORT_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "PD" &&
              this.router.url.indexOf(this.constantService.PRODUCTION_DASHBOARD_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            } else if (
              this.router.url &&
              currentMenuItems[i].menu === "SL" &&
              this.router.url.indexOf(this.constantService.SOLVE_SYNC_URL) > -1
            ) {
              flag = true;
              this.router.navigate([this.router.url]);
              break;
            }
          }
        }
      }
      if (!flag) {
        for (let j = 0; j < groupList.length; j++) {
          if (groupList[j].menu === currentMenuItems[0].menu) {
            this.router.navigate([groupList[j].url]);
          }
        }
      }
      if (callback) {
        callback(flag);
      }
    } else {
      console.log("No currentMenuItems", currentMenuItems);
      this.authService.logout();
      localStorage.removeItem("currentUser");
      localStorage.removeItem("token");
      localStorage.removeItem("menuItems");
      // this.router.navigate(["/login"]);
    }
  }

  /**
   *  function to get user accessible menu list
   */

  getGroups(callback: any) {
    if (localStorage.getItem("menuItems")) {
      const currentMenuItems = JSON.parse(localStorage.getItem("menuItems") || "");
      this.azureGroup = currentMenuItems;
      console.log(" this.azureGroup", this.azureGroup);
      if (!this.azureGroup.length) {
        this.router.navigate(["/access-denied"]);
        return;
      }
      this.getReleaseDetails(() => {
        console.log("Release Version if");
        if (callback) {
          callback(this.azureGroup);
        }
      });
    } else if (localStorage.getItem("token")) {
      const res = this.getMenuItemAuth(environment.domainUrl + "/backend/auth");
      res.subscribe((result) => {
        if (result["data"] && result["data"]["getUserMenuInfo"]) {
          const temp = JSON.parse(result["data"]["getUserMenuInfo"]);
          if (temp) {
            this.azureGroup = [];
            for (let i = 0; i < temp.length; i++) {
              this.azureGroup.push(temp[i]);
            }
          }
        }
        if (this.azureGroup.length > 0) {
          localStorage.setItem("menuItems", JSON.stringify(this.azureGroup));
          this.getReleaseDetails(() => {
            console.log("Release Version else");
            if (callback) {
              callback(this.azureGroup);
            }
          });
        } else {
          this.router.navigate(["/access-denied"]);
        }
      });
    }
  }

  createAuthorizationHeaderSalesApp(headers: HttpHeaders) {
    const token = localStorage.getItem("token");
    headers.append("Authorization", `Bearer ${token}`);
    headers.append("userId", `192753250`);
  }

  getMenuItemAuth(url: string) {
    const token = localStorage.getItem("token");
    const authenticationHeader = new HttpHeaders({
      authorization: token ? `Bearer ${token}` : "",
      'Cache-Control': 'no-cache, no-store'
    });
    return this.http
      .get(url, {
        headers: authenticationHeader,
      })
      .pipe(map((res: any) => res))
      .pipe(
        catchError((error: any) => {
          console.log("errr", error);
          if (error.status === 502) {
            const activityData1 = {
              activityName: "502 Bad Gateway",
              activityDescription: "Current Page: " + this.router.url,
              activityData: error,
            };
            this.saveActivity("DB Bad Gateway", activityData1);
            alert(this.constantService.DATABASE_NOT_ACCESSABLE_MESSAGE);
            this.logoutRedirect();
          } else {
            const activityData1 = {
              activityName: "DB Error",
              activityDescription: "Current Page: " + this.router.url,
              activityData: error,
            };
            this.saveActivity("DB Error", activityData1);
            // alert(JSON.parse(error._body).errors[0].message);
            // this.logoutRedirect();
          }
          this.errorCallback(error, this);
          return throwError(() => error.status);
        })
      );
  }
  /**
   * printMarkUp function handling mark-up formatting
   * @param value
   * @param markuptype
   */
  printMarkUp(value: any, markuptype: any) {
    let markUpValue: any = "";
    if (markuptype) {
      const markupValueInp: any = (value - 1) * 100;
      markUpValue = value ? this._perpipe.transform(markupValueInp.toString()) : null;
    } else {
      markUpValue = value ? parseFloat(value).toFixed(4) : null;
    }
    return markUpValue;
  }
  /**
   * Function for get dollar format
   */
  getDollarFormat(input: any) {
    return "$ ###,##0";
  }
  /**
   * Function for get dollar format with 2 decimal point
   */
  getDollarFormat2DecimalPoint(input: any) {
    return "$ ###,##0.00";
  }

  get2DecimalPointFormat(input: any) {
    return "###,##0.00";
  }

  get4DecimalPointFormat(input: any) {
    return "###,##0.0000";
  }

  getNumberFormat(input: any) {
    return "###,##0";
  }

  isJSON(str: string) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }
  /**
   * Function for return cell property
   */
  getCellPropertyObj(
    fgColor: any,
    bgColor: any,
    rgb: any,
    borderRight: any,
    alignment: any,
    fontProprty: any,
    inlineFontProperty: any
  ) {
    const s: { [k: string]: any } = {};
    const fill: { [k: string]: any } = { patternType: "solid" };
    let font: { [k: string]: any } = {};
    if (!fontProprty) {
      font = {
        name: "Arial, Helvetica, sans-serif",
        sz: inlineFontProperty && inlineFontProperty.sz ? inlineFontProperty.sz : 18,
        bold: inlineFontProperty && inlineFontProperty.bold ? inlineFontProperty.bold : false,
        italic: false,
        underline: false,
      };
      const clr: any = { rgb: rgb };
      font["color"] = clr;
    } else {
      font = fontProprty;
    }
    const c1: any = { rgb: fgColor };
    const c2: any = { rgb: bgColor };
    fill["bgColor"] = c2;
    fill["fgColor"] = c1;
    s["fill"] = fill;
    s["font"] = font;
    if (borderRight) {
      s["border"] = borderRight;
    }
    s["alignment"] = alignment;
    return s;
  }
  getCalenderPropertyObject() {
    return {
      useCurrent: true,
      widgetPositioning: {
        horizontal: "left",
      },
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-arrow-left",
        next: "fa fa-arrow-right",
      },
      format: "MM-DD-YYYY",
    };
  }
  getCalenderPropertyObjectForLeaves() {
    return {
      useCurrent: true,
      widgetPositioning: {
        horizontal: "right",
        vertical: "top",
      },
      icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-arrow-left",
        next: "fa fa-arrow-right",
      },
      format: "MM-DD-YYYY",
      daysOfWeekDisabled: [0, 6],
    };
  }

  ROIMonthCalculation(totalProfitAcqCost: any, factoryAdjustedUplift: any) {
    if (totalProfitAcqCost != null && factoryAdjustedUplift != null) {
      const rioResult = totalProfitAcqCost / (factoryAdjustedUplift / 12);
      let result = "";
      if (rioResult) {
        result = parseFloat(rioResult.toString()).toFixed(2);
      } else {
        result = "0.00";
      }
      return result;
    } else {
      return "0.00";
    }
  }

  payBackPeriodCalculation(totalProfitAcqCost: any, calculatedPayBackPriod: any) {
    // sum(pb period) = sum(total Profit acq cost) / sum(total Profit acq cost / pb period)
    let initialI12MIPaybackPeriodFinal = null;
    initialI12MIPaybackPeriodFinal = totalProfitAcqCost / calculatedPayBackPriod;
    if (initialI12MIPaybackPeriodFinal) {
      initialI12MIPaybackPeriodFinal = parseFloat(
        initialI12MIPaybackPeriodFinal.toString()
      ).toFixed(2);
    } else {
      initialI12MIPaybackPeriodFinal = "0.00";
    }
    return initialI12MIPaybackPeriodFinal;
  }
  /**
   * Function for generating random version number for reports
   */
  randomVersionNumberGenerator(vFormat: any) {
    let r = "",
      ch,
      n;
    for (let i = 0; i < vFormat.length; i++) {
      ch = vFormat.substr(i, 1);
      if (ch === "n") {
        r += parseInt((Math.random() * 10).toString(), 10);
      } else if (ch === "A") {
        r += String.fromCharCode(65 + parseInt((Math.random() * 26).toString(), 10));
      } else if (ch === "e") {
        n = parseInt((Math.random() * 36).toString(), 10);
        if (n > 9) {
          r += String.fromCharCode(55 + n);
        } else {
          r += n;
        }
      } else {
        r += ch;
      }
    }
    return r;
  }

  get2DecimalValue(value: string) {
    return value ? parseFloat(value).toFixed(2) : null;
  }

  cellBackgroundClassSelection(group: any, value: any) {
    const colorRed = "factBehaviorClassRed";
    const colorGreen = "factBehaviorClassGreen";
    const colorWhite = "factBehaviorClassWhite";
    let bgClass = colorGreen;
    if (group === 1) {
      bgClass = colorWhite;
      if (value != null) {
        if (value < 0) {
          bgClass = colorRed;
        } else if (value > 0) {
          bgClass = colorGreen;
        } else {
          bgClass = colorWhite;
        }
      }
    } else if (group === 2) {
      bgClass = colorWhite;
      if (value != null) {
        if (value <= 0) {
          bgClass = colorRed;
        } else {
          bgClass = colorWhite;
        }
      }
    } else if (group === 3) {
      bgClass = colorWhite;
      if (value != null) {
        if (value < -1) {
          bgClass = colorRed;
        } else if (value >= -1 && value <= 1) {
          bgClass = colorWhite;
        } else {
          bgClass = colorGreen;
        }
      }
    } else if (group === 4) {
      bgClass = colorWhite;
      if (value != null) {
        if (value < -1) {
          bgClass = colorRed;
        } else {
          bgClass = colorWhite;
        }
      }
    }
    return bgClass;
  }

  s2ab(s: any) {
    if (typeof ArrayBuffer !== "undefined") {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      // tslint:disable-next-line:no-bitwise
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    } else {
      const buff = new Array(s.length);
      // tslint:disable-next-line:no-bitwise
      for (let i = 0; i !== s.length; ++i) {
        buff[i] = s.charCodeAt(i) & 0xff;
      }
      return buff;
    }
  }

  transformDateFormat(date: any, format: any) {
    let newDate = "";
    if (date && date !== null && date !== "") {
      if (format == "" || format == null) {
        format = "MM-DD-YYYY";
      }
      const d = moment(date);
      newDate = d.tz(moment.tz.guess()).format(format);
    }
    return newDate;
  }

  updateCommonAttribute(updateElement: any) {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser") || "")
      : "";
    let userName = "";
    if (currentUser) {
      userName = currentUser.userPrincipalName;
    }
    updateElement["updatedOn"] = moment().format("YYYY-MM-DD");
    updateElement["updatedBy"] = userName ? userName : this.constantService.SOLVE360_RESET_VALUE;
    return updateElement;
  }

  updateStoreCommonAttribute(updateElement: any) {
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser") || "")
      : "";
    let userName = "";
    if (currentUser) {
      userName = currentUser.userPrincipalName;
    }
    updateElement["modifiedOn"] = moment().format("YYYY-MM-DD");
    updateElement["modifiedBy"] = userName ? userName : this.constantService.SOLVE360_RESET_VALUE;
    return updateElement;
  }
  fileUploadAttachmentToSolve(contentData: any, fileName: any, projectId: any, callback: any) {
    console.log("fileUploadAttachmentToSolve", contentData, fileName, projectId);
    const uploadFile = this.apollo.use("solve360updation").mutate({
      mutation: this.fileUploadAttachment,
      variables: {
        parent: projectId,
        fileName: fileName,
        fileContent: contentData,
      },
    });
    uploadFile.subscribe(
      ({ data }) => {
        const result: any = data;
        if (callback) {
          callback(data);
        }
      },
      (err) => {}
    );
    return uploadFile;
  }
  fileUploadToSolve(contentData: any, fileName: any, projectId: any, callback: any) {
    console.log("fileUploadToSolve started::====>>>", fileName, projectId);
    const resObj = {
      fileName: fileName,
      projectId: projectId,
    };
    const activityData = {
      activityName: "fileUploadToSolve",
      activityDescription: "fileUploadToSolve: started for " + projectId,
      activityData: resObj,
    };
    this.saveActivity("fileUploadToSolve", activityData);
    const uploadFile = this.apollo.use("solve360updation").mutate({
      mutation: this.uploadFileTOSolve,
      variables: {
        parent: projectId,
        fileName: fileName,
        fileContent: contentData,
      },
    });
    uploadFile.subscribe(
      (data) => {
        console.log("fileUploadToSolve completed::====>>>", data, projectId);
        const result: any = JSON.parse(JSON.stringify(data));
        const activityData2 = {
          activityName: "fileUploadToSolve",
          activityDescription: "fileUploadToSolve: completed for " + projectId,
          activityData: data,
        };
        this.saveActivity("fileUploadToSolve", activityData2);
        if (result && result["data"] && result["data"]["fileUpload"] && result["data"]["fileUpload"]["id"]) {
          this.showStatusMessage(`${fileName} uploaded to solve360`, "success");
        } else {
          this.showStatusMessage(`${fileName} upload failed to solve360`, "failure");
        }
        if (callback) {
          if(result && result["data"]){
            callback(result["data"]);
          } else {
            callback(result);
          }
        }
      },
      (err) => {
        const activityData3 = {
          activityName: "fileUploadToSolve",
          activityDescription: "fileUploadToSolve: Error occured for " + projectId,
          activityData: err,
        };
        this.saveActivity("fileUploadToSolve", activityData3);
        this.errorCallback(err, this);
      }
    );
    return uploadFile;
  }
  removeFilesFromSolve(fileRefs: any) {
    const fileRemove = this.apollo.use("solve360updation").mutate({
      mutation: this.removeSolveFiles,
      variables: {
        files: fileRefs,
      },
    });
    fileRemove.subscribe(
      ({ data }) => {
        const result: any = data;
      },
      (err) => {}
    );
    return fileRemove;
  }

  showStatusMessage(message: string, statusType: string) {
    let statusClass;
    if (statusType === "success") {
      statusClass = "flashfade alert-success flash-message";
    } else {
      statusClass = "flashfade alert-danger";
    }
    if (this.flashMessagesService && typeof this.flashMessagesService.show === 'function') {
      this.flashMessagesService.show(message, {
        cssClass: statusClass,
        timeout: 5000,
      });
    } else {
      console.error('FlashMessagesService is not correctly initialized or show method is missing');
    }
  }

  getBrowserInfo() {
    const nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = "" + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    let osName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) {
      osName = "Windows";
    }
    if (navigator.appVersion.indexOf("Mac") != -1) {
      osName = "MacOS";
    }
    if (navigator.appVersion.indexOf("X11") != -1) {
      osName = "UNIX";
    }
    if (navigator.appVersion.indexOf("Linux") != -1) {
      osName = "Linux";
    }

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1) {
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    } else if ((nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(" ")) != -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }
    let screenWidth = null;
    let screenHeight = null;

    if (window && window.screen) {
      screenWidth = window.screen.availWidth;
      screenHeight = window.screen.availHeight;
    }
    const resObj = {
      osName: osName,
      browserName: browserName,
      fullVersion: fullVersion,
      majorVersion: majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent,
      screenWidth: screenWidth,
      screenHeight: screenHeight,
    };
    return resObj;
  }

  getTodayDataDate() {
    const d = moment();
    const newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss:SSS Z");
    return newDate;
  }

  setActivityKey() {
    localStorage.setItem("activityKey", this.getTodayDataDate());
  }

  removeActivityKey() {
    localStorage.removeItem("activityKey");
  }

  saveActivity(activityPage: any, activityObj: any) {
    const browserObject = this.getBrowserInfo();
    let activityKey = null;
    const currentUser = localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser") || "")
      : "";
    let userName = "";
    if (currentUser) {
      if (currentUser.displayName) {
        userName = currentUser.displayName;
      }
    }
    if (localStorage.getItem("activityKey")) {
      activityKey = localStorage.getItem("activityKey");
    }
    const obj: any = {
      activityName: activityObj.activityName ? activityObj.activityName : "",
      activityUser: userName,
      activityTime: moment().format(),
      activityKey: activityKey,
      activityDescription: activityObj.activityDescription ? activityObj.activityDescription : "",
      activityPage: activityPage ? activityPage : "",
      activityData: activityObj.activityData ? activityObj.activityData : "",
      activityDb: activityObj.activityDb ? activityObj.activityDb : "",
      activityFunction: activityObj.activityFunction ? activityObj.activityFunction : "",
      browserInfo: browserObject,
      releaseVerion: this.constantService.releaseVerion,
    };
    analytics.page(activityPage);
    if (currentUser && localStorage.getItem("token")) {
      analytics.track("List", obj);
    }
  }

  adminActionPermissionCheck() {
    let weightage = 0;
    if (localStorage.getItem("menuItems")) {
      const currentMenuItems = JSON.parse(localStorage.getItem("menuItems")!);
      if (currentMenuItems) {
        for (let i = 0; i < currentMenuItems.length; i++) {
          if (currentMenuItems[i].menu && currentMenuItems[i].menu === "SO") {
            weightage = currentMenuItems[i].weightage;
            break;
          }
        }
      }
    }
    return weightage;
  }

  transformDateMMDDYYYYHHmmss(date: any) {
    let newDate: any = "";
    if (date && date !== null && date !== "") {
      const d = moment(date);
      newDate = d.tz(moment.tz.guess()).format("MM-DD-YYYY HH:mm:ss");
    }
    return newDate;
  }

  errorCallback(graphQLErrors: any, elm: any) {
    console.log("errorCallback===========>>>>", graphQLErrors);
    const activityData = {
      activityName: "Error occured on DB process",
      activityDescription: "Current Page: " + this.router.url,
      activityData: graphQLErrors,
    };
    this.saveActivity("Error", activityData);
    if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].hasOwnProperty("status")) {
      if (graphQLErrors[0].status === this.constantService.MIDDLEWARE_ERROR_CODE) {
        alert(graphQLErrors[0].message);
      } else if (
        graphQLErrors[0].status === this.constantService.MIDDLEWARE_ERROR_CODE_FOR_LOGOUT
      ) {
        alert(graphQLErrors[0].message);
        this.logoutRedirect();
      } else if (
        graphQLErrors &&
        graphQLErrors[0] &&
        graphQLErrors[0].message &&
        graphQLErrors[0].message.name === "TokenExpiredError"
      ) {
        // alert(graphQLErrors[0].message.message);
        this.logoutRedirect();
      }
    } else if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message) {
      alert(graphQLErrors[0].message);
      // this.logout();
    } else if (graphQLErrors && graphQLErrors.toString().includes("Unexpected token")) {
      alert(this.constantService.DATABASE_NOT_ACCESSABLE_UNEXPECTED_TOKEN_MESSAGE);
      // this.logout();
    } else {
      console.log("else:::", graphQLErrors);
      if (
        graphQLErrors &&
        graphQLErrors.length &&
        graphQLErrors.indexOf("Received status code 400") !== -1
      ) {
        console.log("Error: Network error===>>", graphQLErrors);
        // alert("Sorry, Your Token Expired");
        // this.logoutRedirect();
      } else {
        console.log("elseeeeeeeee===>>", graphQLErrors);
        if (
          graphQLErrors &&
          graphQLErrors.length &&
          graphQLErrors[0] &&
          graphQLErrors[0].message &&
          graphQLErrors[0].message.name === "TokenExpiredError"
        ) {
          // alert(graphQLErrors[0].message.message);
          this.logoutRedirect();
        } else if (
          graphQLErrors &&
          graphQLErrors.status &&
          graphQLErrors.error &&
          graphQLErrors.error.errors &&
          this.isJSON(graphQLErrors.error.errors) &&
          JSON.parse(graphQLErrors.error.errors).message != null &&
          JSON.parse(graphQLErrors.error.errors).message.name == "TokenExpiredError"
        ) {
          this.logoutRedirect();
        } else if (
          graphQLErrors &&
          graphQLErrors.status &&
          graphQLErrors.error &&
          graphQLErrors.error.errors &&
          this.isJSON(graphQLErrors.error.errors) &&
          JSON.parse(graphQLErrors.error.errors).message != null &&
          JSON.parse(graphQLErrors.error.errors).message == "Error Decoding JWT Token"
        ) {
          this.logoutRedirect();
        } else if (graphQLErrors && graphQLErrors.status && graphQLErrors.statusText) {
          console.log("++++++++++2", graphQLErrors);

          alert(graphQLErrors.statusText);
          if (
            graphQLErrors &&
            graphQLErrors.errors &&
            graphQLErrors.errors.length &&
            graphQLErrors.errors[0]["message"]["name"] != null
          ) {
            if (graphQLErrors.errors[0]["message"]["name"] === "TokenExpiredError") {
              this.logoutRedirect();
            }
          } else {
            if (
              graphQLErrors &&
              graphQLErrors.errors &&
              graphQLErrors.errors.length &&
              graphQLErrors.errors[0]["message"] != null
            ) {
              if (graphQLErrors.errors[0]["message"] === "Error Decoding JWT Token") {
                this.logoutRedirect();
              }
            }
          }
        } else {
          console.log("Failed to fetch===>>", graphQLErrors);
          // console.log("graphQLErrors===>>", JSON.parse(graphQLErrors));
          // console.log(
          //   "graphQLErrors.message===>>",
          //   JSON.parse(graphQLErrors).message,
          // );

          if (
            this.isJSON(graphQLErrors) &&
            JSON.parse(graphQLErrors).message != null &&
            JSON.parse(graphQLErrors).message.name == "TokenExpiredError"
          ) {
            // alert(JSON.parse(graphQLErrors).message.message);
            this.logoutRedirect();
          } else if (
            this.isJSON(graphQLErrors) &&
            JSON.parse(graphQLErrors).message != null &&
            JSON.parse(graphQLErrors).message == "Error Decoding JWT Token"
          ) {
            alert(JSON.parse(graphQLErrors).message);
            this.logoutRedirect();
          } else if (
            graphQLErrors.length &&
            graphQLErrors.indexOf("Failed to fetch") === -1 &&
            graphQLErrors.indexOf("Failed to execute") === -1
          ) {
            alert(graphQLErrors);
          }
        }
      }
    }
    if (elm.loading) {
      elm.loading = false;
    }
    if (elm.loadingSkey) {
      elm.loadingSkey = false;
    }
    if (elm.loadingSwap) {
      elm.loadingSwap = false;
    }
    if (elm.loadingInvoice) {
      elm.loadingInvoice = false;
    }
    if (elm.mageProcessLoading) {
      elm.mageProcessLoading = false;
    }
    if (elm.impactDataLoading) {
      elm.impactDataLoading = false;
    }
    if (elm.auditorDataLoading) {
      elm.auditorDataLoading = false;
    }
    if (elm.preauditReviewUpdateProcess) {
      elm.preauditReviewUpdateProcess = false;
    }
    if (elm.preauditReviewConfirmProcess) {
      elm.preauditReviewConfirmProcess = false;
    }
    if (elm.preauditReviewDeclineProcess) {
      elm.preauditReviewDeclineProcess = false;
    }
  }

  logoutRedirect() {
    console.log("LOGOUT REDIRECT...........................");
    // if (localStorage.getItem("token")) {
    console.log("Storage clear......");
    // const previousUrl = localStorage.getItem("previousUrl");
    const previousUrl = "/home";
    // localStorage.clear();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("menuItems");
    localStorage.removeItem("userName");
    //localStorage.removeItem('adminAction');
    if (previousUrl) {
      localStorage.setItem("previousUrl", previousUrl);
    }
    this.authService.loginRedirect();
    // }
  }

  getReleaseDetails(callback: any) {
    const activityData = {
      activityName: " fetch allReleaseDetails",
      activityDescription: "Current Page: " + this.router.url,
    };
    this.saveActivity(this.router.url, activityData);
    const allReleaseDetailsList = this.apollo.use("portaldbClient").query({
      query: allReleaseDetails,
      fetchPolicy: "network-only",
    });
    allReleaseDetailsList.pipe(takeUntil(this.subscription$)).subscribe({
      next: (listdata) => {
        const result: any = listdata;
        const releaseList = result["data"]["allReleaseDetails"]["edges"];
        const latestVersion = releaseList?.length ? releaseList[0].node["versionNo"] : "";
        const currentVersion = this.constantService.releaseVerion;
        const versionMismatch = latestVersion != currentVersion ? true : false;
        localStorage.setItem("versionMismatch", versionMismatch.toString());
        const verObj = {
          currentVersion: currentVersion,
          latestVersion: latestVersion,
          versionMismatch: versionMismatch,
        };
        console.log("Release Version", verObj);
        if (callback) {
          callback(verObj);
        }
        const activityData1 = {
          activityName: " fetch allReleaseDetails successfully",
          activityDescription: "Current Page: " + this.router.url,
        };
        this.saveActivity(this.router.url, activityData1);
      },
      error: (err) => {
        const activityData2 = {
          activityName: "allReleaseDetails error",
          activityDescription: "Current Page: " + this.router.url,
        };
        if (callback) {
          callback(null);
        }
        this.saveActivity(this.router.url, activityData2);
        this.errorCallback(err, this);
      },
      complete: () => {
        console.log("Completed");
      },
    });
  }

  hardRefresh() {
    // Trigger a hard reload similar to Ctrl+Shift+R
    location.reload();
  }

  updateGlobalSolve360Data(data: any, callback: any) {
    const inData = data; // No need to parse, variables is already an object
    const token = localStorage.getItem("token");
    const authenticationHeader = new HttpHeaders({
      authorization: "Bearer " + token,
      "Content-Type": "application/json",
    });
    this.http
      .post(
        environment.redirectUri + "/backend/solve360web/globalSolveUpdate",
        JSON.stringify(inData),
        {
          headers: authenticationHeader,
        }
      )
      .subscribe({
        next: (listdata:any) => {
          console.log("updateGlobalSolve360Data", listdata);
          if(listdata['message'] == 'success'){
            if (callback) {
              callback(true);
            }
          } else {
            if (callback) {
              callback(false);
            }
          }
        },
        error: (err: any) => {
          this.errorCallback(err, this);
          if (callback) {
            callback(false);
          }
        },
        complete: () => {
          console.log("Completed");
        },
      });
  }
}
